import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import s from "./GroupChatMemberListContainer.module.scss";
import { InputSearch } from "../../../../../../UI/molecules/inputSearch/InputSearch";
import { useEffect } from "react";
import { PaginatorWithSearchParams } from "../../../../../../common/paginator/components/PaginatorWithSearchParams";
import LeftArrow from "../../../../../../assets/icons/arrowLeft.svg?react";
import { useAppDispatch, useAppSelector } from "../../../../../../common/state/store";
import {
  getMemberList,
  selectIsOpenMembers,
  selectMemberList,
  selectSearchText,
  setIsOpenMembers,
  setSearchTextMemberList,
} from "./GroupChatMemberListSlice";
import { GroupChatMemberList } from "./components/GroupChatMemberList/GroupChatMemberList";
import { useTranslation } from "react-i18next";
import { MemberListPaginatorModel } from "./GroupChatMemberListModel";
import { selectGroupChatInfo } from "../GroupChatInfo/GroupChatInfoSlice";
import { useSearchParams } from "react-router-dom";
import { MEMBERS_PER_PAGE } from "../../../../../../common/paginator/paginatorSizes";

export const GroupChatMemberListContainer = () => {
  const groupChatInfoTranslation = useTranslation("translation", { keyPrefix: "groupChat.groupChatInfo" }).t;
  const memberList = useAppSelector(selectMemberList);
  const isOpen = useAppSelector(selectIsOpenMembers);
  const searchText = useAppSelector(selectSearchText);
  const dispatch = useAppDispatch();
  const groupChatInfo = useAppSelector(selectGroupChatInfo);
  const [searchParams, setSearchParams] = useSearchParams("");
  const currentPage = Number(searchParams.get("page") ?? 1);

  const setSearchText = (val: string) => {
    dispatch(setSearchTextMemberList(val));
  };

  useEffect(() => {
    if (groupChatInfo?.id && isOpen) {
      dispatch(getMemberList({ page: 1, size: MEMBERS_PER_PAGE, groupId: groupChatInfo?.id, search: searchText }));
    }
  }, [searchText]);

  useEffect(() => {
    if (groupChatInfo?.id && isOpen) {
      dispatch(getMemberList({ page: currentPage, size: MEMBERS_PER_PAGE, groupId: groupChatInfo?.id, search: searchText }));
    }
  }, [currentPage]);

  const memberListPaginator: MemberListPaginatorModel = {
    totalItems: memberList.totalItems,
    currentPage: memberList.currentPage,
    totalPages: memberList.totalPages,
    items: memberList.items || [],
  };

  useEffect(() => {
    if (!isOpen) {
      dispatch(setSearchTextMemberList(""));
      searchParams.delete("page");
      setSearchParams(searchParams);
    }
  }, [isOpen]);

  const closeMemberList = () => {
    dispatch(setIsOpenMembers(false));
  };

  return (
    <Flex className={`${s.container} ${isOpen ? s.open : ""}`}>
      <Flex className={s.top}>
        <Flex gap="8px" align="center">
          <Icon onClick={() => closeMemberList()} cursor="pointer" color="#8592A3" m="4px" as={LeftArrow} boxSize="24px" />
          <Text variant="extraLargeBold">{groupChatInfoTranslation("Members")}</Text>
        </Flex>
        <Box>
          <InputSearch
            placeholder={`${groupChatInfoTranslation("MembersPage.Search by members")}`}
            searchText={searchText}
            setSearchText={setSearchText}
            data-pw="automation-header-search-input"
          />
        </Box>
      </Flex>
      <GroupChatMemberList searchText={searchText} isOpen={isOpen} />
      <PaginatorWithSearchParams
        loadingScope="getMemberList"
        sizeItems={MEMBERS_PER_PAGE}
        paddingLeft="32px"
        paddingRight="32px"
        data={memberListPaginator}
        typeOfPage={"MemberList"}
      />
    </Flex>
  );
};
