import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useGetLoadingState } from "../../common/loading/hooks/useGetLoadingState";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { selectBot, selectCurrentBot, selectIsCurrentBotLoading } from "../sidebar/SidebarSlice";
import BotImageStart from "../../assets/images/botImageStart.svg?react";

import s from "./BotListContainer.module.scss";
import { BotModel } from "./BotListModel";
import { createBotNewFlow, getBotList, selectBotList } from "./BotListSlice";
import { BotListItem } from "./components/BotListItem/BotListItem";
import { PaginatorWithSearchParams } from "../../common/paginator/components/PaginatorWithSearchParams";
import BaseEmptyPage from "../../common/baseEmptyPage/BaseEmptyPage";

export const BotListContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "botList" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;

  const dispatch = useAppDispatch();
  const botList = useAppSelector(selectBotList);
  const [searchParams] = useSearchParams("");
  const currentPage = Number(searchParams.get("page") ?? 1);
  const search = searchParams.get("search") ?? "";
  const currentBot = useAppSelector(selectCurrentBot);
  const isCurrentBotLoading = useAppSelector(selectIsCurrentBotLoading);
  const loadingBotList = useGetLoadingState("getBotList");
  const navigate = useNavigate();
  const location = useLocation();
  const isLocationAddBot = location.pathname === "/addbot" ? true : false;

  useEffect(() => {
    dispatch(getBotList({ page: currentPage, search }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchParams]);

  const createNewFlow = (botInfo: BotModel) => {
    dispatch(createBotNewFlow(botInfo));
  };

  const redirectToBotFlows = (bot: BotModel) => {
    dispatch(selectBot(bot));
    navigate("/automation/flows");
  };

  if ((!currentBot && !isCurrentBotLoading) || isLocationAddBot) {
    return (
      <Box className={s.botListEmptyPageContainer}>
        <BaseEmptyPage
          isAddBot={isLocationAddBot}
          botTitle={!currentBot ? t("It's time to create the first bot!") : t("It's time to create a bot!")}
          botDescription={t("Our simple step-by-step instruction will help you with this")}
          Image={BotImageStart}
        ></BaseEmptyPage>
      </Box>
    );
  }

  if (!botList || loadingBotList || isCurrentBotLoading) {
    return <Box h={"100vh"}></Box>;
  }

  return (
    <>
      <Box className={s.botListContainer}>
        {botList?.items.length ? (
          <>
            <Box className={s.botListContent}>
              <Flex
                className={s.botListColumns}
                justifyContent={"space-between"}
                position={"absolute"}
                width={"100%"}
                height={"52px"}
              >
                <Box>{t("Bot")}</Box>
                <Box>{t("Username")}</Box>
                <Box>{t("Flows")}</Box>
                <Box></Box>
              </Flex>
              <Box
                className={s.itemsBox}
                overflowY={!(botList && !loadingBotList && botList.items.length === 0 && search === "") ? "auto" : "hidden"}
              >
                {botList.items.map((el: BotModel) => {
                  return (
                    <BotListItem
                      currentPage={currentPage}
                      key={el.id}
                      createNewFlow={createNewFlow}
                      redirectToBotFlows={redirectToBotFlows}
                      info={el}
                    />
                  );
                })}
              </Box>
            </Box>
            <PaginatorWithSearchParams sizeItems={10} data={botList} typeOfPage={"BotList"} />
          </>
        ) : (
          <Box className={s.noData}>
            <Box className={s.botListColumns} justifyContent={"space-between"}>
              <Box>{t("Bot")}</Box>
              <Box>{t("Username")}</Box>
              <Box textAlign={"center"}>{t("Flows")}</Box>
              <Box></Box>
            </Box>
            <Box p={"20px 24px"} bg={"white"} borderRadius={"8px"} textAlign={"center"}>
              {ct("No results found")}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
