import React, { CSSProperties } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Flex, Icon } from "@chakra-ui/react";
import DoubleArrowIcon from "../../../assets/icons/doubleArrowIcon.svg?react";
import s from "./SortableItem.module.scss";

export function SortableItem(props: {
  id: string;
  children: React.ReactNode;
  handle: boolean;
  iconStyle?: CSSProperties | undefined;
  isOpenEdit?: boolean;
}) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: "100%",
  };

  if (props.handle) {
    return (
      <Flex
        width={"100%"}
        ref={setNodeRef}
        style={style}
        {...{ ...attributes, tabIndex: -1 }}
        className={`${s.dragHandle} ${isDragging && s.isDragging}
        pos-relative}`}
        data-pw="sortable-item"
      >
        {props.handle && !props.isOpenEdit ? (
          <span className={s.dragHandleContent} {...listeners} style={props.iconStyle} data-pw="drag-handle">
            <Icon boxSize={"20px"} className={isDragging ? s.dragingIcon : ""} as={DoubleArrowIcon} />
          </span>
        ) : null}
        {props.children}
      </Flex>
    );
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {props.children}
    </div>
  );
}
