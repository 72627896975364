import { useEffect } from "react";
import { Box, Text, Button, useDisclosure, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { selectAIAssistantList, getAIAssistantList, clearAIAssistantListState } from "./AIAssistantListSlice";
import { NewAIAssistantModal } from "./components/newAIAssistantModal/NewAIAssistantModal";
import { AIAssistantList } from "./components/AIAssistantList";
import { SkeletonList } from "../../UI/organisms/SkeletonList";
import { useMobileView } from "../layout/LayoutHelper/ResolutionHooks";
import { TariffPlanNameTypeEnum } from "../../common/AppEnums";
import BaseEmptyPage from "../../common/baseEmptyPage/BaseEmptyPage";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { useGetLoadingState } from "../../common/loading/hooks/useGetLoadingState";
import { selectTariffPlanName } from "../../common/tariffPlan/TariffPlanSlice";
import { AppSettings } from "../../common/AppSettings";
import ImageCreateAIAssistant from "../../assets/images/imageCreateAIAssistant.svg?react";
import PlusIcon from "../../assets/icons/plusIconTile.svg?react";
import s from "./AIAssistantListContainer.module.scss";

declare const appSettings: AppSettings;

export const AIAssistantListContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "AI-assistant" });
  const dispatch = useAppDispatch();
  const isMobile = useMobileView();
  const { isOpen: isNewAIAssistantModalOpen, onOpen: onNewAIAssistant, onClose: onNewAIAssistantModalClose } = useDisclosure();
  const loadingAIAssistantList = useGetLoadingState("getAIAssistantList");
  const loadingCreateAIAssistant = useGetLoadingState("createAIAssistant");

  const aiAssistantList = useAppSelector(selectAIAssistantList);

  const tariffPlanName = useAppSelector(selectTariffPlanName);
  const isTariffProOrUnlimited =
    tariffPlanName === TariffPlanNameTypeEnum.Pro || tariffPlanName === TariffPlanNameTypeEnum.Unlimited;
  const isTariffFreeOrTrial =
    tariffPlanName === TariffPlanNameTypeEnum.Free || tariffPlanName === TariffPlanNameTypeEnum.ProTrial;
  const isStarterTariff = tariffPlanName === TariffPlanNameTypeEnum.Starter;
  const canAddAIAssistant = isTariffProOrUnlimited || isStarterTariff;

  const handleNewAIAssistant = () => {
    onNewAIAssistant();
  };
  const handleEditAIAssistantItem = () => {
    onNewAIAssistant();
  };

  useEffect(() => {
    dispatch(getAIAssistantList());
    return () => {
      dispatch(clearAIAssistantListState());
    };
  }, []);

  const onButtonClick = () => {
    if (canAddAIAssistant) {
      onNewAIAssistant();
    } else {
      window.open(appSettings.billingPortalUrl);
    }
  };

  const emptyPageTitle = isTariffFreeOrTrial
    ? t("AI Assistant title")
    : isStarterTariff
    ? t("AI Assistant - now available for you!")
    : t("You don’t have any AI assistants yet");

  const emptyPageAdditionalText = isTariffFreeOrTrial ? (
    <Text variant="medium" className={s.additionalText}>
      {t("Try the AI Assistant with the Starter plan for free or get it forever with the Pro plan")}
    </Text>
  ) : isStarterTariff ? (
    <Text variant="medium" className={s.additionalText}>
      {t("You can try our new AI Assistant completely free on your Starter plan!")}
    </Text>
  ) : (
    <></>
  );

  const emptyPageButtonName = isTariffFreeOrTrial
    ? t("Select plan")
    : isStarterTariff
    ? t("Try the AI Assistant")
    : t("Add Assistant");

  return (
    <>
      <Box className={s.aiAssistantListContainer}>
        {!loadingAIAssistantList && !aiAssistantList?.length ? (
          <Box className={s.emptyPageScroll}>
            <BaseEmptyPage
              botTitle={t("No AI Assistants")}
              botDescription={t("Create or connect an existing bot to add your first AI Assistant.")}
              title={emptyPageTitle}
              description={t("Upload a knowledge base, and the AI will use it to answer your contacts' questions on your behalf")}
              Image={ImageCreateAIAssistant}
            >
              <Flex flexDir="column" gap="8px">
                <Button variant="dominoViolet" className={s.emptyPageButton} onClick={onButtonClick}>
                  {canAddAIAssistant && <PlusIcon />}
                  {emptyPageButtonName}
                </Button>
                {emptyPageAdditionalText}
              </Flex>
            </BaseEmptyPage>
          </Box>
        ) : !aiAssistantList?.length || loadingCreateAIAssistant || loadingAIAssistantList ? (
          <SkeletonList isMobile={isMobile} height="108px" />
        ) : (
          <Box className={s.aiAssistantListContent}>
            <Box className={s.aiAssistantListTitle}>
              <Text className={s.titleText} variant="medium">
                {t("Title")}
              </Text>
              <Text className={s.titleText} variant="medium">
                {t("Description")}
              </Text>
              <Text className={s.titleText} variant="medium">
                {t("Answers File")}
              </Text>
            </Box>
            <AIAssistantList onNewAIAssistant={handleNewAIAssistant} onEditAIAssistantItem={handleEditAIAssistantItem} />
          </Box>
        )}
      </Box>
      <NewAIAssistantModal newAIAssistantState={isNewAIAssistantModalOpen} onClose={onNewAIAssistantModalClose} />
    </>
  );
};
