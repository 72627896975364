import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  ModalFooter,
  Box,
  Textarea,
  Flex,
  Icon,
  useDisclosure,
  Skeleton,
} from "@chakra-ui/react";
import React, { useState, useEffect, DragEvent } from "react";

import { useTranslation } from "react-i18next";
import FileIcon from "../../../../assets/icons/fileIcon.svg?react";
import FileUploadCheck from "../../../../assets/icons/fileUploadCheck.svg?react";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { clearCurrentAIAssistant, createAIAssistant, selectAIAssistant, updateAIAssistant } from "../../AIAssistantListSlice";
import { EditAIAssistantFormModel, NewAIAssistantFormModel } from "../../AIAssistantListModel";
import { DeleteModal } from "../../../../UI/molecules/deleteModal/DeleteModal";
import { notify } from "../../../../common/notifications/NotificationSlice";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { AIAssistantEnum } from "../AIAssistantListEnums";
import s from "./NewAIAssistantModal.module.scss";
interface Props {
  newAIAssistantState: boolean;
  onClose: () => void;
}
export const NewAIAssistantModal = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "AI-assistant" });
  const dispatch = useAppDispatch();
  const confirmDeleteFileModal = useDisclosure();
  const currentAIAssistant = useAppSelector(selectAIAssistant);
  const loadingCurrentAIAssistant = useGetLoadingState("getAIAssistant");
  const [name, setName] = useState("");
  const [aiSourceText, setAiSourceText] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [isDragging, setDragging] = useState(false);
  const [isAddEnabled, setAddEnabled] = useState(true);

  const modalStateClear = () => {
    setName("");
    setAiSourceText("");
    setFile(null);
    setFileName(null);
  };

  const handleAdd = () => {
    if (currentAIAssistant?.name) {
      const editData: EditAIAssistantFormModel = {
        name,
        aiSourceText,
        file,
        id: currentAIAssistant.id,
      };
      dispatch(updateAIAssistant({ updatedAIAssistant: editData, aiAssistantId: currentAIAssistant.id }));
    } else {
      const data: NewAIAssistantFormModel = {
        name,
        aiSourceText,
        file,
      };
      dispatch(createAIAssistant(data));
    }
    props.onClose();
    modalStateClear();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  const onDeleteFile = () => {
    setFile(null);
    setFileName(null);
  };

  useEffect(() => {
    if (currentAIAssistant?.name) {
      setName(currentAIAssistant.name);
    }
    if (currentAIAssistant?.aiSourceText) {
      setAiSourceText(currentAIAssistant.aiSourceText);
    }
    if (currentAIAssistant?.fileName) {
      setFileName(currentAIAssistant.fileName);
    }
  }, [currentAIAssistant]);

  useEffect(() => {
    modalStateClear();
  }, [props.newAIAssistantState]);

  useEffect(() => {
    if (name && (aiSourceText || fileName)) {
      setAddEnabled(false);
    } else {
      setAddEnabled(true);
    }
  }, [name, aiSourceText, fileName]);

  const handleDataChange = (file: File | null, fileType: string, fileSizeB: number) => {
    const extentsion = file?.name.split(".").pop();

    if (!fileType.includes(extentsion ?? "")) {
      dispatch(notify({ message: "This file format isn’t supported", type: "error" }));
      return;
    }
    if (file?.size && file.size > fileSizeB) {
      dispatch(notify({ message: "File is too big", type: "error" }));
      return;
    }
    if (file) {
      setFile(file);
      setFileName(file.name);
      return;
    }
  };

  const onDropFile = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer?.files[0];
    if (file) {
      handleDataChange(file, AIAssistantEnum.fileType, AIAssistantEnum.fileSizeB);
    }
  };

  const handleDrag = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragging(true);
    } else if (e.type === "dragleave" || e.type === "dragend") {
      setDragging(false);
    }
  };

  const handleModalClose = () => {
    props.onClose();
    modalStateClear();
    dispatch(clearCurrentAIAssistant());
  };

  return (
    <>
      <Modal isOpen={props.newAIAssistantState} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent className={s.modalContent} maxW={{ base: "328px", sm: "460px" }}>
          <ModalHeader>
            <Text variant="h1">{t("Support Assistant")}</Text>
          </ModalHeader>
          <ModalCloseButton className={s.modalCloseButton} />
          <ModalBody>
            <FormControl mb={6}>
              <FormLabel variant="preLargeBold">{t("Title")}</FormLabel>
              {loadingCurrentAIAssistant ? (
                <Skeleton startColor="line" endColor="bgLight" borderRadius="8px" height="36px" />
              ) : (
                <Input
                  className={s.inputTextBlock}
                  placeholder={t("Please name your assistant to proceed")}
                  value={name}
                  px="8px"
                  fontSize="15px"
                  onChange={e => setName(e.target.value)}
                  _placeholder={name ? { color: "#232B39" } : { color: "#8592A3" }}
                />
              )}
            </FormControl>
            <FormControl mb={6}>
              <FormLabel variant="preLargeBold">{t("Write and/or upload information for responses")}</FormLabel>
              {loadingCurrentAIAssistant ? (
                <Skeleton startColor="line" endColor="bgLight" borderRadius="8px" height="148px" />
              ) : (
                <Textarea
                  className={s.inputTextBlock}
                  placeholder={`${t("Question: What time do you close?")}\n${t(
                    "Answer: We close at 6:00 PM and on weekdays only",
                  )}\n\n${t("Question: ...")}`}
                  value={aiSourceText}
                  onChange={e => setAiSourceText(e.target.value)}
                  rows={6}
                  px="8px"
                  variant="dominoColor"
                  fontSize="15px"
                  resize="none"
                  _placeholder={aiSourceText ? { color: "#232B39" } : { color: "#8592A3" }}
                />
              )}
            </FormControl>
            <FormControl mb={2}>
              <Flex
                className={s.fileContainer}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={onDropFile}
                position="relative"
              >
                {isDragging && (
                  <Flex className={s.dragFileContainer}>
                    <Text variant="medium" color="darkGrey">
                      {t("Drag the file here")}
                    </Text>
                  </Flex>
                )}
                <Flex boxSize="72px" className={s.fileIconBox}>
                  <Icon color="darkGrey" as={fileName ? FileUploadCheck : FileIcon} boxSize="24px" />
                </Flex>
                <Box>
                  {loadingCurrentAIAssistant ? (
                    <Skeleton startColor="line" endColor="bgLight" borderRadius="8px" height="15px" />
                  ) : (
                    <Box
                      htmlFor="file-upload"
                      as="label"
                      data-pw="select-file-button"
                      cursor={fileName ? "none" : "pointer"}
                      className={s.fileBox}
                    >
                      {fileName ? fileName : t("Select file")}
                    </Box>
                  )}
                  <Input
                    id="file-upload"
                    onChange={handleFileChange}
                    variant="unstyled"
                    type="file"
                    accept=".txt"
                    display="none"
                    isDisabled={!!fileName}
                  />
                  {!fileName ? (
                    <>
                      <Text variant="extraSmall" color="#8592A3">
                        {t("Or drag a file here")}
                      </Text>
                      <Text variant="extraSmall" color="#8592A3">
                        {t("Max size: 512mb")}
                      </Text>
                      <Text variant="extraSmall" color="#8592A3">
                        {t("Format: txt")}
                      </Text>
                    </>
                  ) : (
                    <Box className={s.deleteFileButton} onClick={confirmDeleteFileModal.onOpen}>
                      {t("Delete")}
                    </Box>
                  )}
                </Box>
              </Flex>
            </FormControl>
          </ModalBody>

          <ModalFooter justifyContent="space-between">
            <Button variant="dominoOutlineViolet" mr={3} onClick={handleModalClose}>
              {t("Cancel")}
            </Button>
            <Button variant="dominoViolet" onClick={handleAdd} disabled={isAddEnabled}>
              {currentAIAssistant?.name ? t("Save") : t("Add")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <DeleteModal
        title={t("Delete the response file")}
        itemTitle={t("Are you sure you want to delete the file")}
        isOpen={confirmDeleteFileModal.isOpen}
        onClose={confirmDeleteFileModal.onClose}
        onDelete={onDeleteFile}
      />
    </>
  );
};
