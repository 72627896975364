import s from "./QuestionMessageNode.module.scss";
import { ActionNodeProps } from "../ActionNodeProps";
import { useTranslation } from "react-i18next";
import { ReactComponent as TelegramIcon } from "../../../../../assets/icons/telegramIcon.svg";
import { ReactComponent as MessageIcon } from "../../../../../assets/icons/sidebar/chatIcon.svg";
import { Icon, Flex } from "@chakra-ui/react";
import { MessageButtonBlock } from "../MessageButtonBlock/MessageButtonBlock";

function QuestionMessageNode(props: { actionData: ActionNodeProps }) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });

  return (
    <Flex flexDirection="column" alignItems="center">
      <div className={s.header}>
        <Icon as={TelegramIcon} boxSize="32px" />
        <div className={s.textBlock}>
          <div className={s.telegram}>{t("Telegram")}</div>
          <div className={s.type}>{t("Question Message")}</div>
        </div>
      </div>
      <MessageButtonBlock actionData={props.actionData} />
      <div className={s.replyWaitingBlock}>
        <Icon as={MessageIcon} boxSize="22px" fill="mainPurple" />
        <div className={s.text}>{t("Waiting for a reply from contact")}...</div>
      </div>
    </Flex>
  );
}

export default QuestionMessageNode;
