import { httpDelete, httpGet, httpPost, httpPostForm } from "../../common/BaseApi";
import { AppSettings } from "../../common/AppSettings";
import { ConversationSendMessageData } from "./ConversationData";

declare const appSettings: AppSettings;

export const getConversation = (conversationId: string) => {
  return httpGet(`/api/conversation/${conversationId}`);
};
export const getConversationMessages = (
  conversationId: string,
  lastId: string,
  searchValue?: string,
  includeMessageId?: string,
) => {
  return httpGet(
    `/api/conversation/${conversationId}/messages?Size=${10}${lastId?.length ? `&lastId=${lastId}` : ""}&filter=${
      searchValue ?? ""
    }${includeMessageId?.length ? `&includeMessageId=${includeMessageId}` : ""}`,
  );
};

export const readMessages = (conversationId: string, messageIds: string[]) => {
  return httpPost(`/api/conversation/${conversationId}/messages/read`, { messageIds });
};

export const sendMessage = (conversationId: string, message: ConversationSendMessageData) => {
  return httpPost(`/api/conversation/${conversationId}/sendMessage`, message);
};

export interface DeleteMessage {
  conversationId: string;
  messageId: string;
}
export const deleteMessage = ({ conversationId, messageId }: DeleteMessage) => {
  return httpDelete(`/api/conversation/${conversationId}/message/${messageId}`);
};

export const makeFileUrl = (fileId: string) => {
  return `${appSettings.apiBaseUrl}/file/${fileId}`;
};

export const getContactCustomVariables = () => {
  return httpGet(`/api/customvariable/definitions/contact`);
};

export const getSystemVariables = () => {
  return httpGet(`/api/customvariable/definitions/system`);
};

export const postFile = (file: File) => {
  const formData = new FormData();
  formData.append("file", file, file.name);
  return httpPostForm("/api/file", formData);
};

export const assignConversation = (conversationId: string, teamId: string | null, operatorId: string | null) => {
  return httpPost(`/api/conversation/${conversationId}/assign`, { teamId, operatorId });
};

export const closeConversation = (conversationId: string) => {
  return httpPost(`/api/conversation/${conversationId}/closeConversation`, null);
};

export const blockConversation = (conversationId: string) => {
  return httpPost(`/api/conversation/${conversationId}/block`, null);
};

export const unblockConversation = (conversationId: string) => {
  return httpPost(`/api/conversation/${conversationId}/unblock`, null);
};

export const getOrganisationUsers = (userName: string) => {
  return httpGet(`/api/organization/user/search?filter=${userName}`);
};
