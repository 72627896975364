export enum MemberRoleEnum {
  Unknown = "Member",
  Owner = "Owner",
  Administrator = "Administrator",
  Member = "Member",
  Restricted = "Restricted",
  Banned = "Banned",
  Left = "Left",
}

export enum MemberStatusEnum {
  Active = "Active",
  Unknown = "Member",
  Owner = "Owner",
  Member = "Member",
  Restricted = "Restricted",
  Administrator = "Administrator",
  Banned = "Banned",
  Left = "Left",
}

export interface MemberListModel {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  items?: MemberModel[];
}

export interface MemberListPaginatorModel {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  items: MemberModel[];
}

export interface MemberModel {
  id: string;
  createDate: string;
  groupId: string;
  firstName: string;
  lastName: string;
  username: string;
  externalId: string;
  latestActivity: string;
  isBot: boolean;
  statusInfo: {
    status: MemberStatusEnum;
    since: string;
    untilDate: string;
  };
  role: MemberRoleEnum;
  avatars: [];
}
