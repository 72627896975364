import React, { useEffect } from "react";
import { GroupChatMemberListItem } from "./components/GroupChatMemberListItem/GroupChatMemberListItem";
import { Box, Flex, Grid, Skeleton, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MemberRoleEnum, MemberStatusEnum } from "../../GroupChatMemberListModel";
import { useAppDispatch, useAppSelector } from "../../../../../../../../common/state/store";
import { getMemberList, selectMemberList } from "../../GroupChatMemberListSlice";
import { selectGroupChatInfo } from "../../../GroupChatInfo/GroupChatInfoSlice";
import s from "./GroupChatMemberList.module.scss";
import { MEMBERS_PER_PAGE } from "../../../../../../../../common/paginator/paginatorSizes";

export const GroupChatMemberList = ({ isOpen, searchText }: { isOpen: boolean; searchText: string }) => {
  const groupChatInfoTranslation = useTranslation("translation", { keyPrefix: "groupChat.groupChatInfo.MembersPage" }).t;

  const dispatch = useAppDispatch();
  const groupChatInfo = useAppSelector(selectGroupChatInfo);
  const memberList = useAppSelector(selectMemberList);

  useEffect(() => {
    if (groupChatInfo && groupChatInfo?.id && groupChatInfo?.membersCount && isOpen) {
      dispatch(
        getMemberList({
          page: 1,
          size: MEMBERS_PER_PAGE,
          groupId: groupChatInfo?.id,
          search: "",
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupChatInfo?.id, groupChatInfo?.membersCount, isOpen]);

  const arrayCountRender = memberList.items
    ? memberList.items?.length
    : groupChatInfo && groupChatInfo?.membersCount > MEMBERS_PER_PAGE
    ? MEMBERS_PER_PAGE
    : groupChatInfo?.membersCount || MEMBERS_PER_PAGE;

  return (
    <>
      <Flex className={s.container}>
        <Grid className={s.gridTop}>
          <Text noOfLines={1} variant="medium">
            {groupChatInfoTranslation("Username")}
          </Text>
          {/* <Text noOfLines={1} variant="medium">
            {groupChatInfoTranslation("The role")}
          </Text> */}
          <Text variant="medium">{groupChatInfoTranslation("Status")}</Text>
          <Text variant="medium" noOfLines={1}>
            {groupChatInfoTranslation("Last Activity")}
          </Text>
        </Grid>
        <Flex
          h={memberList && memberList.totalItems > MEMBERS_PER_PAGE ? "calc(100% - 52px) !important" : "100%"}
          className={s.flexMembersContainers}
        >
          <Flex
            mb={memberList && memberList.totalPages === 1 ? "85px !important" : "55px"}
            gap={!!memberList.items ? "0px" : "1px"}
            className={s.flexMembers}
          >
            {groupChatInfo &&
              Array(arrayCountRender)
                .fill(1)
                .map((_, ind) => {
                  const el = memberList.items && memberList.items[ind];
                  return (
                    <Skeleton className={s.skeleton} isLoaded={!!memberList.items} key={el?.id}>
                      <GroupChatMemberListItem
                        externalId={el?.externalId || ""}
                        firstDate={el?.createDate || "-"}
                        lastDate={el?.latestActivity || "-"}
                        isBot={el?.isBot || false}
                        role={el ? MemberRoleEnum[el?.role] : MemberRoleEnum.Unknown}
                        status={el && el?.statusInfo?.status ? MemberStatusEnum[el?.statusInfo?.status] : MemberStatusEnum.Member}
                        username={el?.username || `${el?.firstName || ""} ${el?.lastName || ""}` || ""}
                        id={el?.id || ""}
                      />
                    </Skeleton>
                  );
                })}
            {memberList.items && memberList.items.length === 0 && searchText !== "" && (
              <Box className={s.noData}>
                <Text>{groupChatInfoTranslation("No members")}</Text>
              </Box>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
