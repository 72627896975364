import s from "./VideoMessageNode.module.scss";
import { ActionNodeProps } from "../ActionNodeProps";
import { SendVideoFlowActionModel } from "../../../FlowBuilderModel";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { useTranslation } from "react-i18next";
import { MessageButtonBlock } from "../MessageButtonBlock/MessageButtonBlock";
import { Flex, Icon } from "@chakra-ui/react";
import TelegramIcon from "../../../../../assets/icons/telegramIcon.svg?react";
import VideoIcon from "../../../../../assets/icons/videoIcon.svg?react";
import { getButtonsWithExactType } from "../../../utils";

export const VideoMessageNode = (props: { actionData: ActionNodeProps }) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const data = props.actionData.node.flowAction as SendVideoFlowActionModel;

  return (
    <Flex flexDirection="column" alignItems="center">
      <div className={s.header}>
        <Icon as={TelegramIcon} boxSize="32px" />
        <div className={s.textBlock}>
          <div className={s.telegram}>{t("Telegram")}</div>
          <div className={s.type}>{t("Video Message")}</div>
        </div>
      </div>
      {data.file?.name || data.fileInfo?.name ? (
        <div data-pw="videoMessageNode" className={s.addedDocument}>
          <Icon as={VideoIcon} color="midDeepBlue" boxSize="28px" />
          <h4>{data.fileInfo?.name ?? data.file?.name}</h4>
          <Loading scope={`postFile${props.actionData.node.id}`} />
        </div>
      ) : (
        <div data-pw="addVideoMessageNode" className={s.noDocument}>
          <Icon as={VideoIcon} fill="darkGrey" boxSize="28px" />
          <h4>{t("Add Video")}</h4>
          <Loading scope={`postFile${props.actionData.node.id}`} />
        </div>
      )}
      {data.text || getButtonsWithExactType(data?.replyMarkup, props.actionData.node.flowAction)?.length ? (
        <MessageButtonBlock actionData={props.actionData} />
      ) : (
        ""
      )}
    </Flex>
  );
};
