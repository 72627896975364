import { TFunction } from "i18next";
import { useEffect, useState } from "react";
import { mapFromOptionsToTranslations } from "../../../UI/atoms/Select/utils";
import { UseSelectReturnValue } from "./types";

interface Props<T> {
  options: T[];
  initValue?: T;
  translator?: TFunction;
  translatePrefix?: string;
  numberForQuantity?: number;
  onChange?: (value: T) => void;
}

export function useSelect<T extends string>({
  options,
  initValue,
  translator,
  translatePrefix,
  numberForQuantity,
  onChange,
}: Props<T>): UseSelectReturnValue<T> {
  const [value, setValue] = useState<T>(initValue ?? options[0]);

  const innerOnChange = (value: T) => {
    setValue(value);
    onChange && onChange(value);
  };
  const onChangeValue = (value: string) => innerOnChange(value as T);

  useEffect(() => {
    if (initValue) {
      setValue(initValue);
    }
  }, [initValue]);

  const translattedOptions =
    translator && translatePrefix
      ? mapFromOptionsToTranslations(options, translator, translatePrefix, numberForQuantity ? numberForQuantity : undefined)
      : undefined;

  return [value, onChangeValue, translattedOptions];
}
