import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Flex,
  Text,
  Box,
  Icon,
  Link,
} from "@chakra-ui/react";
import { ChangeEvent, useState, DragEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGetLoadingState } from "../../../../../common/loading/hooks/useGetLoadingState";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { notify } from "../../../../../common/notifications/NotificationSlice";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { ReactComponent as FileIcon } from "../../../../../assets/icons/fileIcon.svg";
import { ReactComponent as CircleCheckIcon } from "../../../../../assets/icons/circleCheckIcon.svg";
import { ReactComponent as CrossIcon } from "../../../../../assets/icons/cross.svg";

import s from "./ImportRecipientsModal.module.scss";
import { selectCurrentBot } from "../../../../sidebar/SidebarSlice";
import { FileNameSplitter } from "../../../../../UI/atoms/fileNameSplitter/FileNameSplitter";
import { useGAWithCustomParameters } from "../../../../../common/ga/GAEventTracker";
import { TooltipWithTouch } from "../../../../../common/tooltipWithTouch/TooltipWithTouch";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { importRecipientsByFile, selectImportRecipientList, setImportRecipientList } from "../../ContactList/RecipientListSlice";
import { EventCategories } from "../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { BroadcastEvents } from "../../../../../common/ga/gaEventsEnums.ts/BroadcastEventsEnums";

const MAX_SIZE_10MB = 10485760;

interface Props {
  isOpen: boolean;
  usage?: string;
  onClose: () => void;
}

export const ImportRecipientsModal = ({ isOpen, usage, onClose }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "importContacts" });
  const ht = useTranslation("translation", { keyPrefix: "broadcast" }).t;
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const currentBot = useAppSelector(selectCurrentBot);
  const importRecipientList = useAppSelector(selectImportRecipientList);

  const dispatch = useAppDispatch();
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [isDragging, setDragging] = useState(false);

  const loadingState = useGetLoadingState("importContacts");
  const trackEvent = useGAWithCustomParameters(EventCategories.Broadcast);

  const chooseFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (file && file.size && file.size <= MAX_SIZE_10MB) {
      setCurrentFile(e.target?.files?.[0] ?? null);
    } else if (file && file.size && file.size > MAX_SIZE_10MB) {
      dispatch(notify({ message: t("File is too big"), type: "error" }));
    }
  };
  useEffect(() => {
    if (importRecipientList === null && loadingState === false) setCurrentFile(null);
  }, [importRecipientList, loadingState]);

  const uploadFile = () => {
    if (currentFile) {
      if (window.location.pathname.includes("broadcast")) {
        trackEvent(BroadcastEvents.BroadcastContactsImport);
      }
      dispatch(importRecipientsByFile({ botId: currentBot?.id, file: currentFile }));
    }
  };
  const handleClose = () => {
    onClose();
    setCurrentFile(null);
  };

  const onDropFile = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer?.files[0];
    if (file) {
      const [, ext] = file.type.split("/");
      if (ext === "csv") {
        if (file && file.size && file.size <= MAX_SIZE_10MB) {
          setCurrentFile(file ?? null);
          dispatch(
            setImportRecipientList({
              blocked: null,
              availableRecipients: null,
              recipients: null,
            }),
          );
        } else if (file && file.size && file.size > MAX_SIZE_10MB) {
          dispatch(notify({ message: t("File is too big"), type: "error" }));
        }
      } else {
        dispatch(notify({ message: t("Select a CSV file"), type: "error" }));
      }
    }
  };

  const handleDrag = function (e: { preventDefault: () => void; stopPropagation: () => void; type: string }) {
    e.preventDefault();
    e.stopPropagation();
    if (!currentFile) {
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragging(true);
      } else if (e.type === "dragleave" || e.type === "dragend") {
        setDragging(false);
      }
    }
  };

  const importedRecipients = importRecipientList
    ? (importRecipientList?.availableRecipients ?? 0) + (importRecipientList.blocked ?? 0)
    : null;

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent maxW={{ base: "300px", sm: "460px" }}>
          <ModalHeader p={{ base: "24px", sm: "32px 32px 24px 32px" }}>
            {!importRecipientList?.recipients ? (
              <Flex>
                <Text variant="h1">{ht("Import Recipients")}</Text>
                <TooltipWithTouch variant="dominoLight" whiteSpace={"pre-wrap"} label={ht("Import Recipients Tooltip")}>
                  <QuestionOutlineIcon ml={"8px"} boxSize={"15px"} color="darkGrey" _hover={{ color: "darkPurple" }} />
                </TooltipWithTouch>
              </Flex>
            ) : (
              <Flex className={s.importResult}>
                <Icon as={CircleCheckIcon} boxSize="40px" />
                <Text color="#0BCB6B" variant="h1">
                  {t("Import has been completed")}
                </Text>
              </Flex>
            )}
          </ModalHeader>
          <ModalCloseButton className={s.closeButton}>
            <CrossIcon />
          </ModalCloseButton>
          <ModalBody px={{ base: "24px", sm: "32px" }} py="0" className={s.modalBody}>
            {!importRecipientList?.availableRecipients && (
              <Box>
                <Text variant="medium" className={s.modalText}>
                  {ht("Select a CSV file containing your recipients")}
                </Text>
                <Link
                  className={s.downloadLink}
                  href={"https://cdn.domino-crm.com/import-contacts-template.csv"}
                  data-pw="download-CSV"
                >
                  {t("Download Sample CSV")}
                </Link>
                <Text variant="medium" className={s.modalText}>
                  {ht("Important: use as a delimiter")}
                </Text>
              </Box>
            )}
            <Box
              className={s.fileUploadContainer}
              borderStyle={importRecipientList ? "solid" : "dashed"}
              borderColor={isDragging ? "mainPurple" : "line"}
              onDragEnter={handleDrag}
            >
              <Flex className={s.fileContainer}>
                {!importRecipientList?.recipients?.length ? (
                  <>
                    <Icon color="black" as={FileIcon} boxSize="24px" />
                    {currentFile ? (
                      <FileNameSplitter fileName={currentFile.name} />
                    ) : (
                      <>
                        <Text variant="largeBold">{t("Drag and drop the file here")}</Text>
                        <Text variant="medium" color="darkGrey">
                          {t("or press the button")}
                        </Text>
                        <Button htmlFor="file-upload" as="label" variant="dominoOutlineViolet" data-pw="file-upload">
                          {t("Select file")}
                        </Button>
                        <Input id="file-upload" onChange={chooseFile} variant="unstyled" type="file" accept=".csv" />
                      </>
                    )}
                    {isDragging && (
                      <div
                        className={s.dragFileElement}
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={onDropFile}
                      ></div>
                    )}
                  </>
                ) : (
                  <Flex className={s.uploadResults}>
                    <Flex className={s.resultsTitle}>
                      <Text variant="large" color="darkGrey" mb="4px">
                        {t("Import")}:
                      </Text>
                      <FileNameSplitter fileName={currentFile?.name ? currentFile.name : ""} />
                    </Flex>
                    <Flex className={s.resultList}>
                      <Text color="defaultGreen" variant="medium">
                        {ht("Imported")} - {importedRecipients}
                      </Text>
                      <Text variant="medium">
                        {ht("Selected")} - {importRecipientList.availableRecipients}
                      </Text>
                      <Text color="mainRed" variant="medium">
                        {ht("Blocked")} - {importRecipientList.blocked}
                      </Text>
                    </Flex>
                  </Flex>
                )}
                <Loading scope="importContacts" />
              </Flex>
            </Box>
          </ModalBody>

          <ModalFooter p="32px">
            {importRecipientList?.recipients ? (
              <Button variant="dominoViolet" onClick={handleClose} data-pw="done-button">
                {ct("Done")}
              </Button>
            ) : (
              currentFile && (
                <Flex width="100%" justifyContent="space-between">
                  <Button
                    className={s.cancelButton}
                    bgColor="white"
                    color="mainRed"
                    onClick={handleClose}
                    data-pw="cancel-button"
                  >
                    {ct("Cancel")}
                  </Button>
                  <Button
                    isDisabled={!currentFile || loadingState}
                    variant="dominoViolet"
                    onClick={uploadFile}
                    data-pw="import-button"
                  >
                    {t("Import")}
                  </Button>
                </Flex>
              )
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
