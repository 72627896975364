import { takeLatest, call, put } from "redux-saga/effects";
import { handleException } from "../../common/SagaHelper";
import { beginScope, completeScope } from "../../common/loading/LoadingStateActions";
import { PayloadAction } from "@reduxjs/toolkit";
import { aiAssistantListSlice } from "./AIAssistantListSlice";
import { AIAssistantModel } from "./AIAssistantListModel";
import { AIAssistantData, NewAIAssistantFormData } from "./AIAssistantListData";
import * as AIAssistantListApi from "./AIAssistantListApi";

export function* aiAssistantListSaga() {
  yield takeLatest(aiAssistantListSlice.actions.getAIAssistant, getAIAssistant);
  yield takeLatest(aiAssistantListSlice.actions.getAIAssistantList, getAIAssistantList);
  yield takeLatest(aiAssistantListSlice.actions.createAIAssistant, createAIAssistant);
  yield takeLatest(aiAssistantListSlice.actions.updateAIAssistant, updateAIAssistant);

  yield takeLatest(aiAssistantListSlice.actions.deleteAIAssistant, deleteAIAssistant);
}

function* getAIAssistant(action: PayloadAction<string>) {
  try {
    yield put(beginScope("getAIAssistant"));
    const data: AIAssistantData = yield call(AIAssistantListApi.getAIAssistant, action.payload);
    const model: AIAssistantModel = data;
    yield put(aiAssistantListSlice.actions.getAIAssistantSucceed(model));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getAIAssistant"));
  }
}

function* getAIAssistantList() {
  try {
    yield put(beginScope("getAIAssistantList"));
    const data: AIAssistantData[] = yield call(AIAssistantListApi.getAIAssistantList);
    const model: AIAssistantModel[] = data;
    yield put(aiAssistantListSlice.actions.getAIAssistantListSucceed(model));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getAIAssistantList"));
  }
}

function* createAIAssistant(action: PayloadAction<NewAIAssistantFormData>) {
  try {
    yield put(beginScope("createAIAssistant"));
    yield call(AIAssistantListApi.createAIAssistant, action.payload);
    yield put(aiAssistantListSlice.actions.getAIAssistantList());
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("createAIAssistant"));
  }
}

function* updateAIAssistant(action: PayloadAction<{ updatedAIAssistant: NewAIAssistantFormData; aiAssistantId: string }>) {
  try {
    yield put(beginScope("updateAIAssistant"));
    const { updatedAIAssistant, aiAssistantId } = action.payload;

    yield call(AIAssistantListApi.updateAIAssistant, updatedAIAssistant, aiAssistantId);
    yield put(aiAssistantListSlice.actions.getAIAssistantList());
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("updateAIAssistant"));
  }
}

function* deleteAIAssistant(action: PayloadAction<string>) {
  try {
    yield put(beginScope("deleteAIAssistant"));
    yield call(AIAssistantListApi.deleteAIAssistant, action.payload);
    yield put(aiAssistantListSlice.actions.getAIAssistantList());
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("deleteAIAssistant"));
  }
}
