import { ProfanityFilterRule, ProfanityFilterTypeEnum } from "../../ProfanityFilterModel";
import { CUSTOM_PROFANITIES_SPLITTER } from "./consts";

export const isProfanityFilterEqual = {
  rules: (srcRules: ProfanityFilterRule[], copyRules: ProfanityFilterRule[]): boolean => {
    if (srcRules.length !== copyRules.length) return false;

    let isEqual = true;
    let index = 0;

    while (isEqual && index < srcRules.length) {
      if (srcRules[index].ruleAction.type !== copyRules[index].ruleAction.type) {
        isEqual = false;
      }

      if (srcRules[index].ruleAction.type === ProfanityFilterTypeEnum.Mute) {
        if (srcRules[index].ruleAction.forLong !== copyRules[index].ruleAction.forLong) {
          isEqual = false;
        }
      }

      index++;
    }

    return isEqual;
  },
  customProfanities: (srcProfanities: string[], copyProfanities: string): boolean => {
    return srcProfanities.join(CUSTOM_PROFANITIES_SPLITTER) === copyProfanities;
  },
};
