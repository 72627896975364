import { extendTheme } from "@chakra-ui/react";
import { badgeTheme } from "./components/badgeTheme";
import { buttonTheme } from "./components/buttonTheme";
import { tabsTheme } from "./components/tabsTheme";
import { menuTheme } from "./components/menuTheme";
import { avatarTheme } from "./components/avatarTheme";
import { cardTheme } from "./components/cardTheme";
import { textTheme } from "./components/textTheme";
import { containerTheme } from "./components/containerTheme";
import { textareaTheme } from "./components/textareaTheme";
import { inputTheme } from "./components/inputTheme";
import { tagTheme } from "./components/tagTheme";
import { drawerTheme } from "./components/drawerTheme";
import { checkboxTheme } from "./components/checkboxTheme";
import { switchTheme } from "./components/switchTheme";
import { popoverTheme } from "./components/popoverTheme";
import { tooltipTheme } from "./components/tooltipTheme";
import { closeButtonTheme } from "./components/closeButtonTheme";
import { alertTheme } from "./components/alertTheme";
import { accordionTheme } from "./components/AccordionTheme";

const theme = extendTheme({
  fonts: {
    heading: `'Lato', sans-serif`,
    body: `'Lato', sans-serif`,
  },
  colors: {
    white: "#FFFFFF",
    blueLink: "#325EE6",
    accentBlue: "#0084FF",
    deepBlue: "#08014B",
    midDeepBlue: "#3E5E95",
    lightDeepBlue: "#DCE7FB",
    bgLight: "#F7FAFF",
    bgMiddle: "#EFF5FE",
    darkGrey: "#8592A3",
    defaultGrey: "#F1F4F9",
    newGrey: "#F1F4F9",
    mainRed: "#EB5038",
    lightRed: "#FED7D7",
    mainPurple: "#6D5BF7",
    darkPurple: "#4F3DED",
    darkGreen: "#089850",
    defaultGreen: "#4EAA4A",
    greenActive: "#61D05A",
    greenBgMuted: "#EFFDF2",
    line: "#DAE1FB",
    greyScaleBorder: "#D9D9D9",
    greyDisable: "#BCC3CD",
    mainYellow: "#FFC300",
    boldYellow: "#E7B000",
    black: "#232B39",
    newBlack: "#232B39",
    newYellow: "#FEF6DC",
    newMiddleDeepBlue: "#3E5E95",
    newDisabledPurple: "#C1B9FF",
  },
  components: {
    Alert: alertTheme,
    Accordion: accordionTheme,
    Tabs: tabsTheme,
    Button: buttonTheme,
    CloseButton: closeButtonTheme,
    Badge: badgeTheme,
    Menu: menuTheme,
    Avatar: avatarTheme,
    Card: cardTheme,
    Text: textTheme,
    Container: containerTheme,
    Textarea: textareaTheme,
    Input: inputTheme,
    Tag: tagTheme,
    Drawer: drawerTheme,
    Checkbox: checkboxTheme,
    Switch: switchTheme,
    Popover: popoverTheme,
    Tooltip: tooltipTheme,
  },
});

export default theme;
