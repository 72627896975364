import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { ReactComponent as CrossIcon } from "../../../../../../../../../../assets/icons/cross.svg";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../../common/state/store";
import { selectCurrentBot } from "../../../../../../../../../sidebar/SidebarSlice";
import { deleteMember } from "../../../../GroupChatMemberListSlice";

export const GroupChatMemberListDeleteModal = ({
  isOpen,
  onClose,
  username,
  memberId,
}: {
  isOpen: boolean;
  onClose: () => void;
  username: string;
  memberId: string;
}) => {
  const groupChatInfoTranslation = useTranslation("translation", { keyPrefix: "groupChat.groupChatInfo.MembersPage" }).t;
  const dispatch = useAppDispatch();
  const currentBot = useAppSelector(selectCurrentBot);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay zIndex={10001} />
      <ModalContent
        containerProps={{
          zIndex: "10001",
        }}
        data-pw="modal"
      >
        <ModalHeader px="32px" pt="32px" pb="16px">
          <Text variant="h1">{groupChatInfoTranslation("Exclude from the group", { username: username })}?</Text>
        </ModalHeader>
        <ModalCloseButton
          data-pw="modal-close"
          borderRadius={"50%"}
          color={"darkGrey"}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
        >
          <CrossIcon />
        </ModalCloseButton>
        <ModalBody px="32px" py="0px">
          <Text variant="medium">
            {groupChatInfoTranslation(
              "You are going to exclude this participant. If you do so, they will loose access to any group discussions and materials.",
            )}
          </Text>
        </ModalBody>
        <ModalFooter px="32px" pt="32px" pb="32px" justifyContent={"space-between"}>
          <Button variant="dominoOutlineViolet" onClick={onClose}>
            {groupChatInfoTranslation("Cancel")}
          </Button>
          <Button
            variant="dominoPrimaryRed"
            onClick={() => dispatch(deleteMember({ botId: currentBot?.id || "", memberId: memberId }))}
          >
            {groupChatInfoTranslation("Exclude")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
