import { useState } from "react";
import { FlowItemModel } from "../../AutomationModel";
import { Box, Card, CardBody, Skeleton, Text, useMediaQuery } from "@chakra-ui/react";
import s from "../../Automation.module.scss";
import { fromNow } from "../../../../common/utils/fromNow";
import { FlowListItemMenu } from "./components/FlowListItemMenu";
import { useTranslation } from "react-i18next";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import DoubleArrowIcon from "../../../../assets/icons/automationDoubleArrowIcon.svg?react";
import { FormattedDateTooltip } from "../../../../UI/atoms/formattedDateTooltip/FormattedDateTooltip";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";
import React from "react";
import { FlowListItemInput } from "./components/FlowListItemInput";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { FlowEvents } from "../../../../common/ga/gaEventsEnums.ts/FlowGAEventsEnums";

interface Props {
  IsExportAvailable: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
  IsUnlimited: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
  accessibilityNodeCount: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
  canAddFlow: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
  isDrag?: boolean;
  showReorder: boolean;
  hideArrow: boolean;
  flowItem: FlowItemModel;
  lng: string;
  onCopy: (flow: FlowItemModel) => void;
  onDelete: (flow: FlowItemModel) => void;
  selectFlow: (currentFlow: FlowItemModel) => void;
}

export const FlowListItem = React.memo(function FlowListItem(props: Props) {
  const isDesktop = useMediaQuery("(min-width: 770px)")[0];

  const [isEditFlowName, setIsEditFlowName] = useState(false);

  const { t } = useTranslation("translation", { keyPrefix: "flowList" });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.flowItem.id });
  const trackEvent = useGAWithCustomParameters(EventCategories.Flow);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box ref={setNodeRef} style={style} className={s.flowItemSortableWrapper} key={`Box-${props.flowItem.id}`}>
      {isDesktop && props.showReorder && (
        <Box key={`Arrow-${props.flowItem.id}`} {...attributes} {...listeners} className={s.dragArea} data-pw="duble-arrow">
          {!props.hideArrow && <DoubleArrowIcon className={`${s.dragIcon} ${props?.isDrag ? s.active : ""}`} />}
        </Box>
      )}
      <Card
        variant="dominoListTile"
        key={`Card-${props.flowItem.id}`}
        onClick={() => {
          if (!isEditFlowName) {
            trackEvent(FlowEvents.FlowСhangeMain);
            props.selectFlow(props.flowItem);
          }
        }}
        className={props.isDrag ? s.dragCard : ""}
        width={"100%"}
        mb={"10px"}
        data-pw="flow-card"
      >
        <CardBody className={s.flowListItem_CardBody_Desktop} data-pw="flow-card">
          <div key={props.flowItem.id} className={`${s.flowListItemGrid}`}>
            <Skeleton isLoaded={!!props.flowItem.title}>
              <FlowListItemInput
                id={props.flowItem.id}
                isEditFlowName={isEditFlowName}
                setIsEditFlowName={setIsEditFlowName}
                title={props.flowItem.title}
              />
            </Skeleton>
            <Skeleton isLoaded={props.IsUnlimited !== undefined}>
              <Text variant="medium" marginLeft="8px" noOfLines={1}>
                {`${props.flowItem.nodesCount}${props.IsUnlimited ? "" : `/${props.accessibilityNodeCount["MaxCount"]}`}`}
              </Text>
            </Skeleton>
            <Text variant="medium" marginLeft="8px" noOfLines={1} data-pw="run-count">
              {props.flowItem.runCount}
            </Text>
            <FormattedDateTooltip lng={props.lng} lastModifiedDate={props.flowItem.lastModifiedDate} />
            <FlowListItemMenu
              flowItem={props.flowItem}
              canAddFlow={props.canAddFlow}
              IsExportAvailable={props.IsExportAvailable}
              onDelete={props.onDelete}
              onCopy={props.onCopy}
            />
          </div>
        </CardBody>
        {/* MOBILE */}
        <CardBody className={`${s.flowListItem_CardBody_Mobile}`}>
          <div className={`${s.flowListItemGridTitleMobile}`}>
            <FlowListItemInput
              id={props.flowItem.id}
              isEditFlowName={isEditFlowName}
              setIsEditFlowName={setIsEditFlowName}
              title={props.flowItem.title}
            />
            <FlowListItemMenu
              flowItem={props.flowItem}
              canAddFlow={props.canAddFlow}
              IsExportAvailable={props.IsExportAvailable}
              onDelete={props.onDelete}
              onCopy={props.onCopy}
            />
          </div>
          <div className={`${s.flowListItemGridMobile}`}>
            <Box className={`${s.flowListFilterMobile}`}>
              <Text className={`${s.flowListFilterMobile_text}`}>{tp("Nodes")}</Text>
              <Text className={`${s.flowListFilterMobile_text}`} noOfLines={1}>
                {t("Runs")}
              </Text>
              <Text className={`${s.flowListFilterMobile_text}`} noOfLines={1}>
                {t("Modified")}
              </Text>
            </Box>
            <Box w="max-content" key={props.flowItem.id}>
              <Text className={`${s.flowListFilterMobile_text}`} mr="36px" noOfLines={1}>
                {props.IsUnlimited
                  ? `${props.flowItem.nodesCount}`
                  : `${props.flowItem.nodesCount}/${props.accessibilityNodeCount["MaxCount"]}`}
              </Text>
              <Text className={`${s.flowListFilterMobile_text}`} noOfLines={1}>
                {props.flowItem.runCount}
              </Text>
              <Text className={`${s.flowListFilterMobile_text}`} noOfLines={1}>
                {fromNow(props.flowItem.lastModifiedDate, props.lng)}
              </Text>
            </Box>
            {!isDesktop && props.showReorder && (
              <Box {...attributes} {...listeners} className={`${s.dragArea} ${s.mobile}`}>
                <DoubleArrowIcon className={s.dragIcon} />
              </Box>
            )}
          </div>
        </CardBody>
        {/* MOBILE */}
      </Card>
    </Box>
  );
});
