import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGeneralOnboardingSteps } from "./toursConstants";
import { OnboardingToursContainer } from "./OnboardingToursContainer";
import { TourNames } from "./OnboardingTourEnums";
import { EventCategories } from "../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

export const GeneralOnboardingTour = () => {
  const { t } = useTranslation("translation", { keyPrefix: "onboardingTour" });

  const [tooltipWidth, setTooltipWidth] = useState("336px");
  const [offset, setOffset] = useState(0);
  const steps = getGeneralOnboardingSteps(offset, t);

  useEffect(() => {
    const updateTooltipWidth = () => {
      if (window.innerWidth <= 480) {
        setTooltipWidth("328px");
      } else {
        setTooltipWidth("336px");
      }
    };
    updateTooltipWidth();
    window.addEventListener("resize", updateTooltipWidth);
    return () => window.removeEventListener("resize", updateTooltipWidth);
  }, []);

  useEffect(() => {
    const updateOffset = () => {
      if (window.innerWidth <= 640) {
        setOffset(-4);
      } else {
        setOffset(0);
      }
    };
    updateOffset();
    window.addEventListener("resize", updateOffset);
    return () => window.removeEventListener("resize", updateOffset);
  }, []);

  return (
    <OnboardingToursContainer
      currentTourName={TourNames.GeneralTourName}
      tourLevelNameForGAEvent={EventCategories.OnboardingOne}
      steps={steps}
      tooltipWidth={tooltipWidth}
    />
  );
};
