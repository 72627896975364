import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import CrossIcon from "../../../../../../../../../../assets/icons/cross.svg?react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../../common/state/store";
import { muteMember } from "../../../../GroupChatMemberListSlice";
import { selectCurrentBot } from "../../../../../../../../../sidebar/SidebarSlice";
import { useGetLoadingState } from "../../../../../../../../../../common/loading/hooks/useGetLoadingState";

export const GroupChatMemberListMuteModal = ({
  isOpen,
  onClose,
  username,
  memberId,
}: {
  isOpen: boolean;
  onClose: () => void;
  username: string;
  memberId: string;
}) => {
  const groupChatInfoTranslation = useTranslation("translation", { keyPrefix: "groupChat.groupChatInfo.MembersPage" }).t;
  const dispatch = useAppDispatch();
  const currentBot = useAppSelector(selectCurrentBot);
  const isLoading = useGetLoadingState("muteMember");

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => {
        if (!isLoading) {
          onClose();
        }
      }}
    >
      <ModalOverlay zIndex={10001} />
      <ModalContent
        containerProps={{
          zIndex: "10001",
        }}
        data-pw="modal"
      >
        <ModalHeader px="32px" pt="32px" pb="16px">
          <Text variant="h1">{groupChatInfoTranslation("Mute for 24 hours", { username: username })}?</Text>
        </ModalHeader>
        <ModalCloseButton
          data-pw="modal-close"
          borderRadius={"50%"}
          color={"darkGrey"}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
        >
          <CrossIcon />
        </ModalCloseButton>
        <ModalBody px="32px" py="0px">
          <Text variant="medium">
            {groupChatInfoTranslation(
              "Are you sure you want to mute this participant for 24 hours? During this time, the participant will not be able to send messages in the group.",
            )}
          </Text>
        </ModalBody>
        <ModalFooter px="32px" pt="32px" pb="32px" justifyContent={"space-between"}>
          <Button
            variant="dominoOutlineViolet"
            onClick={() => {
              if (!isLoading) {
                onClose();
              }
            }}
          >
            {groupChatInfoTranslation("Cancel")}
          </Button>
          <Button
            variant="dominoPrimaryRed"
            onClick={() => dispatch(muteMember({ botId: currentBot?.id || "", memberId: memberId }))}
            isLoading={isLoading}
            spinnerPlacement="start"
            loadingText={groupChatInfoTranslation("Mute")}
          >
            {groupChatInfoTranslation("Mute")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
