import { Box, Text, Image } from "@chakra-ui/react";
import { Step } from "react-joyride";
import { TFunction } from "i18next";
import { TooltipTitleContainer } from "./TooltipTitleContainer";
import addNodeGif from "../../../src/assets/images/addNode.gif";
import { buttonBackStyles, getFloaterProps, tooltipContentStyle } from "./onboardingStyles";

export const getGeneralOnboardingSteps = (offset: number, t: TFunction<"translation", "onboardingTour">): Step[] => [
  {
    title: <TooltipTitleContainer titleText={t("Automation")} progressStep="  1/5" />,
    content: <Text>{t("automation")} </Text>,
    target: ".automation",
    placement: "right",
    disableBeacon: true,
    offset: offset,
    floaterProps: getFloaterProps(9, 16),
    spotlightPadding: 0,
  },
  {
    title: <TooltipTitleContainer titleText={t("Live Chat")} progressStep="  2/5" />,
    content: <Text>{t("chats")}</Text>,
    target: ".chats",
    placement: "right",
    offset: offset,
    floaterProps: getFloaterProps(9, 16),
    styles: buttonBackStyles,
    spotlightPadding: 0,
  },
  {
    title: <TooltipTitleContainer titleText={t("Broadcasting")} progressStep="  3/5" />,
    content: <Text>{t("broadcasting")}</Text>,
    offset: offset,
    floaterProps: getFloaterProps(9, 16),
    target: ".broadcasting",
    placement: "right",
    styles: buttonBackStyles,
    spotlightPadding: 0,
  },
  {
    title: <TooltipTitleContainer titleText={t("Contacts")} progressStep="  4/5" />,
    content: <Text>{t("contacts")}</Text>,
    target: ".contacts",
    placement: "right",
    offset: offset,
    floaterProps: getFloaterProps(9, 16),
    styles: buttonBackStyles,
    spotlightPadding: 0,
  },
  {
    title: <TooltipTitleContainer titleText={t("Dashboard")} progressStep="  5/5" />,
    content: <Text>{t("dashboard")}</Text>,
    target: ".dashboard",
    placement: "right",
    offset: offset,
    floaterProps: getFloaterProps(9, 16),
    styles: buttonBackStyles,
    spotlightPadding: 0,
  },
];

export const getFlowBuilderOnboardingSteps = (t: TFunction<"translation", "onboardingTour">): Step[] => {
  return [
    {
      title: <TooltipTitleContainer titleText={t("What is a trigger?")} progressStep="  1/3" />,
      content: <Text>{t("Trigger description")} </Text>,
      target: ".targetFlowTrigger",
      placement: "right",
      disableBeacon: true,
      offset: 6,
      floaterProps: getFloaterProps(8, 16),
    },
    {
      title: <TooltipTitleContainer titleText={t("Add Flow Block")} progressStep="  2/3" />,
      content: <Text>{t("Flow Block description")}</Text>,
      target: ".targetAddNode",
      placement: "bottom-start",
      offset: 6,
      floaterProps: getFloaterProps(8, 16),
      styles: buttonBackStyles,
      spotlightPadding: 6,
    },
    {
      title: <TooltipTitleContainer titleText={t("Go to Starting Step")} progressStep="  3/3" />,
      content: <Text>{t("Navigation work description")}</Text>,
      floaterProps: getFloaterProps(8, 16),
      target: ".targetGoToStartButton",
      placement: "bottom-start",
      styles: buttonBackStyles,
      spotlightPadding: 6,
      offset: 6,
    },
  ];
};

export const getTriggerOnboardingStep = (t: TFunction<"translation", "onboardingTour">): Step[] => {
  const addNodeVideoContainer = (
    <Box>
      <Text pb={"16px"}>{t("Trigger step")}</Text>
      <Image height="160px" width="288px" borderRadius="8px" src={addNodeGif} />
    </Box>
  );
  return [
    {
      title: <TooltipTitleContainer titleText={t("Trigger")} />,
      content: addNodeVideoContainer,
      target: ".targetStartTrigger",
      placement: "right",
      disableBeacon: true,
      styles: tooltipContentStyle,
      offset: 0,
      floaterProps: getFloaterProps(8, 16),
    },
  ];
};
