import { Flex, Icon, IconButton, Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import SearchIcon from "../../../../assets/icons/searchIcon.svg?react";
import DownArrow from "../../../../assets/icons/downArrowIcon.svg?react";
import CloseIcon from "../../../../assets/icons/cross.svg?react";
import s from "./SearchBox.module.scss";
import { useTranslation } from "react-i18next";
import { notHaveOnlySpaces } from "../../../../common/validation/defaultValidators";

interface Props {
  scrollToMessage: (isNext: boolean) => void;
  onSearchValueChange: (value: string) => void;
  searchedValue: string;
  totalSearchItems?: number;
  isDisabled: { nextButton: boolean; prevButton: boolean };
  isSearchedMessages: boolean;
}

export const SearchBox = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "conversation.searchBox" });
  const [searchIsActive, setSearchIsActive] = useState(false);
  // for optimization and preventing whole message list rerender, searchValue state is put inside this component
  const [interstitialSearchValue, setInterstitialSearchValue] = useState("");
  const [isClosing, setIsClosing] = useState(false);
  const [isViewMob, setIsViewMob] = useState(false);

  const onClose = () => {
    setIsClosing(true);
    setInterstitialSearchValue("");
    setIsViewMob(false);
    setTimeout(() => {
      setSearchIsActive(false);
      setIsClosing(false);
    }, 500);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      props.onSearchValueChange(interstitialSearchValue);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interstitialSearchValue]);

  const onChangeSearchText = (search: string) => {
    const errors = notHaveOnlySpaces()(search);
    if (errors) return;
    setInterstitialSearchValue(search);
  };
  return (
    <>
      <IconButton
        position="absolute"
        right="16px"
        top="16px"
        zIndex={1}
        variant="searchCircleButton"
        aria-label="Search"
        icon={<SearchIcon />}
        onClick={() => {
          setSearchIsActive(true);
          setTimeout(() => {
            setIsViewMob(true);
          }, 200);
        }}
      />
      {searchIsActive && (
        <Flex className={`${s.searchBox} ${isClosing && s.hideSearchBox} ${isViewMob && s.show}`} alignItems="center">
          {/* <Flex className={`${s.searchBox} ${searchIsActive && s.show}`} alignItems="center"> */}
          <Flex alignItems="center" justifyContent="space-between" className={s.arrowBlock}>
            <IconButton
              aria-label="downArrow"
              icon={<DownArrow />}
              boxSize="20px"
              onClick={() => props.scrollToMessage(true)}
              className={`${s.selectButton} ${props.isDisabled.nextButton ? s.disabledButton : ""}`}
            />
            <IconButton
              aria-label="upArrow"
              icon={<DownArrow />}
              boxSize="20px"
              onClick={() => props.scrollToMessage(false)}
              className={`${s.selectButton} ${props.isDisabled.prevButton ? s.disabledButton : ""}`}
              transform="rotate(180deg)"
            />
          </Flex>

          <InputGroup className={s.searchInput}>
            <InputLeftElement pointerEvents="none">
              <Icon as={SearchIcon} color="darkgray" />
            </InputLeftElement>
            <Input
              autoFocus
              bg="white"
              placeholder={`${t("Search through Live Chat conversation")}`}
              value={interstitialSearchValue}
              onChange={e => onChangeSearchText(e.target.value)}
            />
            <InputRightElement>
              <IconButton
                height="25px"
                width="25px"
                aria-label="clear"
                variant="cLoseButton"
                mt="2px"
                icon={<CloseIcon width="15px" height="15px" />}
                onClick={() => onChangeSearchText("")}
              />
            </InputRightElement>
          </InputGroup>

          <IconButton
            position="absolute"
            right="16px"
            top="16px"
            variant="searchCircleButton"
            aria-label="Search"
            icon={<CloseIcon />}
            onClick={onClose}
          />
        </Flex>
      )}
      {props.searchedValue && searchIsActive && (
        <Flex alignItems="center" justifyContent="center" className={s.noResults}>
          {props.totalSearchItems ? `${t("Found results")}: ` + props.totalSearchItems : t("No results found")}
        </Flex>
      )}
    </>
  );
};
