import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import {
  clearOrganisationUsers,
  deleteUserFromOrganisation,
  getOrganisationUsers,
  selectOrganisationUserUsage,
  selectOrganisationUsers,
} from "../../OrganisationSlice";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import s from "./OperatorsPage.module.scss";
import { Box, Flex, Text, Show } from "@chakra-ui/react";
import { selectIsOwner } from "../../../../common/user/UserSlice";
import { DeleteUserFromOrganisationPopup } from "../DeleteUserFromOrganisation/DeleteUserFromOrganisationPopup";
import { ReactComponent as RedTrashIcon } from "../../../../assets/icons/trash.svg";
import { PaginatorWithSearchParams } from "../../../../common/paginator/components/PaginatorWithSearchParams";
import DotMenuContainer from "../../../../UI/atoms/DotMenu/DotMenuContainer";

export const OperatorsPage = () => {
  const { t } = useTranslation("translation", { keyPrefix: "organisation" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const rolesT = useTranslation("translation", { keyPrefix: "roles" }).t;
  const dispatch = useAppDispatch();
  const organisationUsers = useAppSelector(selectOrganisationUsers);
  const operatorUsageFlows = useAppSelector(selectOrganisationUserUsage);
  const [searchParams] = useSearchParams("");
  const isOwner = useAppSelector(selectIsOwner);
  const currentPage = Number(searchParams.get("page") ?? 1);
  const [deletePopupState, setDeletePopupState] = useState<{
    id: string;
    itemName: string;
  } | null>(null);

  const onDelelePopupClose = () => setDeletePopupState(null);
  const deleteUserDispatch = (userId: string) => {
    dispatch(deleteUserFromOrganisation({ userId }));
  };

  useEffect(() => {
    dispatch(getOrganisationUsers({ page: currentPage }));
  }, [currentPage, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearOrganisationUsers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!organisationUsers) {
    return <></>;
  }

  return (
    <Box className={s.operatorListContainer}>
      <Box className={s.operatorListContent}>
        <Show breakpoint={"(min-width: 999px)"}>
          <Box color="darkGrey" className={`${s.userListItemGrid} ${s.userListName}`}>
            <Text p="16px 0 16px 24px" noOfLines={1} variant="medium">
              {t("Username")}
            </Text>
            <Text p="16px 0" noOfLines={1} variant="medium">
              {t("Role")}
            </Text>
          </Box>
        </Show>
        <Flex className={s.mainFlexContainer}>
          {organisationUsers.items.length === 0 ? (
            <Flex color="darkGrey" p="24px" justify="center" align="center">
              {t("No results found")}
            </Flex>
          ) : (
            <>
              {organisationUsers.items.map((user, index) => (
                <Box
                  borderBottom={index !== organisationUsers.items.length - 1 ? "1px solid" : "none"}
                  borderColor="bgMiddle"
                  p="14px 0"
                  className={`${s.userListItemGrid}`}
                  key={user.userId}
                >
                  <Text ml="24px" variant="medium" noOfLines={1}>
                    {user.username}
                  </Text>
                  <Flex align="center" gap={"8px"}>
                    <Text className={s.mobileRoles} minW={"33px"} color="darkGray" noOfLines={1} variant="medium">
                      {t("Role")}
                    </Text>
                    <Text variant="medium" noOfLines={1}>
                      {rolesT(`${user.roles[0]}`)}
                    </Text>
                  </Flex>

                  {isOwner && (
                    <DotMenuContainer
                      menuItems={[
                        {
                          MenuItem: {
                            color: "mainRed",
                            icon: <RedTrashIcon width="18px" height="18px" />,
                            text: ct("Delete"),
                            clickEvent: e => {
                              e.stopPropagation();
                              setDeletePopupState({ id: user.userId, itemName: user.username });
                            },
                          },
                        },
                      ]}
                    />
                  )}
                </Box>
              ))}
            </>
          )}
        </Flex>
        <Box className={s.paginatorBox}>
          <PaginatorWithSearchParams
            sizeItems={10}
            paddingRight={{ base: "28px", sm: "16px", md: "32px" }}
            data={organisationUsers}
            typeOfPage="OperatorList"
          />
        </Box>
      </Box>
      <DeleteUserFromOrganisationPopup
        deleteUserState={deletePopupState}
        onDelete={deleteUserDispatch}
        onClose={onDelelePopupClose}
        flows={operatorUsageFlows}
      />
    </Box>
  );
};
