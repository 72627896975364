import { Box, Flex, Tooltip, Text, Image } from "@chakra-ui/react";
import { fromNowChat } from "../../../../../../common/utils/fromNowChat";
import { MessageStatusEnum } from "../../../../../conversation/ConversationModel";
import s from "../../../../ChatList.module.scss";
import { ChatListItemModel } from "../../../../ChatListModel";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../../common/state/store";
import { selectLanguage } from "../../../../../../common/user/UserSlice";
import { ReactComponent as ImageMessageIcon } from "../../../../../../assets/icons/imageMessageIcon.svg";
import { ReactComponent as DocumentMessageIcon } from "../../../../../../assets/icons/documentMessageIcon.svg";
import { ReactComponent as Circle } from "../../../../../../assets/images/dangerCircle.svg";
import { AppSettings } from "../../../../../../common/AppSettings";
import GTM from "../../../../../../common/ga/GAEventTracker";
import { ChatEvent } from "../../../../../../common/ga/gaEventsEnums.ts/ChatGaEventsEnums";
import { EventCategories } from "../../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { RenderSmoothImage } from "../../../../../../UI/molecules/renderSmoothImage/RenderSmoothImage";

declare const appSettings: AppSettings;

interface Props {
  compareDate: string;
  chat: ChatListItemModel;
}

export default function ChatListItem({ compareDate, chat }: Props) {
  const ce = useTranslation("translation", { keyPrefix: "conversation.errors" }).t;
  const cx = useTranslation("translation", { keyPrefix: "conversation" }).t;
  const lng = useAppSelector(selectLanguage);

  const navigate = useNavigate();
  const params = useParams();
  const id = params["*"]?.split("/")[1];

  const trackEvent = GTM(EventCategories.Chats);

  const selectChat = (currentConversation: ChatListItemModel) => {
    navigate(`/chats/${currentConversation.id}`, { replace: true });
  };

  const errorHandler = (error?: string) => {
    if (!error) {
      return "";
    }
    const reg = new RegExp(/Wrong HTTP URL/gm);

    return reg.test(error) ? ce("Wrong Url") : ce(error);
  };

  return (
    <Box
      data-pw="chat-item"
      onClick={() => {
        selectChat(chat);
        trackEvent(ChatEvent.ChatConversationOpen);
      }}
      className={id === chat.id ? `${s.chatListItem} ${s.active}` : s.chatListItem}
    >
      {chat.contact && chat.contact.avatars?.length ? (
        <RenderSmoothImage
          opacity={chat.isBlocked ? "50%" : "inherit"}
          boxSize="36px"
          alt={chat.name}
          src={`${appSettings.apiBaseUrl}/file/${chat.contact.avatars[0][0].fileId}`}
        />
      ) : (
        <RenderSmoothImage opacity={chat.isBlocked ? "50%" : "inherit"} boxSize="36px" alt={chat.name} />
      )}
      <Box className={s.chatInfo}>
        {compareDate === null ? (
          <Flex width="100%" justify="space-between" alignItems={"center"}>
            <Text
              color={chat?.isBlocked ? "darkGrey" : "inherit"}
              noOfLines={1}
              fontSize={"14px"}
              lineHeight={"143%"}
              wordBreak="break-all"
              inlineSize={"81%"}
            >
              {chat.name}
            </Text>
          </Flex>
        ) : (
          <>
            {chat?.lastMessage?.status === MessageStatusEnum.Failed ? (
              <Flex width="100%" justify="space-between" alignItems={"center"}>
                <Text
                  color={chat?.isBlocked ? "darkGrey" : "inherit"}
                  noOfLines={1}
                  fontSize={"14px"}
                  wordBreak="break-all"
                  lineHeight={"143%"}
                  inlineSize={"81%"}
                >
                  {chat.name}
                </Text>
                <Flex alignItems="center">
                  <Tooltip
                    hasArrow
                    variant="dominoChat"
                    label={errorHandler(chat?.lastMessage?.errorCode)}
                    openDelay={500}
                    placement="top"
                    background="#FEF6DC"
                  >
                    <Box>
                      <Image w={4} as={Circle} alt="" />
                    </Box>
                  </Tooltip>
                  <Text minW="max-content" noOfLines={2} variant="extraSmall" color="darkGrey" lineHeight="14px" textAlign="end">
                    {fromNowChat(compareDate, lng, true)}
                  </Text>
                </Flex>
              </Flex>
            ) : (
              <Flex width="100%" justify="space-between" alignItems={"center"}>
                <Text
                  color={chat?.isBlocked ? "darkGrey" : "inherit"}
                  noOfLines={1}
                  fontSize={"14px"}
                  lineHeight={"143%"}
                  wordBreak="break-all"
                  inlineSize={"81%"}
                >
                  {chat.name}
                </Text>
                <Text minW="max-content" noOfLines={2} variant="extraSmall" color="darkGrey" lineHeight="14px" textAlign="end">
                  {fromNowChat(compareDate, lng, true)}
                </Text>
              </Flex>
            )}
          </>
        )}
        <Flex width="98%" justify="space-between">
          <Flex width="90%" gap="4px" alignItems="center">
            {chat?.lastMessage?.isContact && <span className={s.lastMessageContact}>{cx("Contact")}</span>}
            {chat?.lastMessage?.isPhoto && <ImageMessageIcon />}
            {chat?.lastMessage?.isDocument && <DocumentMessageIcon />}
            <Tooltip variant="dominoLight" label={chat?.lastMessage?.text} openDelay={500} placement="bottom-start">
              <Text
                color={chat?.isBlocked ? "darkGrey" : "midDeepBlue"}
                noOfLines={1}
                width="100%"
                fontSize={"14px"}
                lineHeight={"143%"}
                height="fit-content"
              >
                {chat?.lastMessage?.text || (!chat?.lastMessage && cx("There's nothing here yet"))}
              </Text>
            </Tooltip>
          </Flex>
          {!!chat?.unreadMessageCount && <div className={s.unreadMessageCount}>{chat?.unreadMessageCount}</div>}
        </Flex>
      </Box>
    </Box>
  );
}
