import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { groupChatInfoSlice } from "./GroupChatInfoSlice";
import { handleException } from "../../../../../../common/SagaHelper";
import * as GroupChatInfoApi from "./GroupChatInfoApi";
import { GroupChatData } from "../../../../GroupChatData";
import { GroupChatModel } from "../../../../GroupChatModel";
import { beginScope, completeScope } from "../../../../../../common/loading/LoadingStateActions";
import { profanityFilterSlice } from "../Tabs/ProfanityFilter/ProfanityFilterSlice";

export function* groupChatInfoSaga() {
  yield takeLatest(groupChatInfoSlice.actions.getGroupChatInfo, getGroupChatInfo);
}

function* getGroupChatInfo(action: PayloadAction<string>) {
  try {
    yield put(beginScope("getGroupChatInfo"));
    const data: GroupChatData = yield call(GroupChatInfoApi.getGroupChatInfo, action.payload);
    const model: GroupChatModel = data;
    yield put(groupChatInfoSlice.actions.getGroupChatInfoCompleted(model));
    yield put(profanityFilterSlice.actions.setProfanityFilter(data.profanitySettings));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getGroupChatInfo"));
  }
}
