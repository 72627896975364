import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Icon,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CrossIcon from "../../../../assets/icons/cross.svg?react";
import s from "./DeleteBroadcastPopup.module.scss";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

interface Props {
  deleteBroadcastState: { id: string; itemName: string } | null;
  onDelete: (broadcastId: string) => void;
  onClose: () => void;
}

export const DeleteBroadcastPopup = (props: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "broadcastList.deletePopup",
  });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;

  const onDeleteClick = () => {
    props.onDelete(props.deleteBroadcastState?.id ?? "");
    props.onClose();
  };
  const trackEvent = useGAWithCustomParameters(EventCategories.Broadcast);

  return (
    <Modal isOpen={Boolean(props.deleteBroadcastState)} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent className={s.modalContent} data-pw="modal">
        <ModalHeader></ModalHeader>
        <ModalCloseButton
          data-pw="modal-close"
          borderRadius={"50%"}
          color={"darkGrey"}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
        >
          <Icon boxSize="20px" as={CrossIcon} />
        </ModalCloseButton>
        <ModalBody paddingTop="0">
          <Text pr="10px" variant="h1">
            {t("Do you really want to remove the post")} &quot;
            {props?.deleteBroadcastState?.itemName}&quot; ?
          </Text>
        </ModalBody>
        <ModalFooter className={s.modalFooter}>
          <Button data-pw="modal-close" variant="dominoOutlineViolet" onClick={props.onClose}>
            {ct("Cancel")}
          </Button>
          <Button
            data-pw="modal-remove"
            variant="dominoPrimaryRed"
            mr={3}
            onClick={() => {
              trackEvent("BroadcastMenu", "", { broadcast_event: "Delete" });
              onDeleteClick();
            }}
          >
            {ct("Remove")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
