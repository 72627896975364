import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross.svg";
import s from "./NewTeamModal.module.scss";
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  ModalBody,
  Box,
  Flex,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import {
  clearOrganisationUsers,
  createOrganisationTeam,
  getOrganisationUsers,
  selectOrganisationUsers,
} from "../../OrganisationSlice";
import { useEffect, useState } from "react";
import { NewTeamModel } from "../../OrganisationModel";
import { MultiSelectMenu } from "../../../multiSelectMenu/MultiSelectMenu";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const NewTeamModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "organisation.newOrganisationModal" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const dispatch = useAppDispatch();
  const operators = useAppSelector(selectOrganisationUsers);
  const multiSelectMenuOptions = operators?.items.map(el => {
    return { name: el.username, id: el.userId };
  });
  const [teamName, setTeamName] = useState("");
  const [isTeamNameError, setIsTeamNameError] = useState<boolean>(false);
  const [assignedOperators, setAssignedOperators] = useState<string[]>([]);

  const handleClose = () => {
    setTeamName("");
    setAssignedOperators([]);
    setIsTeamNameError(false);
    onClose();
  };

  const getAdditionalOperators = () => {
    if (operators) {
      dispatch(getOrganisationUsers({ page: operators.currentPage + 1 }));
    }
  };

  const onOperatorListChange = (operatorIds: string[]) => {
    setAssignedOperators(operatorIds);
  };

  const handleTeamNameInputBlur = () => {
    if (!teamName.length) {
      setIsTeamNameError(true);
      return;
    }
    setIsTeamNameError(false);
  };

  const onCreate = () => {
    const newTeam: NewTeamModel = {
      name: teamName.trim(),
      members: assignedOperators,
    };
    dispatch(createOrganisationTeam(newTeam));
    setAssignedOperators([]);
    handleClose();
  };

  const onSearchTextChange = (value: string) => {
    dispatch(getOrganisationUsers({ page: 1, filter: value }));
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(clearOrganisationUsers());
      dispatch(getOrganisationUsers({ page: 1 }));
    }
  }, [isOpen, dispatch]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent maxW={{ base: "330px", sm: "460px" }}>
          <ModalHeader p="32px">
            <Text variant="h1">{t("Create new Team")}</Text>
          </ModalHeader>
          <ModalCloseButton borderRadius={"50%"} color={"darkGrey"} _hover={{ bg: "defaultGrey", color: "mainPurple" }}>
            <CrossIcon />
          </ModalCloseButton>
          <ModalBody px="32px" py="0" className={s.modalBody}>
            <Box>
              <FormControl height="93px" isInvalid={isTeamNameError}>
                <FormLabel>{t("Team name")}</FormLabel>
                <Input
                  value={teamName}
                  onChange={e => setTeamName(e.target.value)}
                  onBlur={handleTeamNameInputBlur}
                  variant="outline"
                  placeholder={t("Enter Team name") ?? ""}
                />
              </FormControl>
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Text variant="medium" mb={"8px"}>
                  {t("Add Operators")}
                </Text>
              </Flex>
              <MultiSelectMenu
                isLastPage={true}
                options={multiSelectMenuOptions ?? []}
                getAdditionalItems={getAdditionalOperators}
                onChange={onOperatorListChange}
                searchProps={{ searchItemName: "Operators", onSearchTextChange }}
              />
            </Box>
          </ModalBody>
          <ModalFooter p="32px">
            <Flex width="100%" justifyContent="space-between">
              <Button variant="dominoOutlineViolet" onClick={handleClose}>
                {ct("Cancel")}
              </Button>
              <Button onClick={onCreate} isDisabled={!teamName.trim().length} variant="dominoViolet">
                {ct("Create")}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
