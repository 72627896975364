import {
  Container,
  FormControl,
  Flex,
  Box,
  Popover,
  PopoverTrigger,
  FormLabel,
  Icon,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  Text,
  Button,
} from "@chakra-ui/react";

import s from "../Broadcast/Broadcast.module.scss";
import sp from "../../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import StarPlan from "../../../../assets/icons/starPlan.svg?react";

import { TariffPlanRedirectButton } from "../../../../common/tariffPlan/TariffPlanRedirectButton";
import { RecipientList } from "./RecipientList";
import { useTranslation } from "react-i18next";
import { TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";
import { getTariffPlanAccessability } from "../../../../common/tariffPlan/TariffPlanUtil";
import { useSelector } from "react-redux";
import { useAppSelector, useAppDispatch, RootState } from "../../../../common/state/store";
import { selectBroadcastDirection, selectContactList, selectRecipientList, setBroadcastDirection } from "./RecipientListSlice";
import { selectBroadcast, selectBroadcastIsNew, selectCustomVariables } from "../../BroadcastSlice";
import { FilterParams } from "../../../complexFilter/ComplexFilterModel";
import { memo, useState } from "react";
import { BroadcastDirection } from "../../BroadcastModel";
import { Loading } from "../../../../common/loading/LoadingStateContainer";

export const RecipientListContainer = memo(function RecipientListContainer() {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const dispatch = useAppDispatch();
  const accessibility = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.BroadcastContacts);
  const { MaxCount, IsUnlimited } = accessibility;
  const [broadcastFilter, setBroadcastFilter] = useState<FilterParams[]>([{}]);
  const [isActive, setIsActive] = useState(false);

  const variables = useAppSelector(selectCustomVariables);
  const contactList = useAppSelector(selectContactList);
  const recipientList = useSelector((state: RootState) => state.app.broadcastState.recipientList);
  const recipients = useAppSelector(selectRecipientList);
  const recipientListItems = recipients?.items;
  const isListEmpty = !recipientListItems || Object.keys(recipientListItems).length === 0;
  const brodcastDirection = useAppSelector(selectBroadcastDirection);
  const isBroadcastForContact = brodcastDirection === BroadcastDirection.TelegramContact;
  const isBroadcastForGroup = brodcastDirection === BroadcastDirection.TelegramGroup;

  const broadcast = useAppSelector(selectBroadcast);
  const isBroadcastNew = useAppSelector(selectBroadcastIsNew);

  const broadcastListLabel = recipientList?.recipients
    ? t("Recipients list")
    : isBroadcastForContact
    ? t("Contact list")
    : t("Group list");

  const handleDirectionButtonClick = (value: BroadcastDirection) => {
    dispatch(setBroadcastDirection(value));
  };

  return (
    <Container variant="dominoContentBlock">
      <FormControl className={`${s.broadcastListItemGrid} ${s.broadcastSheduleAdaptive}`}>
        <FormLabel>{t("Audience")}</FormLabel>
        <Box className={s.scheduleBoxMobile}>
          <Flex>
            <Button
              className={`${s.formTitleButtons} ${s.sendImmediatelyButton}`}
              variant={isBroadcastForContact ? "dominoViolet" : "dominoOutlineViolet"}
              isDisabled={isBroadcastNew ? false : broadcast.direction !== BroadcastDirection.TelegramContact}
              onClick={() => handleDirectionButtonClick(BroadcastDirection.TelegramContact)}
              data-pw="contact-list-button"
            >
              {t("Contact list")}
            </Button>
            <Button
              className={`${s.formTitleButtons} ${s.scheduleButton}`}
              variant={isBroadcastForGroup ? "dominoViolet" : "dominoOutlineViolet"}
              isDisabled={isBroadcastNew ? false : broadcast.direction !== BroadcastDirection.TelegramGroup}
              onClick={() => handleDirectionButtonClick(BroadcastDirection.TelegramGroup)}
              data-pw="group-chats-button"
            >
              {t("Group list")}
            </Button>
          </Flex>
        </Box>
      </FormControl>
      <Loading scope={"contactList2"} setIsActive={setIsActive} />
      <FormControl className={`${s.broadcastListItemGrid} ${s.broadcastListItemGridStart} ${s.broadcastAdaptiveContactList}`}>
        {!IsUnlimited &&
        isBroadcastForContact &&
        contactList?.totalItems &&
        contactList?.totalItems > MaxCount &&
        !isListEmpty ? (
          <Flex flexDir="column">
            <Box w="max-content">
              <Popover trigger={"hover"}>
                {({ onClose }) => (
                  <>
                    <PopoverTrigger>
                      <Flex className={sp.boxHover}>
                        <Box>
                          <FormLabel>{t("Contact list")}</FormLabel>
                        </Box>
                        <Box mt="2px">
                          <Icon as={StarPlan} boxSize="20px" ml="-4px" />
                        </Box>
                      </Flex>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                        <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                        <Flex alignItems="center" justifyContent="center" direction="column">
                          <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                            {tp("Advanced feature")}
                          </PopoverHeader>
                          <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                            {tp("Please upgrade your plan to select more recipients")}
                          </PopoverBody>
                          <TariffPlanRedirectButton onClose={onClose} />
                        </Flex>
                      </PopoverContent>
                    </Portal>
                  </>
                )}
              </Popover>
            </Box>
            <Box w="60%">
              <Text fontSize={15} color={"#8592A3"}>
                {tp(`No more than ${MaxCount} recipients on your current plan`)}
              </Text>
            </Box>
          </Flex>
        ) : (
          <FormLabel opacity={isListEmpty ? "0" : "1"}>{broadcastListLabel}</FormLabel>
        )}
        <RecipientList
          variables={variables}
          broadcastRecipientsFilter={broadcastFilter}
          setBroadcastFilter={setBroadcastFilter}
          isActive={isActive}
        />
        <Flex id="filter-container" className={s.flexFilter}></Flex>
      </FormControl>
    </Container>
  );
});
