import { Avatar, Box, Flex, Icon, Tooltip, useBoolean, useDisclosure } from "@chakra-ui/react";
import s from "../../../Conversation.module.scss";
import { AppSettings } from "../../../../../common/AppSettings";
import { ReactComponent as BotAvatar } from "../../../../../assets/icons/sidebar/botIcon.svg";
import { ReactComponent as MailIcon } from "../../../../../assets/icons/mailIcon.svg";
import { ReactComponent as PaymentIcon } from "../../../../../assets/icons/PaymentNodeIcon.svg";
import { AvatarModel, ConversationMessageModel } from "../../../ConversationModel";
import { PhotoMessageItem } from "../PhotoMessageItem";
import { DocumentMessageItem } from "../DocumentMessageItem";
import { TextMessageItem } from "../TextMessageItem";
import { ButtonMessageItem } from "../ButtonMessageItem";
import { useAppSelector } from "../../../../../common/state/store";
import { selectCurrentBot } from "../../../../sidebar/SidebarSlice";
import { MessageOriginType } from "../../../../../common/AppEnums";
import { TooltipWithTouch } from "../../../../../common/tooltipWithTouch/TooltipWithTouch";
import { useNavigate } from "react-router-dom";
import { BotModel } from "../../../../sidebar/SidebarModel";
import { ContactMessageItem } from "../ContactMessageItem";
import PaymentMessageItem from "../PaymentMessageItem";
import { UnsupportedMessageItem } from "../UnsupportedMessageItem";
import { MessageContextMenu } from "./components/MessageContextMenu/MessageContextMenu";
import { ReplyToMessage } from "./components/ReplyToMessage/ReplyToMessage";
import { RenderSmoothImage } from "../../../../../UI/molecules/renderSmoothImage/RenderSmoothImage";

declare const appSettings: AppSettings;

interface Props {
  message: ConversationMessageModel;
  highlightedText?: string;
  isInViewport?: boolean;
  isHighlighted?: boolean;
  userName?: string;
  avatars: AvatarModel[][];
  onReply: (reply: {
    enabled: boolean;
    message?: {
      id: string;
      author: string;
      text?: string;
      photoFileId?: string;
      documentName?: string;
      contact?: string;
    };
  }) => void;
  onReplyClick: (targetMessageId: string) => void;
  isReply?: boolean;
  featureFlag: "LIVE_CHAT" | "GROUP_CHAT"; // TODO: Remove this field after add delete-support for group chats
}

export const MessageItem = ({ ...props }: Props) => {
  const [messageHover, setMessageHover] = useBoolean(false);
  const botInfo = useAppSelector(selectCurrentBot);

  let displayImageIndex = 1;
  const message = props.message;

  const messagePhotoLength = props.message.photos.length;
  if (messagePhotoLength <= displayImageIndex) {
    displayImageIndex = messagePhotoLength - 1;
  }
  if (props.message.contact && props.message.replyToMessage) {
    const elem = document.getElementById(`contact-${props.message.id}`);
    const elemReply = document.getElementById(`reply-${props.message.id}`);
    if (elemReply && elem) {
      elemReply.style.width = `${elem.offsetWidth}px`;
    }
  }

  const isSupported = !!(
    message.replyToMessage ||
    message.text ||
    message.contact ||
    message.documents.length > 0 ||
    message.photos.length > 0 ||
    message.payment ||
    message.replyMarkup
  );

  return (
    <Box
      className={`${s.messageItemWrapper} ${s[props.message.direction]} ${props.message.replyToMessage ? s["WithReply"] : ""}`}
      onMouseLeave={setMessageHover.off}
    >
      {props.isInViewport && <Box className={s.backgroundHiglighted}></Box>}
      <Box className={`${s.messageHiglighted} ${props.isHighlighted ? s.messageHiglightedShow : s.messageHiglightedHide}`}></Box>
      {props.userName === "bot" && botInfo ? (
        <MessageAvatar origin={props.message.origin} botInfo={botInfo} />
      ) : props.message.payment ? (
        <Tooltip label={props.userName} variant="dominoLight">
          <Box className={s.payment}>
            <Icon as={PaymentIcon} boxSize="18px" viewBox="0 0 18 18" />
          </Box>
        </Tooltip>
      ) : props.avatars.length ? (
        <RenderSmoothImage
          isShowTooltip
          boxSize="32px"
          alt={props.userName}
          src={`${appSettings.apiBaseUrl}/file/${props.avatars[0][0].fileId}`}
        />
      ) : (
        <Tooltip label={props.userName} variant="dominoLight">
          <Avatar name={props.userName} className={s.avatar} size="smDomino" />
        </Tooltip>
      )}
      <Flex direction="column" style={{ maxWidth: "80%" }}>
        <Box
          onMouseEnter={setMessageHover.on}
          className={`${s.message} ${props.message.replyMarkup !== null ? s.messagWithButtons : ""}`}
          style={{
            maxWidth: props.message.photos[displayImageIndex]?.width ?? "",
          }}
        >
          {isSupported ? (
            <>
              {props.message.replyToMessage && (
                <ReplyToMessage
                  botInfo={botInfo}
                  displayImageIndex={displayImageIndex}
                  message={props.message}
                  onReplyClick={props.onReplyClick}
                />
              )}
              <PhotoMessageItem message={message} />
              <DocumentMessageItem message={message} />
              <TextMessageItem message={message} />
              <ContactMessageItem message={message} />
              <PaymentMessageItem message={message} />
            </>
          ) : (
            <UnsupportedMessageItem message={message} />
          )}
        </Box>
        <ButtonMessageItem message={message} />
      </Flex>
      <MessageContextMenu
        message={message}
        onReply={props.onReply}
        isSupported={isSupported}
        isReply={props.isReply}
        userName={props.userName}
        messageHover={messageHover}
        featureFlag={props.featureFlag}
      />
    </Box>
  );
};

const MessageAvatar = (props: {
  origin?: {
    type: MessageOriginType;
    name: string | null;
    id: string | null;
  };
  botInfo: BotModel;
}) => {
  if (!props.origin) {
    return <BotAvatarComponent botInfo={props.botInfo} />;
  }

  switch (props.origin.type) {
    case MessageOriginType.Broadcast:
      return <BroadcastAvatarComponent name={props.origin.name} id={props.origin.id} />;
    case MessageOriginType.Operator:
      return !!props.origin.name ? (
        <TooltipWithTouch label={props.origin.name} bg={"#FEF6DC"} variant={"dominoLight"} placement="top">
          <Avatar bg={"#3E5E95"} color={"#FFFFFF"} size={"sm"} name={props.origin.name} />
        </TooltipWithTouch>
      ) : (
        <BotAvatarComponent botInfo={props.botInfo} />
      );
    case MessageOriginType.Bot:
    case MessageOriginType.ApiClient:
    case MessageOriginType.Undefined:
    default:
      return <BotAvatarComponent botInfo={props.botInfo} />;
  }
};

const BotAvatarComponent = (props: { botInfo: BotModel }) => {
  const navigate = useNavigate();
  const tooltip = useDisclosure();

  const openBot = () => {
    navigate(`/bots/${props.botInfo?.id}`);
  };

  return (
    <Tooltip
      isOpen={tooltip.isOpen}
      label={props.botInfo?.name ?? props.botInfo?.username}
      bg={"#FEF6DC"}
      variant={"dominoLight"}
      placement="top"
    >
      <Box
        className={s.avatarBot}
        onMouseEnter={e => tooltip.onOpen()}
        onMouseLeave={e => tooltip.onClose()}
        onClick={e => openBot()}
      >
        <Icon as={BotAvatar} boxSize="20px" viewBox="0 0 20 20" />
      </Box>
    </Tooltip>
  );
};

const BroadcastAvatarComponent = (props: { name: string | null; id: string | null }) => {
  const navigate = useNavigate();
  const tooltip = useDisclosure();

  const openBroadcast = () => {
    if (props.id) {
      navigate(`/broadcasts/${props.id}/view`);
    }
  };

  return (
    <Tooltip isOpen={tooltip.isOpen} label={props.name} bg={"#FEF6DC"} variant={"dominoLight"} placement="top">
      <Box
        onClick={e => openBroadcast()}
        className={`${s.avatarBroadcast} ${!!props.id ? s.canOpen : ""}`}
        onMouseEnter={e => tooltip.onOpen()}
        onMouseLeave={e => tooltip.onClose()}
      >
        <Icon as={MailIcon} boxSize="20px" viewBox="0 0 20 20" />
      </Box>
    </Tooltip>
  );
};
