import {
  Box,
  Flex,
  Icon,
  Menu,
  MenuItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import sp from "../../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import s from "../../Sidebar.module.scss";
import { TariffPlanRedirectButton } from "../../../../common/tariffPlan/TariffPlanRedirectButton";
import { useTranslation } from "react-i18next";
import StarPlan from "../../../../assets/icons/starPlan.svg?react";

export const SideBarBotsLimitPopover = () => {
  const { t } = useTranslation("translation", { keyPrefix: "sidebar" });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  return (
    <Popover trigger={"hover"}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Box className={sp.boxHover}>
              <Menu>
                <MenuItem height={{ base: "48px", md: "60px" }} className={s.addBotBtn}>
                  <Icon
                    as={StarPlan}
                    p="8px"
                    boxSize={{ base: "24px", md: "37px" }}
                    color="midDeepBlue"
                    mr="12px"
                    className={s.addBotIcon}
                  />
                  {t("Create new bot")}
                </MenuItem>
              </Menu>
            </Box>
          </PopoverTrigger>
          <Portal>
            <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
              <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
              <Flex alignItems="center" justifyContent="center" direction="column">
                <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                  {tp("Advanced feature")}
                </PopoverHeader>
                <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                  {tp("Please upgrade your plan to add more Bots")}
                </PopoverBody>
                <TariffPlanRedirectButton onClose={onClose} />
              </Flex>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
