// Function returns the rounded down difference in seconds(by default)
type ReturnFormat = "ms" | "s" | "m" | "h";
const convertingValues: Record<ReturnFormat, number> = {
  ms: 1,
  s: 1000,
  m: 1000 * 60,
  h: 1000 * 60 * 60,
};
export function getDateDifference(earlierDate: Date, laterDate: Date, returnFormat: ReturnFormat = "s"): number {
  return Math.floor((laterDate.getTime() - earlierDate.getTime()) / convertingValues[returnFormat]);
}
