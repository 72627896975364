import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CrossIcon from "../../../assets/icons/cross.svg?react";
import { ReactNode } from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDelete?: () => void;
  title?: string;
  itemTitle?: string | ReactNode;
  isDisabled?: boolean;
  children?: ReactNode;
};

export const DeleteModal = (props: Props) => {
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const onDeleteClick = () => {
    props?.onDelete?.();
    props.onClose();
  };

  return (
    <Modal isOpen={!!props?.isOpen} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent mx={"16px"} p="32px" borderRadius="8px">
        <ModalHeader p="0" mb="12px" fontSize="24px" lineHeight="32px" fontWeight="500">
          {props?.title && props.title}?
        </ModalHeader>
        <ModalCloseButton borderRadius={"50%"} color={"darkGrey"} _hover={{ bg: "defaultGrey", color: "mainPurple" }}>
          <CrossIcon />
        </ModalCloseButton>
        <ModalBody p="0" mb="24px" fontSize="15px" lineHeight={"20px"}>
          <Box>{props?.itemTitle && props.itemTitle}</Box>
          {props.children}
        </ModalBody>
        <ModalFooter p="0" justifyContent="space-between">
          <Button variant="dominoOutlineViolet" onClick={props.onClose} data-pw="cancel-button">
            {ct("Cancel")}
          </Button>
          <Button isDisabled={props.isDisabled} variant="dominoPrimaryRed" mr={3} onClick={onDeleteClick} data-pw="delete-button">
            {ct("Delete")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
