import s from "../../../UserProfile.module.scss";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { TooltipWithTouch } from "../../../../../common/tooltipWithTouch/TooltipWithTouch";
import React from "react";
import { useTranslation } from "react-i18next";
import QuestionIcon from "../../../../../assets/icons/questionIcon.svg?react";
import { useAppSelector } from "../../../../../common/state/store";
import { TelegramLinkAuthButton } from "../../../../../common/TelegramAuth/TelegramLinkAuthButton";
import { selectLinkedAccounts } from "../../../../../common/user/UserSlice";

interface Props {
  disableAllBots: () => void;
}

export const UserProfilePushTitle = (props: Props) => {
  const linkedAccounts = useAppSelector(selectLinkedAccounts);
  const isTelegramAuth = linkedAccounts.length > 0;

  const { t } = useTranslation("translation", { keyPrefix: "userProfile" });
  return (
    <Flex>
      <Flex className={s.contentItemPushTitle}>
        <Text className={s.title} variant="h2">
          {t("Telegram notification")}
          <TooltipWithTouch
            variant="dominoLight"
            label={t("Select bot(s) to receive notifications about dialogues assigned to you or your team.")}
            placement="auto"
            maxW={"270px"}
          >
            <Icon
              as={QuestionIcon}
              ml={"4px"}
              boxSize={"15px"}
              borderRadius={"50%"}
              fill="darkGrey"
              _hover={{ fill: "darkPurple" }}
            />
          </TooltipWithTouch>
        </Text>
        <Flex className={s.telegramAuthBtnWrap}>
          {!isTelegramAuth ? (
            <>
              <Box className={s.telegramAuthBtn}>
                <TelegramLinkAuthButton size={"100%"} />
              </Box>
              <Text className={s.infoBlock}>
                {t("To enable Telegram notifications, you need to authorize in the Domino bot Telegram channel")}
              </Text>
            </>
          ) : (
            <>
              <Box className={s.telegramAuthBtn}>
                <span className={s.authText}> {t("Account connected")}</span>
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
