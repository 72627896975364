import { Box, Flex, Icon, Link, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import s from "../ChatList.module.scss";
import sp from "../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import StarPlan from "../../../assets/icons/starPlan.svg?react";
import { AppSettings } from "../../../common/AppSettings";

declare const appSettings: AppSettings;

interface Props {
  overLimitCount: number;
}

export const ConversationsOverLimitPanel = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "chatList" });
  const link = appSettings.billingPortalUrl;
  let overLimitText = "";
  switch (props.overLimitCount) {
    case 1:
      overLimitText = "chat over the plan limit";
      break;
    default:
      overLimitText =
        props.overLimitCount >= 2 && props.overLimitCount <= 4 ? "chats over the plan limit" : "chats over the plan limit(0-5)";
      break;
  }
  return (
    <div className={s.overLimitItem}>
      <div>
        <Flex gap="4px" marginBottom="2px" paddingLeft={4}>
          <Text left="100px" fontWeight={"700"} noOfLines={1} fontSize={"16px"} lineHeight={"20px"}>
            {props.overLimitCount}
          </Text>
          <Text fontWeight={"500"} noOfLines={1} fontSize={"16px"} lineHeight={"20px"}>
            {t(overLimitText)}
          </Text>
        </Flex>
        <Flex justify="space-between" marginTop="2px" paddingLeft={4}>
          <Flex width="100%" alignItems="center" gap="4px">
            <Box className={sp.boxStarHover}>
              <Flex align="center" justify="space-between">
                <Icon className={s.overLimitStarHover} fill="lightDeepBlue" stroke="mainPurple" boxSize="22px" as={StarPlan} />
              </Flex>
            </Box>
            <Link href={link} style={{ textDecoration: "none" }} isExternal={true}>
              <Text
                fontWeight={"400"}
                noOfLines={1}
                width="fit-content"
                fontSize={"16px"}
                lineHeight={"20px"}
                color="darkPurple"
                height="fit-content"
              >
                {t("Upgrade your plan to see all")}
              </Text>
            </Link>
          </Flex>
        </Flex>
      </div>
    </div>
  );
};
