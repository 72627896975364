import { Flex, Input, Button, Text, Box, Skeleton, Link, useBreakpointValue } from "@chakra-ui/react";
import EditInputIcon from "../../../../assets/icons/editInput.svg?react";

import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import {
  selectFlow,
  selectNodeEditorState,
  setFlowTitle,
  discardFlow,
  selectDraftFlowId,
  saveFlow,
} from "../../FlowBuilderSlice";
import { useTranslation } from "react-i18next";
import { BackButton } from "../../../backButton/BackButton";
import { useEffect, useState } from "react";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { useDocumentTitle } from "../../../../common/hooks/useDocumentTitle";
import { FlowModel } from "../../FlowBuilderModel";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";
import { selectCurrentBot } from "../../../sidebar/SidebarSlice";
import { useMobileView, useTabletView } from "../../../layout/LayoutHelper/ResolutionHooks";
import { selectTrialPlan } from "../../../organisation/OrganisationSlice";
import s from "./FlowBuilderHeader.module.scss";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { FlowEvents } from "../../../../common/ga/gaEventsEnums.ts/FlowGAEventsEnums";

export const FlowBuilderHeader = () => {
  const { t } = useTranslation("translation", { keyPrefix: "flow.flowHeader" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const nodeEditor = useAppSelector(selectNodeEditorState);
  const flow: FlowModel | undefined = useAppSelector(selectFlow);
  const currentBot = useAppSelector(selectCurrentBot);
  const trialPlan = useAppSelector(selectTrialPlan);
  const isTrialModel = trialPlan?.isAvailable;

  const isMobile = useMobileView();

  const isTablet = useTabletView();

  const loadingFlowBuilder = useGetLoadingState("flowBuilder");
  const draftFlowId = useAppSelector(selectDraftFlowId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isInputFocused, setInputFocused] = useState(false);
  const [lastFlow, setLastFlow] = useState(flow);
  const [lastFlowTitle, setLastFlowTitle] = useState(flow?.title);
  const location = useLocation();
  const locationState = location?.state as { search: string } | undefined;
  const [flowSavingState, setFlowSavingState] = useState<boolean>(false);
  const trackEvent = useGAWithCustomParameters(EventCategories.Flow);

  useDocumentTitle(t("Flow Builder"), "Flow Builder");

  const discardButtonName = useBreakpointValue({
    sm: t("Discard changes"),
    base: t("Discard"),
  });

  const filterNodeAccordion = (flow: FlowModel) => {
    const updatedNodes = flow.nodes.filter(el => el.id !== "creation_menu");
    const updatedEdges = flow.edges.filter(el => el.target !== "creation_menu" && el.source !== "creation_menu");
    return { ...flow, nodes: updatedNodes, edges: updatedEdges };
  };

  useEffect(() => {
    if (flow?.id === "import") {
      setFlowSavingState(false);
    } else {
      const includeFlowId = flow?.id !== undefined;
      const includeEditor = draftFlowId !== undefined;
      setFlowSavingState(!(includeFlowId && includeEditor));
    }
  }, [flow, draftFlowId, nodeEditor]);

  if (loadingFlowBuilder) {
    return (
      <Skeleton
        speed={0.5}
        startColor="line"
        endColor="bgLight"
        border="1px solid"
        borderColor="line"
        width="200px"
        height="27px"
      ></Skeleton>
    );
  }

  if (!flow) {
    return <></>;
  }

  if (!lastFlow) {
    setLastFlow(flow);
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      (e.target as HTMLInputElement).blur();
      handleBlur();
    }
  };

  const handleSave = () => {
    const updateFlow = filterNodeAccordion(flow);
    if (updateFlow.title?.trim() === "") {
      setLastFlowTitle(updateFlow?.title);
      dispatch(saveFlow({ ...updateFlow, title: lastFlowTitle }));
    } else {
      setLastFlow(updateFlow);
      setLastFlowTitle(updateFlow?.title);
      dispatch(saveFlow(updateFlow));
    }
  };

  const handleDiscard = () => {
    if (flow.id) {
      dispatch(discardFlow(flow.id));
    }
  };

  const handleBlur = () => {
    setInputFocused(false);
    if (flow.title?.trim() === "") {
      setLastFlowTitle(flow?.title);
      dispatch(setFlowTitle(lastFlowTitle ?? ""));
    } else {
      setLastFlowTitle(flow?.title);
      dispatch(setFlowTitle(flow.title?.replace(/ +/g, " ").trim() ?? ""));
    }
  };

  const changeFlowTitle = (title: string) => {
    trackEvent(FlowEvents.FlowNameChangeFromFlow);
    setLastFlowTitle(flow?.title);
    dispatch(setFlowTitle(title));
  };
  const flowHeaderContent = (
    <>
      <Flex alignItems={"center"}>
        <BackButton onButtonClick={() => navigate(`/automation/flows${locationState?.search ?? ""}`)} />
        {!isTablet && (
          <Box className={s.inputGroup} ml="16px" fill="darkGrey" _hover={{ fill: "darkPurple" }}>
            <Box fill="inherit" visibility={isInputFocused ? "hidden" : "visible"} className={s.preview}>
              <Text wordBreak="break-all" noOfLines={1} variant="h3" color="midDeepBlue">
                {flow.title}
              </Text>
              <EditInputIcon data-pw="edit-flow-name-icon" fill="inherit" />
            </Box>
            <Input
              className={s.input}
              variant="inline"
              size="lg"
              opacity={!isInputFocused ? 0 : 1}
              width={`${(flow.title?.length ?? 0) * 14}px`}
              value={flow.title}
              onFocus={() => {
                setInputFocused(true);
              }}
              onBlur={handleBlur}
              onChange={e => changeFlowTitle(e.target.value)}
              onKeyDown={handleKeyPress}
              data-pw="edit-flow-name-input"
            />
          </Box>
        )}
      </Flex>
      <Flex className={`${s.buttonsContainer} ${isTablet ? s.isTabletButtons : ""}`}>
        <Button
          isDisabled={draftFlowId === undefined || flow?.id === "import"}
          size="sm"
          variant="dominoOutlineRed"
          color="mainRed"
          mx="20px"
          colorScheme="whiteAlpha"
          onClick={handleDiscard}
          data-pw="discard-button"
        >
          {discardButtonName}
        </Button>
        {!isTablet && (
          <Link href={`https://t.me/${currentBot?.username}`} isExternal>
            <Button
              isDisabled={draftFlowId !== undefined || flow?.id === "import" || flow?.id === "new"}
              size="sm"
              variant="dominoOutlineViolet"
              data-pw="test-button"
            >
              {t("Test")}
            </Button>
          </Link>
        )}
        <Button
          isDisabled={flowSavingState}
          size="sm"
          variant="dominoViolet"
          mx="20px"
          onClick={handleSave}
          data-pw="save-button"
          className={s.saveButton}
        >
          {ct("Save")}
        </Button>
      </Flex>
    </>
  );

  return (
    <>
      {!isTablet ? (
        <>{flowHeaderContent}</>
      ) : (
        <Flex className={`${isTablet ? s.isTabletContainer : ""} ${isTrialModel && isMobile ? s.addMarginForTrial : ""}`}>
          {flowHeaderContent}
        </Flex>
      )}
    </>
  );
};
