import { Box, Flex, Skeleton, Text } from "@chakra-ui/react";
import s from "./GroupChatList.module.scss";
import { InputSearch } from "../../../../UI/molecules/inputSearch/InputSearch";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import { GroupChatListItem } from "./components/GroupChatListItem/GroupChatListItem";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import {
  getGroupChatList,
  getGroupChatListCount,
  selectCanUpload,
  selectGroupChatList,
  selectSearchGroupChatList,
  setSearchGroupChatList,
} from "./GroupChatListSlice";
import { selectCurrentBot } from "../../../sidebar/SidebarSlice";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { ChatListSortEnum, OverLimitConversationsModel } from "../../../chatList/ChatListModel";
import { ConversationsOverLimitPanel } from "../../../chatList/components/ConversationsOverLimitPanel";
export const GroupChatList = ({ overLimitConversations }: { overLimitConversations?: OverLimitConversationsModel[] }) => {
  const { t } = useTranslation("translation", { keyPrefix: "groupChat" });

  const searchText = useAppSelector(selectSearchGroupChatList);
  const groupChatList = useAppSelector(selectGroupChatList);
  const currentBot = useAppSelector(selectCurrentBot);
  const canUploadConv = useAppSelector(selectCanUpload);
  const loadingGroupChatList = useGetLoadingState("getGroupChatList");

  const overLimitCount = overLimitConversations?.length ?? 0;
  const isOverLimit = overLimitCount > 0;

  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const setSearchText = (value: string) => {
    dispatch(setSearchGroupChatList(value));
  };

  useEffect(() => {
    if (currentBot) {
      dispatch(
        getGroupChatList({
          botId: currentBot.id,
          search: searchText,
          sort: ChatListSortEnum.newest,
          lastId: "1",
        }),
      );
      dispatch(
        getGroupChatListCount({
          botId: currentBot.id,
          search: searchText,
          sort: ChatListSortEnum.newest,
          lastId: "1",
        }),
      );
    }
  }, [currentBot, dispatch, searchText]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const { scrollHeight, clientHeight } = container;
      if (scrollHeight <= clientHeight && canUploadConv && !loadingGroupChatList) {
        const lastId = Number(groupChatList?.lastId || "0");
        const nextLastId = String(lastId + 1);
        getChatListOnScroll(nextLastId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupChatList, canUploadConv, loadingGroupChatList]);

  const getChatListOnScroll = (lastId: string) => {
    if (currentBot) {
      dispatch(
        getGroupChatList({
          botId: currentBot.id,
          search: searchText,
          sort: ChatListSortEnum.newest,
          lastId,
        }),
      );
    }
  };

  const getAdditionalChatsOnScroll = (current: HTMLDivElement) => {
    if (current) {
      const scrollHeight = current.scrollHeight;
      const scrollTop = current.scrollTop;
      const clientHeight = current?.clientHeight;
      const isBottom = clientHeight + scrollTop > scrollHeight - 2;

      if (isBottom && groupChatList && !loadingGroupChatList && canUploadConv) {
        const lastId = Number(groupChatList.lastId);
        const id = String(lastId + 1);
        getChatListOnScroll(id);
      }
    }
  };

  return (
    <Flex className={s.groupChatListContainer}>
      <Box borderBottom="1px solid var(--chakra-colors-line)" flex={1} p="12px 16px" maxH="60px">
        <InputSearch
          placeholder={`${t("Search by groups")}`}
          searchText={searchText}
          setSearchText={setSearchText}
          data-pw="automation-header-search-input"
        />
      </Box>
      <Flex
        ref={containerRef}
        overflow="auto"
        flexDirection="column"
        onScroll={e => getAdditionalChatsOnScroll(e.currentTarget)}
        flex={1}
      >
        {isOverLimit && (
          <Box className={s.overLimitItem}>
            <ConversationsOverLimitPanel overLimitCount={overLimitCount} />
          </Box>
        )}
        {groupChatList && groupChatList.items.map((el, ind) => <GroupChatListItem chat={el} key={ind} />)}
        {groupChatList && groupChatList.items.length === 0 && !loadingGroupChatList && (
          <Flex height="100%" align="center" justify="center">
            <Text color="var(--chakra-colors-darkGrey)">{`${t("No Group Chats")}`}</Text>
          </Flex>
        )}
        {loadingGroupChatList &&
          Array(5)
            .fill(1)
            .map((_, ind) => (
              <Skeleton mt="8px" key={ind} speed={0.5} startColor="line" endColor="bgLight" minH="60px" width="100%" />
            ))}
      </Flex>
    </Flex>
  );
};
