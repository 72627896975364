import { Dispatch, SetStateAction } from "react";
import { ProfanityFilterRule, ProfanityFilterTypeEnum } from "../../../../../../ProfanityFilterModel";
import { convertTimeFrameToForLong } from "./utils";
import { TIME_FRAME_AMOUNTS, TIME_FRAMES } from "./consts";
import { TimeFramesEnum } from "./types";

interface HandleRuleChange {
  setRules: Dispatch<SetStateAction<ProfanityFilterRule[]>>;
  restriction: ProfanityFilterTypeEnum;
  position: number;
  timeFrameNumber: string;
  timeFrameValue: TimeFramesEnum;
}

export const handleRestriction =
  ({ position, setRules }: Pick<HandleRuleChange, "position" | "setRules">) =>
  (restriction: ProfanityFilterTypeEnum) => {
    setRules((rules: ProfanityFilterRule[]) =>
      rules.map((rule, index) => {
        if (index === position - 1) {
          const newElem: ProfanityFilterRule = {
            ...rule,
            ruleAction: {
              type: restriction,
              forLong:
                restriction === ProfanityFilterTypeEnum.Mute
                  ? convertTimeFrameToForLong(Number(TIME_FRAME_AMOUNTS[TIME_FRAMES[0]][0]), TIME_FRAMES[0])
                  : null,
            },
          };
          return newElem;
        }

        return rule;
      }),
    );

    setRules(rules => {
      const updatedRules: ProfanityFilterRule[] = [];
      let wasBlock = false;

      rules.forEach((rule, index) => {
        if (wasBlock) return;
        if (rule.ruleAction.type === ProfanityFilterTypeEnum.Block) {
          wasBlock = true;
        }

        if (index === position - 1) {
          updatedRules.push({
            ...rule,
            ruleAction: {
              type: restriction,
              forLong:
                restriction === ProfanityFilterTypeEnum.Mute
                  ? convertTimeFrameToForLong(Number(TIME_FRAME_AMOUNTS[TIME_FRAMES[0]][0]), TIME_FRAMES[0])
                  : null,
            },
          });
        } else {
          updatedRules.push(rule);
        }
      });

      return updatedRules;
    });
  };

export const handleRuleChange = ({ setRules, restriction, position, timeFrameNumber, timeFrameValue }: HandleRuleChange) => ({
  timeFrame: () => {
    if (restriction === ProfanityFilterTypeEnum.Mute) {
      setRules(rules =>
        rules.map((rule, index) => {
          if (index === position - 1) {
            const newElem: ProfanityFilterRule = {
              ...rule,
              ruleAction: { ...rule.ruleAction, forLong: convertTimeFrameToForLong(Number(timeFrameNumber), timeFrameValue) },
            };
            return newElem;
          }

          return rule;
        }),
      );
    }
  },
});
