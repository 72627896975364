import {
  Menu,
  MenuButton,
  IconButton,
  Icon,
  MenuList,
  Box,
  PopoverTrigger,
  PopoverArrow,
  Popover,
  Flex,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  Portal,
  MenuItem,
  SystemStyleObject,
} from "@chakra-ui/react";
import { ReactComponent as DotMenu } from "../../../assets/icons/dotMenuIcon.svg";
import { ReactComponent as StarPlan } from "../../../assets/icons/starPlan.svg";

import { TariffPlanRedirectButton } from "../../../common/tariffPlan/TariffPlanRedirectButton";
import { useTranslation } from "react-i18next";

import sp from "../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import { ReactElement } from "react";

interface MenuItemDot {
  icon?: ReactElement;
  text: string;
  clickEvent: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.TouchEvent<HTMLButtonElement> | React.DragEvent<HTMLButtonElement>,
  ) => void;
  color?: string;
  isDisabled?: boolean;
}

export type MenuItemsDot = {
  isActive?: boolean;
  MenuItem?: MenuItemDot;
}[];

interface Props {
  MenuHover?: SystemStyleObject;
  menuItems: MenuItemsDot;
}

export default function DotMenuContainer({ menuItems, MenuHover = { bg: "lightDeepBlue", fill: "midDeepBlue" } }: Props) {
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;

  return (
    <Menu autoSelect={false} size="max" variant="dominoDotsMenu">
      <MenuButton
        onClick={e => e.stopPropagation()}
        fill="darkGrey"
        _hover={MenuHover}
        as={IconButton}
        variant="iconDotTheme"
        icon={<Icon as={DotMenu} boxSize="24px" />}
        data-pw="flow-menu-button"
      />
      <MenuList
        onTouchStart={e => e.stopPropagation()}
        onMouseDown={e => e.stopPropagation()}
        onDragStart={e => e.stopPropagation()}
      >
        {menuItems.map((el, index) => {
          if (el.MenuItem === undefined) {
            return <></>;
          } else if (el.isActive || el.isActive === undefined) {
            return (
              <MenuItem
                key={index}
                onClick={el.MenuItem.clickEvent}
                icon={el.MenuItem.icon}
                data-pw="edit-flow"
                color={el.MenuItem.color || ""}
                isDisabled={el.MenuItem.isDisabled}
              >
                {el.MenuItem.text}
              </MenuItem>
            );
          } else {
            return (
              <Popover placement="auto" key={index} trigger={"hover"} closeOnBlur={false}>
                {({ onClose }) => (
                  <>
                    <PopoverTrigger>
                      <Box className={sp.boxStarHover}>
                        <MenuItem onClick={e => e.stopPropagation()} icon={el.MenuItem?.icon} data-pw="edit-flow">
                          <Flex align="center" justify="space-between">
                            {el.MenuItem?.text}
                            <Icon className={sp.starPlanHover} stroke="#3E5E95" ml="6px" boxSize="20px" as={StarPlan} />
                          </Flex>
                        </MenuItem>
                      </Box>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent
                        onClick={e => e.stopPropagation()}
                        background={"#FEF6DC"}
                        borderColor="#FEF6DC"
                        zIndex={1}
                        maxW={250}
                      >
                        <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                        <Flex alignItems="center" justifyContent="center" direction="column">
                          <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                            {tp("Advanced feature")}
                          </PopoverHeader>
                          <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                            {tp("Please upgrade your plan to create more Flows")}
                          </PopoverBody>
                          <TariffPlanRedirectButton onClose={onClose} />
                        </Flex>
                      </PopoverContent>
                    </Portal>
                  </>
                )}
              </Popover>
            );
          }
        })}
      </MenuList>
    </Menu>
  );
}
