import s from "./PaymentNode.module.scss";
import { ActionNodeProps } from "../ActionNodeProps";
import { PaymentFlowActionModel } from "../../../FlowBuilderModel";
import { useTranslation } from "react-i18next";
import { Text, Flex, Icon, Box } from "@chakra-ui/react";
import TelegramIcon from "../../../../../assets/icons/telegramIcon.svg?react";

import { MessageButtonBlock } from "../MessageButtonBlock/MessageButtonBlock";
import { endingHours, endingMinutes } from "../../../../../common/utils/endingDateTime";
import { AppSettings } from "../../../../../common/AppSettings";

declare const appSettings: AppSettings;

export const PaymentNode = (props: { actionData: ActionNodeProps }) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const data = props.actionData.node.flowAction as PaymentFlowActionModel;

  return (
    <Flex flexDirection="column" alignItems="center">
      <Box className={s.header}>
        <Icon as={TelegramIcon} boxSize="32px" />
        <Box className={s.textBlock}>
          <Box className={s.telegram}>{t("Telegram")}</Box>
          <Box className={s.type}>{t("Payment")}</Box>
        </Box>
      </Box>
      {data && (data.text || data.description) && (
        <MessageButtonBlock borderRadius={`8px 8px 0px 0px`} actionData={props.actionData} />
      )}
      {data && (data.fileUrl || data.fileId) && (
        <Box className={s.imgBox}>
          <img alt="" src={data.fileUrl || `${appSettings.apiBaseUrl}/file/${data.fileId}`} />
        </Box>
      )}
      {data && data.price?.amount && (
        <Flex
          borderTop={`${data.fileUrl || data.fileId ? "" : "1px solid #DAE1FB"}`}
          borderRadius={`0px 0px 8px 8px`}
          background="#EFF5FE"
          w="100%"
          alignSelf="start"
          p="16px"
          gap="8px"
          data-pw="nodePrice"
        >
          <Text variant="medium">{t("editNodePopup.Price")}:</Text>
          <Text variant="medium" color="#3E5E95 !important">
            {data.price?.amount} {data.price?.currency}
          </Text>
        </Flex>
      )}
      {data && (data.gap || data.details) && (
        <Flex flexWrap="wrap" className={s.wrapper} data-pw="payment-details">
          {data.details && data.details?.length > 0 && (
            <Flex w="100%">
              <Text maxW="43%" color="darkGrey">
                {t("editNodePopup.Details")}:&nbsp;
              </Text>
              {data.details.map((el, ind) => (
                <Text
                  key={ind}
                  maxW="60%"
                  color="midDeepBlue !important"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {`${t(`editNodePopup.${el}`)}${data.details?.length !== ind + 1 ? "," : ""}`}
                </Text>
              ))}
            </Flex>
          )}
          {data.gap && (
            <Flex w="100%">
              <Text maxW="43%" color="darkGrey">
                {t("Duration")}:&nbsp;
              </Text>
              <Text maxW="60%" color="midDeepBlue !important" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {`${data.gap.hours || ""}`}
                {`${data.gap.hours ? ` ${endingHours("час", "hour", String(data.gap.hours))} ` : ""}`}
                {`${data.gap.minutes || ""}`}
                {`${data.gap.minutes ? ` ${endingMinutes("минут", "minute", String(data.gap.minutes))}` : ""}`}
              </Text>
            </Flex>
          )}
        </Flex>
      )}
      {!data.text && !data.gap && !data.description && !data.file && !data.price?.amount && (
        <div className={s.noCondition}>
          <h4>{t("Set up the Payment")}</h4>
        </div>
      )}
    </Flex>
  );
};
