import { Icon, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Handle, Position } from "reactflow";
import { CurlFlowActionModel, HandleNegativeTypes, HandlePositiveTypes } from "../../../FlowBuilderModel";
import { ActionNodeProps } from "../ActionNodeProps";
import GlobeIcon from "../../../../../assets/icons/globeIcon.svg?react";
import RefUrlIcon from "../../../../../assets/icons/refUrlIcon.svg?react";
import s from "./CurlMessageNode.module.scss";

function CurlMessageNode(props: { actionData: ActionNodeProps }) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const data = props.actionData.node.flowAction as CurlFlowActionModel;

  const isOkActive = props.actionData.isEdgeInHandle(props.actionData.node.id, HandlePositiveTypes.ok);
  const isErrorActive = props.actionData.isEdgeInHandle(props.actionData.node.id, HandleNegativeTypes.error);

  const highlightCurlCommand = () => {
    const regExp = /(@{[\wа-яА-ЯёЁ_-]+:[\wа-яА-ЯёЁ_-]+})/g;
    const message = data.curlCommand?.split(regExp);
    const highLightedMessage = message?.map((el, index) => {
      if (el.match(regExp)) {
        return (
          <span key={index} className={s.highlightedWord}>
            {el}
          </span>
        );
      }
      return el;
    });
    return highLightedMessage;
  };

  return (
    <Flex flexDirection="column" alignItems="center">
      <div className={s.header}>
        <Icon as={GlobeIcon} fill="midDeepBlue" boxSize="32px" />
        <div className={s.textBlock}>
          <div className={s.type}>{t("HTTP Message")}</div>
        </div>
      </div>

      {data.curlCommand ? (
        <div className={s.wrapper}>
          <div className={s.message}>{highlightCurlCommand()}</div>
        </div>
      ) : (
        <div className={s.noDocument}>
          <Icon as={RefUrlIcon} color="midDeepBlue" boxSize="28px" />
          <h4>{t("editNodePopup.cURL Request")}</h4>
        </div>
      )}

      <div className={s.handleWrp}>
        <div className={s.handle}>
          <Handle
            className={`${s.handleStyle} ${s.handleStyleOk} ${isOkActive ? s.activeOk : ""}`}
            type="source"
            isValidConnection={() => !isOkActive}
            position={Position.Right}
            id={HandlePositiveTypes.ok}
            isConnectable={!isOkActive}
          />
          <p>{t("Success")}</p>
        </div>
        <div className={s.handle}>
          <Handle
            className={`${s.handleStyle} ${s.handleStyleError} ${isErrorActive ? s.activeError : ""}`}
            type="source"
            isValidConnection={() => !isErrorActive}
            position={Position.Right}
            id={HandleNegativeTypes.error}
            isConnectable={!isErrorActive}
          />
          <p>{t("Error")}</p>
        </div>
      </div>
    </Flex>
  );
}

export default CurlMessageNode;
