import { Box, Flex, Text } from "@chakra-ui/react";
import s from "./UtmTags.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { getBotUtmTags, selectBotUtmTags } from "../../BotSlice";
import { useCallback, useEffect, useRef, useState } from "react";
import { BOT_UTM_TAGS_SIZE } from "../../../../common/paginator/paginatorSizes";
import { useTranslation } from "react-i18next";
import { BotModel, BotUtmTagModel, BotUtmTagsModel } from "../../BotModel";
import { UtmTagsItem } from "./UtmTagsItem/UtmTagsItem";

interface Props {
  botInfo: BotModel | undefined;
  botRef: React.RefObject<HTMLDivElement>;
  botId: string;
}

export const UtmTags = ({ botRef, botInfo, botId }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "bot" });
  const dispatch = useAppDispatch();
  const utmTags = useAppSelector(selectBotUtmTags);
  const currentPageRef = useRef(0);
  const loadingRef = useRef(false);
  const [items, setItems] = useState<BotUtmTagModel[]>([]);
  const [tags, setTags] = useState<BotUtmTagsModel | null>(null);

  useEffect(() => {
    dispatch(getBotUtmTags({ botId, page: 1, size: BOT_UTM_TAGS_SIZE }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botId]);

  useEffect(() => {
    if (utmTags) {
      setTags(utmTags);
      setItems(utmTags.items);
      currentPageRef.current = utmTags.currentPage;
      loadingRef.current = false;
    }
  }, [utmTags]);

  const loadCommandsOnScroll = useCallback(() => {
    if (!loadingRef.current && tags && currentPageRef.current < tags.totalPages) {
      const nextPage = currentPageRef.current + 1;
      loadingRef.current = true;
      dispatch(getBotUtmTags({ botId: botInfo?.id ?? "", page: nextPage, size: BOT_UTM_TAGS_SIZE }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, botInfo]);

  const handleScroll = () => {
    if (botRef.current) {
      const scrollHeight = botRef.current.scrollHeight;
      const scrollTop = botRef.current.scrollTop;
      const scrollPosition = scrollHeight - scrollTop;
      const isBottom = scrollPosition < botRef.current?.getBoundingClientRect().bottom;
      if (isBottom) {
        loadCommandsOnScroll();
      }
    }
  };

  useEffect(() => {
    const currentBotRef = botRef.current;
    if (currentBotRef) {
      currentBotRef.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (currentBotRef) {
        currentBotRef.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleScroll]);

  return (
    <Box className={s.utmTagsContainer}>
      <Box className={s.listContainer}>
        <Box className={`${s.row} ${s.header}`}>
          <Box>{t("Identifier")}</Box>
          <Box>{t("Link")}</Box>
          <Box>{t("Transitions")}</Box>
          <Box>{t("Unique")}</Box>
          <Box></Box>
        </Box>
        {utmTags?.items.length ? (
          <Box mb={"36px"} borderRadius="12px" border="1px solid var(--chakra-colors-defaultGrey)" background="white">
            {items.map(item => (
              <UtmTagsItem key={item.id} item={item} botInfo={botInfo} />
            ))}
          </Box>
        ) : (
          <Flex className={s.noData} marginBottom="16px">
            <Text color="black">{t("Your UTM tags will be here")}</Text>
            <Text textAlign={"center"}>{t("No UTM instruction")}</Text>
          </Flex>
        )}
      </Box>
    </Box>
  );
};
