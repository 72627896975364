import s from "../../ContactList.module.scss";
import { Box, Flex, Skeleton, Text } from "@chakra-ui/react";
import React from "react";
import { ContactListModel, ContactModel } from "../../ContactListModel";
import { NavigateFunction, useNavigate } from "react-router-dom";
// import { LanguageCodeEnum } from "../../../../common/user/UserModel";
import { FormattedDateTooltip } from "../../../../UI/atoms/formattedDateTooltip/FormattedDateTooltip";
import DotMenuContainer from "../../../../UI/atoms/DotMenu/DotMenuContainer";
import { useTranslation } from "react-i18next";
import EditIcon from "../../../../assets/icons/editIcon.svg?react";
import RedTrashIcon from "../../../../assets/icons/trash.svg?react";
import { OverflowEllipsesText } from "../../../../UI/atoms/textOverflow/OverflowEllipsesText";
import { LanguageCodeEnum } from "../../../../common/user/UserModel";
import { RenderSmoothImage } from "../../../../UI/molecules/renderSmoothImage/RenderSmoothImage";

interface Props {
  apiBaseUrl: string;
  contactList: ContactListModel | undefined;
  navigate: NavigateFunction;
  setDeletePopupState: (value: React.SetStateAction<{ id: string; itemName: string } | null>) => void;
  lng: LanguageCodeEnum | string;
  search: string;
  loadingContactList: boolean;
}

export const ContactListBig = (props: Props) => {
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const navigate = useNavigate();

  const onEditContactItem = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.TouchEvent<HTMLButtonElement> | React.DragEvent<HTMLButtonElement>,
    contact: ContactModel,
  ) => {
    e.stopPropagation();
    navigate(`/contacts/${contact.id}`, {
      state: { from: `${window.location.pathname}${window.location.search}` },
    });
  };

  return (
    <Box className={s.contactListGrid} bg={!props.contactList?.items ? "transparent !important" : "white"}>
      <Box>
        {props.contactList?.items ? (
          <>
            {props.contactList.items.map(contact => (
              <Box
                borderBottom="1px solid"
                borderColor="bgMiddle"
                className={s.contactListItem}
                key={contact.id}
                onClick={() => props.navigate(`/contacts/${contact.id}`)}
                data-pw="contact-list-item"
              >
                <div />
                {contact.avatars.length ? (
                  <RenderSmoothImage
                    boxSize="36px"
                    alt={contact.lastName ? `${contact.firstName} ${contact.lastName}` : contact.firstName}
                    src={`${props.apiBaseUrl}/file/${contact.avatars[0][0].fileId}`}
                  />
                ) : (
                  <RenderSmoothImage
                    boxSize="36px"
                    alt={contact.lastName ? `${contact.firstName} ${contact.lastName}` : contact.firstName}
                  />
                )}

                <OverflowEllipsesText
                  text={contact.firstName}
                  content={
                    <Text data-pw="contact-first-name" variant="Ymedium" className={s.contactInfoField}>
                      {contact.firstName}
                    </Text>
                  }
                />
                <Text data-pw="contact-last-name" variant="Ymedium" noOfLines={1} maxWidth={"130px"} paddingLeft={"16px"}>
                  {contact.lastName}
                </Text>

                <Text data-pw="contact-username" variant="medium" noOfLines={1} paddingLeft={"16px"}>
                  {contact.username ? `@${contact.username}` : contact.externalId}
                </Text>
                <FormattedDateTooltip lng={props.lng} lastModifiedDate={contact.createDate} />
                <Box className={s.boxIconButton} paddingLeft={"16px"}>
                  <DotMenuContainer
                    menuItems={[
                      {
                        MenuItem: {
                          icon: <EditIcon width="18px" height="18px" />,
                          text: ct("Edit"),
                          clickEvent: e => onEditContactItem(e, contact),
                        },
                      },
                      {
                        MenuItem: {
                          icon: <RedTrashIcon width="18px" height="18px" />,
                          text: ct("Delete"),
                          clickEvent: e => {
                            e.stopPropagation();
                            props.setDeletePopupState({
                              id: contact.id,
                              itemName: `${contact.firstName ?? ""} ${contact.lastName ?? ""}`,
                            });
                          },
                          color: "mainRed",
                        },
                      },
                    ]}
                  />
                </Box>
                <div />
              </Box>
            ))}
          </>
        ) : (
          <>
            <Flex flexDirection="column" gap="8px">
              {Array(15)
                .fill(1)
                .map((el, ind) => (
                  <Skeleton
                    speed={0.5}
                    startColor="line"
                    endColor="bgLight"
                    height="60px"
                    width="100%"
                    borderColor="bgMiddle"
                    borderBottom="1px solid"
                    borderRadius="8px"
                    key={ind}
                  />
                ))}
            </Flex>
          </>
        )}
      </Box>
    </Box>
  );
};
