import AudioIcon from "../../../../../assets/icons/audioIcon.svg?react";
import s from "./AudioMessageNode.module.scss";
import { ActionNodeProps } from "../ActionNodeProps";
import { SendAudioFlowActionModel } from "../../../FlowBuilderModel";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { useTranslation } from "react-i18next";
import { MessageButtonBlock } from "../MessageButtonBlock/MessageButtonBlock";
import { Flex, Icon } from "@chakra-ui/react";
import TelegramIcon from "../../../../../assets/icons/telegramIcon.svg?react";
import { getButtonsWithExactType } from "../../../utils";
import { useRef } from "react";

export const AudioMessageNode = (props: { actionData: ActionNodeProps; audioPlayer: JSX.Element; forceStop?: boolean }) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const data = props.actionData.node.flowAction as SendAudioFlowActionModel;
  const ref = useRef<HTMLDivElement>(null);
  return (
    <Flex flexDirection="column" alignItems="center" ref={ref}>
      <div className={s.header}>
        <Icon as={TelegramIcon} boxSize="32px" />
        <div className={s.textBlock}>
          <div className={s.telegram}>{t("Telegram")}</div>
          <div className={s.type}>{t("Audio Message")}</div>
        </div>
      </div>
      {data.file?.name || data.fileInfo?.name ? (
        <div
          data-pw="audioMessageNode"
          className={s.addedAudioDocument}
          onMouseEnter={() => ref.current?.blur()}
          onMouseLeave={() => ref.current?.focus()}
        >
          {props.audioPlayer}
          <Loading scope={`postFile${props.actionData.node.id}`} />
        </div>
      ) : (
        <div data-pw="audioMessageNode" className={s.noDocument}>
          <Icon as={AudioIcon} fill="darkGrey" boxSize="28px" />
          <h4>{t("Add Audio")}</h4>
          <Loading scope={`postFile${props.actionData.node.id}`} />
        </div>
      )}
      {data.text || getButtonsWithExactType(data?.replyMarkup, props.actionData.node.flowAction)?.length ? (
        <MessageButtonBlock actionData={props.actionData} />
      ) : (
        ""
      )}
    </Flex>
  );
};
