import { useTranslation } from "react-i18next";
import { getFlowBuilderOnboardingSteps } from "./toursConstants";
import { OnboardingToursContainer } from "./OnboardingToursContainer";
import { EventCategories } from "../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { TourNames } from "./OnboardingTourEnums";

export const FlowBuilderOnboardingTour = () => {
  const { t } = useTranslation("translation", { keyPrefix: "onboardingTour" });
  const steps = getFlowBuilderOnboardingSteps(t);

  return (
    <OnboardingToursContainer
      currentTourName={TourNames.FlowBuilderTourName}
      tourLevelNameForGAEvent={EventCategories.OnboardingTwo}
      steps={steps}
    />
  );
};
