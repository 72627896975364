import { SetStateAction } from "react";
import { Flex, Show } from "@chakra-ui/react";
import LogoIcon from "../../../../assets/icons/sidebar/logoIcon.svg?react";
import LogoText from "../../../../assets/icons/sidebar/logoText.svg?react";
import SidebarCrossIcon from "../../../../assets/icons/sidebar/sidebarCrossIcon.svg?react";
import s from "../../Sidebar.module.scss";

interface Props {
  setMobileOpenMenu: (value: SetStateAction<boolean>) => void;
}
export const SideBarMenuPanelMobile = ({ setMobileOpenMenu }: Props) => {
  return (
    <Flex flexDirection={"row"} alignItems="center" className={s.logoBlock}>
      <Show breakpoint="(max-width: 999px)">
        <SidebarCrossIcon className={s.sidebarCrossIcon} onClick={() => setMobileOpenMenu(false)} />
      </Show>
      <Show breakpoint="(min-width: 1000px)">
        <LogoIcon className={s.logoIcon} />
        <LogoText className={s.logoText} />
      </Show>
      <Show breakpoint="(max-width: 999px)">
        <LogoText className={s.logoText} />
      </Show>
    </Flex>
  );
};
