import { Box, Button, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ButtonsHandlersReturnedValue } from "../../handlers";
import s from "./ProfanityFilterButtons.module.scss";
import { useGetLoadingState } from "../../../../../../../../../../../common/loading/hooks/useGetLoadingState";
import { isProfanityFilterEqual } from "../../utils";
import { ProfanityFilterModel, ProfanityFilterRule } from "../../../../ProfanityFilterModel";
import { CUSTOM_PROFANITIES_SPLITTER, PROFANITY_FILTER_DEFAULT } from "../../consts";
import { useAppSelector } from "../../../../../../../../../../../common/state/store";
import { selectIsCustomProfanitiesValid } from "../../../../ProfanityFilterSlice";

interface Props {
  wasChanges: boolean;
  buttonsHandlers: ButtonsHandlersReturnedValue;
  rulesCopy: ProfanityFilterRule[];
  customProfanities: string;
  profanityFilter: ProfanityFilterModel;
}

export const ProfanityFilterButtons = ({ wasChanges, buttonsHandlers, profanityFilter, rulesCopy, customProfanities }: Props) => {
  const { cancelChanges, resetToDefault, saveChanges } = buttonsHandlers;
  const t = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const isSaveChangesLoading = useGetLoadingState("profanityFilter/saveChanges");
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const isCustomProfanitiesValid = useAppSelector(selectIsCustomProfanitiesValid);

  const isResetDisabled =
    wasChanges ||
    (isProfanityFilterEqual.rules(profanityFilter.rules, PROFANITY_FILTER_DEFAULT.rules) &&
      isProfanityFilterEqual.customProfanities(
        profanityFilter.customProfanities,
        PROFANITY_FILTER_DEFAULT.customProfanities.join(CUSTOM_PROFANITIES_SPLITTER),
      ));

  return (
    <Flex gap="8px 16px" direction={isMobile ? "column" : "row"}>
      <Flex gap="8px 16px" flex="1" direction={isMobile ? "column" : "row"}>
        <Button
          variant="dominoOutlineRed"
          w="100%"
          borderColor="var(--chakra-colors-mainRed)"
          className={`${s.cancelButton} ${wasChanges ? s.visible : ""}`}
          onClick={cancelChanges}
        >
          <Text>{t("Cancel")}</Text>
        </Button>
        <Button variant="dominoOutlineViolet" w="100%" onClick={resetToDefault} isDisabled={isResetDisabled}>
          <Text>{t("Reset everything")}</Text>
        </Button>
      </Flex>
      <Box flex="1">
        <Button
          variant="dominoViolet"
          w="100%"
          isDisabled={!wasChanges || isSaveChangesLoading || !isCustomProfanitiesValid}
          onClick={saveChanges}
        >
          {t("Save")}
        </Button>
      </Box>
    </Flex>
  );
};
