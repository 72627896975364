import { Box, Text } from "@chakra-ui/react";
import { ConversationMessageModel } from "../../../../../ConversationModel";
import { useTranslation } from "react-i18next";
import { AppSettings } from "../../../../../../../common/AppSettings";
import { BotModel } from "../../../../../../sidebar/SidebarModel";
import s from "../../../../../Conversation.module.scss";

declare const appSettings: AppSettings;

interface Props {
  message: ConversationMessageModel;
  displayImageIndex: number;
  onReplyClick: (targetMessageId: string) => void;
  botInfo: BotModel | undefined;
}

export const ReplyToMessage = ({ message, displayImageIndex, onReplyClick, botInfo }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "conversation" });

  const handleReply = () => {
    if (message.replyToMessage?.isDeleted) return;
    onReplyClick(message.replyToMessage?.id ?? "");
  };

  return (
    <Box
      className={`${s.messageReplyWrapper} ${message.replyToMessage?.contact !== null ? s.messageReplySharedContactMes : ""}`}
      id={`${message.contact && `reply-${message.id}`}`}
      style={{
        width: message.photos[displayImageIndex]?.width ?? "",
      }}
    >
      <Box
        className={`${s.messageReply} ${message.replyToMessage?.contact !== null ? s.messageReplySharedContactWrp : ""} `}
        onClick={handleReply}
        onTouchStart={handleReply}
      >
        {message.replyToMessage?.contact && (
          <Box className={s.messageReplySharedContact}>
            <Box className={s.contactName}>
              {message.replyToMessage?.contact.lastName && (
                <>
                  <span>{message.replyToMessage?.contact.lastName} </span>
                  <p>&nbsp;</p>
                </>
              )}
              <span> {message.replyToMessage?.contact.firstName}</span>
            </Box>
            <span className={s.contactLabel}>{t("Contact")}</span>
          </Box>
        )}
        {message.replyToMessage?.photoFileId && (
          <span className={s.messageReplyContentPhoto}>
            <img alt="img" className={s.photo} src={`${appSettings.apiBaseUrl}/file/${message.replyToMessage?.photoFileId}`} />
          </span>
        )}
        <div className={s.messageReplyContent}>
          <span className={s.messageReplyContentAuthor}>
            {message.replyToMessage?.author === "bot" ? botInfo?.name : message.replyToMessage?.author}
          </span>

          {message.replyToMessage?.documentName !== null ? (
            <span className={`${s.messageReplyContentText} ${s.description}`}>{message.replyToMessage?.documentName}</span>
          ) : (
            <>
              {message.replyToMessage?.photoFileId && !message.replyToMessage?.text ? (
                <span className={`${s.messageReplyContentText} ${s.description}`}>{t("Image")}</span>
              ) : (
                <span className={s.messageReplyContentText}>{message.replyToMessage?.text}</span>
              )}
            </>
          )}

          {message.replyToMessage?.isDeleted && (
            <Text className={s.messageReplyContentDeleted} color="midDeepBlue">
              {t("Message was deleted")}
            </Text>
          )}
        </div>
      </Box>
    </Box>
  );
};
