import { ProfanityFilterTypeEnum } from "../../../../../../ProfanityFilterModel";
import { TimeFramesEnum } from "./types";

const MAX_HOURS = 23;
export const MAX_DAYS = 29;
const MAX_WEEK = 3;
const MAX_MONTH = 12;

const { Warning, Mute, Block } = ProfanityFilterTypeEnum;
export const RESTRICTIONS: ProfanityFilterTypeEnum[] = [Warning, Mute, Block];
export const RESTRICTIONS_NOT_SUPER_GROUP: ProfanityFilterTypeEnum[] = [Warning, Block];

const { Hour, Day, Week, Month } = TimeFramesEnum;
export const TIME_FRAMES = [Hour, Day, Week, Month];

export const TIME_FRAME_AMOUNTS: Record<TimeFramesEnum, string[]> = {
  [Hour]: new Array(MAX_HOURS).fill(0).map((_, i) => String(i + 1)),
  [Day]: new Array(MAX_DAYS).fill(0).map((_, i) => String(i + 1)),
  [Week]: new Array(MAX_WEEK).fill(0).map((_, i) => String(i + 1)),
  [Month]: new Array(MAX_MONTH).fill(0).map((_, i) => String(i + 1)),
};
