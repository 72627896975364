import { Button, useDisclosure } from "@chakra-ui/react";
import { ConversationInfoModel } from "../../../conversation/ConversationModel";
import BlockIcon from "../../../../assets/icons/blockIcon.svg?react";
import { useTranslation } from "react-i18next";
import { ContactInfoBlockConversationDialog } from "./components/ContactInfoBlockConversationDialog";
import { useAppDispatch } from "../../../../common/state/store";
import { blockConversation } from "../../../conversation/ConversationSlice";

interface Props {
  conversationInfo: ConversationInfoModel;
}

export const ContactInfoBlockConversation = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "contactInfo" });
  const dispatch = useAppDispatch();
  const conversationInfo = props.conversationInfo;
  const modalState = useDisclosure();

  const onBlock = () => {
    dispatch(blockConversation({ conversationId: conversationInfo.id }));
  };

  return (
    <>
      <ContactInfoBlockConversationDialog
        conversationName={conversationInfo.name.trim()}
        isOpen={modalState.isOpen}
        onApply={onBlock}
        onClose={modalState.onClose}
      />
      <Button
        mt="16px"
        mb="24px"
        width={"100%"}
        variant="dominoOutlineRed"
        flexShrink={0}
        iconSpacing={"4px"}
        leftIcon={<BlockIcon color={"inherit"} />}
        onClick={modalState.onOpen}
      >
        {t("Block")}
      </Button>
    </>
  );
};
