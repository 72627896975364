import { ConversationNoteModel } from "../../../ConversationModel";

type GetNoteTextParts = Pick<ConversationNoteModel, "taggedOperatorsNames" | "text">;
interface NoteTextPart {
  text: string;
  isMention: boolean;
}
export const getNoteTextParts = ({ taggedOperatorsNames, text }: GetNoteTextParts): NoteTextPart[] => {
  const operatorsRegex = /\(mention\[(.*?)\]\)/gm;
  const result: NoteTextPart[] = [];
  const parts = text.split(operatorsRegex);

  let index = 0;
  for (const part of parts) {
    const isMention = index++ % 2 === 1;
    result.push({
      isMention: isMention,
      text: isMention ? taggedOperatorsNames.find(i => i.id === part)?.username ?? "" : part,
    });
  }
  return result;
};
