import { Button, Flex, Icon, Skeleton, Text } from "@chakra-ui/react";
import { ReactComponent as StopIcon } from "../../../../../../../../../assets/icons/removeTimeIcon.svg";
import { ReactComponent as BackArrowIcon } from "../../../../../../../../../assets/icons/backArrowIcon.svg";
import { GroupChatListModel } from "../../../../../../../GroupChatModel";
import s from "../../../GroupChatInfo.module.scss";
import { useTranslation } from "react-i18next";
import { ProfanityFilterButton } from "./components/ProfanityFilterButton/ProfanityFilterButton";

interface Props {
  loadingGroupChatInfo: boolean;
  groupChatList: GroupChatListModel | undefined;
}

export const UsefulFunctions = ({ loadingGroupChatInfo, groupChatList }: Props) => {
  const commonWords = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const groupChatInfoTranslation = useTranslation("translation", { keyPrefix: "groupChat.groupChatInfo" }).t;

  return (
    <Flex flexDirection="column" gap="8px">
      <Text variant="large">{groupChatInfoTranslation("Useful functions")}</Text>
      <Flex borderRadius="8px" overflow="hidden" border="1px solid var(--chakra-colors-newGrey)" flexDir="column">
        <ProfanityFilterButton loadingGroupChatInfo={loadingGroupChatInfo} groupChatList={groupChatList} />
        {false && (
          <Skeleton isLoaded={!(loadingGroupChatInfo || groupChatList === undefined)}>
            <Button
              className={s.buttonInfo}
              borderRadius="0px"
              w="100%"
              leftIcon={<Icon className={s.leftIcon} boxSize="20px" as={StopIcon} />}
              rightIcon={<Icon className={s.rightIcon} transform="rotate(180deg)" p="3px" boxSize="20px" as={BackArrowIcon} />}
            >
              <Flex w="100%" align="center" justifyContent="space-between">
                <Text variant="medium">Access control</Text>
                <Text variant="medium" color="var(--chakra-colors-darkGrey)">
                  {commonWords("Off")}
                </Text>
              </Flex>
            </Button>
          </Skeleton>
        )}
      </Flex>
    </Flex>
  );
};
