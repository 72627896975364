import { Flex, Text, Icon } from "@chakra-ui/react";
import DurationTimeEditor from "../../../../../DurationTimeEditor/DurationTimeEditor";
import addTimeIcon from "../../../../../../../../../assets/icons/addTimeIcon.svg?react";
import removeTimeIcon from "../../../../../../../../../assets/icons/TrashGrey.svg?react";
import { useTranslation } from "react-i18next";

interface Props {
  addTime: (dayTime: string) => void;
  removeTime: (dayTime: string, index: number) => void;
  copyDay: (dayTime: string) => void;
  changeTime: (
    newTime: {
      beginTime: string;
      endTime: string;
    },
    index: number,
    dayTime: string,
  ) => void;
  label: string;
  time: {
    beginTime: string;
    endTime: string;
  }[];
  timeMenu: { hours: number; minutes: number }[];
  intersection: boolean;
}

export default function WorkingTimeItem({
  label,
  time,
  addTime,
  removeTime,
  copyDay,
  changeTime,
  timeMenu,
  intersection,
}: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });

  return (
    <Flex justify="space-between" align="start" gap="8px">
      <Text minW="34px">{t(label.slice(0, 3))}</Text>
      <Flex gap="8px" flexDirection="column" className={intersection ? "intersection" : ""}>
        {time.length !== 0 ? (
          time.map((el, index) => (
            <DurationTimeEditor
              time={el}
              index={index}
              label={label}
              addTime={addTime}
              copyDay={copyDay}
              changeTime={changeTime}
              removeTime={removeTime}
              timeMenu={timeMenu}
              key={`${index}${el}`}
              intersection={intersection}
            />
          ))
        ) : (
          <Flex gap="8px">
            <Text color="grey" w="143px">
              {t("Unavailable")}
            </Text>
            <Flex align="center" gap="4px">
              <Icon
                color="#8592A3"
                _hover={{ color: "#089850", bg: "#F1F4F9", borderRadius: "4px" }}
                onClick={() => addTime(label)}
                cursor="pointer"
                as={addTimeIcon}
                boxSize="24px"
              />
              <Icon
                color="#8592A3"
                _hover={{ color: "#EB5038", bg: "#F1F4F9", borderRadius: "4px" }}
                opacity={time && time?.length !== 0 ? 1 : 0}
                as={removeTimeIcon}
                boxSize="24px"
              />
              {/* <Icon
                color="#8592A3"
                _hover={{ color: "#325EE6", bg: "#F1F4F9", borderRadius: "4px" }}
                opacity={time && time?.length !== 0 ? 1 : 0}
                as={copyTimeIcon}
                boxSize="24px"
              /> */}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
