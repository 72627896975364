import { TimeFramesEnum } from "./types";
import { MAX_DAYS } from "./consts";

const DAYS_IN_WEEK = 7;
const DAYS_IN_MONTH = 30;

const { Hour, Day, Week, Month } = TimeFramesEnum;
export function convertTimeFrameToForLong(amount: number, typeOfTime: TimeFramesEnum): string {
  const converterByType = {
    [Hour]: `${amount < 10 ? "0" : ""}${amount}:00:00`,
    [Day]: `${amount}.00:00:00`,
    [Week]: `${amount * 7}.00:00:00`,
    [Month]: `${amount * 30}.00:00:00`,
  };

  return converterByType[typeOfTime];
}

export function convertForLongToTimeFrame(forLong: string): { timeFrameAmount: number; timeFrame: TimeFramesEnum } {
  const splitted = forLong.split(".");

  if (splitted.length > 1) {
    const [days] = splitted;

    if (Number(days) > MAX_DAYS) {
      return {
        timeFrameAmount: Math.floor(Number(days) / DAYS_IN_MONTH),
        timeFrame: TimeFramesEnum.Month,
      };
    } else if (Number(days) % DAYS_IN_WEEK === 0) {
      return {
        timeFrameAmount: Number(days) / DAYS_IN_WEEK,
        timeFrame: TimeFramesEnum.Week,
      };
    } else {
      return {
        timeFrameAmount: Number(days),
        timeFrame: TimeFramesEnum.Day,
      };
    }
  } else {
    const [time] = splitted;
    const splittedTime = time.split(":");

    return {
      timeFrameAmount: Number(splittedTime[0]),
      timeFrame: TimeFramesEnum.Hour,
    };
  }
}
