export enum TourNameGA {
  "GeneralTour" = "OnboardingOne",
  "FlowBuilderTour" = "OnboardingTwo",
  "TriggerTour" = "OnboardingTrigger",
}

export enum TourNames {
  "GeneralTourName" = "GeneralTour",
  "FlowBuilderTourName" = "FlowBuilderTour",
  "TriggerTourName" = "TriggerTour",
}
