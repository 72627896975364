import { ActionNodeProps } from "../ActionNodeProps";
import { useTranslation } from "react-i18next";
import { ShoppingCartFlowActionModel } from "../../../FlowBuilderModel";
import s from "./CartNode.module.scss";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { MessageButtonBlock } from "../MessageButtonBlock/MessageButtonBlock";
import TelegramIcon from "../../../../../assets/icons/telegramIcon.svg?react";
import PlayIcon from "../../../../../assets/icons/Play.svg?react";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { getDraftYamlFile, selectIsAvaliableShoppingCartFile } from "../../../FlowBuilderSlice";
import { useEffect } from "react";

export default function CartNode(props: { actionData: ActionNodeProps }) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const data = props.actionData.node.flowAction as ShoppingCartFlowActionModel;
  const isAvaliableShoppingCartFile = useAppSelector(selectIsAvaliableShoppingCartFile);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isAvaliableShoppingCartFile === undefined) {
      dispatch(getDraftYamlFile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDirection="column" alignItems="center">
      <Box className={s.header}>
        <Icon as={TelegramIcon} boxSize="32px" />
        <Box className={s.textBlock}>
          <Box className={s.telegram}>{t("Telegram")}</Box>
          <Box className={s.type}>{t("Catalog")}</Box>
        </Box>
      </Box>
      {data && (data.text || data.replyMarkup) && <MessageButtonBlock actionData={props.actionData} />}
      {data && isAvaliableShoppingCartFile && (
        <Flex data-pw="cartCatalogUploaded" gap="8px" className={s.preview}>
          <Icon color="#6D5BF7" as={PlayIcon} boxSize="22px" />
          <Text color="black !important" variant="medium">
            {t("editNodePopup.Catalog Uploaded")}
          </Text>
        </Flex>
      )}

      {!(data.text || data.replyMarkup) && !isAvaliableShoppingCartFile && (
        <div className={s.noCondition}>
          <h4>{t("Set up the Catalog")}</h4>
        </div>
      )}
    </Flex>
  );
}
