import {
  ButtonType,
  ButtonTypeDiscriminator,
  CustomVariableScope,
  CustomVariableType,
  MarkupTypeDiscriminator,
  MessageDirection,
  MessageOriginType,
} from "../../common/AppEnums";
import { ConversationTypeEnum } from "./ConversationModel";

export interface ConversationData {
  conversationInfo: ConversationInfoData;
  messages: ConversationMessagesData;
}

export interface LastMessageData {
  text: string | null;
  date: string | null;
  isPhoto: boolean;
  isDocument: boolean;
  isContact: boolean;
  status?: string;
}

export interface ConversationInfoData {
  id: string;
  botId: string;
  contact: {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    avatars: AvatarData[][];
  };
  group: {
    id: string;
    username: string;
    description: string;
    title: string;
    avatars: AvatarData[][];
  };
  name: string;
  status: string;
  avatars: AvatarData[][];
  lastMessage: LastMessageData;
  unreadMessageCount: string;
  flowExecutionId: string;
  activeFlow?: ConversationFlowData;
  groupId?: string;
  isOverLimited: boolean;
  isNew: boolean;
  isBlocked: boolean;
  createDate: string;
  action?: string;
  conversationType: ConversationTypeEnum;
}

export interface ConversationFlowData {
  id: string;
  sourceFlowId: string;
  title: string;
}

export interface ConversationMessagesData {
  items: MessageData[];
  totalItems: number;
  lastId: string;
  nextSearch?: string;
  currentSearch?: string;
  totalSearchItems?: number;
}

export interface BaseMessageData {
  id: string;
  conversationId: string;
  conversation: ConversationInfoData;
  text: string;
  date: Date;
  typeDiscriminator: MessageTypeDiscriminator;
}

export interface ConversationMessageData extends BaseMessageData {
  typeDiscriminator: MessageTypeDiscriminator.Message;
  direction: MessageDirection;
  status: string;
  photos: ConversationMessagePhotoData[];
  shoppingCartOrder: any | null;
  documents: ConversationMessageDocumentData[];
  replyMarkup: InlineKeyboardMarkupData | ReplyKeyboardMarkupData;
  replyToMessage?: ReplyMessageData;
  entities?: ConversationMessageEntity[];
  origin?: MessageOriginData;
  сontact?: SharedContactData;
  externalId: string;
  payment: {
    orderInfo: orderInfoData;
    providerChargeId: string;
    telegramChargeId: string;
    currency: string;
    amount: string;
  };
}

export interface orderInfoData {
  name: string;
  email: string;
  phoneNumber: string;
  shippingAddress: {
    countryCode: string;
    state: string;
    city: string;
    streetLine1: string;
    streetLine2: string;
    postCode: string;
  };
}

export interface ConversationMessageEntity {
  type: string;
  offset: number;
  length: number;
  url?: string;
}

export interface ReplyMessageData {
  id: string;
  text: string;
  author: string;
  photoFileId: boolean;
  documentName: boolean;
  сontact: SharedContactData;
  isDeleted?: boolean;
}

export interface InlineKeyboardMarkupData {
  typeDiscriminator: MarkupTypeDiscriminator.InlineKeyboardMarkup;
  buttons: InlineKeyboardButtonData[][];
}

export interface InlineKeyboardButtonData {
  typeDiscriminator: ButtonTypeDiscriminator.InlineKeyboardButton;
  callbackData?: string;
  url?: string;
  payload: string;
  label: string;
  type: ButtonType;
}

export interface ReplyKeyboardMarkupData {
  typeDiscriminator: MarkupTypeDiscriminator.ReplyKeyboardMarkup;
  buttons: ReplyKeyboardButtonData[][];
}

export interface ReplyKeyboardButtonData {
  typeDiscriminator: ButtonTypeDiscriminator.ReplyKeyboardButton;
  label: string;
}

export interface SystemMessageData extends BaseMessageData {
  typeDiscriminator: MessageTypeDiscriminator.SystemMessage;
  externalId: string;
}

export interface TaggedOperatorData {
  id: string;
  username: string;
}

export interface ConversationNoteData extends BaseMessageData {
  typeDiscriminator: MessageTypeDiscriminator.NoteTextConversation;
  taggedOperatorsNames: TaggedOperatorData[];
  authorName: string;
  externalId: string;
}

export type MessageData = ConversationMessageData | SystemMessageData | ConversationNoteData;

export interface DeletedMessageData {
  conversationId: string;
  messageId: string;
}

export interface ConversationMessagePhotoData {
  fileId: string;
  fileSize: number;
  fileUniqueId: string;
  height: number;
  width: number;
}

export interface ConversationMessageDocumentData {
  fileId: string;
  fileSize: number;
  fileName: string;
}

export interface ConversationSendMessageData {
  text: string;
  entities?: ConversationMessageEntity[];
  photo?: string;
  document?: string;
  typeDiscriminator: OutgoingTypeDiscriminator;
  customVariableIds?: string[];
}

export interface AvatarData {
  fileId: string;
  fileSize: number;
  width: number;
  height: number;
}

export interface MessageOriginData {
  type: MessageOriginType;
  name: string | null;
  id: string | null;
}

export enum OutgoingTypeDiscriminator {
  OutgoingTextMessage = "OutgoingTextMessage",
  OutgoingPhotoMessage = "OutgoingPhotoMessage",
  OutgoingDocumentMessage = "OutgoingDocumentMessage",
  NoteTextConversation = "OutgoingNoteTextConversation",
}

export enum MessageTypeDiscriminator {
  Message = "ConversationMessage",
  SystemMessage = "SystemMessage",
  NoteTextConversation = "NoteConversationMessage",
}

export interface CustomVariableData {
  id?: string;
  containerId: string | null;
  scope: CustomVariableScope;
  key: string;
  type: CustomVariableType;
  description: string;
}

export interface FileData {
  id: string;
  name: string;
  extension: string;
  contentType: string;
  externalId: string;
}

export interface SharedContactData {
  phoneNumber: string;
  firstName: string | null;
  lastName: string | null;
  externalUserId: string;
}
