/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomVariableModel } from "../../common/AppEnums";
import { ValidationModel } from "../../common/ErrorModel";
import { RootState } from "../../common/state/store";
import { DeletedMessageData, OutgoingTypeDiscriminator } from "./ConversationData";
import {
  AssignedItemModel,
  ConversationInfoModel,
  ConversationMessageModel,
  ConversationMessagesModel,
  ConversationSendMessageModel,
  FileUploadType,
  MessageModel,
  TaggedOperatorModel,
} from "./ConversationModel";
import { DeleteMessage } from "./ConversationApi";

export interface ConversationState {
  conversationInfo?: ConversationInfoModel;
  messages?: ConversationMessagesModel;
  variables?: CustomVariableModel[];
  messageValidation: ValidationModel;
  message: ConversationSendMessageModel;
  scrollToMessageId?: string;
  taggedOperators?: TaggedOperatorModel[];
}

const initialState: ConversationState = {
  message: {
    text: "",
    typeDiscriminator: OutgoingTypeDiscriminator.OutgoingTextMessage,
  },
  messageValidation: {
    errors: [],
  },
};

export const conversationSlice = createSlice({
  name: "conversationSlice",
  initialState,
  reducers: {
    getConversationInfo: (state, action: PayloadAction<{ conversationId: string }>) => {
      return {
        ...state,
      };
    },
    getConversationInfoSucceed: (state, action: PayloadAction<ConversationInfoModel>) => {
      return {
        ...state,
        conversationInfo: action.payload,
      };
    },
    getConversationMessages: (
      state,
      action: PayloadAction<{
        conversationId: string;
        lastId: string;
        searchValue?: string;
        currentSearchMessagesLength?: number;
        includeMessageId?: string;
        callback?: () => void;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    getConversationMessagesSucceed: (state, action: PayloadAction<ConversationMessagesModel>) => {
      return {
        ...state,
        messages: action.payload,
      };
    },
    setScrollToMessageId: (state, action: PayloadAction<{ messageId?: string }>) => {
      return {
        ...state,
        scrollToMessageId: action.payload.messageId,
      };
    },
    getTaggedOperators: (state, action: PayloadAction<{ name: string }>) => {
      return {
        ...state,
      };
    },
    getTaggedOperatorsSucceed: (state, action: PayloadAction<TaggedOperatorModel[]>) => {
      return {
        ...state,
        taggedOperators: action.payload,
      };
    },
    clearMessages: state => {
      return {
        ...state,
        messages: undefined,
      };
    },
    getCustomVariables: (state, action: PayloadAction<{ botId: string }>) => {
      return {
        ...state,
      };
    },
    getCustomVariablesSucceed: (state, action: PayloadAction<CustomVariableModel[]>) => {
      return {
        ...state,
        variables: action.payload,
      };
    },
    sendConversationMessage: (
      state,
      action: PayloadAction<{
        conversationId: string;
        message: ConversationSendMessageModel;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    deleteConversationMessage: (state, action: PayloadAction<DeleteMessage>) => {
      return {
        ...state,
      };
    },
    deleteConversationMessageCompleted: (state, action: PayloadAction<ConversationMessagesModel>) => {
      return {
        ...state,
        messages: action.payload,
      };
    },
    setConversationMessage: (state, action: PayloadAction<ConversationSendMessageModel>) => {
      return {
        ...state,
      };
    },
    setConversationMessageCompleted: (state, action: PayloadAction<ConversationSendMessageModel>) => {
      return {
        ...state,
        message: action.payload,
      };
    },
    setMessageValidation: (state, action: PayloadAction<ValidationModel>) => {
      return {
        ...state,
        messageValidation: action.payload,
      };
    },
    uploadMessageFile: (state, action: PayloadAction<{ file: File; type: FileUploadType }>) => {
      return {
        ...state,
      };
    },
    messageSignalReceived: (state, action: PayloadAction<MessageModel>) => {
      return {
        ...state,
      };
    },
    messageSignalReceiveDeleted: (state, action: PayloadAction<DeletedMessageData>) => {
      return {
        ...state,
      };
    },
    readMessages: (
      state,
      action: PayloadAction<{ conversationId: string; messageIds: string[]; lastMessage?: ConversationMessageModel }>,
    ) => {
      return {
        ...state,
      };
    },
    clearConversationState: state => {
      return initialState;
    },
    closeConversation: (state, action: PayloadAction<{ conversationId: string }>) => {
      return {
        ...state,
      };
    },
    blockConversation: (state, action: PayloadAction<{ conversationId: string }>) => {
      return {
        ...state,
      };
    },
    unblockConversation: (state, action: PayloadAction<{ conversationId: string }>) => {
      return {
        ...state,
      };
    },
    assignConversation: (
      state,
      action: PayloadAction<{ conversationId: string; team?: AssignedItemModel; operator?: AssignedItemModel }>,
    ) => {
      return {
        ...state,
        conversationInfo: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...state.conversationInfo!,
          assignedTeam: action.payload.team,
          assignedOperator: action.payload.operator,
        },
      };
    },
  },
});

export const {
  getConversationInfo,
  getConversationInfoSucceed,
  getConversationMessages,
  getConversationMessagesSucceed,
  clearMessages,
  getCustomVariables,
  getCustomVariablesSucceed,
  sendConversationMessage,
  setConversationMessage,
  setConversationMessageCompleted,
  setMessageValidation,
  uploadMessageFile,
  clearConversationState,
  closeConversation,
  assignConversation,
  getTaggedOperators,
  getTaggedOperatorsSucceed,
  setScrollToMessageId,
  blockConversation,
  unblockConversation,
  deleteConversationMessage,
} = conversationSlice.actions;

export const selectConversationMessages = (state: RootState) => state.app.conversationState.messages;
export const selectConversationInfo = (state: RootState) => state.app.conversationState.conversationInfo;
export const selectConversationVariables = (state: RootState) => state.app.conversationState.variables;
export const selectConversationMessageValidation = (state: RootState) => state.app.conversationState.messageValidation;
export const selectConversationMessage = (state: RootState) => state.app.conversationState.message;
export const selectScrollToMessageId = (state: RootState) => state.app.conversationState.scrollToMessageId;
export const selectTaggedOperators = (state: RootState) => state.app.conversationState.taggedOperators;

export default conversationSlice.reducer;
