import { Box, Text } from "@chakra-ui/react";

interface Props {
  titleText: string;
  progressStep?: string;
}

export const TooltipTitleContainer = ({ titleText, progressStep }: Props) => {
  return (
    <Box>
      <Text as="span">{titleText}</Text>
      <Text as="span" fontSize="14px" color="#C1B9FF">
        {progressStep}
      </Text>
    </Box>
  );
};
