import { httpDelete, httpGet, httpPostForm, httpPut } from "../../common/BaseApi";
import { NewAIAssistantFormData } from "./AIAssistantListData";

export const getAIAssistantList = () => {
  return httpGet(`/api/aiAgents`);
};

export const getAIAssistant = (agentId: string) => {
  return httpGet(`/api/aiAgents/${agentId}`);
};

export const createAIAssistant = (newAIAssistantData: NewAIAssistantFormData) => {
  const formData = new FormData();
  formData.append("name", newAIAssistantData.name);

  if (newAIAssistantData.aiSourceText) {
    formData.append("aiSourceText", newAIAssistantData.aiSourceText);
  }
  if (newAIAssistantData.file) {
    formData.append("file", newAIAssistantData.file, newAIAssistantData.file.name);
  }
  return httpPostForm(`/api/aiAgents`, formData);
};

export const updateAIAssistant = (updatedAIAssistantData: NewAIAssistantFormData, agentId: string) => {
  const formData = new FormData();
  formData.append("name", updatedAIAssistantData.name);

  if (updatedAIAssistantData.aiSourceText) {
    formData.append("aiSourceText", updatedAIAssistantData.aiSourceText);
  }
  if (updatedAIAssistantData.file) {
    formData.append("file", updatedAIAssistantData.file, updatedAIAssistantData.file.name);
  }
  return httpPut(`/api/aiAgents/${agentId}`, formData);
};

export const deleteAIAssistant = (agentId: string) => {
  return httpDelete(`/api/aiAgents/${agentId}`);
};
