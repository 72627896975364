import { all, call, put, select, takeEvery, takeLatest, takeLeading } from "redux-saga/effects";
import { groupChatMessagesListSlice, selectConversationInfo, selectConversationMessages } from "./GroupChatMessagesListSlice";
import { signalRConnection } from "../../../../../../../../middlewares/signalRMiddleware";
import {
  ConversationInfoData,
  ConversationMessagesData,
  CustomVariableData,
  DeletedMessageData,
  FileData,
  MessageData,
  MessageTypeDiscriminator,
  OutgoingTypeDiscriminator,
  TaggedOperatorData,
} from "../../../../../../../conversation/ConversationData";
import {
  AssignedItemModel,
  ConversationMessageModel,
  ConversationMessagesModel,
  ConversationSendMessageModel,
  FileUploadType,
  MessageModel,
} from "../../../../../../../conversation/ConversationModel";
import * as mapper from "./GroupChatMessagesListMapper";
import * as GroupChatMessagesListApi from "./GroupChatMessagesListApi";

import { store } from "../../../../../../../..";
import { MessageDirection } from "../../../../../../../../common/AppEnums";
import {
  ValidationError,
  NotFoundRequestError,
  ValidationModel,
  BadRequestError,
} from "../../../../../../../../common/ErrorModel";
import { beginScope, completeScope } from "../../../../../../../../common/loading/LoadingStateActions";
import { notificationSlice } from "../../../../../../../../common/notifications/NotificationSlice";
import { handleException } from "../../../../../../../../common/SagaHelper";
import { FileValidateModel, FileValidate } from "../../../../../../../../common/validation/fileValidator";
import { CustomVariablesModel } from "../../../../../../../flowBuilder/FlowBuilderModel";
import { mapConversationInfoModelToModelList } from "../../../../../../GroupChatListMapper";
import { PayloadAction } from "@reduxjs/toolkit";
import { groupChatListSlice, selectGroupChatList } from "../../../../../GroupChatList/GroupChatListSlice";
import { GroupChatListItemModel, GroupChatListModel } from "../../../../../../GroupChatModel";

export function* groupChatMessagesListSaga() {
  yield takeLatest(groupChatMessagesListSlice.actions.getConversationInfo, getConversationInfo);
  yield takeLatest(groupChatMessagesListSlice.actions.getConversationMessages, getConversationMessages);
  yield takeLeading(groupChatMessagesListSlice.actions.sendConversationMessage, sendConversationMessage);
  yield takeLatest(groupChatMessagesListSlice.actions.getCustomVariables, getCustomVariables);
  yield takeLatest(groupChatMessagesListSlice.actions.setConversationMessage, setConversationMessage);
  yield takeEvery(groupChatMessagesListSlice.actions.uploadMessageFile, uploadMessageFile);
  yield takeEvery(groupChatMessagesListSlice.actions.messageSignalReceived, addMessage);
  yield takeEvery(groupChatMessagesListSlice.actions.messageSignalReceiveDeleted, deleteMessage);
  yield takeEvery(groupChatMessagesListSlice.actions.readMessages, readMessages);
  yield takeLatest(groupChatMessagesListSlice.actions.assignConversation, assignConversation);
  yield takeLatest(groupChatMessagesListSlice.actions.closeConversation, closeConversation);
  yield takeEvery(groupChatMessagesListSlice.actions.getTaggedOperators, getTaggedOperators);

  signalRConnection.addHandler("receiveGroupMessage", (data: MessageData) => {
    const model: MessageModel = mapper.mapMessageToModel(data);
    store.dispatch(groupChatMessagesListSlice.actions.messageSignalReceived(model));
  });

  signalRConnection.addHandler("receiveMessageDeleted", (data: DeletedMessageData) => {
    store.dispatch(groupChatMessagesListSlice.actions.messageSignalReceiveDeleted(data));
  });
}

export function* getConversationInfo(action: PayloadAction<{ conversationId: string }>) {
  try {
    const conversationInfoData: ConversationInfoData = yield call(
      GroupChatMessagesListApi.getConversation,
      action.payload.conversationId,
    );
    const conversationInfoModel = mapper.mapConversationInfoDataToConversationInfoModel(conversationInfoData);
    yield put(groupChatMessagesListSlice.actions.getConversationInfoSucceed(conversationInfoModel));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* closeConversation(action: PayloadAction<{ conversationId: string }>) {
  try {
    yield call(GroupChatMessagesListApi.closeConversation, action.payload.conversationId);
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* getConversationMessages(
  action: PayloadAction<{
    conversationId: string;
    lastId: string;
    searchValue?: string;
    currentSearchMessagesLength?: number;
    includeMessageId?: string;
  }>,
) {
  try {
    yield put(beginScope("messageGroupList"));
    const messagesData: ConversationMessagesData = yield call(
      GroupChatMessagesListApi.getConversationMessages,
      action.payload.conversationId,
      action.payload.lastId,
      action.payload.searchValue,
      action.payload.includeMessageId,
    );

    const currentMessages = store.getState().app.groupChatMessagesListState.messages;
    const updMessages: MessageModel[] = messagesData.items.map(message => {
      switch (message.typeDiscriminator) {
        case MessageTypeDiscriminator.SystemMessage: {
          return {
            ...message,
            text: message.text?.trim(),
          };
        }
        case MessageTypeDiscriminator.NoteTextConversation: {
          return {
            ...message,
            text: message.text?.trim(),
          };
        }
        case MessageTypeDiscriminator.Message:
        default: {
          return {
            ...message,
            replyToMessage: message.replyToMessage,
            text: message.text?.trim(),
            photos: message.photos.map(p => {
              return {
                ...p,
                url: p.fileId != null ? GroupChatMessagesListApi.makeFileUrl(p.fileId) : "/noimage",
              };
            }),
          };
        }
      }
    });

    const concatedMessages = concatMessages(updMessages.reverse(), currentMessages?.items ?? []);
    const isLastPage = concatedMessages.length === messagesData.totalItems;
    const isNextSearch = !isLastPage && !(!messagesData.nextSearch && !!action.payload.searchValue);
    const totalSearchItems = currentMessages?.totalSearchItems
      ? currentMessages.totalSearchItems
      : (action.payload.currentSearchMessagesLength ?? 0) + (messagesData.totalSearchItems ?? 0);
    const model = {
      ...messagesData,
      items: concatedMessages,
      isNextSearch,
      totalSearchItems,
      lastId: messagesData.lastId ?? currentMessages?.lastId,
    };
    yield put(
      groupChatMessagesListSlice.actions.readMessages({
        conversationId: action.payload.conversationId,
        messageIds: model.items.map(message => message.id),
      }),
    );
    yield put(groupChatMessagesListSlice.actions.getConversationMessagesSucceed(model));
    if (action.payload.includeMessageId) {
      yield put(groupChatMessagesListSlice.actions.setScrollToMessageId({ messageId: action.payload.includeMessageId }));
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("messageGroupList"));
  }
}

function* deleteMessage(action: PayloadAction<DeletedMessageData>) {
  const { conversationId, messageId } = action.payload;

  const currentConversation: ReturnType<typeof selectConversationInfo> = yield select(selectConversationInfo);
  if (currentConversation?.id !== conversationId) return;

  const messages: ReturnType<typeof selectConversationMessages> = yield select(selectConversationMessages);

  if (!messages) return;

  const itemsWithoutDeleted = messages.items.filter(message => message.id !== messageId);

  const updatedItems: MessageModel[] = itemsWithoutDeleted.map(message => {
    if ("replyToMessage" in message) {
      if (message.replyToMessage?.id === messageId) {
        return { ...message, replyToMessage: { id: messageId, isDeleted: true, contact: null } } as MessageModel;
      }
    }
    return message;
  });

  yield put(groupChatMessagesListSlice.actions.getConversationMessagesSucceed({ ...messages, items: updatedItems }));
}

export const concatMessages = (model: MessageModel[], prevModel: MessageModel[]) => {
  //remove filtration of messages by id

  // const messageIds = model.map(el => el.id);
  // const tuplePrevModel = prevModel.filter(el => !messageIds.includes(el.id));
  const newModel = [...model];
  newModel.push(...prevModel);
  return newModel;
};

export function* getCustomVariables(action: PayloadAction<{ botId: string }>) {
  try {
    const contactVariablesData: CustomVariableData[] = yield call(GroupChatMessagesListApi.getContactCustomVariables);
    const systemVariablesData: CustomVariableData[] = yield call(GroupChatMessagesListApi.getSystemVariables);
    const variablesModel = systemVariablesData.concat(contactVariablesData);
    yield put(groupChatMessagesListSlice.actions.getCustomVariablesSucceed(variablesModel));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* setConversationMessage(action: PayloadAction<ConversationSendMessageModel>) {
  try {
    const message = {
      ...action.payload,
    };
    // set type depends on content
    let typeDiscriminator = OutgoingTypeDiscriminator.OutgoingTextMessage;
    if (message.photo) {
      typeDiscriminator = OutgoingTypeDiscriminator.OutgoingPhotoMessage;
    }
    if (message.document) {
      typeDiscriminator = OutgoingTypeDiscriminator.OutgoingDocumentMessage;
    }
    const messageModel = {
      ...message,
      typeDiscriminator,
    };
    yield put(groupChatMessagesListSlice.actions.setConversationMessageCompleted(messageModel));
    validateSendMessage(messageModel);
    yield put(groupChatMessagesListSlice.actions.setMessageValidation({ errors: [] }));
  } catch (e: unknown) {
    if (e instanceof ValidationError) {
      yield put(groupChatMessagesListSlice.actions.setMessageValidation(e.validationData));
    } else {
      yield handleException(e);
    }
  }
}

export function* sendConversationMessage(
  action: PayloadAction<{
    conversationId: string;
    message: ConversationSendMessageModel;
  }>,
) {
  try {
    yield put(beginScope("sendMessage"));
    if (action.payload.message.typeDiscriminator === OutgoingTypeDiscriminator.NoteTextConversation) {
      yield put(
        groupChatMessagesListSlice.actions.setConversationMessageCompleted({
          text: "",
          typeDiscriminator: OutgoingTypeDiscriminator.NoteTextConversation,
        }),
      );
    } else {
      yield put(
        groupChatMessagesListSlice.actions.setConversationMessageCompleted({
          text: "",
          typeDiscriminator: OutgoingTypeDiscriminator.OutgoingTextMessage,
        }),
      );
    }
    const customVariables: CustomVariablesModel[] | undefined = store.getState().app.conversationState.variables;
    const conversationId = action.payload.conversationId;
    const message = action.payload.message;
    const messageData = {
      ...message,
      document: message.document?.id,
      photo: message.photo?.id,
    };

    const messages = mapper.mapMessageToArray(messageData, customVariables);
    yield all(messages.map(m => call(GroupChatMessagesListApi.sendMessage, conversationId, m)));
    yield put(
      groupChatMessagesListSlice.actions.setMessageValidation({
        errors: [
          {
            field: "text",
            message: "Message is required",
          },
        ],
      }),
    );
    if (messageData.photo) {
      const filter = store.getState().app.chatListState.filter;
      if (filter.type === "closed") {
        //yield put(contactInfoSlice.actions.getContactInfoFiles({ conversationId }));
      }
      //yield put(contactInfoSlice.actions.incMediaFilesCompleted());
    }
    yield put(completeScope("sendMessage"));
  } catch (e: unknown) {
    yield handleException(e);
    yield put(completeScope("sendMessage"));
  }
}

function* uploadMessageFile(action: PayloadAction<{ file: File; type: FileUploadType }>) {
  try {
    validateFile(action.payload.file, action.payload.type);
    yield put(beginScope("messageFileUpload"));
    const data: FileData = yield call(GroupChatMessagesListApi.postFile, action.payload.file);
    const message = store.getState().app.groupChatMessagesListState.message;
    const updatedMessage = {
      ...message,
      ...(action.payload.type === FileUploadType.photo && { photo: data }),
      ...(action.payload.type === FileUploadType.document && {
        document: data,
      }),
    };

    yield put(groupChatMessagesListSlice.actions.setConversationMessage(updatedMessage));
  } catch (e: unknown) {
    if (e instanceof ValidationError) {
      yield put(
        notificationSlice.actions.notify({
          message: e.validationData.errors[0].message,
          type: "warning",
        }),
      );
    } else {
      yield handleException(e);
    }
  } finally {
    yield put(completeScope("messageFileUpload"));
  }
}

function* addMessage(action: PayloadAction<MessageModel>) {
  const conversationInfo: ReturnType<typeof selectConversationInfo> = yield select(selectConversationInfo);
  const chatList: ReturnType<typeof selectGroupChatList> = yield select(selectGroupChatList);

  if (!chatList) {
    return;
  }

  if (conversationInfo?.id !== action.payload.conversation.id) {
    yield put(groupChatListSlice.actions.updateChatInfo(mapConversationInfoModelToModelList(action.payload.conversation)));
    return;
  }

  const currentMessages = store.getState().app.groupChatMessagesListState.messages;
  if (!currentMessages) {
    return;
  }

  let items = [...currentMessages.items];
  if (items.some(x => x.id === action.payload.id)) {
    items = items.map(x => {
      if (x.id === action.payload.id) {
        return action.payload;
      }
      return x;
    });
  } else {
    items = mapper.mapMessagesToSortMessages([...items, action.payload]);
  }

  const model: ConversationMessagesModel = {
    ...currentMessages,
    items,
  };

  if (action.payload.typeDiscriminator === MessageTypeDiscriminator.Message) {
    if (action.payload.direction === MessageDirection.Outgoing) {
      yield put(groupChatListSlice.actions.updateChatInfo(mapConversationInfoModelToModelList(action.payload.conversation)));
    }
    if (action.payload.direction === MessageDirection.Incoming) {
      const conversationLastMessage = action.payload as ConversationMessageModel;
      yield put(
        groupChatMessagesListSlice.actions.readMessages({
          conversationId: conversationInfo?.id,
          messageIds: [action.payload.id],
          lastMessage: conversationLastMessage,
        }),
      );
    }
  }
  yield put(groupChatMessagesListSlice.actions.getConversationMessagesSucceed(model));
}

function* readMessages(
  action: PayloadAction<{ conversationId: string; messageIds: string[]; lastMessage?: ConversationMessageModel }>,
) {
  try {
    const chatList: GroupChatListModel | undefined = store.getState().app.groupChatListState.chatList;
    if (chatList) {
      let chatListItem: GroupChatListItemModel | undefined = chatList.items.find(el => el.id === action.payload.conversationId);
      if (chatListItem) {
        const messagesPerPageCount = 10;
        let unreadMessageCount = parseInt(chatListItem.unreadMessageCount) - messagesPerPageCount;
        unreadMessageCount = unreadMessageCount > 0 ? unreadMessageCount : 0;
        const unreadMessageCountString = unreadMessageCount ? unreadMessageCount.toString() : "";
        const lastMessage = mapper.mapMessageModelToLastMessage(action.payload.lastMessage);
        chatListItem = {
          ...chatListItem,
          ...(lastMessage && { lastMessage }),
          unreadMessageCount: unreadMessageCountString,
        };
        yield put(groupChatListSlice.actions.updateChatInfo(chatListItem));
        yield call(GroupChatMessagesListApi.readMessages, action.payload.conversationId, action.payload.messageIds);
      }
    }
  } catch (e: unknown) {
    if (e instanceof NotFoundRequestError) {
      yield put(
        notificationSlice.actions.notify({
          message: "Something wrong with API",
          type: "warning",
        }),
      );
    } else {
      yield handleException(e);
    }
  }
}

const validateSendMessage = (message: ConversationSendMessageModel) => {
  const inputMaxLength = 2000;

  const result: ValidationModel = {
    errors: [],
  };
  switch (message.typeDiscriminator) {
    case OutgoingTypeDiscriminator.OutgoingTextMessage: {
      if (message.text.length < 1) {
        result.errors.push({
          field: "text",
          message: "Message is required",
        });
      }
      if (message.text.length && message.text.trim().length === 0) {
        result.errors.push({
          field: "text",
          message: "Message contains only empty chars",
        });
      }
      if (message.text.length > inputMaxLength) {
        result.errors.push({
          field: "text",
          message: "Message is too long",
        });
      }
      break;
    }
    case OutgoingTypeDiscriminator.OutgoingDocumentMessage:
    case OutgoingTypeDiscriminator.OutgoingPhotoMessage: {
      if (message.text.length && message.text.trim().length === 0) {
        result.errors.push({
          field: "text",
          message: "Message contains only empty chars",
        });
      }
      if (message.text.length > inputMaxLength) {
        //we send photo and text as separate messages
        result.errors.push({
          field: "text",
          message: "Message is too long",
        });
      }
      break;
    }
  }

  if (result.errors.length > 0) {
    throw new ValidationError(result);
  }
};
const validateFile = (file: File, type: FileUploadType) => {
  const result: FileValidateModel = FileValidate(file, type);
  if (result.isInvalid) {
    throw new BadRequestError(result.error);
  }
};

export function* assignConversation(
  action: PayloadAction<{ conversationId: string; team?: AssignedItemModel; operator?: AssignedItemModel }>,
) {
  try {
    yield call(
      GroupChatMessagesListApi.assignConversation,
      action.payload.conversationId,
      action.payload.team ? action.payload.team.id : null,
      action.payload.operator ? action.payload.operator.id : null,
    );
  } catch (e: unknown) {
    yield handleException(e);
  }
}

export function* getTaggedOperators(name: PayloadAction<{ name: string }>) {
  try {
    const data: TaggedOperatorData[] = yield call(GroupChatMessagesListApi.getOrganisationUsers, name.payload.name);
    const model = data;
    yield put(groupChatMessagesListSlice.actions.getTaggedOperatorsSucceed(model));
  } catch (e: unknown) {
    yield handleException(e);
  }
}
