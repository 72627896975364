import { useTranslation } from "react-i18next";
import CrossIcon from "../../../../assets/icons/cross.svg?react";
import s from "./NewOrganisationModal.module.scss";
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  ModalBody,
  Flex,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useAppDispatch } from "../../../../common/state/store";
import { createOrganisation } from "../../OrganisationSlice";
import { useState } from "react";
import { OrganisationModel } from "../../OrganisationModel";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const NewOrganisationModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "organisation.newOrganisationModal" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const dispatch = useAppDispatch();
  const [organisationName, setorganisationName] = useState("");
  const [isOrganisationNameError, setIsOrganisationNameError] = useState<boolean>(false);

  const handleClose = () => {
    setorganisationName("");
    setIsOrganisationNameError(false);
    onClose();
  };

  const handleTeamNameInputBlur = () => {
    if (!organisationName.length) {
      setIsOrganisationNameError(true);
      return;
    }
    setIsOrganisationNameError(false);
  };

  const onCreate = () => {
    const newOrganisation: OrganisationModel = {
      name: organisationName,
      id: "",
    };
    dispatch(createOrganisation(newOrganisation));
    handleClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent maxW={{ base: "330px", sm: "460px" }}>
          <ModalHeader p="32px" textAlign="center">
            <Text variant="h1">{t("Create new Organisation")}</Text>
          </ModalHeader>
          <ModalCloseButton
            borderRadius={"50%"}
            color={"darkGrey"}
            _hover={{ bg: "defaultGrey", color: "mainPurple" }}
            data-pw="close-modal-button"
          >
            <CrossIcon />
          </ModalCloseButton>
          <ModalBody px="32px" py="0" className={s.modalBody}>
            <FormControl height="93px" isInvalid={isOrganisationNameError}>
              <FormLabel>{t("Organisation name")}</FormLabel>
              <Input
                value={organisationName}
                onChange={e => setorganisationName(e.target.value)}
                onBlur={handleTeamNameInputBlur}
                variant="outline"
                placeholder={t("Enter new Organisation name") ?? ""}
                data-pw="organisation-name-input"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter pr="32px" pl="32px">
            <Flex width="100%" justifyContent="space-between">
              <Button variant="dominoOutlineViolet" onClick={handleClose} data-pw="cancel-button">
                {ct("Cancel")}
              </Button>
              <Button
                onClick={onCreate}
                isDisabled={!organisationName.trim().length}
                variant="dominoViolet"
                data-pw="create-button"
              >
                {ct("Create")}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
