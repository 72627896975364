import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Icon,
  Box,
  Link,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CrossIcon from "../../../../assets/icons/cross.svg?react";
import { useEffect } from "react";
import { useAppDispatch } from "../../../../common/state/store";
import { checkIsTeamInUse } from "../../OrganisationSlice";
import { Loading } from "../../../../common/loading/LoadingStateContainer";

interface Props {
  deleteItemState: { id: string; itemName: string } | null;
  flows?: { id: string; name: string }[];
  onDelete: (teamId: string) => void;
  onClose: () => void;
}

export const DeleteTeamPopup = (props: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "organisation.deletePopup",
  });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const dispatch = useAppDispatch();

  const onDeleteClick = () => {
    props.onDelete(props.deleteItemState?.id ?? "");
    props.onClose();
  };

  useEffect(() => {
    if (props.deleteItemState?.id) {
      dispatch(checkIsTeamInUse({ teamId: props.deleteItemState?.id ?? "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deleteItemState]);

  return (
    <Modal isOpen={Boolean(props.deleteItemState)} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent width={{ base: "300px", sm: "460px" }} maxW="460px" borderRadius="12px">
        <ModalHeader>{t("Delete Team")}</ModalHeader>
        <ModalCloseButton borderRadius={"50%"} color={"darkGrey"} _hover={{ bg: "defaultGrey", color: "mainPurple" }}>
          <Icon boxSize="20px" as={CrossIcon} />
        </ModalCloseButton>
        <ModalBody paddingTop={"0"}>
          {!props.flows && (
            <Box className="loaderContainer">
              <Loading scope={"checkVariableUsage"} />
            </Box>
          )}

          {props.flows && props.flows.length < 1 && (
            <Box>
              {t("Do you really want to delete Team")} <b>{props.deleteItemState?.itemName}</b>
            </Box>
          )}

          {props.flows && props.flows.length > 0 && (
            <>
              <Box>
                <b>{props.deleteItemState?.itemName} </b>
                {t("can't be deleted because it is assigned in flow(s)")}:
              </Box>
              {props.flows.map(flow => (
                <Box key={flow.id}>
                  <Link href={`/automation/flows/${flow.id}`} isExternal={true}>
                    <b>{flow.name}</b>
                  </Link>
                </Box>
              ))}
              <Box>{t("Please, remove Team from the flow(s) and try again.")}</Box>
            </>
          )}
        </ModalBody>
        <ModalFooter paddingBottom="32px" justifyContent="space-between">
          <Button variant="dominoOutlineViolet" onClick={props.onClose}>
            {ct("Cancel")}
          </Button>
          <Button variant="dominoPrimaryRed" mr={3} onClick={onDeleteClick} isDisabled={!props.flows || props.flows.length > 0}>
            {ct("Remove")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
