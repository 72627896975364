import {
  Menu,
  MenuButton,
  Button,
  Icon,
  Flex,
  MenuList,
  MenuItem,
  IconButton,
  Box,
  useMediaQuery,
  Skeleton,
} from "@chakra-ui/react";
import { ChatListFilterTypes, ChatListModel, ChatListSortEnum, TeamsModel } from "../../../../ChatListModel";
import { getMyTeams, selectFilter, selectSort, setFilter, setSort } from "../../../../ChatListSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../common/state/store";
import { useTranslation } from "react-i18next";

import { ReactComponent as DownArrowMenu } from "../../../../../../assets/images/downArrow.svg";
import { ReactComponent as FilterNewestIcon } from "../../../../../../assets/icons/filterNewestIcon.svg";
import { ReactComponent as FilterOldestIcon } from "../../../../../../assets/icons/filterOldestIcon.svg";

import s from "../../../../ChatList.module.scss";
import { useEffect } from "react";
import { getFilterFromStorage } from "../../../../ChatListSaga";
import { useGAWithCustomParameters } from "../../../../../../common/ga/GAEventTracker";
import { ChatEvent } from "../../../../../../common/ga/gaEventsEnums.ts/ChatGaEventsEnums";
import { EventCategories } from "../../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

interface Props {
  myTeams?: TeamsModel;
  chatList?: ChatListModel;
}

export default function ChatListFilters({ myTeams, chatList }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "chatList" });
  const dispatch = useAppDispatch();

  const [isTablet] = useMediaQuery("(min-width: 1000px) and (max-width: 1269px)");
  const [isMobile] = useMediaQuery("(max-width: 999px)");

  const filter = useAppSelector(selectFilter);

  const trackEvent = useGAWithCustomParameters(EventCategories.Chats);

  useEffect(() => {
    const storageFilter: {
      type: ChatListFilterTypes;
      teamId?: string;
      teamName?: string;
    } = getFilterFromStorage();
    if (storageFilter && storageFilter?.type !== filter.type) {
      if (storageFilter.type === "assignedToOneOfMyTeams") {
        if (storageFilter.teamId && myTeams?.items.find(el => el.id === storageFilter.teamId)) {
          dispatch(setFilter(storageFilter));
        }
      } else {
        dispatch(setFilter(storageFilter));
      }
    } else if (storageFilter && storageFilter?.type === filter.type) {
      if (storageFilter.type === "assignedToOneOfMyTeams") {
        if (storageFilter.teamId && myTeams?.items.find(el => el.id === storageFilter.teamId)) {
          dispatch(setFilter(storageFilter));
        } else {
          dispatch(setFilter({ type: "all" }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const sort = useAppSelector(selectSort);
  const ChatlistFilterEnum = {
    open: "Open",
    all: "All",
    closed: "Closed",
    assignedToMe: "Assigned to me",
    assignedToMyTeams: "Assigned to my Teams",
    assignedToOneOfMyTeams: "Assigned to one of my Teams",
  };

  const ChatlistFilterEnumButton = {
    open: t("Open"),
    all: t("All"),
    closed: t("Closed"),
    assignedToMe: t("Assigned to me"),
    assignedToMyTeams: t("Assigned to my Teams"),
    assignedToOneOfMyTeams: filter.teamName,
  };

  const getAdditionalTeams = () => {
    if (myTeams) {
      dispatch(getMyTeams({ page: myTeams.currentPage + 1 }));
    }
  };
  return (
    <Box className={s.filterSortBlock}>
      <Box className={s.sortBlock}>
        <Menu variant="dominoDotsMenu" gutter={0} offset={[-30, 0]}>
          {({ isOpen, onClose }) => (
            <>
              <MenuButton
                data-pw="chat-filter-button"
                isActive={isOpen}
                as={Button}
                variant="sideBar"
                p={0}
                color="black"
                _hover={{
                  color: "black",
                }}
                _active={{
                  color: "black",
                }}
                onClick={e => e.stopPropagation()}
                rightIcon={
                  <Icon
                    as={DownArrowMenu}
                    boxSize="24px"
                    color="midDeepBlue"
                    borderRadius={"4px"}
                    transition="ease-out .2s"
                    bg={isOpen ? "lightDeepBlue" : ""}
                  />
                }
              >
                <Flex maxW={isTablet ? "120px" : "150px"}>
                  <Box w="100%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                    {ChatlistFilterEnumButton[filter.type as keyof typeof ChatlistFilterEnumButton]}
                  </Box>
                  <Box position="relative">
                    <span className={s.chatQuantity}>{chatList?.totalItems ?? 0}</span>
                    <Skeleton
                      isLoaded={chatList && chatList?.totalItems > -1}
                      position="absolute"
                      top={0}
                      left="4px"
                      width="100%"
                      height="100%"
                      speed={0.5}
                      opacity={1}
                      startColor="line"
                      endColor="bgLight"
                      borderColor="line"
                      borderRadius="4px"
                    />
                  </Box>
                </Flex>
              </MenuButton>
              <MenuList className={s.menuList}>
                <MenuItem
                  data-pw="open-chats"
                  onClick={() => {
                    dispatch(setFilter({ type: "open" }));
                  }}
                >
                  {t(ChatlistFilterEnum.open)}
                </MenuItem>
                <MenuItem
                  data-pw="closed-chats"
                  onClick={() => {
                    dispatch(setFilter({ type: "closed" }));
                  }}
                >
                  {t(ChatlistFilterEnum.closed)}
                </MenuItem>
                <MenuItem
                  data-pw="all-chats"
                  onClick={() => {
                    dispatch(setFilter({ type: "all" }));
                  }}
                >
                  {t(ChatlistFilterEnum.all)}
                </MenuItem>
                <MenuItem
                  data-pw="assigned-to-me-chats"
                  onClick={() => {
                    dispatch(setFilter({ type: "assignedToMe" }));
                  }}
                >
                  {t(ChatlistFilterEnum.assignedToMe)}
                </MenuItem>
                {!!myTeams?.items.length && (
                  <MenuItem
                    data-pw="assigned-to-my-teams-chats"
                    onClick={() => {
                      dispatch(setFilter({ type: "assignedToMyTeams" }));
                    }}
                  >
                    {t(ChatlistFilterEnum.assignedToMyTeams)}
                  </MenuItem>
                )}
                {!!myTeams?.items.length && (
                  <Menu placement={!isMobile ? "right-start" : "bottom"}>
                    <MenuButton
                      p="8px"
                      _active={{ backgroundColor: "none" }}
                      _hover={{ backgroundColor: "bgLight" }}
                      data-pw="assigned-to-one-of-my-teams"
                    >
                      <Flex alignItems="center">
                        {t(ChatlistFilterEnum.assignedToOneOfMyTeams ?? "")}
                        <Icon as={DownArrowMenu} boxSize="15px" transform="rotate(270deg)" ml="14px" />
                      </Flex>
                    </MenuButton>
                    <MenuList className={s.menuTeamsList}>
                      {myTeams?.items?.map(el => {
                        return (
                          <MenuItem
                            data-pw={el.name}
                            key={el.id}
                            p="8px"
                            onClick={() => {
                              onClose();
                              dispatch(
                                setFilter({
                                  type: "assignedToOneOfMyTeams",
                                  teamId: el.id,
                                  teamName: el.name,
                                }),
                              );
                            }}
                          >
                            {el.name}
                          </MenuItem>
                        );
                      })}
                      {myTeams && myTeams.currentPage < myTeams.totalPages && (
                        <MenuItem
                          data-pw="show-more-chats"
                          closeOnSelect={false}
                          onClick={() => getAdditionalTeams()}
                          height={{ base: "40px", lg: "40px" }}
                        >
                          <Flex alignItems={"center"} justifyContent={"center"} w={"100%"} cursor={"pointer"}>
                            <div>{t("Show more")}</div>
                            <Icon as={DownArrowMenu} boxSize="26px" color="midDeepBlue" />
                          </Flex>
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                )}
              </MenuList>
            </>
          )}
        </Menu>
      </Box>
      <Box className={s.fiilterBlock}>
        <Menu variant="dominoDotsMenu" gutter={0} offset={[-30, 0]}>
          {({ isOpen }) => (
            <>
              <MenuButton
                data-pw="sort-chats"
                isActive={isOpen}
                as={IconButton}
                variant="sideBar"
                p={0}
                color="black"
                onClick={e => e.stopPropagation()}
                icon={sort === ChatListSortEnum.oldest ? <FilterOldestIcon /> : <FilterNewestIcon />}
              ></MenuButton>
              <MenuList className={s.menuList}>
                <MenuItem
                  data-pw="newest-chats"
                  onClick={() => {
                    dispatch(setSort(ChatListSortEnum.newest));
                    trackEvent(ChatEvent.ChatFilter, "", { filter: "New" });
                  }}
                >
                  {t("Newest")}
                </MenuItem>
                <MenuItem
                  data-pw="oldest-chats"
                  onClick={() => {
                    dispatch(setSort(ChatListSortEnum.oldest));
                    trackEvent(ChatEvent.ChatFilter, "", { filter: "Old" });
                  }}
                >
                  {t("Oldest")}
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Box>
    </Box>
  );
}
