import { httpPost } from "../../../../../../../common/BaseApi";
import { ProfanityFilterModel } from "./ProfanityFilterModel";

interface ChangeProfanityFilter {
  profanityFilter: ProfanityFilterModel;
  groupId: string;
}
export const changeProfanityFilter = ({ profanityFilter, groupId }: ChangeProfanityFilter) => {
  return httpPost(`/api/group/${groupId}/profanity`, profanityFilter);
};
