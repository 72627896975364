/* eslint-disable @typescript-eslint/no-explicit-any */
import { HubConnection } from "@microsoft/signalr";
import { AppSettings } from "../common/AppSettings";
import { ApiTokenModel } from "../common/auth/AuthModel";
import { authSlice } from "../common/auth/AuthSlice";
import { HubSettings, setupHub } from "./hubSetup";

declare const appSettings: AppSettings;

let conversationHubConnection: HubConnection;
let groupHubConnection: HubConnection;

export class HubConnectionWrapper {
  constructor() {
    this.handlers = [];
  }

  handlers: { methodName: string; method: (...args: any[]) => any }[];

  addHandler(methodName: string, method: (...args: any[]) => any) {
    this.handlers = [...this.handlers, { methodName, method }];
  }

  onCreated(connection: HubConnection) {
    this.handlers.forEach(handler => {
      connection.on(handler.methodName, handler.method);
    });
  }
}

let authToken: ApiTokenModel | null = null;

export const signalRConnection: HubConnectionWrapper = new HubConnectionWrapper();

const signalRMiddleware = () => (next: any) => async (action: any) => {
  // register signalR after the tokenReceived
  if (!authSlice.actions.tokenReceived.match(action)) {
    return next(action);
  }

  const isNewToken = authToken?.token !== action.payload.token;
  authToken = action.payload as ApiTokenModel;

  const accessTokenFactory = (): Promise<string> => {
    return new Promise(resolve => {
      resolve(authToken?.token ?? "");
    });
  };

  const settings: HubSettings = {
    appSettings,
    accessTokenFactory,
    authToken,
    isNewToken,
  };

  conversationHubConnection = await setupHub(conversationHubConnection, settings, next, action, "/conversation");
  groupHubConnection = await setupHub(groupHubConnection, settings, next, action, "/group");

  return next(action);
};

export default signalRMiddleware;
