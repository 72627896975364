import { useEffect, useRef, useState } from "react";
import { Box, Button, Flex, Link, Tooltip } from "@chakra-ui/react";
import { ConversationMessageModel } from "../../ConversationModel";
import LinkOut from "../../../../assets/icons/linkOut.svg?react";
import { ButtonType, ButtonTypeDiscriminator, MarkupTypeDiscriminator } from "../../../../common/AppEnums";
import s from "../../Conversation.module.scss";

interface Props {
  message: ConversationMessageModel;
}

export const ButtonMessageItem = (props: Props) => {
  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const [showTooltip, setShowTooltip] = useState<boolean[]>([]);

  const timeString = new Date(props?.message?.date).toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    hourCycle: "h23",
  });

  const messageTextLength = props.message.text?.length;
  const messagePhotoLength = props.message.photos.length;
  const messageDocumentLength = props.message.documents.length;

  useEffect(() => {
    const checkOverflow = () => {
      const showTooltipCurrent = buttonRefs.current?.map(button => {
        if (button !== null) {
          const isOverflowing = button.scrollWidth > button.clientWidth;
          return isOverflowing;
        }
        return false;
      });

      setShowTooltip(showTooltipCurrent);
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [props.message.replyMarkup]);

  return (
    <Box>
      <Box className={s.messageButtons}>
        {props.message.replyMarkup !== null &&
        props.message.replyMarkup?.typeDiscriminator === MarkupTypeDiscriminator.InlineKeyboardMarkup ? (
          <>
            {props.message.replyMarkup?.buttons?.map(button =>
              button.map((b, index) => (
                <>
                  {b.typeDiscriminator === ButtonTypeDiscriminator.InlineKeyboardButton && b.type === ButtonType.UrlButton ? (
                    <Link key={index} isExternal _hover={{ textDecoration: "none" }} href={b?.url}>
                      <Button
                        mt="2px"
                        variant="messageButton"
                        cursor="pointer"
                        width="100%"
                        className={`${props.message.direction === "Incoming" ? s.singleChild : ""} ${
                          (!!messageDocumentLength || messagePhotoLength > 0 || messageTextLength > 0) && s.firstBtn
                        }`}
                        position="relative"
                      >
                        <LinkOut className={s.linkOut} />
                        {b.label}
                      </Button>
                    </Link>
                  ) : (
                    <>
                      <Button
                        key={index}
                        mt="2px"
                        variant="messageButton"
                        cursor="default"
                        className={`${props.message.direction === "Incoming" ? s.singleChild : ""} ${
                          (!!messageDocumentLength || messagePhotoLength > 0 || messageTextLength > 0) && s.firstBtn
                        }`}
                        position="relative"
                      >
                        {b.label}
                      </Button>
                      {props.message.direction === "Incoming" ? (
                        <Flex fontSize={12} color="darkGrey" justify="end">
                          {!messagePhotoLength && !messageDocumentLength && <Box className={s.date}>{timeString}</Box>}
                        </Flex>
                      ) : null}
                    </>
                  )}
                </>
              )),
            )}
          </>
        ) : (
          <>
            {props.message.replyMarkup?.buttons?.map((button, i) =>
              button.map((b, index) => (
                <>
                  <Tooltip
                    key={`${i}-${index}`}
                    hasArrow
                    variant="dominoChat"
                    label={b.label}
                    openDelay={500}
                    placement="top"
                    background="#FEF6DC"
                    isDisabled={!showTooltip[i * button.length + index]}
                  >
                    <Button
                      key={index}
                      variant="messageButton"
                      className={`${props.message.direction === "Incoming" ? s.singleChild : ""} ${
                        (!!messageDocumentLength || messagePhotoLength > 0 || messageTextLength > 0) && s.firstBtn
                      } ${s.replyButton}`}
                      ref={el => (buttonRefs.current[i * button.length + index] = el)}
                    >
                      {b.label}
                    </Button>
                  </Tooltip>
                </>
              )),
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
