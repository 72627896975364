import { httpDelete, httpGet, httpPost } from "../../../../../../common/BaseApi";

export const getMembers = (page: number, size: number, groupId: string, search: string) => {
  return httpGet(
    `/api/group/${groupId}/member?page=${page}&size=${size}&filterBy=FirstName&filterBy=LastName&filterBy=Username&filter=${search}`,
  );
};

export const blockMember = (memberId: string, botId: string) => {
  return httpPost(`/api/group/member/${memberId}/ban/${botId}`, {});
};

export const unlockMember = (memberId: string, botId: string) => {
  return httpPost(`/api/group/member/${memberId}/unban/${botId}`, {});
};

export const muteMember = (memberId: string, botId: string) => {
  return httpPost(`/api/group/member/${memberId}/mute/${botId}`, {});
};

export const unmuteMember = (memberId: string, botId: string) => {
  return httpPost(`/api/group/member/${memberId}/unmute/${botId}`, {});
};

export const deleteMember = (memberId: string, botId: string) => {
  return httpDelete(`/api/group/member/${memberId}/${botId}`);
};
