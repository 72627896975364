import { ProtectedHeaderContainer } from "../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { Box, Flex } from "@chakra-ui/react";
import { useAppSelector } from "../../../../common/state/store";
import { selectIsOrganizationTrial } from "../../../organisation/OrganisationSlice";
import { MOBILE_VIEW, TABLET_VIEW } from "../../LayoutHelper/ResolutionConst";
import { useNowWidthView } from "../../LayoutHelper/ResolutionHooks";
import { AIAssistantListHeader } from "../../../aiAssistant/components/aiAssistantListHeader/AIAssistantListHeader";
import { AIAssistantListContainer } from "../../../aiAssistant/AIAssistantListContainer";
import s from "./AIAssistantPage.module.scss";

export const AIAssistantPage = () => {
  const isTrial = useAppSelector(selectIsOrganizationTrial);
  const nowWidth = useNowWidthView();
  const boxHeight =
    nowWidth <= TABLET_VIEW ? `calc(100dvh - ${isTrial && nowWidth <= MOBILE_VIEW ? "240px" : "180px"})` : "calc(100dvh - 60px)";

  return (
    <>
      <Flex className={s.aiPageContainer}>
        <ProtectedHeaderContainer>
          <AIAssistantListHeader />
        </ProtectedHeaderContainer>
        <Box bg="bgLight" height={boxHeight}>
          <AIAssistantListContainer />
        </Box>
      </Flex>
    </>
  );
};
