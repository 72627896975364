import { Box, Flex, Show } from "@chakra-ui/react";
import LogoIcon from "../../../assets/icons/sidebar/logoIcon.svg?react";
import LogoText from "../../../assets/icons/sidebar/logoText.svg?react";
import s from "./ProtectedHeaderContainer.module.scss";
import { useAppSelector } from "../../../common/state/store";
import { selectIsOrganizationTrial, selectTrialPlan } from "../../organisation/OrganisationSlice";
import { TrialPlanComponent } from "../../organisation/components/TrialPlan/TrialPlanComponent";
import { useMobileView, useTabletView } from "../../layout/LayoutHelper/ResolutionHooks";
import { memo } from "react";

export const ProtectedHeaderContainer = memo(function ProtectedHeaderContainer(props: { children?: React.ReactNode }) {
  const trialPlan = useAppSelector(selectTrialPlan);
  const isMobile = useMobileView();
  const isTablet = useTabletView();
  const isTrial = useAppSelector(selectIsOrganizationTrial);
  const isShowTrial = !window.location.pathname.startsWith("/automation/flows/");

  return (
    <Flex className={s.headerContainer}>
      <Show breakpoint="(max-width: 999px)">
        <Flex className={s.logoContainerWrapper}>
          <Flex className={s.logoContainer}>
            <LogoIcon width={"31px"} />
            <LogoText width={"94px"} />
          </Flex>
          {isTrial && trialPlan && trialPlan.isAvailable && isTablet && !isMobile && (
            <Box overflow="hidden" flex={1} className="TrialWrapper">
              <TrialPlanComponent trialPlan={trialPlan} />
            </Box>
          )}
        </Flex>
      </Show>
      {isShowTrial && isTrial && trialPlan && trialPlan.isAvailable && isMobile && (
        <Box className={`TrialWrapperMobile_${window.location.pathname.replace(/\//g, "_")}`}>
          <TrialPlanComponent trialPlan={trialPlan} />
        </Box>
      )}
      <Flex className={s.headerContent}>{props.children}</Flex>
    </Flex>
  );
});
