import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Heading,
  Alert,
  AlertIcon,
  AlertDescription,
  Text,
  Skeleton,
} from "@chakra-ui/react";
import {
  ConditionModel,
  CustomVariablesModel,
  NodeValidation,
  ScheduleTrigger,
  ScheduleTriggerTypeDiscriminator,
  TimeUnits,
  TriggerModel,
  TriggerTypeDiscriminator,
} from "../../../FlowBuilderModel";
import s from "./ScheduleTriggerPopup.module.scss";
import { useTranslation } from "react-i18next";
import { ConditionBox } from "../../../../complexFilter/components/ConditionBox";
import {
  ComplexFilterFieldTypes,
  ComplexFilterGroupTypes,
  ConditionsByField,
  FieldParamModel,
  FilterParamTypes,
  FilterParams,
} from "../../../../complexFilter/ComplexFilterModel";
import { useAppSelector } from "../../../../../common/state/store";
import { selectCustomVariables } from "../../../FlowBuilderSlice";
import { CustomVariableScope, CustomVariableType } from "../../../../../common/AppEnums";
import { forwardRef, useEffect, useState } from "react";
import { DatePicker, customDateInputIcon, getMinTime } from "../../../../../UI/atoms/datePicker/DatePicker";
import dayjs from "dayjs";
import { formatDateTimeToString } from "../../../../../common/utils/formatDate";
import Alarm from "../../../../../assets/icons/alarmClock.svg?react";
import { RecurringTriggerPopup } from "./ReccuringTriggerPopup/RecurringTriggerPopup";
import { scheduleTriggerPopupValidator, validationDependencies } from "./validation/ScheduleTriggerPopupValidator";
import { addTranslationPrefix } from "../../../../../common/utils/convertPascalCaseToText";

type Props = {
  isOpen?: boolean;
  triggerNode?: TriggerModel;
  validation: NodeValidation;
  onCancel: (triggerType: TriggerTypeDiscriminator) => void;
  onSave: (trigger: TriggerModel) => void;
  onValidate: (trigger: TriggerModel) => void;
};

const defaultTriggerNode: TriggerModel = {
  id: "",
  typeDiscriminator: TriggerTypeDiscriminator.Schedule,
  isEnabled: true,
  triggerGroupId: Math.floor(Math.random() * 1000),
};

export const ScheduleTriggerPopup = ({ isOpen, triggerNode = defaultTriggerNode, validation, onCancel, onSave }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const vt = useTranslation("validation").t;
  const cft = useTranslation("translation", { keyPrefix: "complexFilter.conditionsByType" }).t;

  const recurInitialValue = {
    startTime: triggerNode.scheduler?.startTime ?? null,
    endTime: triggerNode.scheduler?.endTime ?? null,
    unit: triggerNode.scheduler?.unit ?? TimeUnits.Minutes,
    value: triggerNode.scheduler?.value ?? "",
    scheduledTime: triggerNode.scheduler?.scheduledTime ?? null,
  };
  const CustomInput = forwardRef(customDateInputIcon);
  const variables = useAppSelector(selectCustomVariables);
  const triggerFilterParams = triggerNode?.conditions?.map(el => mapConditionModelToFilter(el, variables) ?? {});
  const [filterParams, setFilterParams] = useState<FilterParams[]>(triggerFilterParams ?? [{}]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [typeDiscriminator, setTypeDiscriminator] = useState<ScheduleTriggerTypeDiscriminator>(
    triggerNode.scheduler?.typeDiscriminator ?? ScheduleTriggerTypeDiscriminator.once,
  );
  const [recurValue, setRecurValue] = useState<{
    startTime: string | null;
    endTime: string | null;
    unit: TimeUnits;
    value: string;
    scheduledTime: string | null;
  }>(recurInitialValue);
  const [onceValue, setOnceValue] = useState<string | null>(triggerNode.scheduler?.scheduledTime ?? null);
  const [warnings, setWarnings] = useState<string[]>([]);
  const locale = localStorage.getItem("i18nextLng");

  const maxValues = [
    { unit: TimeUnits.Minutes, maxValue: 525600 },
    { unit: TimeUnits.Hours, maxValue: 8760 },
    { unit: TimeUnits.Days, maxValue: 365 },
  ];

  // We should wait till onOpen animation of drawer will end because positioning of inside menu works incorrect during animation
  useEffect(() => {
    if (isOpen) {
      setRecurValue(recurInitialValue);
      setOnceValue(triggerNode.scheduler?.scheduledTime ?? null);
    }
    const timer = setTimeout(() => {
      setIsDrawerOpen(true);
    }, 400);
    return () => {
      setIsDrawerOpen(false);
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    validateWarnings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onceValue, recurValue, typeDiscriminator]);

  const onCreate = () => {
    const startTime = recurValue.startTime;
    const endTime = typeDiscriminator === ScheduleTriggerTypeDiscriminator.once ? null : recurValue.endTime;
    const unit = recurValue.unit;
    const value = typeDiscriminator === ScheduleTriggerTypeDiscriminator.once ? undefined : recurValue.value;
    const conditions = filterParams.map(el => mapFilterToConditionModel(el, variables));
    const scheduledTime = onceValue;
    const scheduler: ScheduleTrigger = { typeDiscriminator, startTime, endTime, unit, value, scheduledTime };
    const triggerModel = { ...triggerNode, scheduler, conditions };
    onSave(triggerModel);
  };

  const validateRepeatIntervalValue = (unit: TimeUnits, value?: string) => {
    const allowedSymbols = /^[0-9]*$/;
    if (!value?.length) {
      return true;
    }
    if (!value.match(allowedSymbols)) {
      return false;
    }
    if (parseInt(value) === 0) {
      return false;
    }
    return !!maxValues.find(el => el.unit === unit && parseInt(value) < el.maxValue);
  };

  const onChangeRecurIntervalValue = (unit: TimeUnits, value?: string) => {
    const isValid = validateRepeatIntervalValue(unit, value);
    if (isValid) {
      setRecurValue(prevState => {
        return { ...prevState, value: value ?? "" };
      });
    }
  };

  const addCondition = () => {
    setFilterParams([...filterParams, {}]);
  };

  const onDeleteConditionBlock = (paramsToDelete: FilterParams) => {
    const params = filterParams.filter(el => JSON.stringify(el) !== JSON.stringify(paramsToDelete));
    setFilterParams(params);
  };

  const onSetFilterParam = (filterParamType: FilterParamTypes, fieldParamValue: FieldParamModel, id: string) => {
    setFilterParams(prevState =>
      prevState.map(el => {
        if (el.id === id || !el.id) {
          if (filterParamType === FilterParamTypes.field && el.field?.type !== fieldParamValue.type) {
            return {
              id,
              [filterParamType]: fieldParamValue,
            };
          }
          return {
            ...el,
            [filterParamType]: fieldParamValue,
          };
        }
        return el;
      }),
    );
  };

  const getMinDate = (dateString: string | null, offset?: number) => {
    if (!dateString) {
      return new Date();
    }
    const dateOffset = offset ?? 0;
    const date = new Date(dateString);
    date.setDate(date.getDate() + dateOffset);
    return date;
  };

  function createFieldsToFilter() {
    let contactList = [
      { fieldName: "ContactId", fieldValue: "ContactId" },
      { fieldName: "ContactUsername", fieldValue: "ContactUsername" },
      { fieldName: "ContactFirstName", fieldValue: "ContactFirstName" },
      { fieldName: "ContactLastName", fieldValue: "ContactLastName" },
      { fieldName: "ContactPhoneNumber", fieldValue: "ContactPhoneNumber" },
    ];

    contactList = contactList.map(el => {
      return {
        ...el,
        type: ComplexFilterFieldTypes.Text,
        fieldName: el.fieldName,
      };
    });
    const groupList = [{ label: t("editNodePopup.Contacts"), items: contactList }];

    if (variables?.length) {
      const contactvariableList = variables
        ?.filter(
          el =>
            ConditionsByField.allowedFieldTypes.includes(el.type) &&
            el.type !== CustomVariableType.Boolean &&
            el.scope === CustomVariableScope.Contact,
        )
        .map(el => {
          return {
            fieldName: el.key,
            fieldValue: el.key,
            type: el.type,
            groupType: ComplexFilterGroupTypes.CustomVariables,
            description: el.description,
          };
        });
      groupList.push({
        label: t("editNodePopup.Custom Contact Fields"),
        items: contactvariableList,
      });
    }

    return { groups: groupList };
  }

  function mapConditionModelToFilter(
    conditionModel: ConditionModel,
    customVariables: CustomVariablesModel[] | undefined,
  ): FilterParams | undefined {
    const field = customVariables?.find(el => el.id === conditionModel.conditionCustomVariableId);
    const valueVariable = customVariables?.find(el => el.id === conditionModel.valueCustomVariableId);
    if (field) {
      return {
        id: conditionModel.id,
        field: {
          title: field.key,
          value: field.key,
          type: ComplexFilterFieldTypes[field.type],
        },
        condition: conditionModel.condition
          ? {
              title: cft(addTranslationPrefix(conditionModel.condition, field.type)),
              value: conditionModel.condition,
              type: ComplexFilterFieldTypes[field.type],
            }
          : undefined,
        conditionValue: conditionModel.value
          ? {
              title: conditionModel.value,
              value: conditionModel.value,
              variableValue: "",
              type: ComplexFilterFieldTypes[field.type],
            }
          : valueVariable?.key
          ? {
              title: valueVariable.key,
              value: "",
              variableValue: valueVariable.key,
              type: ComplexFilterFieldTypes[field.type],
            }
          : undefined,
      };
    }
  }

  function mapFilterToConditionModel(filter: FilterParams, customVariables: CustomVariablesModel[] | undefined) {
    const conditionCusVarId = customVariables?.find(el => el.key === filter?.field?.value)?.id;
    const conditionCustomVariableName = filter.field?.value;
    const valueCustomVariableId = customVariables?.find(el => el.key === filter.conditionValue?.variableValue)?.id;
    const isValueAllow = !ConditionsByField.noValueConditions.includes(filter.condition?.value ?? "");
    const id = filter.id;
    if (conditionCusVarId) {
      return {
        id,
        conditionCustomVariableId: conditionCusVarId,
        conditionCustomVariableName,
        condition: filter.condition?.value,
        value: isValueAllow ? filter?.conditionValue?.value : undefined,
        valueCustomVariableId: isValueAllow ? valueCustomVariableId : undefined,
      };
    }
    return {};
  }

  const getScheduleMinEndTime = (dateString: string | null, offset?: number) => {
    if (!dateString) {
      return new Date();
    }
    const endDate = new Date(dateString);
    const startDate = new Date(recurValue.startTime ?? "");
    const dateOffset = offset ?? 0;
    const currentDate = new Date();
    if (endDate.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0)) {
      endDate.setHours(23);
      endDate.setMinutes(59);
      return endDate;
    }
    if (endDate.setHours(0, 0, 0, 0) === startDate.setHours(0, 0, 0, 0)) {
      startDate.setMinutes(startDate.getMinutes() + dateOffset);
      return startDate;
    }
    return getMinTime(endDate);
  };

  const getScheduleMinStartTime = (dateString: string | null) => {
    if (!dateString) {
      return new Date();
    }
    const startDate = new Date(dateString);
    const currentDate = new Date();
    if (startDate.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0)) {
      startDate.setHours(23);
      startDate.setMinutes(59);
      return startDate;
    }
    if (startDate < currentDate) {
      return currentDate;
    }
    return getMinTime(startDate);
  };

  const validateWarnings = () => {
    const validationDep: validationDependencies = {
      typeDiscriminator: typeDiscriminator,
      endTime: recurValue.endTime,
      onceValue: onceValue ?? "",
      startTime: recurValue.startTime,
    };
    const warningArray = scheduleTriggerPopupValidator("warnings", validationDep);
    setWarnings(warningArray);
  };

  return (
    <Drawer
      placement="right"
      size={"xs"}
      isOpen={isOpen ?? false}
      onClose={() => onCancel(triggerNode.typeDiscriminator)}
      isFullHeight={false}
      blockScrollOnMount={false}
    >
      <DrawerContent p="8px">
        <DrawerHeader className={s.header}>
          <Heading fontSize="17px" color="defaultGreen">
            {t("editTriggerPopup.Schedule")}
          </Heading>
        </DrawerHeader>
        <DrawerBody px={"14px"}>
          <Flex flexDirection={"column"}>
            <Flex fontSize={"16px"} mt={"18px"} justifyContent={"space-between"} alignItems={"center"}>
              <Flex alignItems={"center"} fontSize={"15px"}>
                <div>{t("editTriggerPopup.Activate for contacts that match all of the following conditions")}:&nbsp;</div>
              </Flex>
            </Flex>
            {!isDrawerOpen && (
              <Box maxH="26vh" overflow="scroll" overflowX="hidden">
                {filterParams.map((_, index) => {
                  return (
                    <Flex key={index} width="100%" pt="20px" pr="10px">
                      <Skeleton
                        speed={0.3}
                        startColor="line"
                        endColor="bgLight"
                        borderRadius="10px"
                        height="46px"
                        width="100%"
                        border="1px solid"
                        borderColor="line"
                      />
                    </Flex>
                  );
                })}
                <Button w="calc(100% - 10px)" mt="46px" variant="dominoDashedViolet" data-pw="add-condition-button">
                  + {t("Condition")}
                </Button>
              </Box>
            )}
            {isDrawerOpen && (
              <ConditionBox
                wrapperStyle={{ maxHeight: "26vh", paddingBottom: "10px" }}
                filterFields={createFieldsToFilter()}
                filterParams={filterParams}
                onDeleteConditionBlock={onDeleteConditionBlock}
                onSetFilterParam={onSetFilterParam}
                menuStyle={"conditionNode"}
                addCondition={addCondition}
                isVariablesToCompareWithForbidden={true}
              />
            )}
            <Flex my="16px" w={"100%"} justifyContent={"center"} alignItems={"center"}>
              <Button
                ml="0"
                borderRightRadius="0"
                width="100%"
                variant={typeDiscriminator === ScheduleTriggerTypeDiscriminator.once ? "dominoViolet" : "dominoOutlineViolet"}
                onClick={() => setTypeDiscriminator(ScheduleTriggerTypeDiscriminator.once)}
                data-pw="once-button"
              >
                {t("editTriggerPopup.One Time")}
              </Button>
              <Button
                width="100%"
                borderLeftRadius="0"
                variant={typeDiscriminator === ScheduleTriggerTypeDiscriminator.recur ? "dominoViolet" : "dominoOutlineViolet"}
                onClick={() => setTypeDiscriminator(ScheduleTriggerTypeDiscriminator.recur)}
                data-pw="recurring-button"
              >
                {t("editTriggerPopup.Recurring")}
              </Button>
            </Flex>

            {typeDiscriminator === ScheduleTriggerTypeDiscriminator.once && (
              <Flex flexDir="column">
                <Text mb="16px">{t("editTriggerPopup.Scheduled time")}</Text>
                <DatePicker
                  wrapperClassName={s.DatePickerWrapper}
                  dateFormat={locale !== "en" ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy h:mm a"}
                  selected={onceValue ? new Date(onceValue) : null}
                  onChange={scheduledDate => {
                    const minTime = new Date();
                    if (scheduledDate && scheduledDate < minTime) {
                      setOnceValue(formatDateTimeToString(minTime));
                    } else {
                      setOnceValue(formatDateTimeToString(scheduledDate));
                    }
                  }}
                  placeholderText={ct("placeholderDateTime") ?? ""}
                  showTimeSelect
                  timeIntervals={15}
                  strictParsing
                  customInput={<CustomInput icon={Alarm} colorIcon="black" />}
                  minDate={new Date()}
                  minTime={getScheduleMinStartTime(onceValue)}
                  maxTime={dayjs().endOf("day").toDate()}
                />
              </Flex>
            )}

            {typeDiscriminator === ScheduleTriggerTypeDiscriminator.recur && (
              <RecurringTriggerPopup
                recurValue={recurValue}
                onChangeRecurIntervalValue={onChangeRecurIntervalValue}
                validateRepeatIntervalValue={validateRepeatIntervalValue}
                getScheduleMinEndTime={getScheduleMinEndTime}
                getScheduleMinStartTime={getScheduleMinStartTime}
                getMinDate={getMinDate}
                setRecurValue={setRecurValue}
              />
            )}
          </Flex>
        </DrawerBody>
        <DrawerFooter>
          <Flex w={"100%"} flexDirection={"column"}>
            {validation.errors.find(el => el.field === "scheduleTrigger") && (
              <Box mb={"24px"}>
                <Alert status="error">
                  <AlertIcon />
                  <AlertDescription>
                    {vt(validation.errors.find(el => el.field === "scheduleTrigger")?.message ?? "")}
                  </AlertDescription>
                </Alert>
              </Box>
            )}
            {!!warnings.length && (
              <Box mb={"24px"}>
                <Alert status="warning">
                  <AlertIcon />
                  <AlertDescription>{vt(warnings[0])}</AlertDescription>
                </Alert>
              </Box>
            )}
            <Flex w={"100%"} justifyContent={"space-between"}>
              <Button
                onClick={() => onCancel(TriggerTypeDiscriminator.Schedule)}
                size={"sm"}
                variant="dominoOutlineViolet"
                data-pw="cancel-button"
              >
                {triggerNode.id ? ct("Discard") : ct("Cancel")}
              </Button>
              <Button onClick={() => onCreate()} size={"sm"} variant="dominoViolet" data-pw="create-button">
                {triggerNode.id ? ct("Apply") : ct("Create")}
              </Button>
            </Flex>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
