import { Box, Button, Flex, Show, Switch, Tab, TabList, Tabs, Text, useDisclosure } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import s from "./BotContainer.module.scss";
import { BotVariableModel } from "./BotModel";
import {
  checkVariableUsage,
  clearVariableUsage,
  createBotVariable,
  deleteBot,
  deleteBotVariable,
  editBotVariableDescription,
  editBotVariableValue,
  getBotInfo,
  getBotVariables,
  selectBotInfo,
  selectBotMenuCommands,
  selectBotVariables,
  selectBotVariableUsage,
  switchBotMenuCommands,
} from "./BotSlice";
import { BotInfo } from "./components/BotInfo/BotInfo";
import { BotVariables } from "./components/BotVariables/BotVariables";
import { AddVariablePopup } from "../modals/addVariable/addVariableModal/AddVariableModal";
import { BotVariableDeletePopup } from "./components/BotVariableDeletePopup/BotVariableDeletePopup";
import { BotRemovePopup } from "./components/BotRemovePopup/BotRemovePopup";
import { useTranslation } from "react-i18next";
import { RefreshTokenPopup } from "./components/RefreshTokenPopup/RefreshTokenPopup";
import { CustomVariableScope } from "../../common/AppEnums";
import MenuCommands from "./components/MenuCommands/MenuCommands";
import AddCommandPopup from "./components/MenuCommands/AddCommandPopup/AddCommandPopup";
import useGA from "../../common/ga/GAEventTracker";
import { BotCommandsEvents, BotFieldsEvents } from "../../common/ga/gaEventsEnums.ts/BotGAEventsEnums";
import { EventCategories } from "../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import AddUTMModal from "./components/UtmTags/AddUTMModal/AddUTMModal";
import { UtmTags } from "./components/UtmTags/UtmTags";

export const BotContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "bot" });
  const trackEvent = useGA(EventCategories.Bot);
  const dispatch = useAppDispatch();
  const { botId } = useParams();
  const [SearchParams, setSearchParams] = useSearchParams("");
  const botInfo = useAppSelector(selectBotInfo);
  const botVariables = useAppSelector(selectBotVariables);
  const botVariableUsage = useAppSelector(selectBotVariableUsage);
  const botMenuCommands = useAppSelector(selectBotMenuCommands);

  const { isOpen: isOpenBotRemoveModal, onOpen: onOpenBotRemoveModal, onClose: onCloseBotRemoveModal } = useDisclosure();
  const { isOpen: isOpenRefreshTokenModal, onOpen: onOpenRefreshTokenModal, onClose: onCloseRefreshTokenModal } = useDisclosure();
  const { isOpen: isOpenAddCommandModal, onOpen: onOpenAddCommandModal, onClose: onCloseAddCommandModal } = useDisclosure();
  const { isOpen: isOpenAddUTMModal, onOpen: onOpenAddUTMModal, onClose: onCloseAddUTMModal } = useDisclosure();
  const [isAllActiveFlag, setIsAllActiveFlag] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [addVariablePopup, setAddVariablePopup] = useState(false);
  const [deleteVariableState, setDeleteVariableState] = useState<BotVariableModel | undefined>();
  const [deleteBotPopupState, setDeleteBotPopupState] = useState<{ id: string; title: string } | undefined>();
  const botContainerRef = useRef<null | HTMLDivElement>(null);

  enum ActiveTab {
    "BotSettings" = "botSettings",
    "Fields" = "fields",
    "Commands" = "commands",
    "UTMTags" = "utm",
  }

  const selectActiveTab = (tab: string) => {
    switch (tab) {
      case ActiveTab.BotSettings:
        setActiveTab(0);
        setSearchParams({ tab: ActiveTab.BotSettings });
        return 0;
      case ActiveTab.Fields:
        setActiveTab(1);
        setSearchParams({ tab: ActiveTab.Fields });
        return 0;
      case ActiveTab.Commands:
        setActiveTab(2);
        setSearchParams({ tab: ActiveTab.Commands });
        return 1;
      case ActiveTab.UTMTags:
        setActiveTab(3);
        setSearchParams({ tab: ActiveTab.UTMTags });
        return 2;
      default:
        setActiveTab(0);
        setSearchParams({ tab: ActiveTab.BotSettings });
        return 0;
    }
  };

  const hangleSwitchCommandActive = () => {
    if (botId) {
      const newIsActiveState = !isAllActiveFlag;
      if (newIsActiveState) {
        trackEvent(BotCommandsEvents.BotCommandsActivate);
      } else {
        trackEvent(BotCommandsEvents.BotCommandDeactivate);
      }

      setIsAllActiveFlag(newIsActiveState);
      dispatch(switchBotMenuCommands({ botId, data: { isActive: newIsActiveState } }));
    }
  };

  const onBotSettingsTabSelect = () => {
    selectActiveTab(ActiveTab.BotSettings);
  };

  const onBotFieldTabSelect = () => {
    selectActiveTab(ActiveTab.Fields);
    trackEvent(BotFieldsEvents.BotFields);
  };

  const onMenuCommandTabSelect = () => {
    selectActiveTab(ActiveTab.Commands);
    trackEvent(BotCommandsEvents.BotCommands);
  };

  const onUTMTagsTabSelect = () => {
    selectActiveTab(ActiveTab.UTMTags);
  };

  const onOpenAddCommandModalClick = () => {
    onOpenAddCommandModal();
    trackEvent(BotCommandsEvents.BotCommandsNew);
  };

  const onOpenAddUTMModalClick = () => {
    onOpenAddUTMModal();
  };

  useEffect(() => {
    if (botId) {
      dispatch(getBotInfo({ botId }));
      dispatch(getBotVariables({ botId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botId]);

  useEffect(() => {
    if (botInfo) {
      setDeleteBotPopupState({
        id: botInfo.id,
        title: botInfo.name,
      });
    }
  }, [botInfo]);

  useEffect(() => {
    const tab = SearchParams.get("tab");
    if (tab) {
      selectActiveTab(tab);
    } else {
      setSearchParams({ tab: ActiveTab.BotSettings });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (botMenuCommands?.items?.every(item => item.isActive)) {
      setIsAllActiveFlag(true);
    } else {
      setIsAllActiveFlag(false);
    }
  }, [botMenuCommands?.items]);

  const onVariableCreate = () => {
    setAddVariablePopup(true);
    trackEvent(BotFieldsEvents.BotFieldsNew);
  };

  const onVariableValueEdit = (variable: BotVariableModel) => {
    if (!variable.id || !botId) return;

    dispatch(editBotVariableValue({ variableId: variable.id, variable: variable, botId }));
  };

  const onVariableDescriptionEdit = (variable: BotVariableModel) => {
    if (!variable.id || !botId) return;

    dispatch(editBotVariableDescription({ variableId: variable.id, variable: variable, botId }));
  };

  const onVariableDelete = (variableId?: string) => {
    if (!variableId || !botId) return;

    dispatch(deleteBotVariable({ variableId, botId }));
    setDeleteVariableState(undefined);
    dispatch(clearVariableUsage());
  };

  const variableDeleteConfirm = (variable: BotVariableModel) => {
    if (!variable.id || !botId) return;

    setDeleteVariableState(variable);
    dispatch(checkVariableUsage(variable.id));
  };

  const onCloseBotVariableUsagePopup = () => {
    setDeleteVariableState(undefined);
    dispatch(clearVariableUsage());
  };

  const createVariable = (variable: BotVariableModel) => {
    if (!botId) return;

    dispatch(createBotVariable({ variable, botId }));
    setAddVariablePopup(false);
  };

  const onCloseAddVariablePopup = () => {
    setAddVariablePopup(false);
  };

  const onDeleteBot = () => {
    if (botId) {
      dispatch(deleteBot(botId));
    }
  };

  if (!botId) {
    return <></>;
  }

  return (
    <>
      <Box
        className={s.botInfoContainer}
        ref={botContainerRef}
        bg={"bgLight"}
        height={window.innerWidth > 999 ? "calc(100vh - 80px)" : "100%"}
      >
        {botInfo && (
          <>
            <Show breakpoint="(min-width: 768px)">
              <Tabs index={activeTab} isManual>
                <TabList className={s.tabList} alignItems="center" m={0} justifyContent="space-between">
                  <Flex position={"relative"}>
                    <Flex
                      borderRadius="4px"
                      p="2px 4px"
                      bg="#0BCB6B"
                      fontSize="12px"
                      fontWeight="700"
                      color={"white"}
                      pos={"absolute"}
                      right={"-34px"}
                      top={"14px"}
                    >
                      NEW
                    </Flex>
                    <Tab onClick={onBotSettingsTabSelect} data-pw="botSettings-tab">
                      {t("Bot Settings")}
                    </Tab>
                    <Tab onClick={onBotFieldTabSelect} data-pw="botFields-tab">
                      {t("Variables")}
                    </Tab>
                    <Tab h="52px" onClick={onMenuCommandTabSelect} data-pw="menuCommands-tab" position={"relative"}>
                      {t("Menu Commands")}
                    </Tab>
                    <Tab onClick={onUTMTagsTabSelect} data-pw="UTM-tab">
                      {t("UTM tags")}
                    </Tab>
                  </Flex>
                  <Flex pb="8px" gap="16px" align="center">
                    {activeTab === 1 && (
                      <Button maxW="max-content" variant="dominoDashedViolet" onClick={onVariableCreate} data-pw="new-variable">
                        + {t("New Variable")}
                      </Button>
                    )}
                    {activeTab === 2 && (
                      <Button onClick={onOpenAddCommandModalClick} maxW="max-content" variant="dominoDashedViolet">
                        + {t("New Command")}
                      </Button>
                    )}
                    {activeTab === 3 && (
                      <Button onClick={onOpenAddUTMModalClick} maxW="max-content" variant="dominoDashedViolet">
                        + {t("Create UTM tag")}
                      </Button>
                    )}
                  </Flex>
                </TabList>
              </Tabs>
            </Show>

            <Show breakpoint="(max-width: 767px)">
              <Tabs index={activeTab} isManual display="flex">
                <TabList className={s.tabList} alignItems="center" m={0} justifyContent="space-between">
                  <Flex className={s.wrap} style={{ width: "calc(100vw - 34px)" }}>
                    <Flex position={"relative"} flexWrap="nowrap" mb="2px">
                      <Show breakpoint="(min-width: 460px)">
                        <Flex
                          borderRadius="4px"
                          p="2px 4px"
                          bg="#0BCB6B"
                          fontSize="12px"
                          fontWeight="700"
                          color={"white"}
                          pos={"absolute"}
                          right={"-34px"}
                          top={"14px"}
                        >
                          NEW
                        </Flex>
                      </Show>
                      <Tab onClick={onBotSettingsTabSelect} data-pw="botSettings-tab" whiteSpace="nowrap">
                        {t("Bot Settings")}
                      </Tab>
                      <Tab onClick={onBotFieldTabSelect} data-pw="botFields-tab" whiteSpace="nowrap">
                        {t("Variables")}
                      </Tab>
                      <Tab
                        h="52px"
                        onClick={onMenuCommandTabSelect}
                        data-pw="menuCommands-tab"
                        position={"relative"}
                        whiteSpace="nowrap"
                      >
                        {t("Menu Commands")}
                      </Tab>
                      <Tab gap="4px" onClick={onUTMTagsTabSelect} data-pw="UTM-tab" whiteSpace="nowrap">
                        {t("UTM tags")}
                      </Tab>
                    </Flex>
                  </Flex>
                </TabList>
              </Tabs>
              {activeTab === 1 && (
                <Button
                  maxW="100%"
                  variant="dominoDashedViolet"
                  onClick={onVariableCreate}
                  data-pw="new-variable"
                  mb="24px"
                  mt="12px"
                >
                  + {t("New Variable")}
                </Button>
              )}
              {activeTab === 2 && (
                <Flex mb="24px" gap="16px" align="center" w="100%" mt="12px" className={s.comBut}>
                  <Button onClick={onOpenAddCommandModalClick} maxW="100%" variant="dominoDashedViolet">
                    + {t("New Command")}
                  </Button>
                  <Flex gap="8px" minW={"max-content"}>
                    <Text>{t("All Commands")}</Text>
                    <Switch
                      justifySelf="center"
                      variant="dominoDefaultGreen"
                      defaultChecked={false}
                      isChecked={isAllActiveFlag}
                      onChange={hangleSwitchCommandActive}
                    />
                  </Flex>
                </Flex>
              )}
              {activeTab === 3 && (
                <Button onClick={onOpenAddUTMModalClick} maxW="100%" variant="dominoDashedViolet" mb="24px" mt="12px">
                  + {t("Create UTM tag")}
                </Button>
              )}
            </Show>
          </>
        )}
        {botInfo && activeTab === 0 && (
          <BotInfo
            botInfo={botInfo}
            onOpenBotRemoveModal={onOpenBotRemoveModal}
            onOpenRefreshTokenModal={onOpenRefreshTokenModal}
          />
        )}
        {botVariables && activeTab === 1 && (
          <Box className={s.infoSection}>
            <BotVariables
              variables={botVariables}
              onVariableCreate={onVariableCreate}
              onVariableDelete={variableDeleteConfirm}
              onVariableEdit={onVariableValueEdit}
              onVariableDescriptionEdit={onVariableDescriptionEdit}
            ></BotVariables>
          </Box>
        )}
        {activeTab === 2 && (
          <MenuCommands
            isOpenAddCommandModal={isOpenAddCommandModal}
            onCloseAddCommandModal={onCloseAddCommandModal}
            botId={botId}
            isAllActiveFlag={isAllActiveFlag}
            botRef={botContainerRef}
            hangleSwitchCommandActive={hangleSwitchCommandActive}
          />
        )}
        {activeTab === 3 && <UtmTags botRef={botContainerRef} botId={botId} botInfo={botInfo} />}
      </Box>

      {addVariablePopup && (
        <AddVariablePopup
          scope={CustomVariableScope.Bot}
          isOpen={addVariablePopup}
          botId={botId}
          onCreate={createVariable}
          onClose={() => onCloseAddVariablePopup()}
        />
      )}

      <RefreshTokenPopup onClose={onCloseRefreshTokenModal} isOpen={isOpenRefreshTokenModal} botInfo={botInfo} />

      {deleteBotPopupState && (
        <BotRemovePopup
          onClose={onCloseBotRemoveModal}
          isOpen={isOpenBotRemoveModal}
          onDelete={onDeleteBot}
          deleteBotPopupState={deleteBotPopupState}
        />
      )}

      <BotVariableDeletePopup
        flows={botVariableUsage}
        variable={deleteVariableState}
        onConfirmDelete={onVariableDelete}
        onClose={() => onCloseBotVariableUsagePopup()}
      />
      <AddCommandPopup isOpen={isOpenAddCommandModal} onClose={onCloseAddCommandModal} botId={botId} />
      <AddUTMModal isOpen={isOpenAddUTMModal} onClose={onCloseAddUTMModal} botInfo={botInfo} botId={botId} />
    </>
  );
};
