/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { act } from "react";
import { AIAssistantListModel, AIAssistantModel, NewAIAssistantFormModel } from "./AIAssistantListModel";

export interface AIAssistantListState {
  aiAssistantList?: AIAssistantModel[];
  AIAssistant?: AIAssistantModel;
  aiAssistantsCount?: number;
}

const initialState: AIAssistantListState = {};

export const aiAssistantListSlice = createSlice({
  name: "aiAssistantListSlice",
  initialState,
  reducers: {
    getAIAssistant: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    getAIAssistantSucceed: (state, action: PayloadAction<AIAssistantModel>) => {
      state.AIAssistant = action.payload;
    },
    getAIAssistantList: state => {
      return {
        ...state,
      };
    },
    getAIAssistantListSucceed: (state, action: PayloadAction<AIAssistantModel[]>) => {
      state.aiAssistantList = action.payload;
    },
    createAIAssistant: (state, action: PayloadAction<NewAIAssistantFormModel>) => {
      return {
        ...state,
      };
    },
    updateAIAssistant: (state, action: PayloadAction<{ updatedAIAssistant: NewAIAssistantFormModel; aiAssistantId: string }>) => {
      return {
        ...state,
      };
    },
    deleteAIAssistant: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    clearCurrentAIAssistant: state => {
      state.AIAssistant = undefined;
    },
    clearAIAssistantListState: state => {
      return initialState;
    },
  },
});
export const {
  getAIAssistantList,
  getAIAssistantListSucceed,
  getAIAssistant,
  getAIAssistantSucceed,
  createAIAssistant,
  updateAIAssistant,
  deleteAIAssistant,
  clearCurrentAIAssistant,
  clearAIAssistantListState,
} = aiAssistantListSlice.actions;

export const selectAIAssistantList = (state: RootState) => state.app.aiAssistantListState.aiAssistantList;
export const selectAIAssistant = (state: RootState) => state.app.aiAssistantListState.AIAssistant;

export default aiAssistantListSlice.reducer;
