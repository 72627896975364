import { isDesktop } from "react-device-detect";
import { FileNameSplitter } from "../../../../../../UI/atoms/fileNameSplitter/FileNameSplitter";
import { Box } from "@chakra-ui/react";
import ImageIcon from "../../../../../../assets/icons/imageChatIcon.svg?react";
import PaperClipIcon from "../../../../../../assets/icons/paperClipIcon.svg?react";
import CloseIcon from "../../../../../../assets/images/messageCloseIcon.svg?react";

import s from "../../MessageBox.module.scss";

import { AppSettings } from "../../../../../../common/AppSettings";
import { ConversationSendMessageModel } from "../../../../ConversationModel";

declare const appSettings: AppSettings;

interface Props {
  message: ConversationSendMessageModel;
  removeAttachedFile: () => void;
}

export default function Attachments(props: Props) {
  return (
    <Box className={s.attachments}>
      {props.message.photo &&
        (isDesktop ? (
          <Box className={s.photoPreview}>
            <img alt="" className={s.photo} src={`${appSettings.apiBaseUrl}/file/${props.message.photo?.id}`} />
            <Box className={s.shade} onClick={props.removeAttachedFile}>
              <CloseIcon className={s.close} />
            </Box>
          </Box>
        ) : (
          <Box className={s.documentPreview}>
            <Box className={s.label}>
              <ImageIcon className={s.labelIcon} />
              {props.message.photo.name}
            </Box>
            <Box className={s.closeButton} onClick={props.removeAttachedFile}>
              <CloseIcon className={s.closeIcon} />
            </Box>
          </Box>
        ))}
      {props.message.document && (
        <Box className={s.documentPreview}>
          <Box className={s.label}>
            <PaperClipIcon className={s.labelIcon} />
            <FileNameSplitter fileName={props.message.document.name} variant="normal" />
          </Box>
          <Box className={s.closeButton} onClick={props.removeAttachedFile}>
            <CloseIcon className={s.closeIcon} />
          </Box>
        </Box>
      )}
    </Box>
  );
}
