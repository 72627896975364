import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import s from "./GroupChatConversation.module.scss";
import { ReactComponent as BackArrowMenu } from "../../../../../../assets/images/downArrow.svg";
import DotMenuContainer from "../../../../../../UI/atoms/DotMenu/DotMenuContainer";
import { GroupChatTextAreaBlock } from "./components/GroupChatTextAreaBlock/GroupChatTextAreaBlock";
import { GroupChatMessagesList } from "./components/GroupChatMessagesList/GroupChatMessagesList";
import { useAppDispatch, useAppSelector } from "../../../../../../common/state/store";
import { selectGroupChatInfo, setIsOpenGroupChatInfo } from "../GroupChatInfo/GroupChatInfoSlice";
import { useNavigate } from "react-router-dom";
import { selectConversationMessage, uploadMessageFile } from "./components/GroupChatMessagesList/GroupChatMessagesListSlice";
import { useState, DragEvent } from "react";
import { FileUploadType } from "../../../../../conversation/ConversationModel";
import { IMAGE_FILE_EXTS } from "../../../../../../common/AppEnums";
import { useGetLoadingState } from "../../../../../../common/loading/hooks/useGetLoadingState";
import { useTranslation } from "react-i18next";

export const GroupChatConversation = () => {
  const { t } = useTranslation("translation", { keyPrefix: "conversation" });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const conversationInfo = useAppSelector(selectGroupChatInfo);
  const message = useAppSelector(selectConversationMessage);
  const [isDragging, setDragging] = useState(false);
  const isLoadingFile = useGetLoadingState("messageFileUpload");

  const uploadFile = (file: File, type: FileUploadType) => {
    dispatch(uploadMessageFile({ file, type }));
  };

  const onDropFile = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer?.files[0];
    if (file) {
      const [type, ext] = file.type.split("/");
      if (type === "image" && IMAGE_FILE_EXTS.includes(ext)) {
        uploadFile(file, FileUploadType.photo);
      } else {
        uploadFile(file, FileUploadType.document);
      }
    }
  };

  const handleDrag = function (e: DragEvent<HTMLDivElement>) {
    if (isLoadingFile) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (!message.document && !message.photo) {
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragging(true);
      } else if (e.type === "dragleave" || e.type === "dragend") {
        setDragging(false);
      }
    }
  };

  return (
    <Flex onDragEnter={handleDrag} className={s.conversationContainer} bg="bgLight" height="100%" flexDir="column">
      <Flex className={s.header}>
        <Flex
          className={s.mobileBackArrow}
          onClick={() =>
            navigate(`/groupChats`, {
              replace: true,
            })
          }
        >
          <Icon as={BackArrowMenu} boxSize="42px" color="midDeepBlue" transform={"rotate(90deg)"} borderRadius={"4px"} />
        </Flex>
        <Box fontWeight={"bold"} flexGrow={"1"} maxW={"100%"} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"}>
          {conversationInfo && <Text className={s.text}>{conversationInfo.title}</Text>}
        </Box>
        {conversationInfo && conversationInfo?.id && (
          <DotMenuContainer
            menuItems={[
              {
                isActive: true,
                MenuItem: {
                  icon: undefined,
                  text: t("Group chat info"),
                  clickEvent: () => {
                    dispatch(setIsOpenGroupChatInfo(true));
                  },
                },
              },
            ]}
          />
        )}
      </Flex>
      <Flex position="relative" overflow="hidden" bg="white" flex={1}>
        <GroupChatMessagesList />
      </Flex>
      <Flex>
        <GroupChatTextAreaBlock />
      </Flex>
      {!isLoadingFile && isDragging && !message.document && !message.photo && (
        <div
          className={s.dragFileElement}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={onDropFile}
        ></div>
      )}
      {!isLoadingFile && isDragging && !message.document && !message.photo && (
        <div className={s.dragFileContainer}>
          <div className={s.dragFileContent}>
            <Text maxW="195px" variant="largeBold" color="white">
              {t("Drag and drop the file here to attach to the message")}
            </Text>
          </div>
        </div>
      )}
    </Flex>
  );
};
