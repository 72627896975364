import { Dispatch, SetStateAction } from "react";
import { ConversationInfoModel } from "../../../ConversationModel";
import { AnyAction } from "redux-saga";
import { deleteConversationMessage } from "../../../ConversationSlice";

interface NoteMessageDeleteModal {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  conversationInfo: ConversationInfoModel | undefined;
  dispatch: Dispatch<AnyAction>;
  messageId: string;
}
export const noteMessageDeleteModal = ({ setIsModalOpen, conversationInfo, dispatch, messageId }: NoteMessageDeleteModal) => {
  return {
    onOpen: () => {
      setIsModalOpen(true);
    },
    onConfirm: () => {
      if (!conversationInfo) return;
      dispatch(deleteConversationMessage({ conversationId: conversationInfo.id, messageId }));
    },
    onClose: () => {
      setIsModalOpen(false);
    },
  };
};
