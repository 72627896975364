import React, { useEffect } from "react";
import { Box, useDisclosure, Text, Button, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import {
  clearContactListState,
  getContactList,
  selectContactList,
  getCustomVariables,
  selectImportResult,
  selectSearchParams,
} from "./ContactListSlice";
import { ContactList } from "./components/ContactList";
import { ImportContactsModal } from "../importContacts/ImportContactsModal";
import { useGetLoadingState } from "../../common/loading/hooks/useGetLoadingState";
import { NewContactModal } from "./components/NewContactModal/NewContacModal";
import s from "./ContactList.module.scss";
import sc from "../contactList/ContactListContainer.module.scss";
import ImageSearch from "../../assets/images/imageSearch.svg?react";
import PlusIcon from "../../assets/icons/plusIconTile.svg?react";
import ImportIcon from "../../assets/icons/download.svg?react";
import { PaginatorWithSearchParams } from "../../common/paginator/components/PaginatorWithSearchParams";
import * as vars from "../layout/LayoutHelper/ResolutionConst";
import { CONTACTS_PAGE_SIZE } from "../../common/paginator/paginatorSizes";
import BaseEmptyPage from "../../common/baseEmptyPage/BaseEmptyPage";

export const ContactListContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "contactList" });
  const importTransl = useTranslation("translation", { keyPrefix: "importContacts" }).t;

  const dispatch = useAppDispatch();
  const contactList = useAppSelector(selectContactList);
  const loadingContactList = useGetLoadingState("getContactList");
  const [searchParams, setSearchParams] = useSearchParams();
  const { isOpen: isImportContactsOpen, onOpen: onImportContactsOpen, onClose: onImportContactsClose } = useDisclosure();
  const { isOpen: isNewContactModalOpen, onOpen: onNewContact, onClose: onNewContactModalClose } = useDisclosure();
  const paginatorData = { ...contactList, items: contactList.items || [] };

  const search = searchParams.get("search") ?? "";
  const filters = searchParams.get("filters") ?? "";
  const currentPage = Number(searchParams.get("page") ?? 1);
  const isNoSearchResults = contactList.items && contactList.items.length === 0 && (search !== "" || filters !== "");
  const importResult = useAppSelector(selectImportResult);
  const searchStateParams = useAppSelector(selectSearchParams);
  const isSessionFilter = sessionStorage.getItem("ContactsFilter");

  useEffect(() => {
    dispatch(getCustomVariables());
    if (!isSessionFilter) {
      setSearchParams("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const size = CONTACTS_PAGE_SIZE;
    dispatch(getContactList({ filters: isSessionFilter ? filters : "", search: search, page: currentPage, size }));
  }, [filters, search, currentPage, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearContactListState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (importResult) {
      const search = searchStateParams?.search ?? "";
      const filters = searchStateParams?.filters ?? "";
      const size = CONTACTS_PAGE_SIZE;
      dispatch(getContactList({ filters: filters, search: search, page: currentPage, size }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importResult, dispatch]);

  return (
    <>
      <Box className={sc.contactListContainer}>
        {!loadingContactList && contactList.items && contactList.items.length === 0 && search === "" && filters === "" ? (
          <Box style={{ overflow: "auto", height: "100%" }}>
            <BaseEmptyPage
              botTitle={t("No contacts")}
              botDescription={t("To begin working with contacts, create or connect an existing bot to Domino")}
              title={t("You haven't added any contacts yet")}
              description={t("Upload them as a single table or add them manually")}
              Image={ImageSearch}
            >
              <Button variant="dominoViolet" onClick={onImportContactsOpen} data-pw="import-contacts-button">
                <Flex gap={"8px"} align={"center"}>
                  <ImportIcon />
                  {importTransl("Import Contacts")}
                </Flex>
              </Button>
              <Button size="sm" variant="dominoOutlineViolet" onClick={onNewContact}>
                <Flex gap={"8px"} align={"center"}>
                  <Flex align="center" justify="center" className={s.plusIcon}>
                    <PlusIcon />
                  </Flex>
                  {t("New Contact")}
                </Flex>
              </Button>
            </BaseEmptyPage>
          </Box>
        ) : (
          <Box className={s.contactListContent}>
            {window.innerWidth >= vars.TABLET_VIEW && (
              <Box className={s.contactListTitle} position={isNoSearchResults ? "relative" : "absolute"}>
                <div />
                <Text noOfLines={1} variant="medium">
                  {t("Avatar")}
                </Text>
                <Text noOfLines={1} variant="medium">
                  {t("First Name")}
                </Text>
                <Text noOfLines={1} variant="medium">
                  {t("Last Name")}
                </Text>
                <Text noOfLines={1} variant="medium">
                  {t("Username")}
                </Text>
                <Text noOfLines={1} variant="medium">
                  {t("Created")}
                </Text>
                <div />
              </Box>
            )}
            <ContactList
              contactList={contactList}
              onImportContactsOpen={onImportContactsOpen}
              onNewContact={onNewContact}
              loadingContactList={loadingContactList}
              filters={filters}
            />
            {window.innerWidth >= vars.TABLET_VIEW ? (
              <PaginatorWithSearchParams
                sizeItems={CONTACTS_PAGE_SIZE}
                loadingScope="getContactList"
                data={paginatorData}
                typeOfPage={"ContactList"}
              />
            ) : window.innerWidth < vars.TABLET_VIEW ? (
              <PaginatorWithSearchParams
                sizeItems={CONTACTS_PAGE_SIZE}
                loadingScope="getContactList"
                data={paginatorData}
                typeOfPage={"ContactList"}
                paddingLeft="5px"
              />
            ) : null}
          </Box>
        )}
      </Box>
      <NewContactModal newContactState={isNewContactModalOpen} onClose={onNewContactModalClose} />
      <ImportContactsModal isOpen={isImportContactsOpen} onClose={onImportContactsClose} />
    </>
  );
};
