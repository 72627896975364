import { Flex, Icon } from "@chakra-ui/react";
import { ReactElement } from "react";
import s from "./WarningBlock.module.scss";
import { ReactComponent as warningIcon } from "../../../assets/icons/attention.svg";

export const WarningBlock = ({ children }: { children: ReactElement }) => {
  return (
    <Flex className={s.warningBlock}>
      <Icon className={s.warningIcon} as={warningIcon} data-pw="warning-icon" />
      {children}
    </Flex>
  );
};
