export const EventCategories = {
  Bot: "Bot",
  Flow: "Flow",
  Broadcast: "Broadcast",
  Chats: "Chats",
  Signin: "Signin",
  Signup: "Signup",
  OnboardingOne: "OnboardingOne",
  OnboardingTwo: "OnboardingTwo",
  OnboardingTrigger: "OnboardingTrigger",
  Group: "Group",
};
