import s from "./SysteActionNode.module.scss";
import { ActionNodeProps } from "../ActionNodeProps";
import { SystemActionFlowActionModel, SystemActionNames, FlowActionType } from "../../../FlowBuilderModel";
import { useTranslation } from "react-i18next";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import ThunderIcon from "../../../../../assets/icons/thunderIcon.svg?react";
import { SetVariableAction } from "./Actions/SetVariableAction";
import { PutItemIntoArrayAction } from "./Actions/PutItemIntoArrayAction";
import { TakeItemFromArrayAction } from "./Actions/TakeItemFromArrayAction";

export const SystemActionNode = (props: { actionData: ActionNodeProps }) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const data = props.actionData.node.flowAction as SystemActionFlowActionModel;

  const getSystemActionNode = () => {
    switch (data.typeDiscriminator) {
      case FlowActionType.SetVariableValueFlowAction:
        return <SetVariableAction flowAction={data} />;
      case FlowActionType.PutItemIntoArrayFlowAction:
        return <PutItemIntoArrayAction flowAction={data} />;
      case FlowActionType.TakeItemFromArrayFlowAction:
        return <TakeItemFromArrayAction flowAction={data} />;
      default:
        return <></>;
    }
  };

  const isActionChosen = data.typeDiscriminator !== FlowActionType.SystemActionFlowAction;

  return (
    <Flex flexDirection="column" alignItems="center">
      <div className={s.header}>
        <Icon as={ThunderIcon} color="midDeepBlue" fill="midDeepBlue" boxSize="32px" />
        <Flex ml="8px" direction="column" justify="center">
          <Text as={Box} variant={isActionChosen ? "small" : "large"} color={isActionChosen ? "darkGrey" : "black"}>
            {t("System Action")}
          </Text>
          {isActionChosen && (
            <Text as={Box} variant="large" color="black">
              {t(SystemActionNames[data.typeDiscriminator])}
            </Text>
          )}
        </Flex>
      </div>
      {!isActionChosen && (
        <div className={s.noCondition}>
          <h4>{t("Add Action")}</h4>
        </div>
      )}
      {getSystemActionNode()}
    </Flex>
  );
};
