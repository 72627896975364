import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Container, Flex, FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";
import dayjs from "dayjs";
import { customDateInputIcon, DatePicker, getMinTime } from "../../../../../../UI/atoms/datePicker/DatePicker";
import { ReactComponent as Alarm } from "../../../../../../assets/icons/alarmClock.svg";
import { useAppDispatch, useAppSelector } from "../../../../../../common/state/store";
import {
  selectBroadcastName,
  selectBroadcastIsScheduled,
  selectBroadcastScheduled,
  setBroadcastIsSheduled,
  setBroadcastSheduledDate,
  setBroadcastTitle,
  setValidationError,
  selectBroadcastValidation,
} from "../../../../BroadcastSlice";
import { validateBroadcastTitle, validateSchedulerDate } from "./BroadcastFormTitleValidation";
import s from "../../Broadcast.module.scss";
import { BroadcastValidationField } from "../../../../BroadcastModel";

export function BroadcastFormTitle() {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const dispatch = useAppDispatch();
  const locale = localStorage.getItem("i18nextLng");

  const broadcastName = useAppSelector(selectBroadcastName);
  const validationState = useAppSelector(selectBroadcastValidation);
  const scheduledDate = useAppSelector(selectBroadcastScheduled);
  const isScheduled = useAppSelector(selectBroadcastIsScheduled);

  const minTime = getMinTime(scheduledDate !== undefined ? scheduledDate : null);
  const maxTime = dayjs().endOf("day").toDate();
  const hasScheduled = scheduledDate ? new Date(scheduledDate) : null;

  const CustomInput = forwardRef(customDateInputIcon);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const onChangeTitleValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    dispatch(setBroadcastTitle(newTitle));

    const result = validateBroadcastTitle(newTitle);
    if (result.isInvalid) {
      dispatch(
        setValidationError({
          key: BroadcastValidationField.name,
          message: t(result.error),
        }),
      );
    } else {
      dispatch(
        setValidationError({
          key: BroadcastValidationField.name,
          message: null,
        }),
      );
    }
  };

  const handleTimeSendButtonClick = (value: boolean) => {
    dispatch(setBroadcastIsSheduled(value));
    if (value) {
      dispatch(setBroadcastSheduledDate(dayjs().add(5, "m").toDate()));
    }
  };

  const handleDateChange = (date: Date | null) => {
    const isSchedulerDateError = validateSchedulerDate(date, t);
    dispatch(setBroadcastSheduledDate(date));
    if (!isSchedulerDateError) {
      dispatch(
        setValidationError({
          key: BroadcastValidationField.schedule,
          message: null,
        }),
      );
    } else {
      dispatch(
        setValidationError({
          key: BroadcastValidationField.schedule,
          message: isSchedulerDateError,
        }),
      );
    }
  };

  return (
    <Container variant="dominoContentBlock">
      <FormControl
        className={`${s.broadcastListItemGrid} ${s.broadcastTitleAdaptive}`}
        isInvalid={Boolean(validationState.validationState.name)}
        onChange={onChangeTitleValidation}
      >
        <FormLabel>{t("Broadcast Title")}</FormLabel>
        <Input
          variant="outline"
          value={broadcastName}
          placeholder={t("Broadcast Title placeholder") ?? ""}
          onKeyDown={handleKeyPress}
          data-pw="broadcast-title-input"
        />
        {validationState.validationState.name && (
          <FormErrorMessage data-pw="broadcast-title-error">{`${validationState.validationState.name}`}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl
        isInvalid={Boolean(validationState.validationState.schedule)}
        className={`${s.broadcastListItemGrid} ${s.broadcastSheduleAdaptive}`}
      >
        <FormLabel>{t("Broadcast schedule")}</FormLabel>
        <Box className={s.scheduleBoxMobile}>
          <Flex>
            <Button
              className={`${s.formTitleButtons} ${s.sendImmediatelyButton}`}
              variant={!isScheduled ? "dominoViolet" : "dominoOutlineViolet"}
              onClick={() => handleTimeSendButtonClick(false)}
              isDisabled={false}
              data-pw="send-immediately-button"
            >
              {t("Send immediately")}
            </Button>
            <Button
              className={`${s.formTitleButtons} ${s.scheduleButton}`}
              isDisabled={false}
              variant={!isScheduled ? "dominoOutlineViolet" : "dominoViolet"}
              onClick={() => handleTimeSendButtonClick(true)}
              data-pw="schedule-button"
            >
              {t("Schedule")}
            </Button>
          </Flex>
          {<FormErrorMessage data-pw="broadcast-date-picker-error">{validationState.validationState.schedule}</FormErrorMessage>}
        </Box>
        <DatePicker
          className={s.datePickerDesktop}
          wrapperClassName={s.DatePickerWrapper}
          disabled={!isScheduled}
          showTimeSelect
          strictParsing
          minDate={new Date()}
          minTime={minTime}
          maxTime={maxTime}
          selected={hasScheduled}
          dateFormat={locale !== "en" ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy h:mm a"}
          onChange={handleDateChange}
          data-pw="broadcast-date-picker"
          customInput={<CustomInput icon={Alarm} disableIcon={isScheduled} colorIcon="darkGrey" />}
          placeholderText={ct("placeholderDateTime") ?? ""}
        />
      </FormControl>
    </Container>
  );
}
