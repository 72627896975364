import React, { useEffect, useRef, useState } from "react";
import { Field, FieldProps, useFormikContext } from "formik";
import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import { ButtonType } from "../../../common/AppEnums";
import { StaticButtonModel } from "../../../common/AppButtonsModel";
import { composeValidators, maxLength, required, validateUrl } from "../../../common/validation/defaultValidators";
import { InputWrapper } from "../../inputWrapper/InputWrapper";
import { InputType } from "../../inputWrapper/InputWrapperModel";
import { useTranslation } from "react-i18next";
import { insertStringIntoText } from "../../../common/utils/insertStringIntoText";
import s from "./ChangeButtonPopover.module.scss";

interface Props {
  isDefaultPayload?: boolean;
  isNoTriggerButtonCreate?: boolean;
}

export const StaticButtonFormFields = ({ isDefaultPayload, isNoTriggerButtonCreate }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const { values, setFieldValue } = useFormikContext<StaticButtonModel>();
  const [cursorPosition, setCursorPosition] = useState(values?.label?.length ?? 0);
  const inputRef = useRef<HTMLInputElement>(null);

  const onAddEmoji = (
    emojiData: string,
    fieldValue: string,
    inputMaxLength: number,
    setFieldValue: (field: string, value: string) => void,
  ) => {
    const text = insertStringIntoText(fieldValue ?? "", emojiData, cursorPosition);
    const lengthError = maxLength(inputMaxLength)(text);
    if (lengthError) {
      return;
    }
    setFieldValue("label", text);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (isNoTriggerButtonCreate) {
      setFieldValue("type", ButtonType.UrlButton);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNoTriggerButtonCreate]);

  return (
    <>
      <Text mb="16px" variant="large">
        {t("Button Title")}
      </Text>
      <Field name="label" validate={composeValidators(required(t("Title field can't be empty")))}>
        {({ field, form }: FieldProps) => (
          <Box height="60px" mb="8px">
            <InputWrapper
              cursorPosition={cursorPosition}
              type={InputType.Input}
              maxLength={32}
              charactersNumber={field.value?.length}
              emojiProps={{
                addEmoji: (emojiData: string) => {
                  onAddEmoji(emojiData, field.value, 32, setFieldValue);
                },
              }}
              childRef={inputRef}
            >
              <Input
                onKeyDown={handleKeyPress}
                ref={inputRef}
                variant="outline"
                isInvalid={!!form.errors.label && !!form.touched.label}
                maxLength={32}
                paddingRight="62px"
                _focus={{ bg: "white" }}
                placeholder={t("Enter the title") ?? ""}
                className={`${field.value?.length > 0 && s.inputNotEmpty}`}
                errorBorderColor={"mainRed"}
                {...field}
                onSelect={e => setCursorPosition(e.currentTarget.selectionStart ?? 0)}
                data-pw="button-title-input"
              />
            </InputWrapper>
          </Box>
        )}
      </Field>
      {!isDefaultPayload ? (
        <>
          <Field name="type">
            {({ field }: FieldProps) => (
              <Flex w="100%" mb="12px">
                {!isNoTriggerButtonCreate ? (
                  <>
                    <Button
                      variant={values.type === ButtonType.CallbackButton ? "dominoViolet" : "dominoOutlineViolet"}
                      borderTopRightRadius={0}
                      borderBottomRightRadius={0}
                      w="100%"
                      onClick={() => {
                        setFieldValue("type", ButtonType.CallbackButton);
                      }}
                      data-pw="payload-button"
                    >
                      {t("Payload")}
                    </Button>
                    <Button
                      variant={values.type === ButtonType.UrlButton ? "dominoViolet" : "dominoOutlineViolet"}
                      borderTopLeftRadius={0}
                      borderBottomLeftRadius={0}
                      w="100%"
                      onClick={() => {
                        setFieldValue("type", ButtonType.UrlButton);
                      }}
                      data-pw="website-button"
                    >
                      {t("Website address")}
                    </Button>
                  </>
                ) : (
                  <Box mt="-8px" data-pw="website-title">
                    {t("Website address")}
                  </Box>
                )}
              </Flex>
            )}
          </Field>
          {values.type === ButtonType.CallbackButton && !isNoTriggerButtonCreate ? (
            <Field name="payload">
              {({ field, form }: FieldProps) => (
                <Box height="60px">
                  <InputWrapper type={InputType.Input} maxLength={64} charactersNumber={field.value?.length}>
                    <Input
                      onKeyDown={handleKeyPress}
                      maxLength={64}
                      placeholder={t("Enter the payload") ?? ""}
                      variant="outline"
                      isInvalid={values.type === ButtonType.CallbackButton && !!form.errors.payload && !!form.touched.payload}
                      {...field}
                      _focus={{ bg: "white" }}
                      className={`${s.input} ${field.value?.length > 0 && s.inputNotEmpty}`}
                      errorBorderColor={"mainRed"}
                      value={field.value ?? ""}
                      data-pw="payload-input"
                    />
                  </InputWrapper>
                  {values.type === ButtonType.CallbackButton && !!form.errors.payload && !!form.touched.payload && (
                    <Box color="mainRed" fontSize={"12px"}>
                      <Text data-pw="payload-error">{String(form.errors.payload)}</Text>
                    </Box>
                  )}
                </Box>
              )}
            </Field>
          ) : (
            <Field validate={composeValidators(validateUrl(t("Website address is required")))} name="url">
              {({ field, form }: FieldProps) => (
                <Box height="124px" mb="12px">
                  <Textarea
                    variant="dominoColor"
                    placeholder={t("Enter the address") ?? ""}
                    isDisabled={values.type === ButtonType.CallbackButton && !isNoTriggerButtonCreate}
                    resize="none"
                    isInvalid={values.type === ButtonType.UrlButton && !!form.errors.url && !!form.touched.url}
                    {...field}
                    value={field.value?.trim() ?? ""}
                    data-pw="website-input"
                  />
                  {values.type === ButtonType.UrlButton && !!form.errors.url && !!form.touched.url && (
                    <Box color="mainRed" fontSize={"12px"}>
                      <Text data-pw="website-error">{String(form.errors.url)}</Text>
                    </Box>
                  )}
                </Box>
              )}
            </Field>
          )}
        </>
      ) : (
        <>
          <Field name="type">
            {({ field }: FieldProps) => (
              <Flex w="100%" mb="16px" justifyContent={"center"} alignItems={"center"}>
                <Button
                  variant={values.type === ButtonType.CallbackButton ? "dominoViolet" : "dominoOutlineViolet"}
                  borderTopRightRadius={0}
                  borderBottomRightRadius={0}
                  w="100%"
                  onClick={() => {
                    setFieldValue("type", ButtonType.CallbackButton);
                  }}
                  data-pw="payload-button"
                >
                  {t("Payload")}
                </Button>
                <Button
                  variant={values.type === ButtonType.UrlButton ? "dominoViolet" : "dominoOutlineViolet"}
                  borderTopLeftRadius={0}
                  borderBottomLeftRadius={0}
                  w="100%"
                  onClick={() => {
                    setFieldValue("type", ButtonType.UrlButton);
                  }}
                  data-pw="website-button"
                >
                  {t("Website address")}
                </Button>
              </Flex>
            )}
          </Field>
          {values.type === ButtonType.CallbackButton ? (
            <Field name="payload">
              {({ field, form }: FieldProps) => (
                <Box height="60px">
                  <InputWrapper type={InputType.Input} maxLength={64} charactersNumber={field.value?.length}>
                    <Input
                      maxLength={64}
                      placeholder={t("Enter the payload") ?? ""}
                      variant="outline"
                      isInvalid={values.type === ButtonType.CallbackButton && !!form.errors.payload && !!form.touched.payload}
                      {...field}
                      _focus={{ bg: "white" }}
                      className={`${s.input} ${field.value?.length > 0 && s.inputNotEmpty}`}
                      errorBorderColor={"mainRed"}
                      value={field.value ?? ""}
                      data-pw="payload-input"
                    />
                  </InputWrapper>
                  {values.type === ButtonType.CallbackButton && !!form.errors.payload && !!form.touched.payload && (
                    <Box color="mainRed" fontSize={"12px"}>
                      <Text data-pw="payload-error">{String(form.errors.payload)}</Text>
                    </Box>
                  )}
                </Box>
              )}
            </Field>
          ) : (
            <Field validate={composeValidators(validateUrl(t("Website address is required")))} name="url">
              {({ field, form }: FieldProps) => (
                <Box height="124px" mb="12px">
                  <Textarea
                    variant="dominoColor"
                    placeholder={t("Enter the address") ?? ""}
                    isDisabled={values.type === ButtonType.CallbackButton}
                    resize="none"
                    isInvalid={values.type === ButtonType.UrlButton && !!form.errors.url && !!form.touched.url}
                    {...field}
                    value={field.value?.trim() ?? ""}
                    data-pw="website-input"
                  />
                  {values.type === ButtonType.UrlButton && !!form.errors.url && !!form.touched.url && (
                    <Box color="mainRed" fontSize={"12px"}>
                      <Text data-pw="website-error">{String(form.errors.url)}</Text>
                    </Box>
                  )}
                </Box>
              )}
            </Field>
          )}
        </>
      )}
    </>
  );
};
