import { Box, Button, Flex, Icon, Textarea } from "@chakra-ui/react";
//import { t } from "i18next";
//import React from "react";
//import { isDesktop } from "react-device-detect";
//import { IMAGE_FILE_EXTS } from "../../../../../../../../common/AppEnums";
//import { addFormatting } from "../../../../../../../../common/formattedText/addFormatting";
//import { Loading } from "../../../../../../../../common/loading/LoadingStateContainer";
//import { FileUploadType } from "../../../../../../../conversation/ConversationModel";
//import { InputWrapper } from "../../../../../../../inputWrapper/InputWrapper";
//import { InputType } from "../../../../../../../inputWrapper/InputWrapperModel";
import ReplyIcon from "../../../../../../../../assets/icons/replyIcon.svg?react";
import CrossIcon from "../../../../../../../../assets/icons/cross.svg?react";
import NoteIcon from "../../../../../../../../assets/icons/noteIcon.svg?react";
import PaperClipIcon from "../../../../../../../../assets/icons/paperClipIcon.svg?react";
import ImageIcon from "../../../../../../../../assets/icons/imageChatIcon.svg?react";

import s from "./GroupChatTextAreaBlock.module.scss";
import { AppSettings } from "../../../../../../../../common/AppSettings";
import { useEffect, useRef, useState } from "react";
import { InputWrapper } from "../../../../../../../inputWrapper/InputWrapper";
import { InputType } from "../../../../../../../inputWrapper/InputWrapperModel";
import { useAppDispatch, useAppSelector } from "../../../../../../../../common/state/store";
import { selectConversationVariables } from "../../../../../../../conversation/ConversationSlice";
import { CustomVariableModel, IMAGE_FILE_EXTS } from "../../../../../../../../common/AppEnums";
import { addFormatting } from "../../../../../../../../common/formattedText/addFormatting";
import { insertStringIntoText } from "../../../../../../../../common/utils/insertStringIntoText";
import { maxLength } from "../../../../../../../../common/validation/defaultValidators";
import { useTranslation } from "react-i18next";
import {
  selectConversationMessage,
  sendConversationMessage,
  setConversationMessage,
  uploadMessageFile,
} from "../GroupChatMessagesList/GroupChatMessagesListSlice";
import { useParams } from "react-router-dom";
import { ConversationSendMessageModel, FileUploadType } from "../../../../../../../conversation/ConversationModel";
import { Attachments } from "../Attachments/Attachments";
import { Loading } from "../../../../../../../../common/loading/LoadingStateContainer";
import { selectReplyMode, setReplyModeState } from "./GroupChatTextAreaBlockSlice";
import { selectCurrentBot } from "../../../../../../../sidebar/SidebarSlice";
import { useGetLoadingState } from "../../../../../../../../common/loading/hooks/useGetLoadingState";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const appSettings: AppSettings;

export const GroupChatTextAreaBlock = () => {
  const groupChatTextAreaTranslation = useTranslation("translation", { keyPrefix: "groupChat.groupChatTextArea" }).t;
  const { t } = useTranslation("translation", { keyPrefix: "conversation" });

  const [messageText, setMessageText] = useState("");
  const variables = useAppSelector(selectConversationVariables);
  const message = useAppSelector(selectConversationMessage);
  const botInfo = useAppSelector(selectCurrentBot);
  const isLoadingFile = useGetLoadingState("messageFileUpload");

  const inputMaxLength = message.photo == null && message.document == null ? 2000 : 1024;
  const replyMode = useAppSelector(selectReplyMode);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  if (replyMode.enabled) {
    textareaRef.current?.focus();
  }
  const dispatch = useAppDispatch();
  const params = useParams();
  const id = params["conversationId"];

  const addEmoji = (emojiData: string) => {
    const text = insertStringIntoText(messageText ?? "", emojiData, textareaRef.current?.selectionStart);
    const lengthError = maxLength(inputMaxLength)(text);
    if (lengthError) {
      return;
    }
    setMessageText(text);
  };
  const addVariableText = (variable: CustomVariableModel) => {
    const text = insertStringIntoText(
      messageText ?? "",
      "@{" + variable.scope + ":" + variable.key + "}",
      textareaRef.current?.selectionStart,
    );
    const lengthError = maxLength(inputMaxLength)(text);
    if (lengthError) {
      return;
    }
    setMessageText(text);
  };

  useEffect(() => {
    if (replyMode.enabled) {
      dispatch(setReplyModeState({ enabled: false, message: undefined }));
    }
  }, [id]);

  const onSendMessage = (message: ConversationSendMessageModel) => {
    if (!id) {
      return;
    }
    dispatch(sendConversationMessage({ conversationId: id, message }));
  };

  const uploadFile = (file: File, type: FileUploadType) => {
    dispatch(uploadMessageFile({ file, type }));
  };

  const setMessage = (message: ConversationSendMessageModel, cursorPosition?: number) => {
    // if ((!message.text && isOpen) || (!message.text.includes("@") && isOpen)) {
    //   onClose();
    // }
    const isUserTryTagged =
      (message.text.includes("@") && cursorPosition) || (message.text.startsWith("@") && cursorPosition === 0);
    if (isUserTryTagged) {
      // const currentText = message.text
      //   .substring(0, cursorPosition + 1)
      //   .split(" ")
      //   .reverse()[0];
      // let isTagged = currentText.startsWith("@");
      // if (!isTagged) {
      //   isTagged = currentText.includes("\n@");
      // }
      //const name = currentText.substring(currentText.indexOf("@"), currentText.length);
      // if (isTagged && name.length >= 1) {
      //   switch (name.length) {
      //     case 1:
      //       dispatch(getTaggedOperators({ name: "" }));
      //       break;
      //     default:
      //       dispatch(getTaggedOperators({ name: name.slice(1) }));
      //       break;
      //   }
      // } else {
      //   onClose();
      // }
    }
    dispatch(setConversationMessage(message));
  };

  const onSend = () => {
    // if (props.isNoteActive) {
    //   onSendMessage({
    //     text: localMessageText,
    //     typeDiscriminator: OutgoingTypeDiscriminator.NoteTextConversation,
    //   });
    // } else
    if (replyMode.enabled) {
      onSendMessage({
        ...message,
        text: messageText,
        replyToMessageId: replyMode.message?.id,
      });
      dispatch(setReplyModeState({ enabled: false, message: undefined }));
    } else {
      onSendMessage({
        ...message,
        text: messageText,
      });
    }

    setMessageText("");
    //props.onCloseReply();
    //props.setIsNoteActive(false);
  };

  const removeAttachedFile = () => {
    const updatedMessage = {
      ...message,
      photo: undefined,
      document: undefined,
    };
    setMessage(updatedMessage);
  };

  return (
    <Flex className={s.container}>
      <Box className={`${s.messageArea}`}>
        <InputWrapper
          type={InputType.TextArea}
          maxLength={inputMaxLength}
          charactersNumber={messageText.length}
          emojiProps={{ addEmoji: addEmoji }}
          addFormatting={action => {
            textareaRef.current && addFormatting(action, messageText, inputMaxLength, textareaRef.current, setMessageText);
          }}
          variablesProps={variables ? { variables: variables, addCustomVariableText: addVariableText } : undefined}
          buttonBlockStyle={{
            borderRadius: "0 0 8px 8px",
            backgroundColor: false ? "#FEF6DC" : "white",
            width: "100%",
          }}
        >
          {replyMode.enabled && replyMode.message && (
            <Flex className={s.replyWrapper}>
              <Flex className={s.replyMessage}>
                <Flex className={s.replyMessageFlex}>
                  <Icon as={ReplyIcon} className={s.replyIcon}></Icon>
                  {replyMode.message.photoFileId && (
                    <span className={s.replyMessagePhoto}>
                      <img alt="" className={s.photo} src={`${appSettings.apiBaseUrl}/file/${replyMode.message.photoFileId}`} />
                    </span>
                  )}
                  <Box className={`${s.replyMessageContent} ${replyMode.message.photoFileId ? s.replyMessageContentImage : ""}`}>
                    <span className={s.replyMessageAuthor}>
                      {replyMode.message?.author === "bot" ? botInfo?.name : replyMode.message?.author}
                    </span>
                    {replyMode.message?.contact && !replyMode.message?.text ? (
                      <Box>
                        <span className={s.replyMessageContact}>{t("Contact")}</span>
                      </Box>
                    ) : (
                      <></>
                    )}
                    {!!replyMode.message?.documentName ? (
                      <span className={`${s.replyMessageText} ${s.description}`}>{replyMode.message?.documentName}</span>
                    ) : (
                      <>
                        {replyMode.message?.photoFileId && !replyMode.message?.text ? (
                          <span className={`${s.replyMessageText} ${s.description}`}>{t("Image")}</span>
                        ) : (
                          <span className={s.replyMessageText}>{replyMode.message?.text}</span>
                        )}
                      </>
                    )}
                  </Box>
                </Flex>
                <Button
                  className={s.replyCancelButton}
                  onClick={() => dispatch(setReplyModeState({ enabled: false, message: undefined }))}
                >
                  <Icon as={CrossIcon} className={s.icon}></Icon>
                </Button>
              </Flex>
            </Flex>
          )}
          <Textarea
            data-pw="groupChat-message-textarea"
            className={`${s.input} ${false && s.noteInputActive}`}
            placeholder={replyMode.enabled ? t("Reply here") : groupChatTextAreaTranslation("Write a message...")}
            resize="none"
            variant="dominoColor"
            ref={textareaRef}
            value={messageText}
            maxLength={inputMaxLength} // we send attached images/docs as separate message
            onChange={e => setMessageText(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Enter" && !e.shiftKey && messageText.trim()) {
                e.preventDefault();
                onSend();
              }
            }}
          ></Textarea>
        </InputWrapper>
      </Box>
      <Loading scope="messageFileUpload" />
      {(message.document || message.photo) && <Attachments removeAttachedFile={removeAttachedFile} message={message} />}

      <Flex className={s.boxActions}>
        <Flex className={s.actions}>
          <label
            data-pw="image-btn"
            className={`${s.input} ${message.document || message.photo || isLoadingFile ? s.disabledInput : ""}`}
          >
            <ImageIcon
              className={`${s.actionIconItem} ${message.document || message.photo || isLoadingFile ? s.disabled : ""}`}
            />
            <input
              className={s.hidden}
              onChange={e => {
                if (e.target.files) {
                  uploadFile(e.target.files[0], FileUploadType.photo);
                }
              }}
              value=""
              type="file"
              accept={IMAGE_FILE_EXTS}
            />
          </label>
          <label
            data-pw="document-btn"
            className={`${s.input} ${message.document || message.photo || isLoadingFile ? s.disabledInput : ""}`}
          >
            <PaperClipIcon
              className={`${s.actionIconItem} ${message.document || message.photo || isLoadingFile ? s.disabled : ""}`}
            />
            <input
              className={s.hidden}
              onChange={e => {
                if (e.target.files) {
                  uploadFile(e.target.files[0], FileUploadType.document);
                }
              }}
              value=""
              type="file"
            />
          </label>
          {false && <NoteIcon className={`${s.noteIcon} ${false && s.noteActive}`} />}
        </Flex>
        <Button
          isDisabled={!messageText.trim() && !message.document && !message.photo}
          onClick={() => onSend()}
          size={"sm"}
          variant="dominoViolet"
          data-pw="send-btn"
          isLoading={false}
          loadingText={groupChatTextAreaTranslation("Send")}
          spinnerPlacement="end"
        >
          <span>{groupChatTextAreaTranslation("Send")}</span>
        </Button>
      </Flex>
    </Flex>
  );
};
