import { ReactElement, useEffect, useState } from "react";
import { Tooltip, IconButton, useClipboard, Flex, ResponsiveValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../common/state/store";
import CopyIcon from "../../../assets/icons/copyIconForText.svg?react";
import { notify } from "../../../common/notifications/NotificationSlice";
import s from "./ClipboardText.module.scss";
interface Props {
  elemIsCopy: ReactElement;
  textIsCopy: string;
  classNameWrapper?: string;
  data_pw?: string;
  opacity?: ResponsiveValue<string>;
  color?: string;
  event?: () => void;
}

export default function ClipboardText({ elemIsCopy, textIsCopy, classNameWrapper, data_pw, opacity, color, event }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "commonWords" });
  const dispatch = useAppDispatch();
  const copyTelegramId = textIsCopy ?? "";
  const { onCopy, hasCopied } = useClipboard(copyTelegramId);

  const [tooltipLabel, setTooltipLabel] = useState(t("Copy"));
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    if (hasCopied) {
      dispatch(notify({ message: `${t("Copied")}!`, type: "success" }));
      setTooltipLabel("");
      setTimeout(() => setTooltipLabel(t("Copy")), 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCopied]);

  return (
    <Flex className={`${classNameWrapper} ${s.wrapper}`}>
      {elemIsCopy}
      <Tooltip
        label={tooltipLabel}
        bg="newYellow"
        color="black"
        closeOnClick={false}
        placement="top"
        fontSize={"13px"}
        isOpen={isTooltipVisible}
      >
        <IconButton
          opacity={opacity}
          isDisabled={false}
          className={s.contactInfoTelegramIdCopyButton}
          variant={"unstyled"}
          onClick={() => {
            onCopy();
            event && event();
          }}
          icon={<CopyIcon width="20px" height="20px" color={color} />}
          aria-label="Copy to clipboard"
          data-pw={data_pw}
          onMouseEnter={() => setIsTooltipVisible(true)}
          onMouseLeave={() => setIsTooltipVisible(false)}
        />
      </Tooltip>
    </Flex>
  );
}
