import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Flex,
  Text,
  ModalFooter,
  Button,
  ModalHeader,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../common/state/store";
import { addBotMenuCommand } from "../../../BotSlice";
import { BotMenuCreateModel } from "../../../BotModel";
import { validateCommandDescription, validateCommandName } from "../../../../../common/validation/botCommandsValidation";
import { t } from "i18next";
import useGA from "../../../../../common/ga/GAEventTracker";
import { BotCommandsEvents } from "../../../../../common/ga/gaEventsEnums.ts/BotGAEventsEnums";
import { EventCategories } from "../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import CommandTriggerIcon from "../../../../../assets/icons/commandTriggerIcon.svg?react";
import CrossIcon from "../../../../../assets/icons/cross.svg?react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  botId: string;
}

export default function AddCommandPopup({ botId, ...props }: Props) {
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [commandNameValidationError, setCommandNameValidationError] = useState("");
  const [commandDescriptionValidationError, setCommandDescriptionValidationError] = useState("");
  const dispatch = useAppDispatch();
  const trackEvent = useGA(EventCategories.Bot);
  const handleInputDescription = (e: { target: HTMLInputElement }) => {
    setDescription(e.target.value);
  };

  const handleInputName = (e: { target: HTMLInputElement }) => {
    setName(e.target.value);
  };

  const handleCancelCreateNewCommand = () => {
    setName("");
    setDescription("");
    setCommandNameValidationError("");
    setCommandDescriptionValidationError("");
    props.onClose();
  };

  const onCreateCommand = () => {
    if (!name) {
      handleInputNameBlur();
      return;
    }
    if (!description) {
      handleInputDescriptionBlur();
      return;
    }
    if (commandNameValidationError) {
      handleInputNameBlur();
      return;
    }
    if (commandDescriptionValidationError) {
      handleInputDescriptionBlur();
      return;
    }

    const data: BotMenuCreateModel = {
      command: name,
      description: description,
      isActive: true,
    };
    props.onClose();
    dispatch(addBotMenuCommand({ botId, data }));
  };

  const handleInputNameBlur = () => {
    const error = validateCommandName(name, t);
    setCommandNameValidationError(error || "");
  };

  const handleInputDescriptionBlur = () => {
    const error = validateCommandDescription(description, t);
    setCommandDescriptionValidationError(error || "");
  };

  useEffect(() => {
    if (!props.isOpen) {
      setName("");
      setDescription("");
      setCommandNameValidationError("");
      setCommandDescriptionValidationError("");
    }
  }, [props.isOpen]);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent p={{ base: "24px", md: "32px" }} mx="16px" color="#232B39" maxW="460px" borderRadius="8px">
        <ModalCloseButton borderRadius={"50%"} color={"darkGrey"} _hover={{ bg: "defaultGrey", color: "mainPurple" }}>
          <CrossIcon />
        </ModalCloseButton>
        <ModalHeader p={0} mb="16px">
          {t("bot.Create New Command")}
        </ModalHeader>
        <ModalBody fontSize="15px" lineHeight="20px" p="0" mb="24px">
          <Text mb="24px" color="#8592A3">
            {t("bot.menuCommands.Create Command description")}
          </Text>
          <Flex gap="8px" flexDir="column" mb="16px">
            <Text>{t("bot.Command Name")}</Text>
            <Flex gap={1} flexDir={"column"}>
              <InputGroup>
                <InputLeftElement>
                  <CommandTriggerIcon width="20px" height="20px" color="#4EAA4A" />
                </InputLeftElement>
                <Input
                  pl="42px"
                  placeholder={t("bot.Enter text")}
                  value={name}
                  onChange={handleInputName}
                  onBlur={handleInputNameBlur}
                  isInvalid={!!commandNameValidationError}
                  onFocus={() => {
                    setCommandNameValidationError("");
                  }}
                  maxLength={32}
                />
              </InputGroup>
              {!!commandNameValidationError && (
                <Text color={"red"} fontSize={"12px"}>
                  {commandNameValidationError}
                </Text>
              )}
            </Flex>
          </Flex>

          <Flex gap="8px" flexDir="column">
            <Text>{t("bot.Description")}</Text>
            <Flex gap={1} flexDir={"column"}>
              <Input
                placeholder={t("bot.Enter text")}
                value={description}
                onChange={handleInputDescription}
                onBlur={handleInputDescriptionBlur}
                isInvalid={!!commandDescriptionValidationError}
                onFocus={() => {
                  setCommandDescriptionValidationError("");
                }}
                type="text"
                maxLength={256}
                minLength={2}
              />
              {!!commandDescriptionValidationError && (
                <Text color={"red"} fontSize={"12px"}>
                  {commandDescriptionValidationError}
                </Text>
              )}
            </Flex>
          </Flex>
        </ModalBody>

        <ModalFooter p="0">
          <Flex w="100%" justifyContent="space-between">
            <Button
              variant="dominoOutlineViolet"
              onClick={() => {
                trackEvent(BotCommandsEvents.BotCommandsNewCancel);
                handleCancelCreateNewCommand();
              }}
            >
              {t("bot.menuCommands.Cancel")}
            </Button>
            <Button
              variant="dominoViolet"
              mr={3}
              onClick={() => {
                trackEvent(BotCommandsEvents.BotCommandsNewCreate);
                onCreateCommand();
              }}
            >
              {t("bot.menuCommands.Create")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
