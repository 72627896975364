import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  ListItem,
  List,
  Text,
} from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import s from "./AccordionUI.module.scss";

export const AccordionUI = ({
  items,
  showTitle,
  hiddenTitle,
}: {
  items: ReactNode[];
  showTitle: string;
  hiddenTitle: string;
}) => {
  const [accordionText, setAccordionText] = useState(showTitle);

  return (
    <Accordion
      variant="stepsAccordion"
      onChange={e => (e.valueOf() === -1 ? setAccordionText(showTitle) : setAccordionText(hiddenTitle))}
      allowToggle
    >
      <AccordionItem>
        <AccordionButton p="12px">
          <Box flex="1" textAlign="left">
            <Text variant="medium">{accordionText}</Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <List className={s.instructionStepsList} spacing={4}>
            {items &&
              items.map((el, ind) => {
                return (
                  <ListItem key={ind} className={s.listItem}>
                    {el}
                  </ListItem>
                );
              })}
          </List>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
