import { useCallback, useEffect } from "react";

const useRemoveCustomSpinner = () => {
  const handleError = (event: ErrorEvent) => {
    if (event.error) {
      setTimeout(() => {
        window.location.href = "https://domino-crm.com/404";
      }, 1000);
    }
  };
  const removeCustomSpinner = useCallback(() => {
    const spinner: HTMLDivElement | null = document.querySelector(".custom-spinner");
    const errorListenerScript: HTMLScriptElement | null = document.querySelector("#errorListener");
    if (spinner) spinner.remove();
    if (errorListenerScript) {
      window.removeEventListener("error", handleError);
      errorListenerScript.remove();
    }
  }, []);

  useEffect(() => {
    // When changing the global font, it should also be changed here
    document.fonts.load("1px Lato").then(() => {
      removeCustomSpinner();
    });
    setTimeout(() => {
      if (document.querySelector(".custom-spinner")) removeCustomSpinner();
    }, 30000);
  }, [removeCustomSpinner]);
};

export default useRemoveCustomSpinner;
