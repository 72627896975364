import { Image } from "@chakra-ui/react";
import Circle from "../../../../assets/images/dangerCircle.svg?react";
import { useTranslation } from "react-i18next";
import { ConversationMessageModel } from "../../ConversationModel";
import { TooltipWithTouch } from "../../../../common/tooltipWithTouch/TooltipWithTouch";

interface Props {
  errorCode: ConversationMessageModel["errorCode"];
}

export const ErrorMessageItem = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "conversation.errors" });
  const errorHandler = (error?: string) => {
    if (!error) {
      return "";
    }
    const tooManyRequestError = new RegExp(/Too Many Requests: retry after/gm);
    if (tooManyRequestError.test(error)) {
      return t("Too Many Requests");
    }
    const reg = new RegExp(/Wrong HTTP URL/gm);

    return reg.test(error) ? t("Wrong Url") : t(error);
  };

  return (
    <TooltipWithTouch
      hasArrow
      variant="dominoChat"
      label={errorHandler(props?.errorCode)}
      openDelay={500}
      placement="top"
      background="#FEF6DC"
    >
      <Image as={Circle} alt="" />
    </TooltipWithTouch>
  );
};
