import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseAccordion = definePartsStyle({
  container: {
    border: "none",
    minHeight: "20px",
    padding: 0,

    _hover: {
      backgroundColor: "none",
    },
  },

  button: {
    backgroundColor: "#FFFFFF",
    border: "1px solid white",
    transition: "ease-out .2s",
    cursor: "pointer",
    borderRadius: "12px",
    mb: "8px",
    padding: "18px 24px",

    _hover: {
      borderColor: "darkPurple",
      backgroundColor: "white",
    },
  },

  panel: {
    padding: "0",
  },
});

const stepsAccordion = definePartsStyle({
  container: {
    border: "none",
    minHeight: "20px",
    padding: 0,
  },

  button: {
    h: "44px",
    border: "1px solid var(--chakra-colors-defaultGrey)",
    transition: "ease-out .2s",
    color: "black  !important",
    cursor: "pointer",
    bg: "white",
    borderRadius: "8px",
    _hover: {
      backgroundColor: "defaultGrey",
      color: "black  !important",
    },
    _expanded: {
      color: "darkGrey",
      borderRadius: "8px 8px 0 0",
    },
  },

  panel: {
    padding: "12px",
    borderRadius: "0 0 8px 8px",
    border: "1px solid var(--chakra-colors-defaultGrey)",
    borderTop: "none",
    bg: "white",
  },
});

export const accordionTheme = defineMultiStyleConfig({
  variants: {
    baseAccordion,
    stepsAccordion,
  },
});
