import s from "./RefUrlEditPopup.module.scss";
import refUrlIcon from "../../../../../assets/icons/refUrlIcon.svg?react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
  Stack,
  Box,
  DrawerFooter,
  Link,
  Heading,
  Icon,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  CustomVariablesModel,
  NodeValidation,
  TriggerModel,
  TriggerNodeModel,
  TriggerTypeDiscriminator,
} from "../../../FlowBuilderModel";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { ExternalLinkIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { CustomVariableContextMenu } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenu";
import { CustomVariableContextMenuType } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import { createCustomVariable } from "../../../FlowBuilderSlice";
import { useAppDispatch } from "../../../../../common/state/store";
import { CustomVariableModel, CustomVariableType } from "../../../../../common/AppEnums";
import { TooltipWithTouch } from "../../../../../common/tooltipWithTouch/TooltipWithTouch";

type Props = {
  isOpen?: boolean;
  triggerNode: TriggerModel;
  nodeEditor: TriggerNodeModel;
  validation: NodeValidation; // no validation
  botUserName: string;
  customVariables: CustomVariablesModel[];
  onCancel: (triggerType: TriggerTypeDiscriminator) => void;
  onSave: (trigger: TriggerModel) => void;
  onValidate: (trigger: TriggerModel) => void;
};

export const RefUrlEditPopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow.editTriggerPopup" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const dispatch = useAppDispatch();

  const [chosenVariable, setChosenVariable] = useState<CustomVariablesModel | undefined>();
  const [isCopied, setIsCopied] = useState(false);

  const refUrl = `https://t.me/${props.botUserName}?start=${props.triggerNode.triggerRefNumber}`;

  useEffect(() => {
    const custVar = props.customVariables.find(
      el => props.triggerNode.targetCustomVariableId === el?.id || props.nodeEditor.newVariableId === el?.id,
    );
    setChosenVariable(custVar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.triggerNode, props.isOpen, props.nodeEditor]);

  const submit = () => {
    const updatedTrigger = {
      ...props.triggerNode,
      targetCustomVariableId: chosenVariable?.id,
    };
    props.onSave(updatedTrigger);
  };

  const onCancel = () => {
    setChosenVariable(undefined);
    props.onCancel(props.triggerNode.typeDiscriminator);
  };

  const selectVariable = (variable: CustomVariablesModel) => {
    setChosenVariable(variable);
  };

  const onCreateVariable = (nodeEditorInfo?: unknown) => (newCustomVariable?: CustomVariableModel) => {
    if (newCustomVariable) {
      dispatch(createCustomVariable({ variable: newCustomVariable, nodeEditorInfo: nodeEditorInfo }));
    }
  };

  return (
    <Drawer
      placement="right"
      size={"xs"}
      isOpen={props.isOpen ?? false}
      onClose={onCancel}
      isFullHeight={false}
      blockScrollOnMount={false}
    >
      <DrawerContent p="8px">
        <Loading scope="nodeValidate" />
        <DrawerHeader className={s.header}>
          <Heading fontSize="17px" color="defaultGreen">
            {t("Reference URL")}
          </Heading>
        </DrawerHeader>
        <DrawerBody p="8px 16px">
          <Stack mt={"16px"} direction={["column"]} spacing="10px">
            <Flex alignItems={"center"}>
              <Icon as={refUrlIcon} boxSize={"31px"} color="green" mr={"10px"} />
              <Box lineHeight="16px" fontWeight={"600"}>
                {" "}
                {t("When this happens")}{" "}
              </Box>
            </Flex>
            <Box className={s.heading}>{t("Your Telegram Ref URL")}</Box>
            <div className={s.refUrl} data-pw="ref-url-box">
              <Box w="80%" data-pw="ref-url">
                {refUrl}
              </Box>
              <Link href={refUrl} isExternal color="blueLink" data-pw="copy-ref-url">
                <ExternalLinkIcon boxSize="17px" />
              </Link>
            </div>
          </Stack>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(`${refUrl}`);
              if (!isCopied) {
                setIsCopied(true);
                setTimeout(() => {
                  setIsCopied(false);
                }, 1000);
              }
            }}
            color={`${isCopied ? "green" : "blueLink"}`}
            variant={"link"}
            mt="8px"
            fontWeight="400"
            data-pw="copy-ref-url"
          >
            {isCopied ? `${t("Copied")}!` : t("Copy URL to Clipboard")}
          </Button>
          <Stack mt={"30px"} direction={["column"]} spacing="5px">
            <Box className={s.customFieldHeading}>
              {t("Save Payload to a custom field")}
              <TooltipWithTouch
                variant="dominoLight"
                placement="right"
                label={t(
                  "You can upgrade your Ref URL with specific affiliate’s ID, username or anything else. It will be saved in selected Custom User Field after contact is opted-in. To add variables in ref URL use -- at the end of the link, e.g. 't.me/?start=ref--PAYLOAD'",
                )}
              >
                <QuestionOutlineIcon ml={"5px"} boxSize={"16px"} color="darkGrey" _hover={{ color: "darkPurple" }} />
              </TooltipWithTouch>
            </Box>
            <CustomVariableContextMenu
              clearVariable={() => setChosenVariable(undefined)}
              type={CustomVariableContextMenuType.Dropdown}
              selectCustomVariable={selectVariable}
              addVariable={onCreateVariable()}
              variables={props.customVariables?.filter(el => el.type !== CustomVariableType.Order) || []}
              chosenVariable={chosenVariable}
              excludeVariableType={CustomVariableType.Order}
            />
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <Flex w={"100%"} justifyContent={"space-between"}>
            <Button variant="dominoOutlineViolet" onClick={onCancel} size={"sm"} data-pw="discard-button">
              {ct("Discard")}
            </Button>
            <Button onClick={() => submit()} size={"sm"} variant="dominoViolet" data-pw="apply-button">
              {ct("Apply")}
            </Button>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
