import { call, put, select, takeLatest } from "redux-saga/effects";
import { profanityFilterSlice, selectProfanityFilter } from "./ProfanityFilterSlice";
import { handleException } from "../../../../../../../common/SagaHelper";
import { PayloadAction } from "@reduxjs/toolkit";
import * as ProfanityFilterApi from "./ProfanityFilterApi";
import { ProfanityFilterModel } from "./ProfanityFilterModel";
import { selectGroupChatInfo } from "../../GroupChatInfo/GroupChatInfoSlice";
import { GroupChatModel } from "../../../../../GroupChatModel";
import { beginScope, completeScope } from "../../../../../../../common/loading/LoadingStateActions";
import { notificationSlice } from "../../../../../../../common/notifications/NotificationSlice";

export function* profanityFilterSaga() {
  yield takeLatest(profanityFilterSlice.actions.toggleIsEnabled, toggleIsEnabled);
  yield takeLatest(profanityFilterSlice.actions.saveChanges, saveChanges);
}

function* toggleIsEnabled(action: PayloadAction<boolean>) {
  try {
    yield put(beginScope("profanityFilter/toggleLoading"));

    const profanityFilter: ProfanityFilterModel = yield select(selectProfanityFilter);
    const groupInfo: GroupChatModel = yield select(selectGroupChatInfo);

    yield call(ProfanityFilterApi.changeProfanityFilter, {
      groupId: groupInfo.id,
      profanityFilter: { ...profanityFilter, isEnabled: action.payload },
    });

    yield put(profanityFilterSlice.actions.toggleIsEnabledCompleted(action.payload));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("profanityFilter/toggleLoading"));
  }
}

function* saveChanges(action: PayloadAction<ProfanityFilterModel>) {
  try {
    yield put(beginScope("profanityFilter/saveChanges"));

    const groupInfo: GroupChatModel = yield select(selectGroupChatInfo);

    const profanityFilter: ProfanityFilterModel = yield call(ProfanityFilterApi.changeProfanityFilter, {
      groupId: groupInfo.id,
      profanityFilter: action.payload.customProfanities[0] === "" ? { ...action.payload, customProfanities: [] } : action.payload,
    });

    yield put(profanityFilterSlice.actions.saveChangesCompleted(profanityFilter));
    yield put(
      notificationSlice.actions.notify({
        message: "Saved successfully!",
        type: "success",
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("profanityFilter/saveChanges"));
  }
}
