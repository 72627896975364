/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import {
  BotFlowModel,
  BotMenuCommandsModel,
  BotMenuCreateModel,
  BotModel,
  BotUtmTagsModel,
  BotVariableModel,
  MenuCommandModel,
  BotUtmTagCreateModel,
} from "./BotModel";
import { BotState } from "./BotState";
import { reorderCommandsData, switchCommandData } from "./BotData";

const initialState: BotState = {
  loading: false,
};

export const botSlice = createSlice({
  name: "botListSlice",
  initialState,
  reducers: {
    getBotInfo: (state, action: PayloadAction<{ botId: string }>) => {
      return {
        ...state,
      };
    },
    getBotInfoSucceed: (state, action: PayloadAction<BotModel>) => {
      return {
        ...state,
        botInfo: action.payload,
      };
    },
    changeBotToken: (state, action: PayloadAction<{ botInfo: BotModel; token: string }>) => {
      return {
        ...state,
      };
    },
    deleteBot: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    getBotVariables: (state, action: PayloadAction<{ botId: string }>) => {
      return {
        ...state,
      };
    },
    getBotVariablesSucceed: (state, action: PayloadAction<BotVariableModel[]>) => {
      return {
        ...state,
        botVariables: action.payload,
      };
    },
    createBotVariable: (state, action: PayloadAction<{ variable: BotVariableModel; botId: string }>) => {
      return {
        ...state,
      };
    },
    deleteBotVariable: (state, action: PayloadAction<{ variableId: string; botId: string }>) => {
      return {
        ...state,
      };
    },
    editBotVariableValue: (
      state,
      action: PayloadAction<{
        variableId: string;
        variable: BotVariableModel;
        botId: string;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    editBotVariableDescription: (
      state,
      action: PayloadAction<{
        variableId: string;
        variable: BotVariableModel;
        botId: string;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    checkVariableUsage: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    checkVariableUsageComplete: (state, action: PayloadAction<BotFlowModel[]>) => {
      return {
        ...state,
        botVariableUsage: action.payload,
      };
    },
    clearVariableUsage: state => {
      return {
        ...state,
        botVariableUsage: undefined,
      };
    },
    getBotMenuCommands: (state, action: PayloadAction<{ botId: string; page?: number; size?: number }>) => {
      return {
        ...state,
        loading: true,
      };
    },
    getBotMenuCommandsCompleted: (state, action: PayloadAction<BotMenuCommandsModel>) => {
      return {
        ...state,
        botMenuCommands: action.payload,
      };
    },
    deleteBotMenuCommand: (state, action: PayloadAction<{ botId: string; commandId: string }>) => {
      return {
        ...state,
      };
    },
    editBotMenuCommand: (state, action: PayloadAction<{ botId: string; data: MenuCommandModel }>) => {
      return {
        ...state,
      };
    },
    addBotMenuCommand: (state, action: PayloadAction<{ botId: string; data: BotMenuCreateModel }>) => {
      return {
        ...state,
      };
    },
    switchBotMenuCommands: (state, action: PayloadAction<{ botId: string; data: switchCommandData }>) => {
      return {
        ...state,
      };
    },
    reorderCommandsItems: (state, action: PayloadAction<{ botId: string; data: reorderCommandsData }>) => {
      return {
        ...state,
      };
    },
    editBotMenuCommandSucceded: (state, action: PayloadAction<MenuCommandModel>) => {
      return {
        ...state,
        botMenuCommands: state.botMenuCommands
          ? {
              ...state.botMenuCommands,
              items: state.botMenuCommands.items.map(item => (item.id === action.payload.id ? action.payload : item)),
            }
          : undefined,
      };
    },
    editBotMenuCommandFailed: state => {
      return {
        ...state,
      };
    },
    getBotUtmTags: (state, action: PayloadAction<{ botId: string; page?: number; size?: number }>) => {
      return {
        ...state,
        loading: true,
      };
    },
    getBotUtmTagsCompleted: (state, action: PayloadAction<BotUtmTagsModel>) => {
      return {
        ...state,
        botUtmTags: action.payload,
      };
    },
    deleteBotUtmTag: (state, action: PayloadAction<{ botId: string; tagId: string }>) => {
      return {
        ...state,
      };
    },
    addBotUtmTag: (state, action: PayloadAction<{ botId: string; data: BotUtmTagCreateModel }>) => {
      return {
        ...state,
      };
    },
    //do not remove
    // editBotMenuCommandFailed: (state, action: PayloadAction<{ error: string; data: MenuCommandModel }>) => {
    //   const newItem: MenuCommandModel = {
    //     ...action.payload.data,
    //     validationError: action.payload.error,
    //   };
    //   return {
    //     ...state,
    //     botMenuCommands: state.botMenuCommands
    //       ? {
    //           ...state.botMenuCommands,
    //           items: state.botMenuCommands.items.map(item => (item.id === action.payload.data.id ? newItem : item)),
    //         }
    //       : undefined,
    //   };
    // },
  },
});

export const {
  getBotInfo,
  getBotVariables,
  changeBotToken,
  deleteBot,
  createBotVariable,
  deleteBotVariable,
  editBotVariableValue,
  editBotVariableDescription,
  checkVariableUsage,
  checkVariableUsageComplete,
  clearVariableUsage,
  getBotMenuCommands,
  getBotMenuCommandsCompleted,
  deleteBotMenuCommand,
  editBotMenuCommand,
  addBotMenuCommand,
  switchBotMenuCommands,
  reorderCommandsItems,
  editBotMenuCommandSucceded,
  editBotMenuCommandFailed,
  getBotUtmTags,
  getBotUtmTagsCompleted,
  deleteBotUtmTag,
  addBotUtmTag,
} = botSlice.actions;

export const selectBotInfo = (state: RootState) => state.app.botState.botInfo;
export const selectBotVariables = (state: RootState) => state.app.botState.botVariables;
export const selectBotVariableUsage = (state: RootState) => state.app.botState.botVariableUsage;
export const selectBotMenuCommands = (state: RootState) => state.app.botState.botMenuCommands;
export const selectBotUtmTags = (state: RootState) => state.app.botState.botUtmTags;
export const selectIsLoading = (state: RootState) => state.app.botState.loading;

export default botSlice.reducer;
