import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  clearBroadcastListState,
  copyBroadcast,
  deleteBroadcast,
  getBroadcastList,
  selectBroadcastListState,
} from "./BroadcastListSlice";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { selectCurrentBot, selectIsCurrentBotLoading } from "../sidebar/SidebarSlice";
import { useTranslation } from "react-i18next";
import { BroadcastList } from "./components/BroadcastList/BroadcastList";
import { CancelBroadcastPopup } from "../broadcast/components/CancelBroadcastPopup/CancelBroadcastPopup";
import { DeleteBroadcastPopup } from "../broadcast/components/DeleteBroadcastPopup/DeleteBroadcastPopup";
import { ReactComponent as EmptyBotImage } from "../../assets/images/botImageHappy.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plusIconTile.svg";
import useGA from "../../common/ga/GAEventTracker";
import s from "./BroadcastList.module.scss";
import sc from "./BroadcastContainer.module.scss";
import { useGetLoadingState } from "../../common/loading/hooks/useGetLoadingState";
import * as vars from "../layout/LayoutHelper/ResolutionConst";
import { PaginatorWithSearchParams } from "../../common/paginator/components/PaginatorWithSearchParams";
import BaseEmptyPage from "../../common/baseEmptyPage/BaseEmptyPage";
import { EventCategories } from "../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { BroadcastEvents } from "../../common/ga/gaEventsEnums.ts/BroadcastEventsEnums";

export const BroadcastListContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcastList" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const trackEvent = useGA(EventCategories.Broadcast);

  const currentBot = useAppSelector(selectCurrentBot);
  const isCurrentBotLoading = useAppSelector(selectIsCurrentBotLoading);
  const { broadcastList } = useAppSelector(selectBroadcastListState);

  const loadingBroadcastList = useGetLoadingState("getBroadcastList");

  const [pageParams] = useSearchParams();
  const [deletePopupState, setDeletePopupState] = useState<{
    id: string;
    itemName: string;
  } | null>(null);
  const [cancelPopupState, setCancelPopupState] = useState<{
    id: string;
    itemName: string;
  } | null>(null);

  const currentPage = Number(pageParams.get("page") ?? 1);
  const search = pageParams.get("search") ?? "";

  useEffect(() => {
    if (currentBot) {
      dispatch(getBroadcastList({ botId: currentBot.id, page: currentPage, filter: search }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBot, pageParams]);

  useEffect(() => {
    return () => {
      dispatch(clearBroadcastListState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBroadcastDeleteClick = (id: string, itemName: string) => {
    setDeletePopupState({ id, itemName });
  };
  const onBroadcastCancelClick = (id: string, itemName: string) => {
    setCancelPopupState({ id, itemName });
  };
  const onDuplicateClick = (id: string) => {
    dispatch(copyBroadcast({ broadcastId: id }));
  };

  const deleteBroadcastDispatch = (broadcastId: string) => {
    dispatch(deleteBroadcast({ broadcastId }));
  };

  const onDeletePopupClose = () => setDeletePopupState(null);
  const onCancelPopupClose = () => setCancelPopupState(null);

  const createBroadcastClick = () => {
    trackEvent(BroadcastEvents.BroadcastCreate);
    navigate("new");
  };

  if (!currentBot && !isCurrentBotLoading) {
    return (
      <Box className={s.broadcastListEmptyPageContainer}>
        <BaseEmptyPage
          botTitle={t("No Broadcasts")}
          botDescription={t("To get started, create or connect an existing bot to Domino")}
          title={t("Create your first broadcast")}
          description={t(
            "Engage your contacts by sending your broadcasts immediately or scheduling them for a specific date and time",
          )}
          Image={EmptyBotImage}
        >
          <Button variant="dominoViolet" onClick={createBroadcastClick} data-pw="create-button">
            <Flex gap={"8px"} align={"center"}>
              <Flex align="center" justify="center" className={s.plusIcon}>
                <PlusIcon />
              </Flex>
              {t("Create a broadcast")}
            </Flex>
          </Button>
        </BaseEmptyPage>
      </Box>
    );
  }

  if (!broadcastList) {
    return <Box h={"100vh"}></Box>;
  }

  if (broadcastList?.items.length === 0) {
    if (search !== "") {
      return (
        <>
          <Box className={s.noDataFound}>
            <Box className={`${s.broadcastListItemGrid} ${s.broadcastListFilter}`}>
              <Text noOfLines={1}>{t("Name")}</Text>
              <Text noOfLines={2}>{t("Recipients count")}</Text>
              <Text mr="36px" noOfLines={1}>
                {t("Status")}
              </Text>
              <Text mr="36px" noOfLines={1}>
                {t("Scheduled")}
              </Text>
              <div />
            </Box>
            <Box p={"20px 24px"} bg={"white"} borderRadius={"8px"} textAlign={"center"}>
              {ct("No results found")}
            </Box>
          </Box>
        </>
      );
    } else if (!loadingBroadcastList) {
      return (
        <Box className={s.broadcastListEmptyPageContainer}>
          <BaseEmptyPage
            botTitle={t("No Broadcasts")}
            botDescription={t("To get started, create or connect an existing bot to Domino")}
            title={t("Create your first broadcast")}
            description={t(
              "Engage your contacts by sending your broadcasts immediately or scheduling them for a specific date and time",
            )}
            Image={EmptyBotImage}
          >
            <Button variant="dominoViolet" onClick={createBroadcastClick} data-pw="create-button">
              <Flex gap={"8px"} align={"center"}>
                <Flex align="center" justify="center" className={s.plusIcon}>
                  <PlusIcon />
                </Flex>
                {t("Create a broadcast")}
              </Flex>
            </Button>
          </BaseEmptyPage>
        </Box>
      );
    }
  }

  return (
    <Box className={sc.broadcastListContainer}>
      <Box className={sc.broadcastListContent}>
        <BroadcastList
          broadcastList={broadcastList}
          currentPage={currentPage}
          onDuplicateClick={onDuplicateClick}
          onBroadcastDeleteClick={onBroadcastDeleteClick}
          onBroadcastCancelClick={onBroadcastCancelClick}
          search={search}
        />
        {window.innerWidth >= vars.TABLET_VIEW ? (
          <PaginatorWithSearchParams sizeItems={10} data={broadcastList} typeOfPage={"BroadcastList"} />
        ) : (
          <PaginatorWithSearchParams sizeItems={10} data={broadcastList} typeOfPage={"BroadcastList"} paddingLeft="2px" />
        )}
        <DeleteBroadcastPopup
          deleteBroadcastState={deletePopupState}
          onDelete={deleteBroadcastDispatch}
          onClose={onDeletePopupClose}
        />
        <CancelBroadcastPopup cancelBroadcastState={cancelPopupState} onClose={onCancelPopupClose} />
      </Box>
    </Box>
  );
};
