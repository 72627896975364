import React, { useState } from "react";
import { Box, Link, Text, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AIAssistantModel } from "../AIAssistantListModel";
import DotMenuContainer from "../../../UI/atoms/DotMenu/DotMenuContainer";
import { useAppDispatch, useAppSelector } from "../../../common/state/store";
import { deleteAIAssistant, getAIAssistant, selectAIAssistantList } from "../AIAssistantListSlice";
import { DeleteModal } from "../../../UI/molecules/deleteModal/DeleteModal";
import EditIcon from "../../../assets/icons/editIcon.svg?react";
import RedTrashIcon from "../../../assets/icons/trash.svg?react";
import { AppSettings } from "../../../common/AppSettings";
import s from "../AIAssistantListContainer.module.scss";

declare const appSettings: AppSettings;

interface Props {
  onNewAIAssistant: () => void;
  onEditAIAssistantItem: () => void;
}

export const AIAssistantList = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "AI-assistant" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const dispatch = useAppDispatch();
  const deleteModal = useDisclosure();
  const confirmDeleteModal = useDisclosure();
  const aiAssistantList = useAppSelector(selectAIAssistantList);
  const [aiAssistant, setAIAssistant] = useState<AIAssistantModel>();
  const hasAIAssistantInFlow = true; // тут будет проверка на использование во флоу

  const onEditAIAssistantItem = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.TouchEvent<HTMLButtonElement> | React.DragEvent<HTMLButtonElement>,
    aiAssistant: AIAssistantModel,
  ) => {
    e.stopPropagation();
    dispatch(getAIAssistant(aiAssistant?.id));
    props.onEditAIAssistantItem();
  };

  const onOpenDeleteModal = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.TouchEvent<HTMLButtonElement> | React.DragEvent<HTMLButtonElement>,
    aiAssistant: AIAssistantModel,
  ) => {
    e.stopPropagation();
    setAIAssistant(aiAssistant);
    deleteModal.onOpen();
  };

  const onDeleteAIAssistantItem = () => {
    if (hasAIAssistantInFlow) {
      confirmDeleteModal.onOpen();
    } else {
      if (aiAssistant) {
        dispatch(deleteAIAssistant(aiAssistant.id));
      }
    }
  };

  const deleteModalText = t("Are you sure you want to delete the assistant", {
    aiAssistantName: aiAssistant?.name,
  });

  const confirmModalText = t("The assistant cannot be deleted as it is assigned to the following scenarios:", {
    aiAssistantName: aiAssistant?.name,
  });

  const additionalModalText = <Text>{t("Please remove the assistant from the scenario(s) and try again")}</Text>;

  return (
    <div className={s.aiAssistantListContainerWrapper}>
      {aiAssistantList?.map(aiAssistant => (
        <Box key={aiAssistant.id} className={s.aiAssistantItem}>
          <Text variant="medium" mobile-title={t("Title")} className={s.aiAssistantItemText}>
            <span className={s.aiAssistantItemTextSpan}>{aiAssistant.name}</span>
          </Text>
          <Text variant="medium" mobile-title={t("Description")} className={s.aiAssistantItemText}>
            <span className={s.aiAssistantItemTextSpan}>{aiAssistant.description}</span>
          </Text>
          <Text variant="medium" mobile-title={t("Answers File")} className={`${s.aiAssistantItemText} ${s.fileName}`}>
            <span className={s.aiAssistantItemTextSpan}>
              <Link href={`${appSettings.apiBaseUrl}/DataFiles/file/${aiAssistant.fileId}`} isExternal>
                {aiAssistant.fileName}
              </Link>
            </span>
          </Text>
          <Box className={s.aiAssistantMenu}>
            <DotMenuContainer
              menuItems={[
                {
                  MenuItem: {
                    icon: <EditIcon className={s.menuIcon} />,
                    text: ct("Edit"),
                    clickEvent: e => onEditAIAssistantItem(e, aiAssistant),
                  },
                },

                {
                  MenuItem: {
                    icon: <RedTrashIcon className={s.menuIcon} />,
                    text: ct("Delete"),
                    clickEvent: e => onOpenDeleteModal(e, aiAssistant),
                    color: "mainRed",
                  },
                },
              ]}
            />
          </Box>
          <DeleteModal
            title={t("Delete the support assistant")}
            itemTitle={deleteModalText}
            isOpen={deleteModal.isOpen}
            onClose={deleteModal.onClose}
            onDelete={onDeleteAIAssistantItem}
          />
          <DeleteModal
            title={t("Delete the support assistant")}
            itemTitle={confirmModalText}
            isOpen={confirmDeleteModal.isOpen}
            onClose={confirmDeleteModal.onClose}
            isDisabled={hasAIAssistantInFlow}
          >
            {additionalModalText}
          </DeleteModal>
        </Box>
      ))}
    </div>
  );
};
