import React from "react";
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";

import dayjs from "dayjs";
import ru from "date-fns/locale/ru";
import { Icon, Input, InputGroup } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { LanguageCodeEnum } from "../../../common/user/UserModel";
registerLocale("ru", ru);

export const checkThisDay = (date: Date | null) => {
  return dayjs().isSame(dayjs(date), "day");
};

export const defineLanguage = (language: LanguageCodeEnum | string, dateFormat: string | string[] | undefined) => {
  const days = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
  const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];
  const localeRu: Locale = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    localize: {
      day: n => days[n],
      month: n => months[n],
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore

    formatLong: {
      date: () => dateFormat ?? "MMMM dd yyyy",
      time: () => "HH:mm",
      dateTime: () => `${dateFormat} HH:mm`,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    match: {
      month: n => months[n],
      day: n => days[n],
    },
    code: "ru",
  };

  return language === "ru" ? localeRu : language;
};

export const getMinTime = (date: Date | null) => (checkThisDay(date) ? new Date() : dayjs().startOf("day").toDate());

export const customDateInput = (props: { [key: string]: unknown }, ref?: React.LegacyRef<HTMLInputElement>) => {
  return (
    <Input
      ref={ref}
      {...props}
      onFocus={(e: { target: { placeholder: string } }) => {
        if (props.placeholdertext) {
          e.target.placeholder = props.placeholdertext as string;
        }
      }}
      onBlur={(e: { target: { placeholder: string } }) => {
        if (props.placeholdertext) {
          e.target.placeholder = `${t("contact.Not set")}`;
        }
      }}
      variant="outline"
      bg="white"
      data-pw="datepicker"
    />
  );
};

export const customDateInputIcon = (
  props: {
    [key: string]: unknown;
    icon?: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;
    colorIcon?: string;
    disableIcon?: boolean;
  },
  ref?: React.LegacyRef<HTMLInputElement>,
) => {
  return (
    <InputGroup alignItems="center">
      <Input
        zIndex={1}
        ref={ref}
        {...props}
        onFocus={(e: { target: { placeholder: string } }) => {
          if (props.placeholdertext) {
            e.target.placeholder = props.placeholdertext as string;
          }
        }}
        onBlur={(e: { target: { placeholder: string } }) => {
          if (props.placeholdertext) {
            e.target.placeholder = `${t("contact.Not set")}`;
          }
        }}
        variant="outline"
        bg="transparent"
        data-pw="datepicker"
      />
      <Icon
        onClick={e => e.preventDefault()}
        right={"8px"}
        position={"absolute"}
        ml={"8px"}
        as={props.icon}
        boxSize="20px"
        color={props.disableIcon ? "#232B39" : props.colorIcon}
        transition="ease-out .2s"
      />
    </InputGroup>
  );
};

export const customDateRangeInput = (props: { [key: string]: unknown }, ref?: React.LegacyRef<HTMLInputElement>) => {
  return (
    <Input
      data-pw="datepicker"
      variant={"dateRange"}
      zIndex={1}
      ref={ref}
      {...props}
      onFocus={(e: { target: { placeholder: string } }) => {
        if (props.placeholdertext) {
          e.target.placeholder = props.placeholdertext as string;
        }
      }}
      onBlur={(e: { target: { placeholder: string } }) => {
        if (props.placeholdertext) {
          e.target.placeholder = `${t("contact.Not set")}`;
        }
      }}
    />
  );
};

export function DatePicker(props: ReactDatePickerProps) {
  const { isClearable = false, showPopperArrow = false, dateFormat, ...rest } = props;
  const locale = dayjs.locale();
  const { t } = useTranslation("translation", { keyPrefix: "commonWords" });

  return (
    <ReactDatePicker
      timeCaption={t("Time") ?? ""}
      isClearable={isClearable}
      showPopperArrow={showPopperArrow}
      locale={defineLanguage(locale, dateFormat)}
      dateFormat={dateFormat}
      {...rest}
      calendarStartDay={1}
    />
  );
}
