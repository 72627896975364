import s from "./StartFlowNode.module.scss";
import { ActionNodeProps } from "../ActionNodeProps";
import { StartFlowActionModel } from "../../../FlowBuilderModel";
import { useTranslation } from "react-i18next";
import { Text, Flex, Icon } from "@chakra-ui/react";
import StartFlowIcon from "../../../../../assets/icons/startFlowIcon.svg?react";

export const StartFlowNode = (props: { actionData: ActionNodeProps }) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const data = props.actionData.node.flowAction as StartFlowActionModel;

  return (
    <Flex flexDirection="column" alignItems="center">
      <div className={s.header}>
        <Icon as={StartFlowIcon} color="midDeepBlue" boxSize="30px" />
        <div className={s.textBlock}>
          <div className={s.type}>{t("Start Flow")}</div>
        </div>
      </div>
      {data && data.title ? (
        <Flex flexWrap="wrap" className={s.wrapper}>
          <Flex w="100%">
            <Text maxW="43%" color="darkGrey">
              {t("Flow Selected")}:&nbsp;
            </Text>
            <Text maxW="60%" color="midDeepBlue !important" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {data.title}
            </Text>
          </Flex>
        </Flex>
      ) : (
        <div className={s.noCondition}>
          <h4>{t("Click to select a Flow")}</h4>
        </div>
      )}
    </Flex>
  );
};
