import { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  Switch,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MenuCommandModel } from "../../../../BotModel";
import { useAppDispatch } from "../../../../../../common/state/store";
import { editBotMenuCommand } from "../../../../BotSlice";
import { ReactComponent as CommandTriggerIcon } from "../../../../../../assets/icons/commandTriggerIcon.svg";
import { ReactComponent as CrossIcon } from "../../../../../../assets/icons/cross.svg";
import { validateCommandDescription, validateCommandName } from "../../../../../../common/validation/botCommandsValidation";
import { t } from "i18next";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  commandItem: MenuCommandModel;
  botId: string;
}

export default function EditCommandModal({ commandItem, botId, onClose, isOpen }: Props) {
  const tt = useTranslation("translation", { keyPrefix: "bot" }).t;
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const [editItem, setEditItem] = useState<MenuCommandModel>({ ...commandItem });
  const [commandNameValidationError, setCommandNameValidationError] = useState("");
  const [commandDescriptionValidationError, setCommandDescriptionValidationError] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen) {
      setEditItem({ ...commandItem });
    }
  }, [isOpen, commandItem]);

  const handleClose = () => {
    setCommandNameValidationError("");
    setCommandDescriptionValidationError("");
    onClose();
  };

  const handleInputNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditItem({ ...editItem, command: e.target.value });
  };

  const handleInputDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditItem({ ...editItem, description: e.target.value });
  };

  const handleInputNameBlur = () => {
    const error = validateCommandName(editItem.command, t);
    setCommandNameValidationError(error || "");
  };

  const handleInputDescriptionBlur = () => {
    const error = validateCommandDescription(editItem.description, t);
    setCommandDescriptionValidationError(error || "");
  };

  const onSaveClick = () => {
    handleInputNameBlur();
    handleInputDescriptionBlur();

    if (!commandNameValidationError && !commandDescriptionValidationError) {
      dispatch(editBotMenuCommand({ botId, data: editItem }));
      handleClose();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent p="24px" gap="24px" borderRadius="12px" mx="16px" color="#232B39">
          <ModalHeader p="0" fontSize="20px" lineHeight="28px">
            {tt("Edit Command")}
          </ModalHeader>
          <ModalCloseButton borderRadius={"50%"} color={"darkGrey"} _hover={{ bg: "defaultGrey", color: "mainPurple" }}>
            <CrossIcon />
          </ModalCloseButton>
          <ModalBody p="0" gap="16px" display="flex" flexDir="column">
            <Flex flexDir="column" gap="8px">
              <Text fontSize="15px" lineHeight="20px">
                {tt("Name")}
              </Text>
              <InputGroup>
                <InputLeftElement>
                  <CommandTriggerIcon width="20px" height="20px" color="#4EAA4A" />
                </InputLeftElement>
                <Input
                  placeholder={tt("Command")}
                  value={editItem.command}
                  onBlur={handleInputNameBlur}
                  isInvalid={!!commandNameValidationError}
                  onFocus={() => {
                    setCommandNameValidationError("");
                  }}
                  onChange={handleInputNameChange}
                />
              </InputGroup>
              {!!commandNameValidationError && (
                <Text color="red" fontSize="12px">
                  {commandNameValidationError}
                </Text>
              )}
            </Flex>
            <Flex flexDir="column" gap="8px">
              <Text fontSize="15px" lineHeight="20px">
                {tt("Description")}
              </Text>
              <Input
                placeholder={tt("Description")}
                value={editItem.description}
                onBlur={handleInputDescriptionBlur}
                isInvalid={!!commandDescriptionValidationError}
                onFocus={() => {
                  setCommandDescriptionValidationError("");
                }}
                onChange={handleInputDescriptionChange}
              />
              {!!commandDescriptionValidationError && (
                <Text color="red" fontSize="12px">
                  {commandDescriptionValidationError}
                </Text>
              )}
            </Flex>
            <Flex justify="space-between" h="36px" align="center">
              <Text fontSize="15px" lineHeight="20px">
                {tt("Active")}
              </Text>
              <Switch
                isChecked={editItem.isActive}
                justifySelf="center"
                variant="dominoDefaultGreen"
                onChange={() => setEditItem({ ...editItem, isActive: !editItem.isActive })}
              />
            </Flex>
          </ModalBody>
          <ModalFooter justifyContent="space-between" p="0">
            <Button variant="dominoOutlineViolet" onClick={onClose}>
              {ct("Cancel")}
            </Button>
            <Button variant="dominoViolet" onClick={onSaveClick}>
              {ct("Done")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
