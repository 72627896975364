import { ReactComponent as QuestionIcon } from "../../../../../assets/icons/questionIcon.svg";
import {
  Text,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Skeleton,
  Tooltip,
  Icon,
  Box,
  Button,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomVariableScope } from "../../../../../common/AppEnums";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { addTranslationPrefix } from "../../../../../common/utils/convertPascalCaseToText";
import {
  ComplexFilterGroupTypes,
  ComplexFilterFieldTypes,
  FilterParams,
  FilterParamTypes,
  FieldParamModel,
  ConditionsByField,
} from "../../../../complexFilter/ComplexFilterModel";
import { ConditionBox } from "../../../../complexFilter/components/ConditionBox";
import {
  ConditionFlowActionModel,
  ConditionModel,
  CustomVariablesModel,
  FlowActionModel,
  FlowModel,
  HandlePositiveTypes,
  NodeModel,
  NodeValidationProp,
} from "../../../FlowBuilderModel";
import { saveNode, selectCustomVariables } from "../../../FlowBuilderSlice";
import { NodeEditorFooter } from "../NodeEditorFooter/NodeEditorFooter";
import s from "./ConditionNodePopup.module.scss";

interface Props {
  flow: FlowModel;
  node: NodeModel;
  deleteEdge: (id: string) => void;
  onDataChange: (flowAction: FlowActionModel) => void;
  validate: (flowAction: FlowActionModel) => void;
  validationInfo: NodeValidationProp;
}

export const ConditionNodePopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const cft = useTranslation("translation", {
    keyPrefix: "complexFilter.conditionsByType",
  }).t;
  const variables = useAppSelector(selectCustomVariables);
  const flowAction = props.node.flowAction as ConditionFlowActionModel;
  const filterParams = flowAction.conditions?.map(el => mapConditionModelToFilter(el, variables) ?? {});
  const [conditions, setConditions] = useState<FilterParams[]>(filterParams);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const dispatch = useAppDispatch();

  const onCloseValidate = () => {
    props.validate(flowAction);
    dispatch(saveNode(props));
  };

  // We should wait till onOpen animation of drawer will end because positioning of inside menu works incorrect during animation
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDrawerOpen(true);
    }, 400);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const conditionParams = conditions.map(el => mapFilterToConditionModel(el, variables));
    const newData: FlowActionModel = {
      ...props.node.flowAction,
      conditions: conditionParams,
    } as ConditionFlowActionModel;

    props.onDataChange(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions]);

  const onSetFilterParam = (filterParamType: FilterParamTypes, fieldParamValue: FieldParamModel, id: string) => {
    setConditions(prevState =>
      prevState.map(el => {
        if (el.id === id || !el.id) {
          if (filterParamType === FilterParamTypes.field && el.field?.type !== fieldParamValue.type) {
            return {
              id,
              [filterParamType]: fieldParamValue,
            };
          }
          if (
            filterParamType === FilterParamTypes.field &&
            el.field?.type === fieldParamValue.type &&
            fieldParamValue.groupType === "CustomVariables"
          ) {
            const newEl = { ...el, [filterParamType]: fieldParamValue, id: el.id };
            delete newEl.conditionValue;
            return newEl;
          }
          return {
            ...el,
            [filterParamType]: fieldParamValue,
          };
        }
        return el;
      }),
    );
  };

  function createFieldsToFilter() {
    const groupList = [];
    if (variables?.length) {
      const systemVariablesList = variables
        ?.filter(el => el.scope === CustomVariableScope.System)
        .map(el => {
          return {
            fieldName: el.key,
            fieldValue: el.key,
            id: el.id,
            type: ComplexFilterFieldTypes[el.type],
            groupType: ComplexFilterGroupTypes.CustomVariables,
            description: el.description,
          };
        });

      const contactvariableList = variables
        ?.filter(el => el.scope === CustomVariableScope.Contact)
        .map(el => {
          return {
            fieldName: el.key,
            fieldValue: el.key,
            id: el.id,
            type: ComplexFilterFieldTypes[el.type],
            groupType: ComplexFilterGroupTypes.CustomVariables,
            description: el.description,
          };
        });

      const botvariableList = variables
        ?.filter(el => el.scope === CustomVariableScope.Bot)
        .map(el => {
          return {
            fieldName: el.key,
            fieldValue: el.key,
            id: el.id,
            type: ComplexFilterFieldTypes[el.type],
            groupType: ComplexFilterGroupTypes.CustomVariables,
            description: el.description,
          };
        });
      const flowVariableList = variables
        ?.filter(el => el.scope === CustomVariableScope.Flow)
        .map(el => {
          return {
            fieldName: el.key,
            fieldValue: el.key,
            id: el.id,
            type: ComplexFilterFieldTypes[el.type],
            groupType: ComplexFilterGroupTypes.CustomVariables,
            description: el.description,
          };
        });
      groupList.push({
        label: t("editNodePopup.System Fields"),
        items: systemVariablesList,
      });

      groupList.push({
        label: t("editNodePopup.Custom Contact Fields"),
        items: contactvariableList,
      });
      groupList.push({ label: t("editNodePopup.Custom Bot Fields"), items: botvariableList });
      groupList.push({ label: t("editNodePopup.Flow Fields"), items: flowVariableList });
    }

    return { groups: groupList };
  }

  function mapConditionModelToFilter(
    conditionModel: ConditionModel,
    customVariables: CustomVariablesModel[] | undefined,
  ): FilterParams | undefined {
    const field = customVariables?.find(el => {
      return el.id === conditionModel.conditionCustomVariableId;
    });
    const valueVariable = customVariables?.find(el => el.id === conditionModel.valueCustomVariableId);
    if (field) {
      return {
        id: conditionModel.id,
        field: {
          id: field.id,
          title: field.key,
          value: field.key,
          type: ComplexFilterFieldTypes[field.type],
        },
        condition: conditionModel.condition
          ? {
              id: field.id,
              title: cft(addTranslationPrefix(conditionModel.condition, ComplexFilterFieldTypes[field.type])),
              value: conditionModel.condition,
              type: ComplexFilterFieldTypes[field.type],
            }
          : undefined,
        conditionValue: conditionModel.value
          ? {
              id: field.id,
              title: conditionModel.value,
              value: conditionModel.value,
              variableValue: "",
              type: ComplexFilterFieldTypes[field.type],
            }
          : valueVariable?.key
          ? {
              id: field.id,
              title: valueVariable.key,
              value: "",
              variableValue: valueVariable.key,
              type: ComplexFilterFieldTypes[field.type],
            }
          : undefined,
      };
    }
  }

  function mapFilterToConditionModel(filter: FilterParams, customVariables: CustomVariablesModel[] | undefined) {
    const conditionCusVarId = customVariables?.find(el => el.id === filter?.field?.id)?.id;
    const valueCustomVariableId = customVariables?.find(el => el.id === filter.conditionValue?.variableValue)?.id;
    const isValueAllow = !ConditionsByField.noValueConditions.includes(filter.condition?.value ?? "");
    const id = filter.id;
    if (conditionCusVarId) {
      return {
        id,
        conditionCustomVariableId: conditionCusVarId,
        condition: filter.condition?.value,
        value: isValueAllow ? filter?.conditionValue?.value : undefined,
        valueCustomVariableId: isValueAllow ? valueCustomVariableId : undefined,
      };
    }
    return {};
  }

  const addCondition = () => {
    setConditions([...conditions, {}]);
  };

  const deleteConditionEdge = (conditionHandle: string) => {
    const edgeToDelete = props.flow.edges.find(el => el.sourceHandle === conditionHandle);
    if (edgeToDelete) {
      props.deleteEdge(edgeToDelete?.id);
    }
  };

  const onDeleteConditionBlock = (paramsToDelete: FilterParams) => {
    const conditions = filterParams.filter(el => JSON.stringify(el) !== JSON.stringify(paramsToDelete));
    const conditionHandle = `${paramsToDelete.id}${HandlePositiveTypes.true}`;
    deleteConditionEdge(conditionHandle);
    setConditions(conditions);
  };

  return (
    <>
      <Drawer isOpen={true} placement="right" onClose={onCloseValidate} variant="dominoDrawer">
        <DrawerContent>
          <DrawerHeader>{t("Condition")}</DrawerHeader>
          <DrawerBody>
            <div className={s.wrapper}>
              <Flex alignItems="center" justifyContent="space-between" pr="10px">
                <Text color="grey">{t("editNodePopup.Only if following conditions match")}</Text>
                <Tooltip
                  variant="dominoLight"
                  placement="left"
                  label={t("editNodePopup.If more than one condition is added, they are executed sequentially.")}
                >
                  <Icon
                    as={QuestionIcon}
                    ml={"4px"}
                    boxSize={"15px"}
                    borderRadius={"50%"}
                    fill="darkGrey"
                    _hover={{ fill: "darkPurple" }}
                  />
                </Tooltip>
              </Flex>
              {!isDrawerOpen && (
                <Box maxH="65vh" pb="10px" overflow="scroll">
                  {conditions.map((_, index) => {
                    return (
                      <Flex key={index} width="100%" pt="20px" pr="10px">
                        <Skeleton
                          speed={0.5}
                          startColor="line"
                          endColor="bgLight"
                          borderRadius="10px"
                          height="46px"
                          width="100%"
                          border="1px solid"
                          borderColor="line"
                        />
                      </Flex>
                    );
                  })}
                </Box>
              )}
              {isDrawerOpen && (
                <ConditionBox
                  wrapperStyle={{ maxHeight: "65vh", paddingBottom: "10px" }}
                  filterFields={createFieldsToFilter()}
                  allowedTypes={[
                    ComplexFilterFieldTypes.Text,
                    ComplexFilterFieldTypes.Number,
                    ComplexFilterFieldTypes.Date,
                    ComplexFilterFieldTypes.DateTime,
                    ComplexFilterFieldTypes.Array,
                  ]}
                  filterParams={conditions}
                  onDeleteConditionBlock={onDeleteConditionBlock}
                  onSetFilterParam={onSetFilterParam}
                  menuStyle={"conditionNode"}
                  addCondition={addCondition}
                />
              )}
            </div>
          </DrawerBody>
          <DrawerFooter margin={"8px"} padding={"0"}>
            {props.validationInfo.isError && <NodeEditorFooter validationMessage={props.validationInfo.errors[0].message} />}
          </DrawerFooter>
          <Button variant="dominoViolet" w={"auto"} margin={"16px"} onClick={() => onCloseValidate()} data-pw="apply-button">
            {t("Apply")}
          </Button>
        </DrawerContent>
      </Drawer>
    </>
  );
};
