import { Avatar, Box, Button, Flex, Icon, useBoolean } from "@chakra-ui/react";
import { ConversationNoteModel } from "../../../ConversationModel";
import { TooltipWithTouch } from "../../../../../common/tooltipWithTouch/TooltipWithTouch";
import { FormattedText } from "../../../../../common/formattedText/formattedText";
import TrashIcon from "../../../../../assets/icons/trash.svg?react";
import { getNoteTextParts } from "./utils";
import { MessageDeleteModal } from "../MessageDeleteModal/MessageDeleteModal";
import { useState } from "react";
import { noteMessageDeleteModal } from "./handlers";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { selectConversationInfo } from "../../../ConversationSlice";
import s from "../../../Conversation.module.scss";

interface Props {
  message: ConversationNoteModel;
  featureFlag: "LIVE_CHAT" | "GROUP_CHAT"; // TODO: Remove this field after add delete-support for group chats
}

export const NoteMessageItem = (props: Props) => {
  const dispatch = useAppDispatch();
  const [messageHover, setMessageHover] = useBoolean(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const conversationInfo = useAppSelector(selectConversationInfo);

  const timeString = new Date(props?.message?.date).toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    hourCycle: "h23",
  });

  const deleteModalActions = noteMessageDeleteModal({ conversationInfo, dispatch, messageId: props.message.id, setIsModalOpen });

  return (
    <>
      <Box className={s.messageItemWrapperNote} onMouseLeave={setMessageHover.off}>
        <TooltipWithTouch label={props.message.authorName} bg="newYellow" variant={"dominoLight"} placement="top">
          <Avatar bg="midDeepBlue" color="white" size="sm" name={props.message.authorName} />
        </TooltipWithTouch>
        <Flex direction="column" maxW="80%">
          <Box className={s.message} onMouseEnter={setMessageHover.on}>
            <Box className={s.messageTextWrapper}>
              <Box className={s.messageTextContent}>
                {props.message.taggedOperatorsNames?.length ? (
                  getNoteTextParts({ text: props.message.text, taggedOperatorsNames: props.message.taggedOperatorsNames }).map(
                    part => {
                      if (part.isMention)
                        return (
                          <span key={part.text} className={s.mention}>
                            @{part.text}
                          </span>
                        );
                      else return <FormattedText key={part.text} text={part.text} entities={props.message.entities || []} />;
                    },
                  )
                ) : (
                  <FormattedText text={props.message.text} entities={props.message.entities || []} />
                )}
              </Box>
              <Box className={s.date}>{timeString}</Box>
            </Box>
          </Box>
        </Flex>
        {props.featureFlag === "LIVE_CHAT" && messageHover && (
          <Button variant="dominoGhost" onClick={deleteModalActions.onOpen} className={s.deleteButton}>
            <Icon as={TrashIcon} boxSize="20px" className={s.deleteButton__icon} />
          </Button>
        )}
      </Box>

      <MessageDeleteModal isOpen={isModalOpen} onConfirm={deleteModalActions.onConfirm} onClose={deleteModalActions.onClose} />
    </>
  );
};
