import { ReactComponent as VariablesIcon } from "../../../assets/icons/variablesIcon.svg";
import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  Tooltip,
  MenuGroup,
  Icon,
  Button,
  MenuButtonProps,
  useDisclosure,
  Flex,
  Box,
} from "@chakra-ui/react";
import { CustomVariablesModel } from "../../flowBuilder/FlowBuilderModel";
import { CustomVariableModel, CustomVariableScope, CustomVariableType } from "../../../common/AppEnums";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as arrayTypeIcon } from "../../../assets/icons/variableTypes/arrayTypeIcon.svg";
import { ReactComponent as textTypeIcon } from "../../../assets/icons/variableTypes/textTypeIcon.svg";
import { ReactComponent as dateTypeIcon } from "../../../assets/icons/variableTypes/dateTypeIcon.svg";
import { ReactComponent as numberTypeIcon } from "../../../assets/icons/variableTypes/numberTypeIcon.svg";
import { ReactComponent as booleanTypeIcon } from "../../../assets/icons/variableTypes/booleanTypeIcon.svg";
import { ReactComponent as catalogTypeIcon } from "../../../assets/icons/variableTypes/catalogTypeIcon.svg";
import s from "./CustomVariableContextMenu.module.scss";
import { CustomVariableContextMenuType } from "./CustomVariableContextMenuModel";
import { AddVariablePopover } from "../addVariable/addVariablePopover/AddVariablePopover";
import { useAppSelector } from "../../../common/state/store";
import { selectFlow } from "../../flowBuilder/FlowBuilderSlice";
import { useNowHeightView } from "../../layout/LayoutHelper/ResolutionHooks";
import { InputSearch } from "../../../UI/molecules/inputSearch/InputSearch";
import { CustomMenuItem } from "../../../UI/molecules/customMenuItem/CustomMenuItem";

interface Props {
  selectCustomVariable: (variable: CustomVariablesModel) => void;
  clearVariable?: () => void;
  variables: CustomVariableModel[];
  chosenVariable?: CustomVariableModel;
  type?: CustomVariableContextMenuType;
  isDisabled?: boolean;
  addVariable?: (newVariable?: CustomVariableModel) => void;
  onOpenMenu?: () => void;
  mt?: string;
  placeholder?: string;
  buttonProps?: MenuButtonProps;
  isInvalid?: boolean;
  scope?: CustomVariableScope.Flow | CustomVariableScope.Contact | CustomVariableScope.Bot;
  flip?: boolean | undefined;
  preventOverflow?: boolean | undefined;
  onlyVariableType?: CustomVariableType;
  excludeVariableType?: CustomVariableType;
  isPaymentNode?: boolean;
  disableBtn?: boolean;
}

export const CustomVariableContextMenu = ({
  selectCustomVariable,
  clearVariable,
  variables,
  chosenVariable,
  type = CustomVariableContextMenuType.Default,
  isDisabled,
  addVariable,
  onOpenMenu,
  mt,
  placeholder,
  buttonProps,
  isInvalid,
  scope,
  flip = true,
  preventOverflow = true,
  onlyVariableType,
  excludeVariableType,
  isPaymentNode = false,
  disableBtn = false,
}: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "flow.editNodePopup",
  });
  const [searchedText, setSearchText] = useState("");
  const [vars, setVars] = useState<CustomVariablesModel[] | undefined>();
  const { isOpen: isOpenAddVariable, onOpen: onOpenAddVariable, onClose: onCloseAddVariable } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const flow = useAppSelector(selectFlow);

  const menuListPlacement =
    type === CustomVariableContextMenuType.Dropdown
      ? "bottom-start"
      : type === CustomVariableContextMenuType.NodeEditor
      ? "left"
      : "left-start";
  const systemFields = vars?.filter(el => el.scope === CustomVariableScope.System);
  const contactFields = vars?.filter(el => el.scope === CustomVariableScope.Contact);
  const botFields = vars?.filter(el => el.scope === CustomVariableScope.Bot);
  const flowFields = vars?.filter(el => el.scope === CustomVariableScope.Flow);

  const nowHeight = useNowHeightView();

  const onSearchTextChange = (value: string) => {
    setSearchText(value);
    const searchedVars = variables.filter(el => el.key.toLowerCase().includes(value.toLocaleLowerCase()));
    if (searchedVars.length) {
      setVars(searchedVars);
      return;
    }
    setVars(undefined);
  };

  useEffect(() => {
    if (excludeVariableType) {
      const filteredVars = vars && vars?.length > 0 ? vars.filter(v => v.type !== excludeVariableType) : undefined;
      setVars(filteredVars);
    }
  }, [excludeVariableType, vars]);

  const handleOpenMenu = () => {
    onOpenMenu?.();
    onOpen();
    setSearchText("");
    setVars(variables);
  };

  const handleCloseMenu = () => {
    onCloseAddVariable();
    onClose();
  };

  const onSelect = (variable: CustomVariablesModel) => {
    selectCustomVariable(variable);
  };

  const variableTypeIcons = {
    [CustomVariableType.Text]: textTypeIcon,
    [CustomVariableType.Array]: arrayTypeIcon,
    [CustomVariableType.Number]: numberTypeIcon,
    [CustomVariableType.Date]: dateTypeIcon,
    [CustomVariableType.DateTime]: dateTypeIcon,
    [CustomVariableType.Boolean]: booleanTypeIcon,
    [CustomVariableType.Order]: catalogTypeIcon,
  };

  const [translateY, setTranslateY] = useState("");
  const menuList = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (nowHeight > 0 && nowHeight <= 900) {
      setTranslateY(blockHeight());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nowHeight, isOpen]);

  const blockHeight = () => {
    if (menuList?.current && menuList.current.getBoundingClientRect().bottom >= nowHeight) {
      return `${menuList.current.getBoundingClientRect().bottom - nowHeight + 80}`;
    } else {
      return translateY;
    }
  };
  return (
    <>
      <Menu
        isOpen={isOpen}
        onOpen={handleOpenMenu}
        gutter={0}
        onClose={handleCloseMenu}
        variant="dominoGroupedMenu"
        preventOverflow={preventOverflow}
        flip={flip}
        autoSelect={false}
        placement={menuListPlacement}
        matchWidth={type === CustomVariableContextMenuType.Dropdown}
      >
        {type === CustomVariableContextMenuType.Dropdown ? (
          <Flex pos="relative">
            <MenuButton
              as={Button}
              variant="dominoDefaultMenuBtn"
              mt={`${mt ?? "24px"} !important`}
              _active={{ borderColor: "line", backgroundColor: "white" }}
              onClick={e => e.stopPropagation()}
              isDisabled={isDisabled}
              color={chosenVariable?.key ? "black" : "darkGrey"}
              {...(isInvalid ? { borderColor: "mainRed" } : {})}
              {...buttonProps}
              data-pw="source-select"
            >
              {chosenVariable?.key ?? `${placeholder ?? t("Select")}`}
            </MenuButton>
            {chosenVariable?.key && (
              <Box bottom="7px" right="5px" pos="absolute" data-pw="cross-icon">
                <CrossIcon
                  cursor="pointer"
                  onClick={() => {
                    if (clearVariable) {
                      clearVariable();
                    }
                  }}
                  className={s.crossIcon}
                />
              </Box>
            )}
          </Flex>
        ) : (
          <MenuButton
            as={IconButton}
            icon={<VariablesIcon />}
            aria-label="Options"
            variant="dominoTextareaIcon"
            boxSize={{ base: "32px", lg: "24px" }}
            mr="6px"
            data-pw="options-button"
            isDisabled={disableBtn}
          ></MenuButton>
        )}
        <MenuList
          ref={menuList}
          mt={`-${translateY}px !important`}
          className={`${s.addVariableModal}  
                ${type === CustomVariableContextMenuType.NodeEditor && s.nodeEditorView} 
                ${type === CustomVariableContextMenuType.Dropdown && s.dropdownView}`}
          zIndex={4}
        >
          <Box className={type === CustomVariableContextMenuType.Dropdown ? s.dropdownSearchBox : s.searchBox}>
            <InputSearch
              inputGroupStyles={{ w: "auto" }}
              placeholder={`${t("Search by variable name")}`}
              searchText={searchedText}
              setSearchText={onSearchTextChange}
              autoFocus
            />
          </Box>
          {vars?.length ? (
            <Box className={s.varList}>
              {type !== CustomVariableContextMenuType.Dropdown && !!systemFields?.length && (
                <MenuGroup title={t("System Fields") ?? "System Fields"}>
                  {systemFields.map(el => {
                    return (
                      <CustomMenuItem
                        data-pw={el.key}
                        key={el.id}
                        onClick={() => {
                          onSelect(el);
                          onClose();
                        }}
                      >
                        <Icon mr="10px" color="darkGrey" boxSize="14px" as={variableTypeIcons[el.type]} />
                        <Tooltip placement="top" label={el.description} fontSize="md">
                          {el.key}
                        </Tooltip>
                      </CustomMenuItem>
                    );
                  })}
                </MenuGroup>
              )}
              {!!contactFields?.length && (
                <MenuGroup title={t("Custom Contact Fields") ?? "Custom Contact Fields"}>
                  {contactFields.map(el => {
                    return (
                      <CustomMenuItem
                        data-pw={el.key}
                        key={el.id}
                        onClick={() => {
                          onSelect(el);
                          onClose();
                        }}
                      >
                        <Icon mr="10px" color="darkGrey" boxSize="14px" as={variableTypeIcons[el.type]} />
                        <Tooltip placement="top" label={el.description} fontSize="md">
                          {el.key}
                        </Tooltip>
                      </CustomMenuItem>
                    );
                  })}
                </MenuGroup>
              )}
              {!!botFields?.length && (
                <MenuGroup title={t("Custom Bot Fields") ?? "Custom Bot Fields"}>
                  {botFields.map(el => {
                    return (
                      <CustomMenuItem
                        data-pw={el.key}
                        key={el.id}
                        onClick={() => {
                          onSelect(el);
                          onClose();
                        }}
                      >
                        <Icon mr="10px" color="darkGrey" boxSize="14px" as={variableTypeIcons[el.type]} />
                        <Tooltip placement="top" label={el.description} fontSize="md">
                          {el.key}
                        </Tooltip>
                      </CustomMenuItem>
                    );
                  })}
                </MenuGroup>
              )}
              {!!flowFields?.length && (
                <MenuGroup title={t("Flow Fields")}>
                  {flowFields.map(el => {
                    return (
                      <CustomMenuItem
                        data-pw={el.key}
                        key={el.id}
                        onClick={() => {
                          onSelect(el);
                          onClose();
                        }}
                      >
                        <Icon mr="10px" color="darkGrey" boxSize="14px" as={variableTypeIcons[el.type]} />
                        <Tooltip placement="top" label={el.description} fontSize="md">
                          {el.key}
                        </Tooltip>
                      </CustomMenuItem>
                    );
                  })}
                </MenuGroup>
              )}
            </Box>
          ) : (
            type !== CustomVariableContextMenuType.Dropdown && (
              <Flex alignItems="center" justifyContent="center" h="40px" color="darkGrey">
                {t("No Fields Found")}
              </Flex>
            )
          )}
          {!!addVariable && (
            <AddVariablePopover
              onCreate={(newVariable: CustomVariableModel) => {
                addVariable(newVariable);
                handleCloseMenu();
              }}
              onlyType={onlyVariableType}
              excludeType={excludeVariableType}
              isOpen={isOpenAddVariable}
              onOpenPopover={onOpenAddVariable}
              onClose={onCloseAddVariable}
              flowId={flow?.newFlowId ?? flow?.id}
              scope={scope}
              vars={vars}
            />
          )}
        </MenuList>
      </Menu>
      <script>{blockHeight()}</script>
    </>
  );
};
