import { useTranslation } from "react-i18next";
import { OnboardingToursContainer } from "./OnboardingToursContainer";
import { EventCategories } from "../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { TourNames } from "./OnboardingTourEnums";
import { getTriggerOnboardingStep } from "./toursConstants";
interface Props {
  isTriggerTourRun?: boolean;
  isEdgeInStartingNodeHandle?: boolean;
}
export const TriggerOnboardingContainer = ({ isTriggerTourRun, isEdgeInStartingNodeHandle }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "onboardingTour" });
  const steps = getTriggerOnboardingStep(t);

  return (
    <OnboardingToursContainer
      currentTourName={TourNames.TriggerTourName}
      tourLevelNameForGAEvent={EventCategories.OnboardingTrigger}
      steps={steps}
      isTriggerTourRun={isTriggerTourRun}
      spotlightClicks={!isEdgeInStartingNodeHandle}
    />
  );
};
