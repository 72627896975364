import React, { useState } from "react";
import { Flex, Box, Text, Show } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ContactListModel } from "../ContactListModel";
import s from "../ContactList.module.scss";
import { selectLanguage } from "../../../common/user/UserSlice";
import { useAppDispatch, useAppSelector } from "../../../common/state/store";
import { AppSettings } from "../../../common/AppSettings";
import { useNavigate, useSearchParams } from "react-router-dom";
import { deleteContact } from "../ContactListSlice";
import { DeleteContactPopup } from "./DeleteContactPopup";
import { ContactListBig } from "./ContactListAdaptive/ContactListBig";
import { ContactListMobile } from "./ContactListAdaptive/ContactListMobile";
import * as vars from "../../layout/LayoutHelper/ResolutionConst";
import { selectIsOrganizationTrial } from "../../organisation/OrganisationSlice";
import { useNowWidthView } from "../../layout/LayoutHelper/ResolutionHooks";

declare const appSettings: AppSettings;

interface Props {
  contactList: ContactListModel | undefined;
  onImportContactsOpen: () => void;
  onNewContact: () => void;
  loadingContactList: boolean;
  filters: string;
}

export const ContactList = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "contactList" });
  const dispatch = useAppDispatch();
  const lng = useAppSelector(selectLanguage);
  const navigate = useNavigate();
  const [deletePopupState, setDeletePopupState] = useState<{
    id: string;
    itemName: string;
  } | null>(null);
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const filters = searchParams.get("filters");

  const isTrial = useAppSelector(selectIsOrganizationTrial);
  const nowWidth = useNowWidthView();

  const onDeletePopupClose = () => setDeletePopupState(null);
  const deleteContactDispatch = (contactId: string) => {
    dispatch(deleteContact({ contactId }));
  };

  return (
    <Flex direction="column">
      <Flex borderRadius="12px">
        {props.contactList?.items && props.contactList.items.length === 0 && (search !== "" || filters !== "") ? (
          <>
            <Box className={s.contactListNoItems}>
              <Text variant="medium" noOfLines={1} align="center" color="darkGrey">
                {t("No results found")}
              </Text>
            </Box>
          </>
        ) : (
          <>
            <Show breakpoint="(min-width: 1000px)">
              <Box
                pb="64px"
                overflow="auto"
                width={"100%"}
                height="calc(100dvh - 60px)"
                paddingTop={window.innerWidth > vars.TABLET_VIEW ? "52px" : "0"}
              >
                <ContactListBig
                  loadingContactList={props.loadingContactList}
                  search={search}
                  apiBaseUrl={appSettings.apiBaseUrl}
                  contactList={props.contactList}
                  navigate={navigate}
                  setDeletePopupState={setDeletePopupState}
                  lng={lng}
                />
              </Box>
            </Show>
            <Show breakpoint="(max-width: 999px)">
              <Box
                pb="64px"
                overflow="auto"
                width={"100%"}
                height={`calc(100dvh - ${isTrial && nowWidth <= vars.MOBILE_VIEW ? "240px" : "180px"})`}
              >
                <ContactListMobile
                  loadingContactList={props.loadingContactList}
                  search={search}
                  apiBaseUrl={appSettings.apiBaseUrl}
                  contactList={props.contactList}
                  navigate={navigate}
                  setDeletePopupState={setDeletePopupState}
                  lng={lng}
                />
              </Box>
            </Show>
          </>
        )}
      </Flex>
      <DeleteContactPopup
        isOpen={Boolean(deletePopupState)}
        deleteContactState={deletePopupState}
        onClose={onDeletePopupClose}
        onDelete={deleteContactDispatch}
      />
    </Flex>
  );
};
