import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import s from "./SelectVariableMenu.module.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ComplexFilterItemsModel, FilterParamTypes, FilterParams } from "../../../ComplexFilterModel";
import Magnifire from "../../../../../assets/images/magnifire.svg?react";
import CrossIcon from "../../../../../assets/icons/cross.svg?react";
import arrayTypeIcon from "../../../../../assets/icons/variableTypes/arrayTypeIcon.svg?react";
import textTypeIcon from "../../../../../assets/icons/variableTypes/textTypeIcon.svg?react";
import dateTypeIcon from "../../../../../assets/icons/variableTypes/dateTypeIcon.svg?react";
import numberTypeIcon from "../../../../../assets/icons/variableTypes/numberTypeIcon.svg?react";
import booleanTypeIcon from "../../../../../assets/icons/variableTypes/booleanTypeIcon.svg?react";
import catalogTypeIcon from "../../../../../assets/icons/variableTypes/catalogTypeIcon.svg?react";
import { CustomVariableType } from "../../../../../common/AppEnums";

interface Props {
  id: string;
  chosenVariable?: string;
  filterFields: ComplexFilterItemsModel;
  selectCustomVariable: (variableValue: string) => void;
  filterParams: FilterParams;
  filterParamType: FilterParamTypes;
}

export const SelectVariableMenu = (props: Props) => {
  const cwt = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const { t } = useTranslation("translation", { keyPrefix: "complexFilter" });
  const [searchedText, setSearchedText] = useState("");
  const [searchedValues, setSearchedValues] = useState<ComplexFilterItemsModel | undefined>(props.filterFields);

  useEffect(() => {
    setSearchedValues(props.filterFields);
  }, [props.filterFields]);

  const variableTypeIcons = {
    [CustomVariableType.Text]: textTypeIcon,
    [CustomVariableType.Array]: arrayTypeIcon,
    [CustomVariableType.Number]: numberTypeIcon,
    [CustomVariableType.Date]: dateTypeIcon,
    [CustomVariableType.DateTime]: dateTypeIcon,
    [CustomVariableType.Boolean]: booleanTypeIcon,
    [CustomVariableType.Order]: catalogTypeIcon,
  };

  const onSearchTextChange = (value: string) => {
    setSearchedText(value);
    const searchedVals: ComplexFilterItemsModel = {
      groups:
        props.filterFields?.groups.map(el => {
          const groupItems = el.items.filter(e => e.fieldName.toLowerCase().includes(value.toLowerCase()));
          return {
            ...el,
            items: groupItems,
          };
        }) ?? [],
    };
    if (searchedVals.groups?.length) {
      setSearchedValues(searchedVals);
      return;
    }
    setSearchedValues(undefined);
  };
  return (
    <>
      <Menu gutter={-37} variant="dominoGroupedMenu" autoSelect={false} matchWidth preventOverflow={false}>
        <MenuButton
          as={Button}
          variant="dominoDefaultMenuBtn"
          _active={{ borderColor: "line", backgroundColor: "white" }}
          onClick={e => e.stopPropagation()}
          color={props.chosenVariable ? "black" : "darkGrey"}
          data-pw="select-variable-menu"
        >
          {props.chosenVariable?.length ? props.chosenVariable : cwt("Select")}
        </MenuButton>
        <MenuList className={s.variableModal}>
          <Box className={s.searchBox}>
            <InputGroup w="auto">
              <InputLeftElement pointerEvents="none">
                <Magnifire />
              </InputLeftElement>
              <Input
                placeholder={cwt("Search") ?? ""}
                onBlur={e => e.target.focus()}
                autoFocus
                value={searchedText}
                onChange={e => onSearchTextChange(e.target.value)}
                data-pw="search-variable-menu"
              />
              <InputRightElement right="10px" top="50%" mt={"-11px"} boxSize="22px">
                {searchedText && <CrossIcon className={s.crossIcon} cursor="pointer" onClick={() => onSearchTextChange("")} />}
              </InputRightElement>
            </InputGroup>
          </Box>
          <Flex flexDir="column" className={s.varList}>
            {searchedValues?.groups?.map((el, index) => {
              return (
                <MenuGroup key={index} mt="10px" title={el.label ?? ""}>
                  {el.items.length ? (
                    el.items.map((elem, index) => {
                      return (
                        <MenuItem
                          key={index}
                          onClick={() => props.selectCustomVariable(elem.fieldValue)}
                          data-pw={elem.fieldName}
                        >
                          {!!elem.type && <Icon mr="10px" color="darkGrey" boxSize="14px" as={variableTypeIcons[elem.type]} />}
                          <Tooltip placement="top" label={elem.description} fontSize="md">
                            {elem.fieldName}
                          </Tooltip>
                        </MenuItem>
                      );
                    })
                  ) : (
                    <Flex key={"No Fields Found"} alignItems="center" justifyContent="center" h="40px" color="darkGrey">
                      {t("No Fields Found")}
                    </Flex>
                  )}
                </MenuGroup>
              );
            })}
          </Flex>
        </MenuList>
      </Menu>
    </>
  );
};
