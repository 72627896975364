import { Button, Icon, Tooltip } from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";

import { OrganisationModel, PluginModel } from "../organisation/OrganisationModel";
import Organisation from "../../assets/icons/sidebar/organisation.svg?react";
import UserManagement from "../../assets/icons/sidebar/userManagement.svg?react";

import Calendar from "../../assets/icons/calendar.svg?react";

import s from "./Sidebar.module.scss";

const iconMap = {
  org: Organisation,
  calendar: Calendar,
  linkedin: UserManagement,
};

interface Props {
  organisation: OrganisationModel;
  isOpenSideBar: boolean;
  onClick: () => void;
}

export const SidebarPlugins = ({ organisation, isOpenSideBar, onClick }: Props) => {
  const location = useLocation();

  const getIcon = (name: string) => {
    switch (name) {
      case "calendar":
        return iconMap.calendar;
      default:
        return iconMap.org;
    }
  };

  const isActive = (plugin: PluginModel) => {
    return location.pathname.startsWith(`/plugin/${plugin.name}`);
  };

  return (
    <>
      {organisation?.plugins?.map((plugin, index) =>
        plugin.isExternal ? (
          <a key={index} href={plugin.url} target="_blank" rel="noreferrer">
            <Button
              onClick={() => {
                onClick();
              }}
              variant="sideBar"
              className={`${s.sideLink} ${isActive(plugin) ? s.activeSideLink : ""}`}
            >
              <Icon as={getIcon(plugin.name)} boxSize="18px" mr="20px" />
              {plugin.label}
            </Button>
          </a>
        ) : (
          <NavLink key={index} to={`/plugin/${plugin.name}`}>
            <Tooltip
              variant="dominoLight"
              label={plugin.label}
              visibility={isOpenSideBar ? "hidden" : "visible"}
              placement={"right"}
            >
              <Button
                onClick={() => {
                  onClick();
                }}
                variant="sideBar"
                className={`${s.sideLink} ${isActive(plugin) ? s.activeSideLink : ""}`}
              >
                <>
                  <Icon as={getIcon(plugin.name)} boxSize="18px" mr="20px" />
                  {plugin.label}
                </>
              </Button>
            </Tooltip>
          </NavLink>
        ),
      )}
    </>
  );
};
