import s from "../../ContactList.module.scss";
import se from "../../components/ContactListAdaptive/ContactListMobile.module.scss";
import { Box, Skeleton, Text, Tooltip } from "@chakra-ui/react";
import { formatDateTimeToView } from "../../../../common/utils/formatDate";
import { fromNow } from "../../../../common/utils/fromNow";
import React from "react";
import { useTranslation } from "react-i18next";
import { ContactListModel, ContactModel } from "../../ContactListModel";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { LanguageCodeEnum } from "../../../../common/user/UserModel";
import { ReactComponent as EditIcon } from "../../../../assets/icons/editIcon.svg";
import { ReactComponent as RedTrashIcon } from "../../../../assets/icons/trash.svg";
import DotMenuContainer from "../../../../UI/atoms/DotMenu/DotMenuContainer";
import { RenderSmoothImage } from "../../../../UI/molecules/renderSmoothImage/RenderSmoothImage";

interface Props {
  apiBaseUrl: string;
  contactList: ContactListModel | undefined;
  navigate: NavigateFunction;
  setDeletePopupState: (value: React.SetStateAction<{ id: string; itemName: string } | null>) => void;
  lng: LanguageCodeEnum | string;
  loadingContactList: boolean;
  search: string;
}

export const ContactListMobile = (props: Props) => {
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const { t, i18n } = useTranslation("translation", { keyPrefix: "contactList" });
  const navigate = useNavigate();

  const onEditContactItem = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.TouchEvent<HTMLButtonElement> | React.DragEvent<HTMLButtonElement>,
    contact: ContactModel,
  ) => {
    e.stopPropagation();
    navigate(`/contacts/${contact.id}`);
  };

  return (
    <Box className={s.contactListGrid}>
      {props.contactList?.items ? (
        <>
          {props.contactList.items.map(contact => (
            <Box
              borderBottom="1px solid"
              borderColor="bgMiddle"
              className={i18n.language === "en" ? s.contactListItem : s.contactListItemOverride}
              key={contact.id}
              onClick={() => props.navigate(`/contacts/${contact.id}`)}
            >
              <Box color={"darkGrey"}>
                <Text noOfLines={1} variant="medium" paddingLeft={"16px"} paddingBottom={"8px"} paddingTop={"7px"}>
                  {t("First Name")}
                </Text>
                <Text noOfLines={1} variant="medium" paddingLeft={"16px"} paddingBottom={"8px"}>
                  {t("Last Name")}
                </Text>
                <Text noOfLines={1} variant="medium" paddingLeft={"16px"} paddingBottom={"8px"}>
                  {t("UsernameShort")}
                </Text>
                <Text noOfLines={1} variant="medium" paddingLeft={"16px"} paddingBottom={"8px"}>
                  {t("Created")}
                </Text>
              </Box>
              <div>
                <Text className={se.name} variant="medium" paddingTop={"7px"}>
                  {contact.firstName && contact.firstName.length ? contact.firstName : ct("Not Set")}
                </Text>
                <Text className={se.name} variant="medium">
                  {contact.lastName && contact.lastName.length ? contact.lastName : ct("Not Set")}
                </Text>
                <Text className={se.name} variant="medium">
                  {contact.username ? `@${contact.username}` : contact.externalId}
                </Text>
                <Text variant="medium" noOfLines={1} paddingBottom={"8px"}>
                  <Tooltip variant={"dominoLight"} label={formatDateTimeToView("Date", contact.createDate)}>
                    {fromNow(contact.createDate, props.lng)}
                  </Tooltip>
                </Text>
              </div>
              <div>
                <Box className={s.boxIconButton} paddingBottom={"39px"}>
                  <DotMenuContainer
                    menuItems={[
                      {
                        MenuItem: {
                          icon: <EditIcon width="18px" height="18px" />,
                          text: ct("Edit"),
                          clickEvent: e => onEditContactItem(e, contact),
                        },
                      },
                      {
                        MenuItem: {
                          icon: <RedTrashIcon width="18px" height="18px" />,
                          text: ct("Delete"),
                          clickEvent: e => {
                            e.stopPropagation();
                            props.setDeletePopupState({
                              id: contact.id,
                              itemName: `${contact.firstName ?? ""} ${contact.lastName ?? ""}`,
                            });
                          },
                          color: "mainRed",
                        },
                      },
                    ]}
                  />
                </Box>
                <Box paddingBottom={"5px"}>
                  {contact.avatars.length ? (
                    <RenderSmoothImage
                      boxSize="36px"
                      alt={contact.lastName ? `${contact.firstName} ${contact.lastName}` : contact.firstName}
                      src={`${props.apiBaseUrl}/file/${contact.avatars[0][0].fileId}`}
                    />
                  ) : (
                    <RenderSmoothImage
                      boxSize="36px"
                      alt={contact.lastName ? `${contact.firstName} ${contact.lastName}` : contact.firstName}
                    />
                  )}
                </Box>
              </div>
            </Box>
          ))}
        </>
      ) : (
        <>
          {Array(15)
            .fill(1)
            .map((el, ind) => (
              <Skeleton
                speed={0.5}
                startColor="line"
                endColor="bgLight"
                margin="12px 6px 0px"
                height="136px"
                borderRadius="12px"
                key={ind}
              />
            ))}
        </>
      )}
    </Box>
  );
};
