/**
 * @type TABLET_VIEW
 * Tablet width
 */
export const TABLET_VIEW = 999;

/**
 * @type MOBILE_VIEW
 * Mobile width
 */
export const MOBILE_VIEW = 767;

/**
 * @type DESKTOP_VIEW
 * Mobile width
 */
export const DESKTOP_VIEW = 1024;

/**
 * @type T_VIEW
 * Mobile width
 */
export const T_VIEW = 991;

/**
 * Fixed height for components
 */
export const HEADER = 60;
export const SUB_HEADER = 60;
export const EXTENDED_SUB_HEADER = 60;
export const PAGINATOR_HEIGHT = 48;
export const TRIAL_HEIGHT = 60;
