import { fork } from "redux-saga/effects";
import { chatListSaga } from "../../components/chatList/ChatListSaga";
import { flowBuilderSaga } from "../../components/flowBuilder/FlowBuilderSaga";
import { automationSaga } from "../../components/automation/AutomationSaga";
import { botListSaga } from "../../components/botList/BotListSaga";
import { loginSaga } from "../../components/login/LoginSaga";
import { signUpSaga } from "../../components/signUp/SignUpSaga";
import { sidebarSaga } from "../../components/sidebar/SidebarSaga";
import { dashboardSaga } from "../../components/dashboard/DashboardSaga";
import { aiAssistantListSaga } from "../../components/aiAssistant/AIAssistantListSaga";
import { authSaga } from "../auth/AuthSaga";
import { conversationSaga } from "../../components/conversation/ConversationSaga";
import { addBotSaga } from "../../components/addBot/AddBotSaga";
import { botSaga } from "../../components/bot/BotSaga";
import { contactInfoSaga } from "../../components/contactInfo/ContactInfoSaga";
import { userSaga } from "../user/UserSaga";
import { tariffPlanSaga } from "../tariffPlan/TariffPlanSaga";
import { broadcastListSaga } from "../../components/broadcastList/BroadcastListSaga";
import { userProfileSaga } from "../../components/userProfile/UserProfileSaga";
import { userManagementSaga } from "../../components/userManagement/UserManagementSaga";
import { broadcastSaga } from "../../components/broadcast/BroadcastSaga";
import { telegramAuthSaga } from "../TelegramAuth/TelegramAuthSaga";
import { contactListSaga } from "../../components/contactList/ContactListSaga";
import { contactSaga } from "../../components/contact/ContactSaga";
import { organisationSaga } from "../../components/organisation/OrganisationSaga";
import { pluginAuthSaga } from "../../components/pluginAuth/PluginAuthSaga";
import { onboardingTourSaga } from "../../components/onboardingTour/OnboardingToursSaga";
import { conversationViewerSaga } from "../../components/conversationViewer/ConversationViewerSaga";
import { recipientListSaga } from "../../components/broadcast/components/ContactList/RecipientListSaga";
import { groupChatListSaga } from "../../components/groupChat/components/GroupChatList/GroupChatListSaga";
import { groupChatInfoSaga } from "../../components/groupChat/components/GroupChatMain/components/GroupChatInfo/GroupChatInfoSaga";
import { groupChatMessagesListSaga } from "../../components/groupChat/components/GroupChatMain/components/GroupChatConversation/components/GroupChatMessagesList/GroupChatMessagesListSaga";
import { groupChatMemberListSaga } from "../../components/groupChat/components/GroupChatMain/components/GroupChatMemberList/GroupChatMemberListSaga";
import { groupChatSaga } from "../../components/groupChat/GroupChatSaga";
import { profanityFilterSaga } from "../../components/groupChat/components/GroupChatMain/components/Tabs/ProfanityFilter/ProfanityFilterSaga";

export function* rootSaga() {
  yield fork(authSaga);
  yield fork(loginSaga);
  yield fork(signUpSaga);
  yield fork(sidebarSaga);
  yield fork(dashboardSaga);
  yield fork(aiAssistantListSaga);
  yield fork(flowBuilderSaga);
  yield fork(automationSaga);
  yield fork(chatListSaga);
  yield fork(botListSaga);
  yield fork(conversationSaga);
  yield fork(conversationViewerSaga);
  yield fork(addBotSaga);
  yield fork(botSaga);
  yield fork(contactInfoSaga);
  yield fork(userSaga);
  yield fork(tariffPlanSaga);
  yield fork(broadcastListSaga);
  yield fork(userProfileSaga);
  yield fork(userManagementSaga);
  yield fork(broadcastSaga);
  yield fork(recipientListSaga);
  yield fork(telegramAuthSaga);
  yield fork(contactListSaga);
  yield fork(contactSaga);
  yield fork(organisationSaga);
  yield fork(pluginAuthSaga);
  yield fork(onboardingTourSaga);
  yield fork(groupChatSaga);
  yield fork(groupChatListSaga);
  yield fork(groupChatInfoSaga);
  yield fork(groupChatMessagesListSaga);
  yield fork(groupChatMemberListSaga);
  yield fork(profanityFilterSaga);
}
