import { useTranslation } from "react-i18next";
import { MessageDirection } from "../../../../../../../common/AppEnums";
import { ConversationMessageModel } from "../../../../../ConversationModel";
import { ContextMenu } from "./components/ContextMenu/ContextMenu";
import { ReplyOnMessage } from "./components/ReplyOnMessage";

interface Props {
  message: ConversationMessageModel;
  userName?: string;
  onReply: (reply: {
    enabled: boolean;
    message?: {
      id: string;
      author: string;
      text?: string;
      photoFileId?: string;
      documentName?: string;
      contact?: string;
    };
  }) => void;
  isSupported: boolean;
  isReply?: boolean;
  messageHover: boolean;
  featureFlag: "LIVE_CHAT" | "GROUP_CHAT"; // TODO: Remove this field after add delete-support for group chats
}
export const MessageContextMenu = ({
  message,
  onReply: onReplyProp,
  userName,
  isSupported,
  isReply,
  messageHover,
  featureFlag,
}: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "conversation" });

  const onReply = () =>
    onReplyProp({
      enabled: true,
      message: {
        id: message.id,
        text: message.text,
        author: userName ?? t("Unknown"),
        documentName: message.documents?.at(0)?.fileName,
        photoFileId: message.photos?.at(0)?.fileId,
        contact: message.contact?.firstName,
      },
    });

  const possibleToReply = !!(isSupported && isReply);

  if (featureFlag === "GROUP_CHAT")
    return messageHover ? <ReplyOnMessage onReply={onReply} possibleToReply={possibleToReply} /> : <></>;

  return (
    <>
      {message.direction === MessageDirection.Outgoing && (
        <ContextMenu onReply={onReply} possibleToReply={possibleToReply} message={message} messageHover={messageHover} />
      )}
      {message.direction === MessageDirection.Incoming && messageHover && (
        <ReplyOnMessage onReply={onReply} possibleToReply={possibleToReply} />
      )}
    </>
  );
};
