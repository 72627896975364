import { store } from "../../../../../..";
import { MEMBERS_PER_PAGE } from "../../../../../../common/paginator/paginatorSizes";
import { MemberData } from "./GroupChatMemberListData";
import { MemberListModel } from "./GroupChatMemberListModel";

export const mapGroupChatMemberListDataToModel = (data: MemberData): MemberListModel => {
  const groupChatMemberList: MemberListModel = store.getState().app.groupChatMemberListState.memberList;
  const memberList = groupChatMemberList.items ?? [];

  const updateGroupChatMemberList = memberList.map(member => (member.id === data.id ? data : member));
  const isNewMember = !updateGroupChatMemberList.some(member => member.id === data.id);
  const isNewMemberForGroup =
    groupChatMemberList.currentPage === groupChatMemberList.totalPages && memberList.length < MEMBERS_PER_PAGE && isNewMember;

  if (isNewMemberForGroup) {
    updateGroupChatMemberList.push(data);
  }

  return {
    ...groupChatMemberList,
    items: updateGroupChatMemberList,
    totalItems: isNewMemberForGroup ? groupChatMemberList.totalItems + 1 : groupChatMemberList.totalItems,
  };
};
