import React from "react";
import s from "./RenderSmoothImage.module.scss";
import { Avatar, Box, BoxProps, Skeleton, Tooltip } from "@chakra-ui/react";

interface Props extends BoxProps {
  src?: string;
  alt?: string;
  isShowTooltip?: boolean;
  boxSize: string;
  avatarStyle?: React.CSSProperties;
}

export const RenderSmoothImage = ({
  src = " ",
  alt = "notFound",
  boxSize,
  avatarStyle,
  isShowTooltip = false,
  ...props
}: Props) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [isValidSrc, setIsValidSrc] = React.useState(!!src);

  return (
    <Tooltip isDisabled={!isShowTooltip} label={alt} variant="dominoLight">
      <Box {...props} maxW={boxSize} maxH={boxSize} w="100%" h="100%" className={s.smoothImageWrapper}>
        {isValidSrc ? (
          <Avatar
            className={`${s.Avatar} ${s.smoothImage} ${imageLoaded ? s.imgVisible : s.imgHidden}`}
            onLoad={() => setImageLoaded(true)}
            onError={() => setIsValidSrc(false)}
            style={avatarStyle}
            boxSize={boxSize}
            bg="midDeepBlue"
            color="white"
            loading="eager"
            src={src}
            data-pw="contact-image-avatar"
          />
        ) : (
          <Avatar
            className={s.Avatar}
            boxSize={boxSize}
            bg="midDeepBlue"
            color="white"
            loading="eager"
            style={avatarStyle}
            name={alt}
            data-pw="contact-image-avatar"
          />
        )}
        {isValidSrc && !imageLoaded && <Skeleton className={s.smoothPreloader} boxSize={boxSize} borderRadius="50%" />}
      </Box>
    </Tooltip>
  );
};
