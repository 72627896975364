import s from "../../Organisation.module.scss";
import {
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import sp from "../../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import { TariffPlanRedirectButton } from "../../../../common/tariffPlan/TariffPlanRedirectButton";
import { clearInviteToken, generateInviteToken, selectInviteToken } from "../../OrganisationSlice";
import { OrganisationModel } from "../../OrganisationModel";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import StarPlan from "../../../../assets/icons/starPlan.svg?react";
import { useEffect } from "react";
import ClipboardText from "../../../../UI/atoms/clipboardText/ClipboardText";

interface Props {
  MaxCount: number /* eslint-disable-line @typescript-eslint/no-explicit-any */;
  IsUnlimited: boolean /* eslint-disable-line @typescript-eslint/no-explicit-any */;
  organisation?: OrganisationModel;
}

export const GenerateLinkButton = (props: Props) => {
  const { t, i18n } = useTranslation("translation", { keyPrefix: "organisation" });
  const isEng = i18n.language === "en";
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const dispatch = useAppDispatch();
  const inviteToken = useAppSelector(selectInviteToken);
  const inviteTokenCopy = useClipboard(inviteToken ? `${window.location.origin}/invitation/${inviteToken}` : "");

  useEffect(() => {
    return () => {
      dispatch(clearInviteToken());
    };
  }, [dispatch]);

  return (
    <>
      <Flex flex={1} className={s.flexLink}>
        {!props.IsUnlimited && props.organisation?.totalMembers && props.organisation?.totalMembers >= props.MaxCount ? (
          <Flex className={s.buttonContainer}>
            <Popover trigger={"hover"}>
              {({ onClose }) => (
                <>
                  <PopoverTrigger>
                    <Button variant="dominoOutlineViolet" className={s.linkButtonLock}>
                      <Icon className={`${sp.starPlanHover} starPlanHover`} as={StarPlan} boxSize="18px" mr={2} />
                      {t("Generate link")}
                    </Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                      <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                      <Flex alignItems="center" justifyContent="center" direction="column">
                        <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                          {tp("Advanced feature")}
                        </PopoverHeader>
                        <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                          {tp("Please upgrade your plan to invite more Operators")}
                        </PopoverBody>
                        <TariffPlanRedirectButton onClose={onClose} />
                      </Flex>
                    </PopoverContent>
                  </Portal>
                </>
              )}
            </Popover>
          </Flex>
        ) : (
          <Flex className={s.buttonContainer}>
            <Button
              onClick={() => dispatch(generateInviteToken())}
              variant="dominoOutlineViolet"
              className={isEng ? s.linkButton : s.linkButtonRU}
            >
              {t("Generate link")}
            </Button>
          </Flex>
        )}

        {inviteToken ? (
          <Flex align="center">
            <ClipboardText
              opacity="1 !important"
              color="#325EE6"
              textIsCopy={`${window.location.origin}/invitation/${inviteToken}`}
              elemIsCopy={
                <Text
                  className={s.textLink}
                  color="blueLink"
                  noOfLines={1}
                  wordBreak="break-all"
                  onClick={() => inviteTokenCopy.onCopy()}
                >
                  {`${window.location.origin}/invitation/${inviteToken}`}
                </Text>
              }
            />
          </Flex>
        ) : (
          <>
            <Text variant="small" noOfLines={3}>
              {t("Create a unique link to invite a new member to your team")}
            </Text>
          </>
        )}
      </Flex>
    </>
  );
};
