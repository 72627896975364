import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { OrganisationModel, OrganisationTeamsModel, OrganisationUsersModel } from "./OrganisationModel";
import { TrialPlanModel } from "./components/TrialPlan/TrialPlanModel";
import organizationReducer from "./reducers/organizationReducer";

export interface OrganisationState {
  organisation?: OrganisationModel;
  organisationUsers?: OrganisationUsersModel;
  organisationsTeams?: OrganisationTeamsModel;
  organisationUserUsage?: { id: string; name: string }[];
  organisationTeamUsage?: { id: string; name: string }[];
  inviteToken?: string;
  organizationTrial?: TrialPlanModel | null;
  isOrganizationTrial: boolean;
  isInitialTrialLoading: boolean;
}

export const initialState: OrganisationState = {
  isOrganizationTrial: false,
  isInitialTrialLoading: false,
};

export const organisationSlice = createSlice({
  name: "organisationSlice",
  initialState,
  reducers: organizationReducer,
});

export const {
  getOrganisation,
  getOrganisationCompleted,
  createOrganisation,
  getOrganisationUsers,
  getOrganisationUsersCompleted,
  getModalOrganisationUsersCompleted,
  clearOrganisationUsers,
  getOrganisationTeams,
  getOrganisationTeamsCompleted,
  generateInviteToken,
  updateOrganisation,
  deleteUserFromOrganisation,
  setInviteToken,
  clearInviteToken,
  clearOrganisationState,
  createOrganisationTeam,
  editOrganisationTeam,
  deleteOrganisationTeam,
  deleteTeamOperator,
  addOperatorsToTeam,
  checkIsOperatorInUse,
  checkIsOperatorInUseSucceed,
  checkIsTeamInUse,
  checkIsTeamInUseSucceed,
  setOrganization,
  getTrialPlan,
  getTrialPlanCompleted,
  activateTrialPlan,
  setTrialPlanState,
  setIsInitialTrialLoading,
} = organisationSlice.actions;

export default organisationSlice.reducer;

export const selectNewOrganisation = (state: RootState) => state.app.organisationState.organisation;
export const selectOrganisation = (state: RootState) => state.app.organisationState.organisation;
export const selectOrganisationUsers = (state: RootState) => state.app.organisationState.organisationUsers;
export const selectOrganisationTeams = (state: RootState) => state.app.organisationState.organisationsTeams;
export const selectInviteToken = (state: RootState) => state.app.organisationState.inviteToken;
export const selectOrganisationUserUsage = (state: RootState) => state.app.organisationState.organisationUserUsage;
export const selectOrganisationTeamUsage = (state: RootState) => state.app.organisationState.organisationTeamUsage;
export const selectTrialPlan = (state: RootState) => state.app.organisationState.organizationTrial;
export const selectIsOrganizationTrial = (state: RootState) => state.app.organisationState.isOrganizationTrial;
export const selectIsInitialTrialLoading = (state: RootState) => state.app.organisationState.isInitialTrialLoading;
