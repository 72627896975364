import { Icon, Menu, MenuItem } from "@chakra-ui/react";
import s from "../../Sidebar.module.scss";
import { useTranslation } from "react-i18next";
import plusIcon from "../../../../assets/icons/plusIcon.svg?react";
import { NavigateFunction } from "react-router-dom";

interface Props {
  navigate: NavigateFunction;
}

export const SideBarCreateNewBot = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "sidebar" });
  return (
    <Menu>
      <MenuItem
        onClick={() => props.navigate("/addbot")}
        height={{ base: "48px", md: "60px" }}
        className={s.addBotBtn}
        data-pw="create-new-bot"
      >
        <Icon as={plusIcon} boxSize={{ base: "24px", md: "30px" }} color="midDeepBlue" mr="12px" className={s.addBotIcon} />
        {t("Create new bot")}
      </MenuItem>
    </Menu>
  );
};
