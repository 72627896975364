import {
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CrossIcon from "../../assets/icons/cross.svg?react";
import DownArrowMenu from "../../assets/images/downArrow.svg?react";
import s from "./MultiSelectMenu.module.scss";
import { InputSearch } from "../../UI/molecules/inputSearch/InputSearch";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { getOrganisationUsers, selectOrganisationUsers } from "../organisation/OrganisationSlice";

interface MultiSelectMenuProps {
  options: { name: string; id: string }[];
  isLastPage?: boolean;
  onChange: (selectedValues: string[]) => void;
  buttonProps?: MenuButtonProps;
  getAdditionalItems: () => void;
  searchProps?: { searchItemName: string; onSearchTextChange: (value: string) => void };
  required?: boolean;
  validationMessage?: string;
  onValidation?: (isValid: boolean) => void;
  isDisabled?: boolean;
}

export const MultiSelectMenu = (props: MultiSelectMenuProps): JSX.Element => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [searchedText, setSearchText] = useState("");
  const [isValid, setValid] = useBoolean(false);
  const [isDirty, setDirty] = useBoolean(false);
  const { t } = useTranslation("translation", {
    keyPrefix: "commonWords",
  });
  const dispatch = useAppDispatch();
  const operators = useAppSelector(selectOrganisationUsers);

  const onChange = (names: string[]) => {
    setDirty.on();
    if (props.options) {
      const ids = props.options.filter(el => names.find(option => el.name === option)).map(el => el.id);
      props.onChange(ids);
    }
  };

  const onSearchTextChange = (value: string) => {
    setDirty.on();
    setSearchText(value);
    props.searchProps?.onSearchTextChange(value);
  };

  useEffect(() => {
    if (!selectedOptions.length) setValid.off();
    else setValid.on();
  }, [selectedOptions, setValid]);

  useEffect(() => {
    if (props.onValidation !== undefined) props.onValidation(isValid);
  }, [isValid, props]);

  const getAdditionalOperators = () => {
    if (operators && operators.currentPage < operators.totalPages) {
      dispatch(getOrganisationUsers({ page: operators.currentPage + 1, forModal: true }));
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      getAdditionalOperators();
    }
  };

  return (
    <>
      <Menu
        matchWidth={true}
        closeOnSelect={false}
        variant="dominoGroupedMenu"
        gutter={0}
        onClose={() => {
          setSearchText("");
          onSearchTextChange("");
        }}
        placement={"bottom-start"}
        autoSelect={false}
      >
        <>
          <MenuButton
            data-pw="select"
            as={Button}
            variant="dominoDefaultMenuBtn"
            borderColor={!isValid && isDirty && (props?.required ?? false) ? "#FF0000" : "line"}
            height={"initial"}
            minHeight={"36px"}
            isDisabled={props.isDisabled}
            p={"8px 16px"}
            _active={{
              borderColor: "line",
            }}
            _focus={{
              outline: "none",
            }}
            {...props.buttonProps}
            rightIcon={
              <Icon
                as={CrossIcon}
                boxSize="14px"
                color="midDeepBlue"
                visibility={selectedOptions.length > 0 ? "visible" : "hidden"}
                onClick={() => {
                  props.onChange([]);
                  setSelectedOptions([]);
                }}
              />
            }
          >
            {selectedOptions.length ? (
              <Flex flexWrap={"wrap"}>
                {selectedOptions.map((option, i) => {
                  return (
                    <Flex key={`${option}${i}`}>
                      {option}
                      {i !== selectedOptions.length - 1 && <Text pr={"4px"}>,</Text>}
                    </Flex>
                  );
                })}
              </Flex>
            ) : (
              <Text fontSize={"15px"} color={"darkGrey"}>
                {t("Select")}
              </Text>
            )}
          </MenuButton>
          <Box
            data-pw="select-error"
            className={!isValid && isDirty && (props?.required ?? false) ? s.errorText : s.errorTextDisabled}
          >
            {props?.validationMessage ?? "Choose one of the options"}
          </Box>
          <MenuList position="absolute" top="-35px" w={"100%"}>
            {!!props.searchProps && (
              <Box className={s.dropdownSearchBox}>
                <InputSearch
                  inputGroupStyles={{ w: "auto" }}
                  placeholder={t(`Search by ${props.searchProps.searchItemName}`) ?? ""}
                  searchText={searchedText}
                  setSearchText={onSearchTextChange}
                  autoFocus
                />
              </Box>
            )}
            <Box maxH="200px" overflow="scroll" onScroll={handleScroll}>
              {props.options.length ? (
                <MenuOptionGroup
                  title={undefined}
                  value={selectedOptions}
                  type="checkbox"
                  /* eslint-disable @typescript-eslint/ban-ts-comment */
                  // @ts-ignore Arguments type is just wrong upstream.
                  onChange={(values: string[]) => {
                    setSelectedOptions(values.filter(_ => _.length));
                    onChange(values);
                  }}
                >
                  {props.options.map(option => {
                    return (
                      <MenuItemOption
                        color={"mainPurple"}
                        key={`multiselect-menu-${option}`}
                        as="button"
                        value={option.name}
                        data-pw={option.name}
                      >
                        <Text color={"black"}>{option.name}</Text>
                      </MenuItemOption>
                    );
                  })}

                  {!props.isLastPage && (
                    <Flex
                      onClick={props.getAdditionalItems}
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"100%"}
                      cursor={"pointer"}
                      data-pw="show-more"
                    >
                      <div>{t("show more")}</div>
                      <Icon as={DownArrowMenu} boxSize="26px" color="midDeepBlue" />
                    </Flex>
                  )}
                </MenuOptionGroup>
              ) : (
                <Flex w="100%" justifyContent="center" alignItems="center" py="10px">
                  <Text color={"grey"}>{t(`No ${props.searchProps?.searchItemName ?? "Items"}`)}</Text>
                </Flex>
              )}
            </Box>
          </MenuList>
        </>
      </Menu>
    </>
  );
};
