import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import ArrowDropIcon from "../../../assets/icons/arrowDrop.svg?react";

export interface SelectProps {
  isDisabled?: boolean;
  value: string;
  placeholder?: string;
  options: string[];
  translattedOptions?: Record<string, string>; // Keys must be options;
  onChange: (option: string) => void;
  shownItems?: number;
}

const ITEM_HEIGHT = 36;
const LIST_PADDINGS = 8;

export const Select = ({ isDisabled, value, placeholder, options, translattedOptions, onChange, shownItems }: SelectProps) => {
  const heightLimiter = shownItems ? LIST_PADDINGS + ITEM_HEIGHT * shownItems : undefined;

  return (
    <Menu variant="dominoBroadcastMenu" matchWidth>
      <MenuButton
        as={Button}
        variant="dominoDefaultMenuBtn"
        _active={{ borderColor: "mainPurple", borderBottom: "mainPurple", borderRadius: "8px" }}
        rightIcon={<ArrowDropIcon />}
        isDisabled={isDisabled}
      >
        {value ? translattedOptions ? translattedOptions[value] : value : <Text color="darkGrey">{placeholder}</Text>}
      </MenuButton>
      <MenuList maxH={heightLimiter} overflowY="auto" minWidth={0}>
        {options.map((option, index) => (
          <MenuItem p="8px 16px" key={option + index} onClick={() => onChange(option)}>
            <Flex align="center" w="100%">
              <Text color="newBlack" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                {translattedOptions ? translattedOptions[option] : option}
              </Text>
            </Flex>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
