import { Flex, IconButton, ResponsiveValue, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import LeftPaginationArrow from "../../assets/images/pagination/leftPaginationArrow.svg?react";
import RightPaginationArrow from "../../assets/images/pagination/rightPaginationArrow.svg?react";
import { isNextPage, isPrevPage } from "../utils/pagination";
import { PaginatorModel } from "./PaginatorModel";
import { PaginatorNumber } from "./components/PaginatorNumbers";
import s from "./components/Paginator.module.scss";
import { useGetLoadingState } from "../loading/hooks/useGetLoadingState";
import { useEffect, useState } from "react";

interface Props {
  data: PaginatorModel;
  getPage: (page: number) => void;
  sizeItems: number;
  loadingScope?: string;
  typeOfPage: string;
  paddingRight?:
    | ResponsiveValue<number | string | "-moz-initial" | "inherit" | "initial" | "revert" | "revert-layer" | "unset">
    | undefined;
  paddingLeft?:
    | ResponsiveValue<number | string | "-moz-initial" | "inherit" | "initial" | "revert" | "revert-layer" | "unset">
    | undefined;
}

export const Paginator = ({ getPage, data, typeOfPage, paddingRight, paddingLeft, loadingScope = "", sizeItems }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "paginator" });

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  useEffect(() => {
    if (isFirstLoad && data.items.length > 0) {
      setIsFirstLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const loadingData = useGetLoadingState(loadingScope);
  function calculateFirstItemInList() {
    switch (data.currentPage) {
      case 1:
        return 1;
      case data.totalPages:
        return data.totalItems - (data.totalItems - sizeItems * (data.totalPages - 1)) + 1;
      default:
        return sizeItems * data.currentPage - sizeItems + 1;
    }
  }

  function calculateLastItemInList() {
    switch (data.currentPage) {
      case data.totalPages:
        return data.totalItems;
      default:
        return sizeItems * data.currentPage;
    }
  }

  return (isNextPage(data) && data.items.length > 0) ||
    (isPrevPage(data) && data.items.length > 0) ||
    (loadingData && data.totalPages !== 1) ||
    isFirstLoad ? (
    <div className={s.paginator}>
      <Flex
        w="100%"
        align="center"
        justify="space-between"
        color="darkGray"
        paddingRight={paddingRight}
        paddingLeft={paddingLeft}
      >
        {isFirstLoad ? (
          <Text className={s.counterOfItems}>{t(typeOfPage)}</Text>
        ) : (
          <Text className={s.counterOfItems}>
            {t(typeOfPage)}&nbsp;
            {calculateFirstItemInList()}&nbsp;-&nbsp;{calculateLastItemInList()}&nbsp;
            {t("of")}
            &nbsp;{data.totalItems}&nbsp;&nbsp;&nbsp;&nbsp;
          </Text>
        )}
        <Flex align="center">
          <IconButton
            isDisabled={!isPrevPage(data) || isFirstLoad}
            aria-label="left arrow"
            onClick={() => getPage(data.currentPage - 1)}
            cursor={"pointer"}
            boxSize={"32px"}
            fill={!isPrevPage(data) || isFirstLoad ? "lightDeepBlue !important" : "darkGrey"}
            background={!isPrevPage(data) || isFirstLoad ? "none !important" : "initial"}
            opacity={"1 !important"}
            _hover={
              isPrevPage(data)
                ? {
                    fill: "darkGrey",
                    bg: "white",
                    border: "1px solid var(--chakra-colors-line)",
                  }
                : undefined
            }
            _focus={
              isPrevPage(data)
                ? {
                    fill: "newMiddleDeepBlue",
                    bg: "lightDeepBlue",
                  }
                : undefined
            }
            minWidth={"24px"}
            marginRight={"8px"}
            icon={<LeftPaginationArrow width="24px" height="24px" />}
            data-pw="paginator-left-arrow"
          />
          <PaginatorNumber
            lastPage={data.totalPages}
            getPage={getPage}
            totalItems={data.totalItems}
            currentPage={data.currentPage}
            totalPages={data.totalPages}
            isFirstLoad={isFirstLoad}
          />
          <IconButton
            isDisabled={!isNextPage(data) || isFirstLoad}
            onClick={() => getPage(data.currentPage + 1)}
            cursor={"pointer"}
            boxSize={"32px"}
            fill={!isNextPage(data) || isFirstLoad ? "lightDeepBlue !important" : "darkGrey"}
            background={!isNextPage(data) || isFirstLoad ? "none !important" : "initial"}
            opacity={"1 !important"}
            _hover={
              isNextPage(data)
                ? {
                    fill: "darkGrey",
                    bg: "white",
                    border: "1px solid var(--chakra-colors-line)",
                  }
                : undefined
            }
            _focus={
              isNextPage(data)
                ? {
                    fill: "newMiddleDeepBlue",
                    bg: "lightDeepBlue",
                  }
                : undefined
            }
            aria-label="right arrow"
            minWidth={"24px"}
            marginLeft={"8px"}
            icon={<RightPaginationArrow width="24px" height="24px" />}
            data-pw="paginator-right-arrow"
          />
        </Flex>
      </Flex>
    </div>
  ) : null;
};
