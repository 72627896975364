import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ButtonTypeDiscriminator } from "../../../../../common/AppEnums";
import { ButtonModel, StaticButtonModel } from "../../../../../common/AppButtonsModel";
import { ReactComponent as LinkIcon } from "../../../../../assets/icons/linkIcon.svg";
import { ReactComponent as DynamicIcon } from "../../../../../assets/icons/dynamicIcon.svg";
import { ReactComponent as CrossIcon } from "../../../../../assets/icons/cross.svg";
import s from "../../ChangeButtonPopover.module.scss";
import sh from "./InlineDynamicButtonItem.module.scss";
import ss from "../../../../dnd/SortableItem/SortableItem.module.scss";

interface Props {
  onStart: () => void;
  button: ButtonModel;
  isBroadcast?: boolean;
  onDelete?: () => void;
}

export const InlineDynamicButtonItem = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });

  const handleDeleteButtonClick = (e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation();
    if (props.onDelete) props.onDelete();
  };

  return (
    <div onClick={props.onStart} className={ss.button_inline} data-pw="inline-dynamic-button">
      {props.button.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageDynamicButton && (
        <Flex className={sh.buttonContainer}>
          <Icon className={sh.buttonIcon} as={DynamicIcon} data-pw="dynamic-icon" />
          <Text className={sh.buttonTitle}>{t("Dynamic buttons")}</Text>
          <CrossIcon onClick={handleDeleteButtonClick} className={ss.crossIcon} data-pw="cross-icon" />
        </Flex>
      )}
      {(props.isBroadcast || props.button.typeDiscriminator !== ButtonTypeDiscriminator.FlowMessageDynamicButton) && (
        <Flex className={sh.buttonContainer}>
          {(props.button as StaticButtonModel)?.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageUrlButton && (
            <Icon boxSize="20px" as={LinkIcon} data-pw="link-icon" />
          )}
          <Box className={s.buttonName}>{props.button?.label}</Box>
          <CrossIcon onClick={handleDeleteButtonClick} className={ss.crossIcon} data-pw="cross-icon" />
        </Flex>
      )}
    </div>
  );
};
