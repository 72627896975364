import { Dispatch, SetStateAction } from "react";
import { ProfanityFilterRule } from "../../../../ProfanityFilterModel";
import { PROFANITY_FILTER_DEFAULT } from "../../consts";

interface RulesButtons {
  setRules: Dispatch<SetStateAction<ProfanityFilterRule[]>>;
}
export interface RulesButtonsReturnedValue {
  delete: (rulePosition: number) => void;
  add: (lastRulePosition: number) => void;
}

export const profanityFilterRulesButtons = ({ setRules }: RulesButtons): RulesButtonsReturnedValue => ({
  delete: (rulePosition: number) => {
    setRules(rules => {
      const filterredRules = rules.filter((_, index) => index + 1 !== rulePosition);
      return filterredRules.map(rule => (rule.violation > rulePosition ? { ...rule, violation: rule.violation - 1 } : rule));
    });
  },
  add: (lastRulePosition: number) => {
    setRules(rules => [...rules, { ...PROFANITY_FILTER_DEFAULT.rules[0], violation: lastRulePosition + 1 }]);
  },
});
