import s from "./CalendarNode.module.scss";
import { ActionNodeProps } from "../ActionNodeProps";
import { CalendarFlowActionModel } from "../../../FlowBuilderModel";
import { useTranslation } from "react-i18next";
import { Text, Flex, Icon } from "@chakra-ui/react";
import CalendarNodeIcon from "../../../../../assets/icons/CalendarNodeIcon.svg?react";
import { MessageButtonBlock } from "../MessageButtonBlock/MessageButtonBlock";
import { endingHours, endingMinutes } from "../../../../../common/utils/endingDateTime";

export const CalendarNode = (props: { actionData: ActionNodeProps }) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const data = props.actionData.node.flowAction as CalendarFlowActionModel;
  const sortWorkingTime = data.workingTimes ? [...data.workingTimes].sort((a, b) => a.order - b.order) : undefined;

  return (
    <Flex flexDirection="column" alignItems="center">
      <div className={s.header}>
        <Icon as={CalendarNodeIcon} boxSize="32px" />
        <div className={s.textBlock}>
          <div className={s.type}>{t("Calendar")}</div>
        </div>
      </div>
      {data && data.text && <MessageButtonBlock actionData={props.actionData} />}
      {data && data.gap && (
        <Flex flexWrap="wrap" className={s.wrapper}>
          <Flex w="100%">
            <Text maxW="43%" color="darkGrey">
              {t("Duration")}:&nbsp;
            </Text>
            <Text maxW="60%" color="midDeepBlue !important" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {`${data.gap.hours || ""}`}
              {`${data.gap.hours ? ` ${endingHours("час", "hour", String(data.gap.hours))} ` : ""}`}
              {`${data.gap.minutes || ""}`}
              {`${data.gap.minutes ? ` ${endingMinutes("минут", "minute", String(data.gap.minutes))}` : ""}`}
            </Text>
          </Flex>
        </Flex>
      )}
      {data.isAvailable && (
        <Flex flexWrap="wrap" className={s.wrapper}>
          <Flex gap="8px" flexDirection="column" w="100%">
            <Text className={s.working} color="black !important">
              {t("Working time")}
            </Text>
            <Flex flexDirection="column" gap="4px">
              {sortWorkingTime &&
                sortWorkingTime.map(el => {
                  if (el.intervals?.length !== 0) {
                    return (
                      <Flex gap="12px" key={el.dayOfWeek}>
                        <Text className={s.day}>{t(el.dayOfWeek?.slice(0, 3))}</Text>
                        <Flex flex={1} flexWrap="wrap" gap="4px">
                          {el.intervals?.map((time, index) => (
                            <Text key={index} className={s.time}>{`${time.beginTime} – ${time.endTime}${
                              index !== el.intervals?.length - 1 ? "," : ""
                            }`}</Text>
                          ))}
                        </Flex>
                      </Flex>
                    );
                  }
                  return <></>;
                })}
            </Flex>
          </Flex>
        </Flex>
      )}
      {!data.text && !data.gap && !data.isAvailable && (
        <div className={s.noCondition}>
          <h4>{t("Set up a Calendar")}</h4>
        </div>
      )}
    </Flex>
  );
};
