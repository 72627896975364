import { TFunction } from "i18next";

export function mapFromOptionsToTranslations(
  from: string[],
  translator: TFunction,
  translatePrefix: string,
  numberForQuantity?: number,
): Record<string, string> {
  const translations: Record<string, string> = {};
  from.forEach(option => {
    translations[option] = translator(translatePrefix + option, { count: numberForQuantity });
  });

  return translations;
}
