import s from "./AssignNode.module.scss";
import { ActionNodeProps } from "../ActionNodeProps";
import { AssignFlowActionModel } from "../../../FlowBuilderModel";
import { useTranslation } from "react-i18next";
import { Flex, Icon, Text } from "@chakra-ui/react";
import AssignIcon from "../../../../../assets/icons/assignIcon.svg?react";

export const AssignNode = (props: { actionData: ActionNodeProps }) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const data = props.actionData.node.flowAction as AssignFlowActionModel;

  return (
    <Flex flexDirection="column" alignItems="center">
      <div className={s.header}>
        <Icon as={AssignIcon} color="midDeepBlue" boxSize="30px" />
        <div className={s.textBlock}>
          <div className={s.type}>{t("Assign")}</div>
        </div>
      </div>
      {data.teamId || !!data.operatorId ? (
        <Flex flexWrap="wrap" className={s.wrapper}>
          {!!data.teamId && (
            <Flex w="100%">
              <Text maxW="43%" color="darkGrey">
                {t("Assigned Team")}:&nbsp;
              </Text>
              <Text maxW="60%" color="midDeepBlue !important" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {data.teamName}
              </Text>
            </Flex>
          )}
          {!!data.operatorId && (
            <Flex w="100%" color="midDeepBlue">
              <Text maxW="47%" color="darkGrey">
                {t("Assigned Operator")}:&nbsp;
              </Text>
              <Text maxW="60%" color="midDeepBlue !important" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {data.operatorName}
              </Text>
            </Flex>
          )}
        </Flex>
      ) : (
        <div className={s.noCondition}>
          <h4>{t("Click to select Assignees")}</h4>
        </div>
      )}
    </Flex>
  );
};
