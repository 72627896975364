import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../../common/state/store";
import { ProfanityFilterModel } from "./ProfanityFilterModel";
import { PROFANITY_FILTER_DEFAULT } from "./components/ProfanityFilterSettings/consts";

export interface ProfanityFilterState {
  isOpenProfanityFilter: boolean;
  profanityFilter: ProfanityFilterModel;
  isToggleLoading: boolean;
  isLoadData: boolean;
  isCustomProfanitiesValid: boolean;
}

const initialState: ProfanityFilterState = {
  isOpenProfanityFilter: false,
  profanityFilter: PROFANITY_FILTER_DEFAULT,
  isToggleLoading: false,
  isLoadData: false,
  isCustomProfanitiesValid: true,
};

export const profanityFilterSlice = createSlice({
  name: "profanityFilterSlice",
  initialState,
  reducers: {
    setIsOpenProfanityFilter: (state, action: PayloadAction<boolean>) => {
      state.isOpenProfanityFilter = action.payload;
    },
    setProfanityFilter: (state, action: PayloadAction<ProfanityFilterModel>) => {
      state.profanityFilter = action.payload || PROFANITY_FILTER_DEFAULT;
      state.isLoadData = true;
    },
    toggleIsEnabled: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
      };
    },
    toggleIsEnabledCompleted: (state, action: PayloadAction<boolean>) => {
      state.profanityFilter.isEnabled = action.payload;
    },
    saveChanges: (state, action: PayloadAction<ProfanityFilterModel>) => {
      return {
        ...state,
      };
    },
    saveChangesCompleted: (state, action: PayloadAction<ProfanityFilterModel>) => {
      state.profanityFilter = action.payload;
    },
    changeIsLoadData: (state, action: PayloadAction<boolean>) => {
      state.isLoadData = action.payload;
    },
    setCustomProfanitiesValidity: (state, action: PayloadAction<boolean>) => {
      state.isCustomProfanitiesValid = action.payload;
    },
  },
});

export const {
  setIsOpenProfanityFilter,
  setProfanityFilter,
  toggleIsEnabled,
  saveChanges,
  changeIsLoadData,
  setCustomProfanitiesValidity,
} = profanityFilterSlice.actions;

export const selectIsProfanityFilterEnabled = (state: RootState) => state.app.profanityFilterState.profanityFilter.isEnabled;
export const selectIsOpenProfanityFilter = (state: RootState) => state.app.profanityFilterState.isOpenProfanityFilter;
export const selectProfanityFilter = (state: RootState) => state.app.profanityFilterState.profanityFilter;
export const selectProfanityFilterisLoadedData = (state: RootState) => state.app.profanityFilterState.isLoadData;
export const selectIsCustomProfanitiesValid = (state: RootState) => state.app.profanityFilterState.isCustomProfanitiesValid;

export default profanityFilterSlice.reducer;
