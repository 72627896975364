import s from "./DocumentNodePopup.module.scss";
import sm from "../NodeEditPopup.module.scss";
import FileIcon from "../../../../../assets/icons/fileIcon.svg?react";
import {
  CustomVariablesModel,
  SendDocumentFlowActionModel,
  NodeModel,
  FlowModel,
  NodeValidationProp,
  FlowActionModel,
} from "../../../FlowBuilderModel";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { editNode, saveNode, selectCustomVariables, selectNodeEditorState } from "../../../FlowBuilderSlice";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  FormControl,
  DrawerFooter,
  Icon,
  Flex,
  Text,
  Button,
} from "@chakra-ui/react";
import { ButtonsList } from "../ButtonsList";
import { MessageTextEditor } from "../MessageTextEditor/MessageTextEditor";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { NodeEditorFooter } from "../NodeEditorFooter/NodeEditorFooter";
import { useTranslation } from "react-i18next";
import { ButtonModel, ReplyKeyboardButtonModel } from "../../../../../common/AppButtonsModel";
import { Fallback } from "../Fallback";
import { getButtonsWithExactType } from "../../../utils";
import { DragAndDropContainer, handleDataChange, handleDrag } from "../DragAndDropContainer";
import { useState } from "react";

interface Props {
  addButton: (button: ButtonModel | ReplyKeyboardButtonModel) => void;
  deleteButton: (buttonId: string) => void;
  setButtons: (buttons: ButtonModel[][] | ReplyKeyboardButtonModel[][]) => void;
  addCustomVariableText: (variable: CustomVariablesModel, variableMaxLength: number) => void;
  editButton: (button: ButtonModel | ReplyKeyboardButtonModel) => void;
  dataChange: (flowAction: FlowActionModel) => void;
  flow: FlowModel;
  node: NodeModel;
  validate: (flowAction: FlowActionModel) => void;
  validationInfo: NodeValidationProp;
}

export const DocumentNodePopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const variables = useAppSelector(selectCustomVariables);
  const dispatch = useAppDispatch();
  const currentNode = useAppSelector(selectNodeEditorState);
  const [isDragging, setDragging] = useState(false);

  const flowAction = props.node.flowAction as SendDocumentFlowActionModel;

  const fieldTypeExt = ".gif,.zip,.pdf,.svg";

  if (!currentNode) {
    return <></>;
  }
  const fileSizeMB = 50;
  const fileSizeB = fileSizeMB * 1024 * 1024;

  const onDataChange = (text: string, file?: File) => {
    handleDataChange({ file, dispatch, currentNode, fieldTypeExt, fileSizeB });

    const newData: SendDocumentFlowActionModel = {
      ...flowAction,
      text,
    };
    props.dataChange(newData);
    dispatch(
      editNode({
        ...currentNode,
        flowAction: newData,
      }),
    );
  };

  const handleFallbackChange = (text: string) => {
    const newFlowAction = { ...flowAction, fallbackMessage: text };
    dispatch(
      editNode({
        ...currentNode,
        flowAction: newFlowAction,
      }),
    );
  };

  const flowActionDataChange = (flowAction: SendDocumentFlowActionModel) => {
    dispatch(
      editNode({
        ...currentNode,
        flowAction: flowAction,
      }),
    );
  };

  const onCloseValidate = () => {
    dispatch(
      saveNode({
        flow: props.flow,
        node: props.node,
      }),
    );
  };

  return (
    <Drawer isOpen={true} placement="right" onClose={onCloseValidate} variant="dominoDrawer">
      <DrawerContent>
        <Loading scope="nodeValidate" />
        <DrawerHeader>{t("Document Message")}</DrawerHeader>
        <DrawerBody paddingInline="7px">
          <div className={s.popupWrapper}>
            <div className={s.popup}>
              <div className={s.wrapper}>
                <div onDragEnter={e => handleDrag(e, setDragging)} className={s.popupItem}>
                  <FormControl className={`${sm.itemFileWrp} ${(flowAction.file || flowAction.fileInfo) && sm.itemFileWrpSolid}`}>
                    <div className={`${s.popupItem} ${s.documentBlock}`}>
                      <Loading scope={`postFileEditor${props.node.id}`} />
                      <label className={s.input}>
                        <Icon as={FileIcon} boxSize="28px" mb="8px" />
                        {flowAction.fileInfo ? (
                          <div data-pw="documentInput" className={sm.fileName}>
                            {flowAction.fileInfo.name}
                          </div>
                        ) : flowAction.file ? (
                          <div data-pw="documentFile" className={sm.fileName}>
                            {flowAction.file.name}
                          </div>
                        ) : (
                          <Flex data-pw="uploadeDocument" textAlign="center" direction="column" className={sm.fileName}>
                            <Text>{t("editNodePopup.Upload Document")}</Text>
                            <Text variant="small">{t("editNodePopup.gif, zip, pdf, svg up to 20 mb")}</Text>
                          </Flex>
                        )}
                        <input
                          onChange={e => {
                            if (e.target.files) {
                              onDataChange(flowAction.text ?? "", e.target.files[0]);
                            }
                          }}
                          type="file"
                          accept={fieldTypeExt}
                        />
                      </label>
                      {isDragging && !flowAction.fileId && (
                        <DragAndDropContainer
                          handleDataChange={{ dispatch, currentNode, fieldTypeExt, fileSizeB }}
                          setDragging={setDragging}
                        />
                      )}
                    </div>
                  </FormControl>
                  <MessageTextEditor
                    variables={variables ?? []}
                    text={flowAction.text ?? ""}
                    maxLength={500}
                    onChange={t => onDataChange(t, flowAction.file)}
                    validate={props.validate}
                    flowAction={flowAction}
                    addCustomVariableText={props.addCustomVariableText}
                    borderRadiusTop="0"
                    maxHeightWithoutScroll={500}
                  />
                  <ButtonsList
                    node={props.node}
                    buttons={
                      (getButtonsWithExactType(flowAction?.replyMarkup, flowAction) as
                        | ButtonModel[][]
                        | ReplyKeyboardButtonModel[][]) ?? []
                    }
                    flowAction={flowAction}
                    setButtons={props.setButtons}
                    addButton={props.addButton}
                    deleteButton={props.deleteButton}
                    editButton={props.editButton}
                  />
                  <Fallback
                    validate={props.validate}
                    flowAction={flowAction}
                    dataChange={flowActionDataChange}
                    maxLength={500}
                    text={""}
                    onChange={handleFallbackChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </DrawerBody>
        {props.validationInfo.isError && (
          <DrawerFooter margin={"8px"} padding={"0"}>
            {props.validationInfo.isError && <NodeEditorFooter validationMessage={props.validationInfo.errors[0].message} />}
          </DrawerFooter>
        )}
        <Button variant="dominoViolet" w={"auto"} margin={"16px"} onClick={() => onCloseValidate()} data-pw="apply-button">
          {t("Apply")}
        </Button>
      </DrawerContent>
    </Drawer>
  );
};
