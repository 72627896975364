import {
  Flex,
  Text,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import TextIcon from "../../../../../../assets/icons/textIcon.svg?react";
import ImageIcon from "../../../../../../assets/icons/imageChatIcon.svg?react";
import DocumentIcon from "../../../../../../assets/icons/fileIcon.svg?react";
import HTTPIcon from "../../../../../../assets/icons/globeIcon.svg?react";
import VideoIcon from "../../../../../../assets/icons/videoIcon.svg?react";
import AudioIcon from "../../../../../../assets/icons/audioIcon.svg?react";
import ConditionIcon from "../../../../../../assets/icons/conditionIcon.svg?react";
import SystemActionsIcon from "../../../../../../assets/icons/thunderIcon.svg?react";
import DelayIcon from "../../../../../../assets/icons/clockIcon.svg?react";
import QuestionIcon from "../../../../../../assets/icons/questionNodeIcon.svg?react";
import AssignIcon from "../../../../../../assets/icons/assignIcon.svg?react";
import StartFlowIcon from "../../../../../../assets/icons/startFlowIcon.svg?react";
import CalendarIcon from "../../../../../../assets/icons/CalendarNodeIcon.svg?react";
import PaymentIcon from "../../../../../../assets/icons/PaymentNodeIcon.svg?react";
import CatalogIcon from "../../../../../../assets/icons/CatalogNodeIcon.svg?react";
import plusIconTile from "../../../../../../assets/icons/plusIconTile.svg?react";
import StarPlan from "../../../../../../assets/icons/starPlan.svg?react";

import s from "./CreationMenuNodeButton.module.scss";
import { FlowActionType, NodeType } from "../../../../FlowBuilderModel";
import { getTariffPlanAccessability } from "../../../../../../common/tariffPlan/TariffPlanUtil";
import { TariffPlanFeatureTypeEnum } from "../../../../../../common/AppEnums";
import { TariffPlanRedirectButton } from "../../../../../../common/tariffPlan/TariffPlanRedirectButton";
interface Props {
  title: string;
  addNode: (type: NodeType, typeDiscriminator: FlowActionType) => void;
}

export default function CreationMenuNodeButton({ title, addNode }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;

  const changeSettings = () => {
    switch (title) {
      case "Text":
        return { type: FlowActionType.SendTextFlowAction, icon: TextIcon };
      case "Question":
        return { type: FlowActionType.QuestionFlowAction, icon: QuestionIcon };
      case "Image":
        return { type: FlowActionType.SendPhotoFlowAction, icon: ImageIcon };
      case "Document":
        return { type: FlowActionType.SendDocumentFlowAction, icon: DocumentIcon };
      case "Audio":
        return { type: FlowActionType.SendAudioFlowAction, icon: AudioIcon };
      case "Video":
        return { type: FlowActionType.SendVideoFlowAction, icon: VideoIcon };
      case "HTTP request":
        return { type: FlowActionType.CurlFlowAction, icon: HTTPIcon };
      case "Condition":
        return { type: FlowActionType.ConditionFlowAction, icon: ConditionIcon };
      case "System Action":
        return { type: FlowActionType.SystemActionFlowAction, icon: SystemActionsIcon };
      case "Delay":
        return { type: FlowActionType.DelayFlowAction, icon: DelayIcon };
      case "Assign":
        return { type: FlowActionType.AssignFlowAction, icon: AssignIcon };
      case "Start Flow":
        return { type: FlowActionType.StartSubFlowAction, icon: StartFlowIcon };
      case "Calendar":
        return { type: FlowActionType.SendAppointmentFlowAction, icon: CalendarIcon };
      case "Payment":
        return { type: FlowActionType.SendPaymentFlowAction, icon: PaymentIcon };
      case "Catalog":
        return { type: FlowActionType.ShoppingCartFlowAction, icon: CatalogIcon };
      default:
        break;
    }
  };

  const typeIsAccessability = () => {
    switch (title) {
      case "HTTP request":
        return TariffPlanFeatureTypeEnum.NodeHttpRequest;
      case "Delay":
        return TariffPlanFeatureTypeEnum.NodeDelay;
      case "Start Flow":
        return TariffPlanFeatureTypeEnum.NodeStartFlow;
      default:
        break;
    }
  };
  const typeAccessability = typeIsAccessability();
  const isAccessability = typeAccessability ? getTariffPlanAccessability(typeAccessability) : { IsAvailable: true };

  return (
    <>
      {!isAccessability["IsAvailable"] ? (
        <Popover placement="right" trigger={"hover"}>
          {({ onClose }) => (
            <>
              <PopoverTrigger>
                <Flex className={s.accordionNodeButton} align="center">
                  <Flex className={s.iconsFlex} minW="44px" w="44px" gap="32px" overflowX="hidden">
                    <Icon className={s.iconLeft} boxSize="20px" as={plusIconTile} />
                    <Icon className={s.iconRight} boxSize="20px" as={changeSettings()?.icon} />
                  </Flex>
                  <Text variant="medium" noOfLines={1} color="#3E5E95">
                    {t(title)}
                  </Text>
                  <Flex justify="end" pr="8px" flex={1}>
                    <Icon className={s.starSVG} boxSize="20px" as={StarPlan} />
                  </Flex>
                </Flex>
              </PopoverTrigger>
              <Portal>
                <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                  <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                  <Flex alignItems="center" justifyContent="center" direction="column">
                    <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                      {tp("Advanced feature")}
                    </PopoverHeader>
                    <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                      {tp("Please upgrade your plan to invite more Operators")}
                    </PopoverBody>
                    <TariffPlanRedirectButton onClose={onClose} />
                  </Flex>
                </PopoverContent>
              </Portal>
            </>
          )}
        </Popover>
      ) : (
        <Flex
          onClick={() => {
            const type = changeSettings()?.type;
            if (type) {
              addNode(NodeType.Action, type);
            }
          }}
          className={s.accordionNodeButton}
          align="center"
        >
          <Flex className={s.iconsFlex} minW="44px" w="44px" gap="32px" overflowX="hidden">
            <Icon className={s.iconLeft} boxSize="20px" as={plusIconTile} />
            <Icon className={s.iconRight} boxSize="20px" as={changeSettings()?.icon} />
          </Flex>
          <Text variant="medium" noOfLines={1} color="#3E5E95">
            {t(title)}
          </Text>
        </Flex>
      )}
    </>
  );
}
