import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import s from "./MessageDeleteModal.module.scss";

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  isOpen: boolean;
}
export const MessageDeleteModal = ({ isOpen, onClose, onConfirm }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "conversation" });
  const { t: commonWords } = useTranslation("translation", { keyPrefix: "commonWords" });

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className={s.modalContent}>
        <ModalCloseButton
          data-pw="modal-close"
          color={"darkGrey"}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
          className={s.modalCloseButton}
        />
        <ModalBody className={s.modalBody}>
          <Flex className={s.modalBodyText}>
            <Text variant="h1" color="newBlack">
              {t("Delete this message")}
            </Text>
            <Text variant="medium" color="darkGrey">
              {t("Confirm your action to delete the message")}
            </Text>
          </Flex>
        </ModalBody>
        <ModalFooter className={s.modalFooter}>
          <Flex className={s.buttons}>
            <Button variant="dominoOutlineViolet" onClick={onClose}>
              {commonWords("Cancel")}
            </Button>
            <Button variant="dominoPrimaryRed" onClick={onConfirm}>
              {commonWords("Delete")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
