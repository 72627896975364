import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Flex,
  Text,
  ModalFooter,
  Button,
  ModalHeader,
  Input,
  Box,
} from "@chakra-ui/react";
import CrossIcon from "../../../../../assets/icons/cross.svg?react";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../common/state/store";
import { addBotUtmTag } from "../../../BotSlice";
import { BotModel, BotUtmTagCreateModel } from "../../../BotModel";
import { t } from "i18next";
import ClipboardText from "../../../../../UI/atoms/clipboardText/ClipboardText";
import { OverflowEllipsesText } from "../../../../../UI/atoms/textOverflow/OverflowEllipsesText";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  botInfo: BotModel | undefined;
  botId: string;
}

export default function AddUTMModal({ isOpen, onClose, botInfo, botId }: Props) {
  const [identifier, setIdentifier] = useState("");
  const [nameError, setNameError] = useState("");
  const dispatch = useAppDispatch();

  const validateName = (value: string) => {
    const maxWidth = 64;
    const allowedSymbols = /^[A-Za-z0-9_]+$/;
    if (!value.replace(/\s/g, "").length) {
      setNameError("Identifier Field is Required");
      return false;
    }
    if (value.length > maxWidth) {
      setNameError("Identifier must be less than 25 characters long");
      return false;
    }
    if (!value.match(allowedSymbols)) {
      setNameError("Identifier can contain only latin letters, numbers and underscores");
      return false;
    }
    setNameError("");
    return true;
  };

  const fieldValidation = () => {
    const nameValidation = validateName(identifier);
    return !!nameValidation;
  };

  const handleInputName = (e: { target: HTMLInputElement }) => {
    setIdentifier(e.target.value);
  };

  const handleCancelCreateNewUTMTag = () => {
    setIdentifier("");
    onClose();
  };

  const onCreateUTMTag = () => {
    if (!fieldValidation()) {
      return;
    }
    const data: BotUtmTagCreateModel = {
      data: identifier,
      botId: botId,
    };
    onClose();
    dispatch(addBotUtmTag({ botId: botId, data: data }));
  };

  useEffect(() => {
    if (!isOpen) {
      setIdentifier("");
    }
  }, [isOpen]);

  const utmLink = `https://t.me/${botInfo?.username}?start=${identifier}`;

  return (
    <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false} onCloseComplete={() => setNameError("")}>
      <ModalOverlay />
      <ModalContent minW={"300px"} p={{ base: "24px", md: "32px" }} w={"95%"} maxW={"460px"} borderRadius="8px" mx={"16px"}>
        <ModalCloseButton borderRadius={"50%"} color={"darkGrey"} _hover={{ bg: "defaultGrey", color: "mainPurple" }}>
          <CrossIcon />
        </ModalCloseButton>
        <ModalHeader p={0} mb="12px" fontSize="24px" lineHeight="32px" fontWeight="500">
          {t("bot.Create UTM tag")}
        </ModalHeader>
        <ModalBody fontSize="15px" p="0">
          <Text mb="24px" lineHeight="20px">
            {t("bot.Create UTM description")}
          </Text>
          <Flex gap="8px" flexDir="column">
            <Text>{t("bot.Identifier")}</Text>
            <Flex gap={1} flexDir={"column"}>
              <Input
                fontSize="15px"
                placeholder={t("bot.Enter text")}
                value={identifier}
                onChange={handleInputName}
                maxLength={64}
                _placeholder={{ fontSize: "15px" }}
                _focus={{ fontSize: "15px" }}
                borderColor={nameError ? "#f92222" : "#DAE1FB"}
              />
              {nameError && (
                <Box color="#f92222">
                  <Text minH="16px" variant="small">
                    {t(`bot.errors.${nameError}`)}
                  </Text>
                </Box>
              )}
            </Flex>
          </Flex>

          <Flex gap="8px" mt="16px" flexDir="column">
            <Text>{t("bot.Result")}</Text>
            <Flex
              p="4px 0px 4px 16px"
              bgColor={"#F1F4F9"}
              border={"1px solid #DAE1FB"}
              fontSize="15px"
              borderRadius="8px"
              whiteSpace="nowrap"
              overflow="auto"
              textOverflow={"ellipsis"}
            >
              <OverflowEllipsesText
                text={utmLink}
                content={<ClipboardText elemIsCopy={<Text>{utmLink}</Text>} textIsCopy={utmLink} color="#3e5e95" />}
              />
            </Flex>
          </Flex>
        </ModalBody>

        <ModalFooter p="24px 0 0 0">
          <Flex w="100%" justifyContent="space-between">
            <Button
              variant="dominoOutlineViolet"
              onClick={() => {
                handleCancelCreateNewUTMTag();
              }}
            >
              {t("bot.menuCommands.Cancel")}
            </Button>
            <Button
              variant="dominoViolet"
              onClick={() => {
                onCreateUTMTag();
              }}
            >
              {t("bot.menuCommands.Create")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
