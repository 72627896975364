export const ChatEvent = {
  ChatFilter: "ChatFilter",
  ChatNewConversation: "ChatNewConversation",
  ChatConversationOpen: "ChatConversationOpen",
  ChatBlock: "ChatBlock",
  ChatBlockCancel: "ChatBlockCancel",
  ChatBlockComplete: "ChatBlockComplete",
  ChatUnBlockComplete: "ChatUnBlockComplete",
  ChatTeamAssigned: "ChatTeamAssigned",
  ChatOperatorAssigned: "ChatOperatorAssigned",
};

export const ChatMessageEvents = {
  ChatMassageSent: "ChatMassageSent",
  ChatMassageNoteSent: "ChatMassageNoteSent",
  ChatMassageMediaSent: "ChatMassageMediaSent",
  ChatMassageAttachmentSent: "ChatMassageAttachmentSent",
};

export const ChatSettings = {
  ChatStopConversation: "ChatStopConversation",
  ChatStopFlow: "ChatStopFlow",
  ChatMedia: "ChatMedia",
  ChatTgID: "ChatTgID",
  ChatTgLinkForward: "ChatTgLinkForward",
  ChatFlowLink: "ChatFlowLink",
  ChatFieldFill: "ChatFieldFill",
};
