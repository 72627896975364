import React from "react";
import {
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import StarPlan from "../../../assets/icons/starPlan.svg?react";
import { TariffPlanRedirectButton } from "../../../common/tariffPlan/TariffPlanRedirectButton";
import s from "./LimitStarPlanButton.module.scss";

interface StarPlanButtonProps {
  buttonName: string;
  keyPrefix: string;
  popoverBodyDescription: string;
  dataPw?: string;
}

export const LimitStarPlanButton: React.FC<StarPlanButtonProps> = ({ popoverBodyDescription, buttonName, keyPrefix, dataPw }) => {
  const { t } = useTranslation("translation", { keyPrefix: keyPrefix });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  return (
    <Popover trigger={"hover"}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button gap="8px" size="sm" variant="dominoViolet" data-pw={dataPw}>
              <Icon as={StarPlan} className={s.starPlan} boxSize="20px" />
              {t(buttonName)}
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent className={s.popoverContent}>
              <PopoverArrow className={s.popoverArrow} />
              <Flex className={s.popoverTextContainer}>
                <PopoverHeader className={s.popoverHeader}>{tp("Advanced feature")}</PopoverHeader>
                <PopoverBody className={s.popoverBody}>{tp(popoverBodyDescription)}</PopoverBody>
                <TariffPlanRedirectButton onClose={onClose} />
              </Flex>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
