import s from "./CurlNodePopup.module.scss";
import sm from "../NodeEditPopup.module.scss";
import {
  CurlFlowActionModel,
  CustomVariablesModel,
  FlowActionModel,
  FlowModel,
  NodeModel,
  NodeValidationProp,
} from "../../../FlowBuilderModel";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import {
  createCustomVariable,
  editNode,
  saveNode,
  selectCustomVariables,
  selectCustomVariablesWithoutSystem,
  selectNodeEditorState,
} from "../../../FlowBuilderSlice";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Box,
  Icon,
  Flex,
  Switch,
  Text,
  Input,
  Tooltip,
  Button,
} from "@chakra-ui/react";
import { MessageTextEditor } from "../MessageTextEditor/MessageTextEditor";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { NodeEditorFooter } from "../NodeEditorFooter/NodeEditorFooter";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import RefUrlIcon from "../../../../../assets/icons/refUrlIcon.svg?react";
import { CloseIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { maxLength } from "../../../../../common/validation/defaultValidators";
import { CustomVariableContextMenu } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenu";
import { CustomVariableContextMenuType } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import { insertStringIntoText } from "../../../../../common/utils/insertStringIntoText";
import { CurlEditorModal } from "./CurlEditorModal";
import { CustomVariableModel } from "../../../../../common/AppEnums";
import { CustomVariableType } from "../../../../modals/addVariable/AddVariableModel";

interface Props {
  flow: FlowModel;
  node: NodeModel;
  validate: (flowAction: FlowActionModel) => void;
  validationInfo: NodeValidationProp;
  onDataChange: (flowAction: CurlFlowActionModel) => void;
}

export const CurlNodePopup = ({ flow, node, validate, validationInfo, onDataChange }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const dispatch = useAppDispatch();
  const variables = useAppSelector(selectCustomVariables);
  const currentNode = useAppSelector(selectNodeEditorState);
  const customVariables = useAppSelector(selectCustomVariablesWithoutSystem);

  const [isCurlCommandActive, setCurlCommandActive] = useState(false);

  const flowAction = node.flowAction as CurlFlowActionModel;
  const canSaveResponse = flowAction.canSaveResponse;
  const textAreaMaxLength = 2000;
  const targetCustomVariables = flowAction.targetCustomVariables;

  const onCurlTextChange = (text: string) => {
    const newData: FlowActionModel = {
      ...node.flowAction,
      curlCommand: text,
    } as CurlFlowActionModel;

    validate(newData);
    if (currentNode) {
      dispatch(editNode({ ...currentNode, flowAction: newData }));
    }
  };

  const onCloseValidate = () => {
    dispatch(saveNode({ flow, node }));
  };

  const addCustomVariableText = (variable: CustomVariablesModel, _: number, cursorPosition?: number) => {
    const text = insertStringIntoText(
      flowAction.curlCommand ?? "",
      "@{" + variable.scope + ":" + variable.key + "}",
      cursorPosition,
    );
    const lengthError = maxLength(textAreaMaxLength)(text);
    if (!lengthError) {
      onCurlTextChange(text);
    }
  };

  const onSaveResponseChange = () => {
    if (currentNode) {
      const newData: FlowActionModel = {
        ...flowAction,
        canSaveResponse: !flowAction.canSaveResponse,
      } as CurlFlowActionModel;
      dispatch(editNode({ ...currentNode, flowAction: newData }));
    }
  };

  const setTargetCustomVariables = (
    targetCustomVariables: {
      contentPath: string;
      targetCustomVariableId: string | null;
    }[],
  ) => {
    onDataChange({
      ...flowAction,
      targetCustomVariables,
    });
  };

  const chooseCustomVariable = (id: string, targetIndex: number) => {
    if (targetCustomVariables) {
      setTargetCustomVariables(
        targetCustomVariables.map((el, index) => (index === targetIndex ? { ...el, targetCustomVariableId: id } : el)),
      );
    }
  };

  const setContentPathText = (text: string, targetIndex: number) => {
    if (targetCustomVariables) {
      setTargetCustomVariables(
        targetCustomVariables.map((el, index) => (index === targetIndex ? { ...el, contentPath: text } : el)),
      );
    }
  };

  const addNewTargetCustomVariable = () => {
    if (targetCustomVariables) {
      setTargetCustomVariables([...targetCustomVariables, { contentPath: "", targetCustomVariableId: null }]);
    }
  };

  const deleteTargetCustomVariable = (targetIndex: number) => {
    if (targetCustomVariables) {
      setTargetCustomVariables(targetCustomVariables.filter((el, index) => index !== targetIndex));
    }
  };

  const onCreateVariable = (nodeEditorInfo?: unknown) => (newCustomVariable?: CustomVariableModel) => {
    if (newCustomVariable) {
      dispatch(createCustomVariable({ variable: newCustomVariable, nodeEditorInfo: nodeEditorInfo }));
    }
  };

  if (!currentNode) {
    return <></>;
  }

  return (
    <Drawer isOpen={true} placement="right" onClose={onCloseValidate} variant="dominoDrawer">
      <DrawerContent>
        <Loading scope="nodeValidate" />
        <DrawerHeader>{t("HTTP Message")}</DrawerHeader>
        <DrawerBody onClick={() => setCurlCommandActive(false)}>
          <Box
            className={s.popupItem}
            onClick={e => {
              e.stopPropagation();
              setCurlCommandActive(true);
            }}
          >
            <Box width="100%">
              <Flex mb="24px">
                {!flowAction.curlCommand && !isCurlCommandActive ? (
                  <div className={`${sm.itemFileWrp} ${sm.oneItem}`}>
                    <div className={`${s.popupItem} ${s.documentBlock}`}>
                      <div className={s.input}>
                        <Icon as={RefUrlIcon} boxSize="28px" mb="16px" />
                        <div className={sm.fileName}>{t("editNodePopup.cURL Request")}</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <MessageTextEditor
                    text={flowAction.curlCommand ?? ""}
                    maxLength={textAreaMaxLength}
                    variables={variables?.filter(v => v.type !== CustomVariableType.Order) ?? []}
                    onChange={e => onCurlTextChange(e)}
                    placeholder={`${t("editNodePopup.Enter curl here")} ...`}
                    validate={validate}
                    flowAction={flowAction}
                    borderRadiusTop="8px"
                    borderRadiusBottom="8px"
                    isEmojiDisabled={true}
                    maxHeightWithoutScroll={500}
                    addCustomVariableText={addCustomVariableText}
                    customButton={
                      <CurlEditorModal
                        curlValue={flowAction.curlCommand ?? ""}
                        setCurlValue={onCurlTextChange}
                        variables={variables ?? []}
                        maxLength={2000}
                      />
                    }
                  />
                )}
              </Flex>
              <Flex direction="column">
                <Flex mb="16px">
                  <Text variant="large">{t("Save response")}</Text>
                  <Switch
                    ml="12px"
                    isChecked={!!flowAction.canSaveResponse}
                    onChange={onSaveResponseChange}
                    colorScheme="green"
                  />
                </Flex>
                {targetCustomVariables?.map((targetCustomVariable, index) => (
                  <Box className={`${s.targetCustomVariableContainer} ${!canSaveResponse ? s.disabled : ""}`} key={index}>
                    <Flex gap="8px" align="center">
                      <Text variant="large">{t("JSON path")}</Text>
                      <Tooltip
                        variant="dominoLight"
                        placement="right"
                        label={t("If JSON path is empty, all data from the response will be saved in the variable")}
                        fontSize="md"
                      >
                        <QuestionOutlineIcon boxSize={"16px"} color="blueLink" />
                      </Tooltip>
                    </Flex>
                    <Input
                      m="8px 0 16px"
                      value={targetCustomVariable.contentPath}
                      onChange={e => setContentPathText(e.target.value, index)}
                      placeholder={t("Enter the path") ?? ""}
                      isDisabled={!canSaveResponse}
                    />
                    <Text variant="large">{t("Custom field")}</Text>
                    <CustomVariableContextMenu
                      isDisabled={!canSaveResponse}
                      type={CustomVariableContextMenuType.Dropdown}
                      excludeVariableType={CustomVariableType.Order}
                      selectCustomVariable={variable => chooseCustomVariable(variable?.id ?? "", index)}
                      addVariable={onCreateVariable(index)}
                      variables={variables?.filter(vars => vars.type !== CustomVariableType.Order) ?? []}
                      chosenVariable={customVariables?.find(el => el.id === targetCustomVariable.targetCustomVariableId)}
                      clearVariable={() =>
                        setTargetCustomVariables([
                          { contentPath: targetCustomVariables[0].contentPath, targetCustomVariableId: null },
                        ])
                      }
                    />
                    <Box position="relative">
                      {targetCustomVariables.length > 1 && canSaveResponse && (
                        <Box
                          className={s.closeIconWrp}
                          onClick={() => {
                            deleteTargetCustomVariable(index);
                          }}
                        >
                          <CloseIcon color="white" boxSize="9px" onMouseDown={e => e.preventDefault()} />
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
                <Button variant="dominoDashedViolet" isDisabled={!canSaveResponse} onClick={addNewTargetCustomVariable}>
                  + {t("Field")}
                </Button>
              </Flex>
            </Box>
          </Box>
        </DrawerBody>
        {validationInfo.isError && (
          <DrawerFooter margin={"8px 8px 0 8px"} padding={"0"}>
            <NodeEditorFooter validationMessage={validationInfo.errors[0].message} />
          </DrawerFooter>
        )}
        <Button
          variant="dominoViolet"
          w={"auto"}
          margin={"24px 16px 16px 16px"}
          onClick={() => onCloseValidate()}
          data-pw="apply-button"
        >
          {t("Apply")}
        </Button>
      </DrawerContent>
    </Drawer>
  );
};
