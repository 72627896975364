import { Box, Flex, Text, Textarea } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProfanityFilterContext } from "../../ProfanityFilterContext";
import { useNowWidthView } from "../../../../../../../../../../layout/LayoutHelper/ResolutionHooks";
import { MINIMAL_SCREEN_SIZE_FOR_DEFAULT_LAYOUT } from "../../consts";
import { useAppDispatch } from "../../../../../../../../../../../common/state/store";
import { setCustomProfanitiesValidity } from "../../../../ProfanityFilterSlice";

interface Props {
  customProfanities: string;
  setCustomProfanities: Dispatch<SetStateAction<string>>;
}

export const ProfanityFilterCustomProfanities = ({ customProfanities, setCustomProfanities }: Props) => {
  const t = useTranslation("translation", {
    keyPrefix: "groupChat.groupChatInfo.ProfanityFilterPage",
  }).t;
  const nowWidth = useNowWidthView();

  //const handlers = customProfanitiesHandlers({ customProfanities, setCustomProfanities });

  const context = useContext(ProfanityFilterContext);
  const columnGap = (context ? context.restrictionInfoNodeWidth - 131 - 178 : 16) + "px";
  const dispatch = useAppDispatch();

  const validateText = (text: string) => {
    const regex = /^([a-zA-Zа-яА-ЯёЁ]+(,\s[a-zA-Zа-яА-ЯёЁ]+)*)?$/;
    return regex.test(text);
  };

  const isInvalid = !validateText(customProfanities);

  const onChangeTextArea = (value: string) => {
    setCustomProfanities(value);
    const isValid = validateText(value);
    dispatch(setCustomProfanitiesValidity(isValid));
  };

  return (
    <Flex
      columnGap={columnGap}
      rowGap="16px"
      justifyContent="space-between"
      direction={nowWidth > MINIMAL_SCREEN_SIZE_FOR_DEFAULT_LAYOUT ? "row" : "column"}
    >
      <Text variant="preLargeBold" color="newBlack" whiteSpace="nowrap" minW="131px">
        {t("Your word list")}
      </Text>
      <Flex direction="column" rowGap="8px" w="100%">
        <Textarea
          variant="dominoOutline"
          placeholder={t("Wordlist placeholder")}
          value={customProfanities}
          minH="76px"
          rows={3}
          errorBorderColor="red"
          isInvalid={isInvalid}
          onChange={e => onChangeTextArea(e.target.value)}
        />
        {isInvalid && (
          <Box color="#f92222">
            <Text minH="16px" variant="small">
              {t(`errors.Wordlist`)}
            </Text>
          </Box>
        )}
        <Text variant="small" color="darkGrey">
          {t("Most profanities have already been included in the database")}
        </Text>
      </Flex>
    </Flex>
  );
};
