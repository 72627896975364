/* eslint-disable react-hooks/exhaustive-deps */
import { KeyboardEvent, useEffect, useState } from "react";
import { Box, Text, Button, FormControl } from "@chakra-ui/react";
import { getFingerprint } from "react-fingerprint";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { getTokenFromStorage } from "../../../common/auth/AuthRepo";
import { useAppDispatch } from "../../../common/state/store";
import { getTokenRequested, getTokenByAuthCodeRequested } from "../LoginSlice";
import EyeIcon from "../../../assets/icons/eyeIcon.svg?react";
import EyeClosedIcon from "../../../assets/icons/eyeClosedIcon.svg?react";
import { AppSettings } from "../../../common/AppSettings";
import { NotificationModel } from "../../../common/notifications/NotificationModel";
import { notify } from "../../../common/notifications/NotificationSlice";
import { InputPlacholderLabel } from "../../../UI/molecules/inputPlacholderLabel/InputPlacholderLabel";
import { ButtonConfirmTypes } from "../LoginModel";
import { signUp, setEmail } from "../../signUp/SignUpSlice";
import { validateIdentity, validatePassword } from "./authValidation";
import { useGetLoadingState } from "../../../common/loading/hooks/useGetLoadingState";
import { Loading } from "../../../common/loading/LoadingStateContainer";
import GTM from "../../../common/ga/GAEventTracker";
import s from "./AuthFormContainer.module.scss";
import { EventCategories } from "../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

declare const appSettings: AppSettings;
interface Props {
  identity: string | null;
  password: string | null;
  setIdentity: (value: string | null) => void;
  setPassword: (value: string | null) => void;
  authButtonTitle: string;
  dataPwConfirmButton: string;
  placeholderText?: string;
  validationErrorText?: string;
  children?: React.ReactNode;
}
export const AuthFormContainer = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "auth" });
  const trackEvent = GTM(EventCategories.Signin);
  const dispatch = useAppDispatch();

  const isLoading = useGetLoadingState("signIn/signUp");
  const isLoadingTelegram = useGetLoadingState("signIn/signUp Telegram");
  const [show, setShow] = useState(false);
  const token = getTokenFromStorage();
  const [searchParams] = useSearchParams("");
  const inviteToken = searchParams.get("inviteToken");
  const authCode = searchParams.get("authCode");

  const redirecturl = searchParams.get("redirect_url");
  const glParams = searchParams.get("_gl");

  const orgIdInSearchParams = new URLSearchParams(window.location.search).get("organizationId");

  const isIdentityError = validateIdentity(props.dataPwConfirmButton, props.identity);
  const isPasswordError = validatePassword(props.password);
  const isSubmitDisabled = !!(
    isIdentityError ||
    isPasswordError ||
    (!props.identity && props.password) ||
    (props.identity && !props.password)
  );

  const [fingerprint, setFingerprint] = useState<string | null>(null);

  const handleIconClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setShow(!show);
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimedUsername = e.target.value.trim();
    props.setIdentity(trimedUsername);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimedPassword = e.target.value.trim();
    props.setPassword(trimedPassword);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.authButtonTitle === "Sign In" ? trackEvent("Signin") : trackEvent("SignUp");

    if (isLoading) {
      return;
    }
    dispatch(setEmail(props.identity));

    if (!props.identity || !props.password) {
      const notification: NotificationModel = {
        message: t("Enter identity and password"),
        type: "error",
      };
      dispatch(notify(notification));
      return;
    }
    if (props.dataPwConfirmButton === ButtonConfirmTypes.signUp) {
      dispatch(
        signUp({
          identity: props.identity,
          password: props.password,
        }),
      );
      return;
    }

    if (props.identity && props.password) {
      dispatch(
        getTokenRequested({
          tokenModel: inviteToken
            ? { identity: props.identity, password: props.password, fingerprint, inviteToken }
            : {
                identity: props.identity,
                password: props.password,
                fingerprint,
              },
          redirectUrl:
            glParams !== null
              ? decodeURI(glParams)
              : redirecturl === null
              ? undefined
              : orgIdInSearchParams === null
              ? decodeURI(redirecturl)
              : decodeURI(`${redirecturl}?organizationId=${orgIdInSearchParams}`),
        }),
      );
    } else {
      const notification: NotificationModel = {
        message: t("Enter identity and password"),
        type: "error",
      };
      dispatch(notify(notification));
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && isSubmitDisabled) {
      e.preventDefault();
      return;
    }
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  useEffect(() => {
    if (token) {
      if (!redirecturl) {
      } else if (redirecturl && redirecturl === appSettings.billingPortalUrl) {
        if (orgIdInSearchParams) {
          window.location.replace(decodeURI(`${redirecturl}?organizationId=${orgIdInSearchParams}`));
        } else {
          window.location.replace(decodeURI(redirecturl));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getFingerprint();
      setFingerprint(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (authCode?.length) {
      dispatch(getTokenByAuthCodeRequested({ authCode: authCode, fingerprint: fingerprint }));
    }
  }, [authCode]);

  const rightIcon = props.password?.length ? (
    <Button className={s.passIcon} onClick={handleIconClick}>
      {show ? (
        <EyeClosedIcon className={s.eyeIcon} data-pw="hide-password" title={`${t("Hide password")}`} />
      ) : (
        <EyeIcon className={s.eyeIcon} data-pw="show-password" title={`${t("Show password")}`} />
      )}
    </Button>
  ) : (
    <></>
  );

  return (
    <FormControl isInvalid={isIdentityError || isPasswordError}>
      <Box>
        <InputPlacholderLabel
          type="text"
          name="username"
          placeholder={props.placeholderText}
          isDisabled={isLoading || isLoadingTelegram}
          value={props.identity ?? ""}
          onKeyDown={e => handleKeyDown(e)}
          onChange={handleUsernameChange}
          isError={isIdentityError}
          data-pw="identity"
          errorMessage={props.validationErrorText ? props.validationErrorText : t("Username Is Required")}
        />
        <InputPlacholderLabel
          type={show ? "text" : "password"}
          name="password"
          placeholder={t("Password")}
          isDisabled={isLoading || isLoadingTelegram}
          value={props.password ?? ""}
          onKeyDown={e => handleKeyDown(e)}
          onChange={handlePasswordChange}
          rightIcon={rightIcon}
          isError={isPasswordError}
          formHelperText={props.dataPwConfirmButton === ButtonConfirmTypes.signIn ? "" : t("Password validation")}
          data-pw="password"
        />
      </Box>
      {props.children}
      <Button
        isDisabled={isSubmitDisabled || isLoadingTelegram}
        variant="dominoViolet"
        className={s.authButton}
        onClick={handleSubmit}
        data-pw={props.dataPwConfirmButton}
        type="button"
      >
        {isLoading ? <Loading scope="signIn/signUp" /> : <span>{t(`${props.authButtonTitle}`)}</span>}
      </Button>
      <Box className={s.dividerBlock}>
        <Text className={s.dividerText}>
          <Text as="span" className={s.devidedTextLine}>
            {t("Or log in using")}
          </Text>
        </Text>
      </Box>
    </FormControl>
  );
};
