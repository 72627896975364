import { Box, Icon } from "@chakra-ui/react";
import ReplyIcon from "../../../../../../../../assets/icons/replyIcon.svg?react";
import s from "../../../../../../Conversation.module.scss";

interface Props {
  onReply: () => void;
  possibleToReply: boolean;
}

export const ReplyOnMessage = ({ onReply, possibleToReply }: Props) => {
  if (!possibleToReply) return <></>;

  return (
    <Box className={s.messageOptionsWrapper}>
      <Icon as={ReplyIcon} onClick={onReply}></Icon>
    </Box>
  );
};
