import { useEffect, useState } from "react";
import { Box, Text, Flex, Image, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Show } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import botImage from "../../assets/images/botImageStart.svg";
import BackArrowIcon from "../../assets/icons/backArrowIcon.svg?react";
import { AuthFormContainer } from "./authFormContainer/AuthFormContainer";
import { EmailConfirmationContainer } from "./emailConfirmationContainer/EmailConfirmationContainer";
import { AuthPrivacyTextContainer } from "./authPrivacyTextContainer/AuthPrivacyTextContainer";
import { ButtonConfirmTypes, TabNames } from "./LoginModel";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { selectEmail, selectSignUpSubmit, setEmail, setSignUpSubmit } from "../signUp/SignUpSlice";
import { TelegramAuthButton } from "../../common/TelegramAuth/TelegramAuthButton";
import { selectNotification } from "../../common/notifications/NotificationSlice";
import { useGetLoadingState } from "../../common/loading/hooks/useGetLoadingState";
import GTM from "../../common/ga/GAEventTracker";
import s from "./SignInSignUpContainer.module.scss";
import { EventCategories } from "../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

export const SignInSignUpContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "auth" });
  const isLoadingAuth = useGetLoadingState("signIn/signUp");
  const trackEvent = GTM(EventCategories.Signin);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const email = useAppSelector(selectEmail);
  const isSignUpSubmit = useAppSelector(selectSignUpSubmit);
  const notification = useAppSelector(selectNotification);
  const notificationType = notification?.type;
  const [identity, setIdentity] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [isSumbitRegistration, setIsSumbitRegistration] = useState(isSignUpSubmit);

  const searchParams = new URLSearchParams(location.search);
  const currentTab = searchParams.get("tab") || TabNames.signIn;
  const authPrivacyTextView = currentTab !== TabNames.signIn && isSumbitRegistration !== true;
  const redirectUrl = searchParams.get("redirect_url");

  const searchParamsObject: { [key: string]: string } = {};

  searchParams.forEach((value, name) => {
    searchParamsObject[name] = value;
  });

  useEffect(() => {
    if (isSignUpSubmit) {
      searchParamsObject["tab"] = TabNames.signUp;
      navigate({
        pathname: `/auth`,
        search: createSearchParams(searchParamsObject).toString(),
      });
    } else {
      searchParamsObject["tab"] = TabNames.signIn;
      navigate({
        pathname: `/auth`,
        search: createSearchParams(searchParamsObject).toString(),
      });
    }
    setIsSumbitRegistration(isSignUpSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignUpSubmit, navigate, redirectUrl]);

  useEffect(() => {
    if (notificationType === "error") {
      setIdentity(null);
      setPassword(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const handleTabChange = (index: number) => {
    dispatch(setEmail(""));
    setIdentity(null);
    setPassword(null);
    setIsSumbitRegistration(false);
    const tab = index === 0 ? TabNames.signIn : TabNames.signUp;
    searchParamsObject["tab"] = tab;
    navigate({
      pathname: `/auth`,
      search: createSearchParams(searchParamsObject).toString(),
    });
    tab === "signIn" ? trackEvent("SigninChoose") : trackEvent("SignUpChoose");
  };

  const handleBackClick = () => {
    dispatch(setSignUpSubmit(false));
    searchParamsObject["tab"] = TabNames.signIn;
    navigate({
      pathname: `/auth`,
      search: createSearchParams(searchParamsObject).toString(),
    });
    trackEvent("SigninChoose");
  };

  return (
    <Flex className={s.authContainer}>
      <Show breakpoint="(max-width: 999px)">
        <Text className={s.welcomeTitle}>{t("Welcome to Domino!")}</Text>
      </Show>
      <Box className={s.tabsContainer}>
        <Tabs
          isLazy={true}
          isFitted
          variant="enclosed"
          index={currentTab === TabNames.signIn ? 0 : currentTab === TabNames.signUp ? 1 : 1}
          onChange={handleTabChange}
        >
          <TabList>
            <Tab
              _hover={{ color: "black" }}
              _selected={{
                bg: "white",
                borderBottom: "none",
                color: "black",
              }}
              onClick={handleBackClick}
            >
              {!isSumbitRegistration ? (
                <span>{t("Sign In Tab")}</span>
              ) : (
                <Flex _hover={{ color: "black", fill: "black" }} className={s.backTab}>
                  <Icon as={BackArrowIcon} fill="currentColor" />
                  <Text>{t("Back")}</Text>
                </Flex>
              )}
            </Tab>
            <Tab _hover={{ color: "black" }} _selected={{ bg: "white", borderBottom: "none", color: "black" }}>
              {isSumbitRegistration ? <span>{t("Email Confirmation")}</span> : <span>{t("Sign Up Tab")}</span>}
            </Tab>
          </TabList>
          <TabPanels className={s.tabsPanels}>
            <TabPanel className={s.tabPanel}>
              <AuthFormContainer
                placeholderText={`${t("Email")}/${t("Username")}`}
                authButtonTitle="Sign In"
                dataPwConfirmButton={ButtonConfirmTypes.signIn}
                identity={identity}
                password={password}
                setIdentity={setIdentity}
                setPassword={setPassword}
              />
            </TabPanel>
            <TabPanel className={s.tabPanel}>
              {!isSignUpSubmit || !email ? (
                <AuthFormContainer
                  placeholderText={t("Email")}
                  authButtonTitle="Sign Up"
                  dataPwConfirmButton={ButtonConfirmTypes.signUp}
                  validationErrorText={t("Email Is Required")}
                  identity={identity}
                  password={password}
                  setIdentity={setIdentity}
                  setPassword={setPassword}
                />
              ) : (
                <EmailConfirmationContainer email={email} />
              )}
            </TabPanel>
            {!isSignUpSubmit && (
              <Box className={s.boxTelegram} data-pw="telegram-button">
                <TelegramAuthButton isDisabled={isLoadingAuth} loadingScope="signIn/signUp Telegram" />
              </Box>
            )}
          </TabPanels>
        </Tabs>
      </Box>
      <AuthPrivacyTextContainer authPrivacyTextView={authPrivacyTextView} />
      <Show breakpoint="(max-width: 999px)">
        <Image className={s.authBottomImage} src={botImage}></Image>
      </Show>
    </Flex>
  );
};
