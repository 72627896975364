import { ProfanityFilterInfo } from "./components/ProfanityFilterInfo/ProfanityFilterInfo";
import { ContentWrapper } from "./components/ui/ContentWrapper/ContentWrapper";
import { ProfanityFilterRules } from "./components/ProfanityFilterRules/ProfanityFilterRules";
import { ProfanityFilterProvider } from "./ProfanityFilterContext";
import { Flex } from "@chakra-ui/react";
import { ProfanityFilterButtons } from "./components/ProfanityFilterButtons/ProfanityFilterButtons";
import { selectProfanityFilter, selectProfanityFilterisLoadedData } from "../../ProfanityFilterSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../common/state/store";
import { useEffect, useState } from "react";
import { profanityFilterButtonsHandlers } from "./handlers";
import { isProfanityFilterEqual } from "./utils";
import { ProfanityFilterCustomProfanities } from "./components/ProfanityFilterCustomProfanities/ProfanityFilterCustomProfanities";
import { ProfanityFilterRule } from "../../ProfanityFilterModel";
import { CUSTOM_PROFANITIES_SPLITTER } from "./consts";

export const ProfanityFilterSettings = () => {
  const dispatch = useAppDispatch();
  const profanityFilter = useAppSelector(selectProfanityFilter);
  const isLoadData = useAppSelector(selectProfanityFilterisLoadedData);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [rulesCopy, setRulesCopy] = useState<ProfanityFilterRule[]>(structuredClone(profanityFilter.rules));
  const [isRulesWasChanged, setIsRulesWasChanged] = useState<boolean>(false);

  useEffect(() => {
    setIsRulesWasChanged(!isProfanityFilterEqual.rules(profanityFilter.rules, rulesCopy));
  }, [profanityFilter.rules, rulesCopy]);

  const [customProfanities, setCustomProfanities] = useState<string>(
    profanityFilter.customProfanities.join(CUSTOM_PROFANITIES_SPLITTER),
  );
  const [isCustomProfanitiesWasChanged, setIsCustomProfanitiesWasChanges] = useState<boolean>(false);
  useEffect(() => {
    setIsCustomProfanitiesWasChanges(
      !isProfanityFilterEqual.customProfanities(profanityFilter.customProfanities, customProfanities),
    );
  }, [profanityFilter.customProfanities, customProfanities]);

  const [wasReset, setWasReset] = useState<boolean>(false);

  const wasChanges = isRulesWasChanged || isCustomProfanitiesWasChanged || wasReset;

  useEffect(() => {
    if (!isLoadData) {
      setIsRulesWasChanged(false);
      setIsCustomProfanitiesWasChanges(false);
    }
  }, [isLoadData]);
  const buttonsHandlers = profanityFilterButtonsHandlers({
    rulesCopy,
    profanityFilter,
    customProfanities,
    dispatch,
    setCustomProfanities,
    setRulesCopy,
    setWasReset,
  });

  const blocks = [
    <ProfanityFilterInfo key="ProfanityFilterInfo" />,
    <ProfanityFilterRules key="ProfanityFilterRules" rules={rulesCopy} setRules={setRulesCopy} />,
    <ProfanityFilterCustomProfanities
      key="ProfanityFilterCustomProfanities"
      customProfanities={customProfanities}
      setCustomProfanities={setCustomProfanities}
    />,
    <ProfanityFilterButtons
      key="ProfanityFilterButtons"
      wasChanges={wasChanges}
      buttonsHandlers={buttonsHandlers}
      profanityFilter={profanityFilter}
      rulesCopy={rulesCopy}
      customProfanities={customProfanities}
    />,
  ];

  return (
    <Flex overflowY="auto">
      <ProfanityFilterProvider>
        <ContentWrapper blocks={blocks} />
      </ProfanityFilterProvider>
    </Flex>
  );
};
